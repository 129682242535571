import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { config } from '../../constants/Constants';

const HeaderReaderGoTo= (props) => {

    const goTo = props.littleSumm;
    const locate = useLocation();

    return(
        <div className="mhead-goto mhead-goto--reader">
            <div className="container-fluid">
                <ul className="list-inline list-inline--large mhead-goto-scroll">
                    {goTo && goTo.map((item, index) => (
                        <li className="list-inline-item" key={index}>
                            <NavLink className="mhead-goto-link " to={"/reader/" + item.idDoc}>
                                {item.text}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default HeaderReaderGoTo;