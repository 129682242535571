import { splitFisco, titleUrlSlugForTealium } from "./helper";

export const updateView = (page_name, authCtx, tokenCtx, titleSpecial, wordToSearch) => {

    let arraySplitPageName = page_name.split(":");

    let view = {
        page_name: page_name,
        page_category1: arraySplitPageName[0],
        page_category2: arraySplitPageName[1],
        page_category10: arraySplitPageName[1],
        page_content_type: titleSpecial === undefined ? page_name : titleSpecial,
        visitor_status: authCtx.isLoggedIn ? "logged-in" : "not-logged-in",
        user_status: authCtx.isLoggedIn ? tokenCtx?.userModules.length > 0 ? "abbonato" : "registrato" : "",
        user_email: authCtx.isLoggedIn ? authCtx.email : "",
        user_id: authCtx.isLoggedIn ? authCtx.username : ""
    };

    if (arraySplitPageName.length > 2) {
        view = {
            ...view,
            page_category3: arraySplitPageName[2] ?? "",
            page_category4: arraySplitPageName[3] ?? "",
        }
    }
    
    if (wordToSearch !== undefined) {
        view={
            ...view,
            search_terms: wordToSearch
        };
    }

    if (view.page_category2 === "area" && view?.page_category4 === "lista") {
        view.page_category10 = view.page_category2.concat("-").concat(view.page_category4);
    }
    if (view.page_category2 === "i-miei-libri") {
        view.page_category10 = titleSpecial.split(":")[1];
    }
    if (view.page_category2 === "reader") {
        if (titleSpecial === "book24ore:reader-anteprima") {
            view.page_category10 = "reader-anteprima";
        } else if (titleSpecial === "book24ore:reader-paid") {
            view.page_category10 = "reader-paid";
        } else if (titleSpecial === "book24ore:reader-bloccato") {
            view.page_category10 = "reader-bloccato";
        }
    }
    if (view.page_category2 === "le-mie-note" || view.page_category2 === "notifiche") {
        view.page_category10 = "area-loggato";
    }

    window.utag.view(view);
};

export const updateLink = (title, titleContainer, extraInformation) => {

    let link = {};

    if (extraInformation === "login" || extraInformation === "logout") {

        let arraySplit = titleContainer.split(":");
        link = {
            event_name: "book24ore:" + titleContainer.split(":")[0]
        };

        if (arraySplit.length > 1) {
            link = {
                ...link,
                user_email: arraySplit[1]
            }
        }

        window.utag.link(link);
        window.utag.link(window.uta_data);

        return;
    }

    const location = window.location.pathname.split("/");

    const name_page = choosePageName(location, extraInformation)

    

    if (title.title === "" && title.name === "") {
        link = {
            event_name: name_page + ":" + titleUrlSlugForTealium(titleContainer)
        };
    } else {
        link = {
            event_name: name_page + ":" + titleUrlSlugForTealium(titleContainer),
            event_type: titleUrlSlugForTealium(title.title) + (title.name === "" ? "" : ":" + title.name)
        };
    }

    window.utag.link(link);
    window.utag.link(window.uta_data);
}

const choosePageName = (namePage, extraInformation) => {

    switch (namePage[1]) {
        case "Area":
            return "book24ore:area:" + titleUrlSlugForTealium(splitFisco(namePage[2]).toLowerCase());
        case "Prodotto":
            return `book24ore:prodotto:${titleUrlSlugForTealium(extraInformation)}`;
        case "Pacchetto":
            return `book24ore:pacchetto:${titleUrlSlugForTealium(extraInformation)}`;
        case "miei-libri":
            return `book24ore:i-miei-libri`;
        case "ricerca":
            return "book24ore:ricerca:risultato";
        case "reader":
            return `book24ore:reader:${titleUrlSlugForTealium(extraInformation)}:blocco`;
        default:
            return "book24ore:home";
    }
}