import React, { useContext, useState } from "react";
import TreeNode from "../../../../../classes/TreeNode";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import useHttpRequest from "../../../../../hooks/http-request";
import { bodyFormattedSumm, castArrayForFE } from "../../../../../helper/helperFunctionGetDocument";
import { config } from "../../../../../constants/Constants";
import GetDocumentContext from "../../../../../store/get-documento";
import TokenContext from "../../../../../store/token-context";
import Link from "../../../../UI/ListLink/Link/Link";
import './CreateTreeView.css';

const CreateTreeView = (props) => {

    const [expanded, setExpanded] = useState(["0"]);
    const [selected, setSelected] = useState("0");
    const [tree, setTree] = useState(new TreeNode({
        id: "0",
        name: "",
        children: props.sommario,
        leaf: false,
        parent: "0",
        livello: 0
    })
    );

    const sendRqst = useHttpRequest();
    const getDocumento = useContext(GetDocumentContext);
    const tokenCtx = useContext(TokenContext);

    const handleChange = (event, nodeId) => {
        if (nodeId === '0') return;

        const node = tree.search(nodeId);
        if (expanded.find(el => el === nodeId && node && node.children.length > 0)) {
            const index = expanded.indexOf(nodeId);
            const newArray = [...expanded];
            newArray.splice(index, 1);
            setExpanded(newArray);
            setSelected(node.parent);
        }
        else if (!expanded.find(el => el === nodeId) && node && node.children.length > 0) {
            setSelected(nodeId);
            setExpanded([...expanded, nodeId]);
        }
        else if (node && !node.children.length) {

            const settingChildren = (data) => {
                const array = castArrayForFE(data.Result.Nodes);
                setTree(tree.addChildren(array, nodeId));
                setSelected(nodeId);
                setExpanded([...expanded, nodeId]);
            };

            const request = {
                url: config.url.BACKEND_SERVICE + config.serviceToCall.getTaxonomy,
                req: {
                    headers: {
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                        'Content-Type': 'application/json',
                        'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                    },
                    body: JSON.stringify(bodyFormattedSumm(getDocumento.indexSumm, nodeId, tokenCtx)),
                    method: "POST"
                }
            };

            sendRqst.sendRequest(request, settingChildren);
        }
    };

    const createItemFromTree = (fromTree) => {

        const name = fromTree.name;
        const classes = fromTree.livello === 1 ? 'page-subtitle' : fromTree.livello === 2 ? 'page-h3' : '';

        if (fromTree.children.length) {
            if (fromTree.livello !== 1) {
                return (
                    <React.Fragment>
                        <TreeItem 
                            key={fromTree.id} 
                            nodeId={fromTree.id} 
                            label={
                                <h3 className="reader-summary-subtitle">
                                    {name}
                                </h3>
                            } 
                            classes={{ label: classes }} 
                        >
                            <h4 className="page-h4"></h4>
                            <ul className="specificClasses list list--large page-list">
                                {fromTree.children.length > 0 &&
                                    fromTree.children.map((child) => createItemFromTree(child)
                                )}
                            </ul>
                        </TreeItem>
                    </React.Fragment>
                );
            } else {
                return (
                    <TreeItem 
                        key={fromTree.id} 
                        nodeId={fromTree.id} 
                        classes={{ label: classes }} 
                        label={
                            <li className="list-lined-item lined">
                                <div className="reader-summary-item">
                                    <a className="reader-summary-title collapsed" role={"button"} data-toggle="collapse" aria-expanded="false">
                                        {name}
                                        <span className="icon icon-angle-right"></span>
                                    </a>
                                </div>
                            </li>
                        }
                    >
                        <div className="reader-summary-wrapper">
                            {fromTree.children.length > 0 &&
                                fromTree.children.map((child) =>
                                    createItemFromTree(child)
                                )}
                        </div>
                    </TreeItem>
                );
            }
        }
        return (
            <React.Fragment>
                {
                    fromTree.leaf &&
                    <Link url={fromTree.documentId} title={fromTree.name} fromTree={fromTree} indexSumm={getDocumento.indexSumm}/>
                }
                {
                    !fromTree.leaf && 
                    <>
                        { fromTree.livello === 1 &&
                            <TreeItem 
                                key={fromTree.id} 
                                nodeId={fromTree.id} 
                                classes={{ label: classes }}
                                label={
                                    <li className="list-lined-item lined">
                                        <div className="reader-summary-item">
                                            <a className="reader-summary-title collapsed" role={"button"} data-toggle="collapse" data-target="#sommario-1" aria-expanded="false">
                                                {name}
                                                <span className="icon icon-angle-right"></span>
                                            </a>
                                        </div>
                                    </li>
                                } 
                            ></TreeItem>
                        }
                        { fromTree.livello !== 1 &&
                            <TreeItem 
                            key={fromTree.id} 
                            nodeId={fromTree.id} 
                            label={
                                <h3 className="reader-summary-subtitle">
                                    {name}
                                </h3>
                            } 
                            classes={{ label: classes }} 
                        >
                            <h4 className="page-h4"></h4>
                            <ul className="specificClasses list list--large page-list">
                                {fromTree.children.length > 0 &&
                                    fromTree.children.map((child) => createItemFromTree(child)
                                )}
                            </ul>
                        </TreeItem>
                        }
                    </>
                }
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <TreeView
                selected={selected}
                onNodeSelect={handleChange}
                expanded={expanded}
            >
                {createItemFromTree(tree)}
            </TreeView>
        </React.Fragment>
    );
};

export default CreateTreeView;