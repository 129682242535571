import React from 'react';
import { NavLink } from 'react-router-dom';
import { isEmpty } from '../../helper/helper';

const ReaderTitle = (props) => {

    const getScrollbarWidth = function () {
        document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    }

    getScrollbarWidth();

    return (
        <React.Fragment>
            <h1 className="reader-title">
                {props.title && decodeURI(props.title)}
                {props.page === 'mainReader' &&
                    <>
                        {Object.keys(props.prevChap).length > 0 &&
                            <NavLink className="reader-head-goto reader-head-goto--prev" to={`${!isEmpty(props.prevChap) ? "/reader/" + props.prevChap.idDoc : "#"}`}>
                                <span className="icon icon-arrow-left"></span>
                            </NavLink>
                        }
                        {Object.keys(props.nextChap).length > 0 &&
                            <NavLink className="reader-head-goto reader-head-goto--next" to={`${!isEmpty(props.nextChap) ? "/reader/" + props.nextChap.idDoc : "#"}`}>
                                <span className="icon icon-arrow-right"></span>
                            </NavLink>
                        }
                    </>
                }
            </h1>
            {props.getTipo !== undefined && props.getTipo.abstract !== "" &&
                <p className="reader-abstract">
                    {props.getTipo.abstract}
                </p>
            }
        </React.Fragment>
    );
}

export default ReaderTitle;