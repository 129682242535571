import React from 'react';

const NotificationsFilter = (props) => {

    const filter = props.filter;

    const checked = (ev) => {
        if(ev.target.dataset.value === undefined) return;

        const isCheckedOrNot = ev.target.dataset.value;
        props.addFilter(isCheckedOrNot);
    }
    
    return (
        <div className="col">
            <div className="page-user-item">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={filter.checked}
                        data-value={filter.dataValue}
                        name={filter.id}
                        id={filter.id}
                        onClick={checked}
                    />
                    <label className="custom-control-label" htmlFor={filter.id}>
                        {filter.label}
                    </label>
                </div>
            </div>
        </div>
    )
}

export default NotificationsFilter