import React from 'react';

const ProgressBar = () => {

    return(
        <div className="progressbar-wrapper">
            <div className="progressbar"></div>
        </div>
    );
}

export default ProgressBar;