import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import AreaAllListHeader from '../components/UI/AreaAllListHeader/AreaAllListHeader';
import LoginModal from '../components/Modals/Login';
import SessioneScaduta from '../components/Modals/SessioneScaduta';
import AuthContext from '../store/auth-context';
import PersonalAreaNotifications from '../components/PersonalArea/PersonalAreaNotifications';
import PersonalAreaProfil from '../components/PersonalArea/PersonalAreaProfil';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import TokenContext from '../store/token-context';

const PersonalArea = (props) => {

    const authCtx = useContext(AuthContext);
    const tokenCtx= useContext(TokenContext);
    const params = useParams();
    const navigate = useNavigate();
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    if (localStorage.getItem("isLoggedIn") !== "1") {
        navigate('/');
    }

    useEffect(() => {

        let clearFunct = setTimeout(() => {
            if (!authCtx.isLoggedIn) {
                navigate('/');
            }
        }, 400);

        return () => {
            clearTimeout(clearFunct);
        };

    }, [authCtx.isLoggedIn]);

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky" isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal> }
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <div className="main-content">

                <AreaAllListHeader title="Personalizza Book24ORE" isPersonalArea={true}>
                    <div className="tab">
                        <ul className="list-inline list-inline--large">
                            <li className="list-inline-item">
                                <NavLink className={(state) => "tab-link " + (state.isActive ? "is-active" : "")} to="/area-personale/profilo">
                                    Il mio profilo
                                </NavLink>
                            </li>
                            <li className="list-inline-item">
                                <NavLink className={(state) => "tab-link " + (state.isActive ? "is-active" : "")} to="/area-personale/notifiche">
                                    Le mie notifiche
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </AreaAllListHeader>
                
                { params.section === 'profilo'  && <PersonalAreaProfil/>}
                { params.section === 'notifiche' && <PersonalAreaNotifications/>}
            </div>

                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default PersonalArea;