import React from 'react';
import { config } from "../../../constants/Constants";

const ReaderNormaCommentata = (props) => {
    const url = document.location.ancestorOrigins[0] + "/private/default.aspx";
    return (
        <React.Fragment>
            { props.listNormaCommentata && props.listNormaCommentata.length !== 0 &&
            <div className="reader-box-wrapper">
            
                <div className="reader-box">
        <h5 className="reader-box-title">Norma commentata</h5>
        <ul className="list list--large">
        {props.listNormaCommentata.map(((item, index) => (
            <li className="list-item" key={item.iddocumento}>
                
                <a target="_parent" className="bdcard" href={url + "#showdoc/" + item.iddocumento + "/?ref=book24"}>
        <div className="ncard-fam ">
            <h4 className="h-meta">
            
                <span className="upper">{item.famiglia}</span>
                <span className="pipe">|</span>
                <span className="serif italic">{item.SottoFamiglia}</span>
            
            </h4>
        </div>

        

        <div className="ncard">
            
            <h3 className="ncard-title serif" dangerouslySetInnerHTML={{ __html: item.Text1 }} />

            <p className="ncard-text" dangerouslySetInnerHTML={{ __html: item.abstract }} />
            
            {/*<p className="ncard-meta ncard-meta-img">
                <image className="retina" src="https://misc.hellobnkr.it/smart24/placeholder/24x24@2x.png" alt="Prodotto" width="24" height="24"></image>
                <span className="strong">Testata</span>
                <span className="pipe">|</span>
        <time>data</time>
        </p>*/}
            
        </div>
        </a>

                
            </li>
        )))
        }
        </ul>
        </div>

            
            </div>
}
        </React.Fragment>
    );
}

export default ReaderNormaCommentata;