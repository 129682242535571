import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InAggiornamento = (props) => {

    const tooltip = (<Tooltip id="tooltip">
        Sempre aggiornato
    </Tooltip>);

    return (
        <React.Fragment>
            {(props.isUpdating === "1") &&
                /*
                <span className="">
                    <a className="tag clr bgr" role={"button"}>
                        Sempre aggiornato
                    </a>
                </span>
                */
                <span className="itool itool--small" data-toggle="tooltip" data-placement="top" title="" data-original-title="Sempre aggiornato">
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <span className="icon icon-history"></span>
                    </OverlayTrigger>
                </span>
            }
        </React.Fragment>
    );
}

export default InAggiornamento;