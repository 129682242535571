import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { config } from '../../../constants/Constants';
import { bodyFormatted } from '../../../helper/helperFunctionGetDocument';
import { formattedUpdateListForFE } from '../../../helper/helperFunctionReader';
import useHttpRequest from '../../../hooks/http-request';
import TokenContext from '../../../store/token-context';
import ReaderCard from '../ReaderCard';

const ReaderMainUpdates = (props) => {

    const [updatesList, setUpdatesList] = useState([]);

    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();

    useEffect(() => {

        const settingsUpdateList = data => {

            if (data.Result.ResultSet === null) return;

            const resultFormatted = formattedUpdateListForFE(data.Result.ResultSet.Root);

            setUpdatesList(resultFormatted);
        };

        const rqst = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted(config.nameList.getAggiornamentiReader, props.id, tokenCtx)),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqst, settingsUpdateList)

    }, [props.id]);

    return (
        <>
            {updatesList && updatesList.map((item, index) => (
                <div className="reader-uplist" key={index}>
                    <h2 className="page-subtitle text-capitalize">
                        {item.title}
                    </h2>
                    <ul className="list list--large">
                        {item.list && item.list.map((itemList, i) => (
                            <ReaderCard itemList={itemList} key={i} />
                        ))}
                    </ul>
                </div>
            ))}
        </>
    );
}

export default ReaderMainUpdates;