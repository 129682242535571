import React, { useContext, useEffect, useState } from "react";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateView } from "../../../../helper/TealiumHelper";
import AuthContext from "../../../../store/auth-context";
import GetDocumentContext from "../../../../store/get-documento";
import TokenContext from "../../../../store/token-context";
import { useParams } from 'react-router-dom';

const SchedaDescrizione = (props) => {

    const [descrizione, setDescrizione] = useState('')
    const [libroPdf, setLibroPdf] = useState('')
    const documentCtx = useContext(GetDocumentContext);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const { isBookIntegrate } = useParams();
    useEffect(() => {
        if(!isBookIntegrate) return;
            sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
      }, [isBookIntegrate]);
    useEffect(() => {
        setDescrizione(documentCtx.descrizione);
    }, [documentCtx.descrizione]);

    useEffect(() => {
        setLibroPdf(documentCtx.libroPdf);
    }, [documentCtx.libroPdf]);

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:prodotto:${titleUrlSlug(documentCtx.prodotto.title.toLowerCase())}:descrizione`, authCtx, tokenCtx, "book24ore:prodotto");

    }, [tokenCtx.token, window.utag, authCtx.isLoggedIn,])

    return (
        <React.Fragment>
            {props.isReader &&
                <>
                    <h3 className="reader-txt-title">
                        {props.title}
                    </h3>
                    <p className="reader-txt" dangerouslySetInnerHTML={{ __html: descrizione }} />
                </>
            }
            {!props.isReader &&
                <div className="page-txt">
                    <div dangerouslySetInnerHTML={{ __html: descrizione }} />
                    {libroPdf != 0 &&     
                        <>      
                            <br />   
                            <p><b>Versione Pdf</b></p>      
                            <p>
                            <i>Accedendo ai contenuti dal pulsante ‘leggi’ o dal sommario del testo, approderai alla versione e-book del volume, che consente di navigare tra i capitoli, visualizzare gli ultimi aggiornamenti - se disponibili - stampare o salvare il capitolo, riprendere la lettura dal punto in era stata interrotta nella sessione precedente e molte altre funzionalità.

                            Se invece vuoi leggere la versione pdf, che replica l’edizione a stampa del volume senza aggiornamenti successivi e senza la possibilità di stampare o salvare il contenuto, <a className="page-link" href={"/reader/" + libroPdf}>clicca qui</a>. </i>
                            </p>   
                        </>
                    }
                </div>                
            }
            
        </React.Fragment>
    );
}

export default SchedaDescrizione;