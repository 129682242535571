import React from 'react';

const Bookmark = () => {

    return(
        <React.Fragment>
            <span className="icon icon-bookmark-o card-bookmark"></span>
        </React.Fragment>
    );
}

export default Bookmark;