import React from 'react'
import ExplorerDocItem from './ExplorerDocItem';
import ExplorerFolderItem from './ExplorerFolderItem';

const BDExplorerList = (props) => {

    //const docs = props.levels?.filter((level) => level.Leaf );
    const folders = props.levels?.filter((level) => !(level.Leaf) );

    return (
        <ul className="list list--large">

            {props.documentsByLeaf && props.documentsByLeaf.map((doc, i) => (
                <ExplorerDocItem doc={doc} key={Math.random()}/> 
            ))}

            {/*folders && !props.documentsByLeaf && folders.map((folder, i) => (
                <ExplorerFolderItem folder={folder} key={Math.random()}/>
            ))*/}

        </ul>
    )
}

export default BDExplorerList