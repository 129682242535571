import React, { useContext } from 'react'
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import AreaAllListHeader from '../components/UI/AreaAllListHeader/AreaAllListHeader';
import LoginModal from '../components/Modals/Login';
import AuthContext from '../store/auth-context';
import NotificationsList from '../components/Header/UserAction/Notifiche/NotificationsList';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import TokenContext from '../store/token-context';
import useHttpRequest from '../hooks/http-request';
import { useState } from 'react';
import { config } from '../constants/Constants';
import { useEffect } from 'react';
import { bodyFormattedNotificationGet, notificationListForFe } from '../helper/helper';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { updateView } from '../helper/TealiumHelper';
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const Notifications = (props) => {

    const authCtx = useContext(AuthContext);
    const [notificationList, setNotificationList] = useState([]);

    const sendRequest = useHttpRequest();
    const tokenCtx = useContext(TokenContext);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {
        if (tokenCtx.token === null || tokenCtx.token === undefined) return;

        const settingsTotNotification = (data) => {

            const notificationListFe = notificationListForFe(data.Result);

            if (notificationListFe.length > 0) {
                setNotificationList(notificationListFe);
            }
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getNotification,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedNotificationGet(tokenCtx.token, new Date())),
                method: 'POST'
            }
        };

        sendRequest.sendRequest(request, settingsTotNotification);
    }, []);

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView("book24ore:notifiche",authCtx, tokenCtx, "book24ore:area-loggato");
                
    },[tokenCtx.token, authCtx.isLoggedIn, window.utag])


    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky" isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal> }
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <div className="main-content">
                    <AreaAllListHeader title="Notifiche"/>
                    <section className="section">
                        <div className="container">
                        <section className="section ">
                            <div className="container">
                                <div className="card-list">
                                    <NotificationsList isLarge={true} notificationList={notificationList}></NotificationsList>
                                </div>
                                { /* Pagination component goes in here */}
                            </div>
                        </section>
                        </div>
                    </section>
                </div>
                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default Notifications;