import React from 'react'
import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { convertThemeAreaForLink } from '../../helper/castingObject';
import GetDocumentContext from '../../store/get-documento';

const ExplorerDocItem = ({ doc }) => {

    const { title } = useParams();
    const documentCtx = useContext(GetDocumentContext);

    const urlNtPro = convertThemeAreaForLink(documentCtx.prodotto.areaTematicaList[0].titleArea);
    let indice = "";

    return (
        <li className="list-item">
            <NavLink
                className="bdcard"
                /*target={"_blank"}*/
                to={`/ntPro/${title}/${urlNtPro}/${doc.idDoc}`}>
                {(doc.family || doc.subfamily) &&
                    <div className="ncard-fam ">
                        <h4 className="h-meta">
                            <div dangerouslySetInnerHTML={{ __html: doc.upTitle }} />
                            {/*doc.family && <span className="upper">Famiglia</span>}
                            {doc.family && doc.subfamily && <span className="pipe">|</span>}
                {doc.subfamily && <span className="serif italic">Sottofamiglia</span>*/}
                        </h4>
                    </div>
                }
                <div className="ncard">
                    {doc.object &&
                        <h3 className="ncard-title serif">
                            <div dangerouslySetInnerHTML={{ __html: doc.object }} />
                        </h3>
                    }
                    {/*<p className="ncard-text">
                        <div dangerouslySetInnerHTML={{ __html: doc.text }} />
                </p>*/}
                    {(doc.family || doc.subFamily) &&
                        <p className="ncard-meta ncard-meta-img">
                            <img className="retina" src="https://misc.hellobnkr.it/smart24/placeholder/24x24@2x.png" alt="Prodotto" width="24" height="24" />
                            {doc.family && <span className="strong">{doc.family}</span>}
                            {(doc.family && doc.subFamily) && <span className="pipe">|</span>}
                            {doc.subFamily && <time>{doc.subFamily}</time>}
                        </p>
                    }
                </div>
            </NavLink>
        </li>
    )
}

export default ExplorerDocItem