import React, { useContext, useEffect, useState, useParams } from "react";
import { NavLink } from 'react-router-dom';

const ReaderHeadSwitchDocPerApprofondire = (props) => {

    const idDoc = props.id;
    const showApprofondire = props.showPerApprofondire

    return (
        <>
            <div className="tab">
            <ul className="list-inline list-inline--large">
                <li className="list-inline-item">
                <NavLink className={"tab-link " + (!showApprofondire ? "is-active" : "")} id="Documento" to={"/reader/" + idDoc} >
                Documento
                </NavLink>
                </li>
                <li className="list-inline-item">
                <NavLink className={"tab-link " + (showApprofondire ? "is-active" : "")} id="Approfondire" to={"/reader/" + idDoc + "/SchedaPerApprofondire"} >
                Per approfondire
                </NavLink>
                </li>
            </ul>
            </div>
        </>
    );
}

export default ReaderHeadSwitchDocPerApprofondire;