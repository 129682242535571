import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ResearchError from '../ResearchError/ResearchError';
import SearchEmpty from '../SearchEmpty/SearchEmpty';
import SearchResult from './SearchResult';

const SearchResults = (props) => {

    const { isFromAnotherPage } = useParams();

    const [isActive, setIsActive] = useState(isFromAnotherPage === "daMieiLibri" ? 0 : 1);

    const addOrRemoveFilter = (ev) => {
        const newFilter = ev.target.getAttribute('value') === '0' ? ['D', 'data desc', 0] : ['R', 'score desc', 1];

        setIsActive(newFilter[2]);

        props.updateResearchList(newFilter);
    };

    const searchError = props?.resultResearch === null || props.resultResearch?.length === 0 || props.testo === '';

    return (
        <React.Fragment>
            <div className="search-results">
                {isFromAnotherPage !== 'daMieiLibri' &&
                    <div className="search-top">
                        <div className={`switch ${searchError ? 'is-disabled' : ''}`}>
                            <a className={`switch-left ${isActive === 1 ? "active" : ""}`} role="button" value='1' onClick={addOrRemoveFilter}>Più rilevante</a>
                            <span className="switch-slider"></span>
                            <a className={`switch-right ${isActive === 0 ? "active" : ""}`} role="button" value='0' onClick={addOrRemoveFilter}>Più recente</a>
                        </div>
                    </div>
                }
                <>
                    {((props.resultResearch === null || props.resultResearch === undefined) && isFromAnotherPage !== "daMieiLibri") &&
                        <SearchEmpty></SearchEmpty>
                    }
                    {(props.resultResearch !== null) &&
                        <>
                            {props.resultResearch.length === 0 && <ResearchError testo={props.testo}></ResearchError>}
                            {props.resultResearch.length > 0 &&

                                <ul className="list list--large">
                                    {props.resultResearch && props.resultResearch.map((prodotto, i) => (
                                        <SearchResult
                                            prodotto={prodotto}
                                            resultBuyOrNot={props.resultBuyOrNot}
                                            isFromAnotherPage={props.isFromAnotherPage}
                                            resultId={i}
                                            key={i}
                                            testo={props.testo}
                                        />
                                    ))}
                                </ul>
                            }
                        </>
                    }
                </>
            </div>
        </React.Fragment>
    );
}

export default SearchResults;

