import React from "react";

const QrCodeWrapper = (props) => {

    return (
        <React.Fragment>
            <div className="main-content ">
                <div className="qrpage">
                    <div className="container">

                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default QrCodeWrapper;