import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AreaComponent from '../components/Area/AreaComponent';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import ListAreaTematica from '../components/Prodotti/ThemeArea/ListAreaTematica/ListAreaTematica';
import { splitFisco, titleUrlSlug } from '../helper/helper';
import { updateView } from '../helper/TealiumHelper';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import TokenContext from '../store/token-context';
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const Area = (props) => {

    let {themeArea} = useParams();

    const [tArea, setTArea] = useState(themeArea);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    
    useEffect(() => {
        setTArea(themeArea);
        window.scrollTo(0, 0);
    },[themeArea]);

    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:area:${titleUrlSlug(splitFisco(themeArea).toLowerCase())}:home`,authCtx, tokenCtx);
                
    },[tokenCtx.token, , authCtx.isLoggedIn, window.utag])

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky"  isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal> }
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <AreaComponent themeArea={tArea}></AreaComponent>
                <ListAreaTematica />
                <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    );

}

export default Area;