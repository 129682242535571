import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Book24Link from '../../UI/Book24Link/Book24Link';

const HeaderSecondRow = () => {

    const [wordToSearch, setWordToSearch] = useState('');

    const navigate = useNavigate();

    const searchHandler = (ev) => {

        if (ev.code === 'Enter') goToResearch(ev);

        setWordToSearch(ev.target.value);
    }
    const deleteLocalStorage = () => {
        localStorage.removeItem("filters");
        localStorage.removeItem("revOrRec");
        localStorage.removeItem("isUpdated");
        localStorage.removeItem("year");
        localStorage.removeItem("search category");
    }
    const goToResearch = (ev) => {
        ev.preventDefault();
        deleteLocalStorage();

        const newWord = wordToSearch.trim();

        if (newWord !== undefined) {
            localStorage.setItem('word', newWord);
            navigate(`/ricerca/${1}`);
        }
    }

    return (
        <React.Fragment>
            <div className="mhead-main">
                <div className="container">
                    <div className="mhead-wrapper">
                        <div className="mhead-logo">
                            <Book24Link/>
                        </div>
                        <div className="mhead-search">
                            <div className="search-input">
                                <input className="input input--round input--shadow" type="text" placeholder="Cerca in Book24ORE" onKeyUp={searchHandler}/>
                                <button className="search-input-submit" type="submit" onClick={goToResearch}>
                                    <span className="icon icon-search"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderSecondRow;