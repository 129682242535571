import { useContext } from "react";
import { useEffect, useCallback, useState } from "react";
//import { Platform } from 'react-native';
import { beginTagInString, endTagInString, getTagHtml } from "../helper/helperFunctionReader";
import AuthContext from "../store/auth-context";

const useContextMenu = () => {

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [textToEdit, setTextToEdit] = useState('');
  const [textCompleteToEdit, setTextCompleteToEdit] = useState('');
  const [range, setRange] = useState({ start: 0, end: 0 });
  const [id, setId] = useState(null);
  const [textToCopy, setTextToCopy] = useState("");
  const [disable, setDisable] = useState(false);

  const authCtx = useContext(AuthContext);

  const handleContextMenu = useCallback(
    (event) => {
      //event.preventDefault();

      window.setTimeout(() => {
        const select = window.getSelection();
        let idNote = null;

        if (select.isCollapsed && event.target.getAttribute("name") !== "buttonEdit") return;
        
        if (event.pageX !== undefined && event.pageY !== undefined) {
          setAnchorPoint({ x: event.pageX, y: event.pageY });
        }
        else {
          setAnchorPoint({ x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY });
        }

        if (event.target.getAttribute("name") === "buttonEdit") {
          idNote = event.target.id;
          //setShow(true);
          setId(idNote);
          //return;
        }

        setTextToEdit(select.toString());
        let output = select.toString();
        if (select.anchorNode && (select.anchorNode == select.extentNode)) {
          if (select.toString() == select.anchorNode.textContent) {
            output = select.anchorNode.parentElement.outerHTML
          }
        } 
        setTextToCopy(output);
        let html = getSelectionHtml();
        //is smartphone && isspan
        if(getMobileOperatingSystem() && event.target.localName === "span"){ 
          setShow(true);
        }else if(!getMobileOperatingSystem() && event.target.localName === "button"){
          setShow(true);
        }else if(event.target.localName === "i" || event.target.localName === "p" || event.target.localName === "span"){
          setShow(true);
        }else{
          setShow(false);
        }
        let containNote = html.html.includes("reader-box-title") || html.html.includes("note-id-exclude");
        if (html.html.length > 16000 || containNote){
          setDisable(true);
          return;
        } 
        let editedText = document.getElementById("textComplete").innerHTML;
        if(document.getElementById("noteComplete")) editedText = editedText.concat(document.getElementById("noteComplete").innerHTML);
        const start = findStart(editedText, html);
        if(document.getElementById("textComplete").innerHTML.length < start.start || document.getElementById("textComplete").innerHTML.length < start.end){
          setDisable(true);
          return;
        }
        const newTextCompleteToEdit = editedText.substring(start.start, start.end);
        setTextCompleteToEdit(html.ancestorWithParagraph.innerHTML.trim());
        setTextToEdit(newTextCompleteToEdit);
        setRange({ start: start.start, end: start.end })
        setDisable(false);
      }, 0)
    },
    [setShow, setAnchorPoint]
  );

  const tryToFindStart = (editedText, html) => {

    let indx = {
      start: editedText.indexOf(html.html),
      end: editedText.indexOf(html.html) + html.html.length
    };

    let newHtml = html.html;

    while (indx.start === -1) {

      let beginHtmlTag = beginTagInString(newHtml);

      if (beginHtmlTag !== "") {

        beginHtmlTag = getTagHtml(newHtml, 0);

        let withoutInitialTag = newHtml.substring(beginHtmlTag.length, newHtml.length);

        indx.start = editedText.indexOf(withoutInitialTag);
        indx.end = indx.start + withoutInitialTag.length;

        if (indx.start !== -1) return indx;

      }

      let endHtmlTag = endTagInString(newHtml);

      if (endHtmlTag !== "") {

        endHtmlTag = "</".concat(endHtmlTag).concat(">");

        let withoutEndingTag = newHtml.substring(0, (newHtml.length - endHtmlTag.length));

        indx.start = editedText.indexOf(withoutEndingTag);
        indx.end = indx.start + withoutEndingTag.length;

        if (indx.start !== -1) return indx;

      }

      if (indx.start === -1 && beginHtmlTag !== "" && endHtmlTag !== "") {

        let withoutBeginAndEndingTag = newHtml.substring(beginHtmlTag.length, (newHtml.length - endHtmlTag.length));

        indx.start = editedText.indexOf(withoutBeginAndEndingTag);
        indx.end = indx.start + withoutBeginAndEndingTag.length;
      }

      newHtml = newHtml.substring(0 + beginHtmlTag.length, (newHtml.length - endHtmlTag.length));
    }

    return indx;
  }

  const findStart = (editedText, html) => {

    let startIndex = tryToFindStart(editedText, html);

    if (startIndex.start !== -1) {
      return startIndex;
    }

    let lengthString = html.html.length / 2;
    startIndex = editedText.indexOf(html.html.substring(0, lengthString));

    for (let i = 0; i < (startIndex + html.ancestor.length); i++) {
      if (editedText[startIndex + i] !== html.html[i]) {
        return {
          start: startIndex,
          end: startIndex + i
        };
      }
    }
  }

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  const getSelectionHtml = () => {
    let html = "";
    if (typeof window.getSelection != "undefined") {
      let sel = window.getSelection();
      if (sel.rangeCount) {
        let container = document.createElement("div");
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
          html = {
            html: container.innerHTML,
            ancestor: sel.getRangeAt(0).commonAncestorContainer.innerHTML === undefined ? sel.getRangeAt(0).commonAncestorContainer.textContent : sel.getRangeAt(0).commonAncestorContainer.innerHTML,
            ancestorWithParagraph: sel.baseNode.parentElement
          }
        }
      }
    } else if (typeof document.selection != "undefined") {
      if (document.selection.type == "Text") {
        html = document.selection.createRange().htmlText;
      }
    }
    return html;
  }

  const cleanFunction = () => {
    setAnchorPoint({ x: 0, y: 0 });
    setShow(false);
    setTextToEdit('');
    setTextCompleteToEdit('');
    setRange({ start: 0, end: 0 });
    setId(null);
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  }

  useEffect(() => {

    if (!authCtx.isLoggedIn) return;

    let element = document.getElementById('readerMain');
    let elementEdit = document.getElementsByClassName('icon icon-edit');

    const isMobile = getMobileOperatingSystem();

    for (let i = 0; i < elementEdit.length; i++) {
      elementEdit[i].addEventListener("touchstart", handleContextMenu);
      elementEdit[i].addEventListener("click", handleContextMenu);
    };
    if(!element) return
    element.addEventListener("touchend", handleContextMenu);
    element.addEventListener("touchstart", handleContextMenu);
    element.addEventListener("mouseup", handleClick);
    element.addEventListener("click", handleContextMenu);

    return () => {
      for (let i = 0; i < elementEdit.length; i++) {
        elementEdit[i].removeEventListener("click", handleContextMenu);
        elementEdit[i].removeEventListener("touchstart", handleContextMenu);
      }
      element.removeEventListener("touchend", handleContextMenu);
      element.removeEventListener("touchstart", handleContextMenu);
      element.removeEventListener("mouseup", handleClick);
      element.removeEventListener("click", handleContextMenu);
    };
  });

  return { anchorPoint, show, textToEdit, textCompleteToEdit, range, handleClick, id, cleanFunction, textToCopy, disable, getMobileOperatingSystem };
};

export default useContextMenu;