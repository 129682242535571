import { config } from '../constants/Constants';
import * as urlSlug from 'url-slug'
import { convertThemeArea } from './castingObject';


export function castForLogin(bodyToCast) {

    var formBody = [];

    for (var property in bodyToCast) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(bodyToCast[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    return formBody.join("&");
}

export const createCookieForDocument = (name, value, days, domain) => {

    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    domain = domain || "";
    if (domain) {
        domain = "; domain=" + domain;
    }

    document.cookie = name + "=" + value + expires + "; path=/" + domain;
}

export const removeCookieSoleAuth = (name, value, days, domain) => {

    if (getCookie(name) !== null) {
        document.cookie = name + "=" + value + "; expires=Session" + "; path=/" + "; Domain=" + domain;
    }
}

export const removeCookie = (name, value, domain) => {

    if (getCookie(name) !== null) {
        document.cookie = name.concat("=").concat(value).concat("; expires=Session").concat("; path=/").concat("; Domain=").concat(domain);
    }
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
};

export const settingArrayParamsWithAut = (documenti) => {


    if (documenti === undefined) return [];

    let inputParameters = {
        provvedimenti: '',
        iddoctype: '',
    };

    documenti.idProv.forEach((element, index) => {

        inputParameters.provvedimenti = inputParameters.provvedimenti.concat(element.idProv);

        if (index !== documenti.idProv.length - 1)
            inputParameters.provvedimenti = inputParameters.provvedimenti.concat(',');
    });

    documenti.idDoctype.forEach((element, index) => {

        inputParameters.iddoctype = inputParameters.iddoctype.concat(element.idDcTyp);

        if (index !== documenti.idDoctype.length - 1)
            inputParameters.iddoctype = inputParameters.iddoctype.concat(',');
    });


    return [
        {
            name: "provvedimenti",
            type: "VarChar",
            value: inputParameters.provvedimenti
        },
        {
            name: "iddoctype",
            type: "VarChar",
            value: inputParameters.iddoctype
        }];
}

export const settingArrayParams = (documenti, areaTematica, rows) => {

    if (documenti === undefined && areaTematica === undefined && rows === undefined) {
        return [];
    }

    let inputParameters = '';

    if (documenti !== undefined) {
        documenti.forEach(element => {
            inputParameters = inputParameters.concat(element.idDocumento + ',');
        });
    }

    let arrayInputParam = areaTematica === undefined ? [] : [{
        name: 'area',
        type: 'VarChar',
        value: areaTematica
    }];

    const documentiToSend = inputParameters !== '' ? {
        name: 'documenti',
        type: 'VarChar',
        value: inputParameters
    } : null;

    if (documentiToSend !== null) {
        arrayInputParam = [...arrayInputParam, documentiToSend];
    }

    if (rows !== undefined) {
        arrayInputParam.push({
            name: 'rows',
            type: 'Int',
            value: rows
        });
    }

    return arrayInputParam;
}

export const createImgLink = (imgNumber) => {

    if (imgNumber === "" || imgNumber === 0 || imgNumber === undefined || imgNumber === null) return "";

    const link = config.imgLink;

    const stringToArray = imgNumber.split('');

    if (stringToArray[0] === "/")
        return imgNumber;

    return link.concat(stringToArray[stringToArray.length - 1]).concat("/").concat(imgNumber).concat(".JPG");

};

export const checkSoleaAuth = () => {
    return document.cookie.split('; ')
        .find((row) => row.startsWith('SoleAuth='))
        ?.split('=')[1];
};

export const splitFisco = (area) => {
    return area === "FiscoEImprese" ? "Fisco e Imprese" : area;
};

export const filterFisco = (area) => {
    return area === "Fisco e Imprese" || area === "FiscoEImprese" ? "Fisco" : area;
};

export const scrollTo = () => {
    window.scrollTo(0, 0);
}

export const createUrlPdf = (pdfNumber) => {

    if (pdfNumber === "") return "";

    const link = config.imgLink;

    const stringToArray = pdfNumber.split('');

    if (stringToArray[0] === "/")
        return pdfNumber;

    return link.concat(stringToArray[stringToArray.length - 1]).concat("/").concat(pdfNumber).concat(".pdf");
};

export const addSubjects = (filters) => {

    let subjectsString = '';
    filters.forEach((el, index) => {
        subjectsString = subjectsString.concat('"').concat(config.appId).concat('\\').concat(el).concat('"');
        if (index < (filters.length - 1))
            subjectsString = subjectsString.concat(" OR ");
    })

    return subjectsString;
}

export const mustToken = (name) => {

    switch (name) {
        case (config.nameList.continuaALegg):
            return true;
        case (config.nameList.giaLetti):
            return true;
        case (config.nameList.tipoDocument):
            return true;
        case (config.nameList.getLittleSumm):
            return true;
        case (config.nameList.getTestataHtml):
            return true;
        case (config.nameList.daLeggere):
            return true;
        case ('banche dati'):
            return true;
        default:
            return false;
    }
}
export const castDate = (date) => {

    const year = date !== undefined && date !== null ? new Date(date).getFullYear() : 1900;

    if (year === 1900) return "";

    let options = { 'day': '2-digit', 'month': 'long', 'year': 'numeric' };
    let dateFormat = new Date(date).toLocaleString('it-IT', options);
    return dateFormat;
}

export const getYearAndMonth = (date) => {

    const month = getMonth(date);

    return month.concat(" ").concat(date.split("-")[0]);
}

export const getMonth = (date) => {

    let options = { 'month': 'long' };
    let dateFormat = new Date(date).toLocaleString('it-IT', options);

    return dateFormat;
}

export const getDateNow = () => {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;

    return formattedToday;
}

export const userNotHaveToken = (error) => {

    if (error === null || error === undefined) return true;

    const arrayError = error.split(",");

    if (arrayError.length === 0) {
        return false;
    }

    const errorCode = arrayError[0].split(" ")[2];

    if (errorCode === '1010') return true;

    return false;
}

export const themeAreaDescription = (themeArea) => {
    switch (themeArea) {
        case "Lavoro":
            return "Aggiornati con tutte le ultime novità normative, gli approfondimenti dottrinali più autorevoli e le soluzioni operative, i volumi coprono le principali tematiche giuslavoristiche.";
        case "Diritto":
            return "Aggiornati con tutte le ultime novità normative, giurisprudenziali e gli approfondimenti dottrinali più autorevoli. I manuali sono stati realizzati sulle principali specializzazioni legali.";
        case "Tecnici":
            return "Aggiornati con tutte le ultime novità normative e giurisprudenziali e gli approfondimenti dei nostri Esperti con un approccio pratico-operativo; i volumi analizzano le principali tematiche di interesse per i professionisti tecnici";
        case "PA":
            return "Aggiornati con tutte le ultime novità normative e giurisprudenziali e gli approfondimenti dei nostri Esperti con un approccio pratico-operativo; i volumi analizzano le principali tematiche di interesse per la pubblica amministrazione";
        default:
            return "Aggiornati con tutte le ultime novità normative, gli approfondimenti dottrinali più autorevoli e le applicazioni operative, i volumi coprono le principali tematiche fiscali.";
    }
}

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export const formattedNoteArrayForFE = (arrayNotes) => {

    let newArrayFormatted = [];
    arrayNotes.forEach(el => {

        let newObj = {
            idprovvedimento: el.data.idprovvedimento,
            title: el.data.bookTitle,
            subTitleList: [{
                idDocumento: el.data.iddocumento,
                subTitle: el.data.documentTitle,
                noteList: arrayFormatted(el.data.note)
            }]
        };

        let newIndx = newArrayFormatted.map(e => e.idprovvedimento).indexOf(el.data.idprovvedimento);
        if (newIndx !== -1) {
            newArrayFormatted[newIndx].subTitleList.push(newObj.subTitleList[0]);
        } else {
            newArrayFormatted.push(newObj);
        }

    });

    return newArrayFormatted;
}

const arrayFormatted = (data) => {

    let arrayNote = [];

    data.forEach(el => {
        arrayNote.push({
            idNote: el.idNote,
            color: "note noted--" + el.color[0],
            text: el.underlinedText,
            note: el.text,
            tags: el.tag,
            date: el.date
        });
    });

    return arrayNote;
}

export const filterNoteByColor = (notFilteredNotes, colorFilters) => {

    let newFilteredArray = [];

    notFilteredNotes.forEach(totNote => {

        let subTitleList = [];
        totNote.subTitleList.forEach(subNote => {
            let allNoteColored = [];

            subNote.noteList.forEach(nota => {

                colorFilters.forEach(color => {
                    if (nota.color[nota.color.length - 1] === color[0])
                        allNoteColored.push(nota);
                })

            });

            if (allNoteColored.length > 0) {
                let newObj = { ...subNote };
                newObj.noteList = allNoteColored;
                subTitleList.push(newObj);
            }

        });

        if (subTitleList.length > 0) {
            let newObj = { ...totNote }
            newObj.subTitleList = subTitleList;
            newFilteredArray.push(newObj);
        }
    });

    return newFilteredArray;
}

export const filterNoteByTag = (notFilteredNotes, tagFilters) => {

    let newFilteredArray = [];

    notFilteredNotes.forEach(totNote => {

        let subTitleList = [];
        totNote.subTitleList.forEach(subNote => {
            let allNoteWithTag = [];

            subNote.noteList.forEach(nota => {

                tagFilters.forEach(tag => {
                    let index = nota.tags.indexOf(tag);
                    if (index !== -1) {
                        index = allNoteWithTag.map(val => val.idNote).indexOf(nota.idNote);
                        if (index === -1)
                            allNoteWithTag.push(nota);
                    }
                })

            });

            if (allNoteWithTag.length > 0) {
                let newObj = { ...subNote };
                newObj.noteList = allNoteWithTag;
                subTitleList.push(newObj);
            }

        });

        if (subTitleList.length > 0) {
            let newObj = { ...totNote }
            newObj.subTitleList = subTitleList;
            newFilteredArray.push(newObj);
        }
    });

    return newFilteredArray;
}

export const filterByBook = (notFilteredNotes, booksFilters) => {

    let newFilteredArray = [];

    booksFilters.forEach(el => {
        let indx = notFilteredNotes.map(val => val.idprovvedimento).indexOf(parseInt(el));
        if (indx !== -1)
            newFilteredArray.push(notFilteredNotes[indx]);
        /*newFilteredArray = notFilteredNotes.filter(val => {
            return val.idprovvedimento === parseInt(el)
        });*/
    });

    return newFilteredArray;
}

export const filterByWord = (notFilteredNotes, wordFilter) => {

    let newFilteredArray = [];

    notFilteredNotes.forEach(totNote => {

        let subTitleList = [];
        totNote.subTitleList.forEach(subNote => {
            let allNoteWithWord = [];

            subNote.noteList.forEach(nota => {

                if (nota.note === "") return;

                if (nota.note.includes(wordFilter))
                    allNoteWithWord.push(nota);
            });

            if (allNoteWithWord.length > 0) {
                let newObj = { ...subNote };
                newObj.noteList = allNoteWithWord;
                subTitleList.push(newObj);
            }

        });

        if (subTitleList.length > 0) {
            let newObj = { ...totNote }
            newObj.subTitleList = subTitleList;
            newFilteredArray.push(newObj);
        }
    });

    return newFilteredArray;


}

export const isChanged = (newFilteredArray, resultsNotes) => {

    if (newFilteredArray.length !== resultsNotes.length) return true;

    let isChanged = false;

    newFilteredArray.forEach(el => {
        let index = resultsNotes.map(element => element.idprovvedimento).indexOf(el.idprovvedimento);

        if (index === -1 || el.subTitleList.length !== resultsNotes[index].subTitleList.length || isChanged) {
            isChanged = true;
            return;
        }

        el.subTitleList.forEach(documents => {

            /*let indexDocument = resultsNotes.map(element => {
                if (element.subTitleList.length > index)
                    return element.subTitleList[index].idDocumento;
                return -1;
            }).indexOf(documents.idDocumento);*/

            let indexDocument = resultsNotes.map(element => element.subTitleList[index]?.idDocumento).indexOf(documents.idDocumento);

            if (indexDocument === -1 || documents.noteList.length !== resultsNotes[index].subTitleList[indexDocument].noteList.length || isChanged) {
                isChanged = true;
                return;
            }

            documents.noteList.forEach(nota => {

                if (resultsNotes[index].subTitleList[indexDocument].noteList.map(element => element.idNote).indexOf(nota.idNote) === -1) {
                    isChanged = true;
                    return;
                }

            });
        })
    });

    return isChanged;
}

export const titleUrlSlug = (title) => {

    return urlSlug.convert(title, {
        separator: "-",
        transformer: false
    });
}

export const titleUrlSlugForTealium = (title) => {

    let titleFormatted = title.split(" ");

    const newTitleFormatted = () => {

        let newTitle = "";
        titleFormatted.forEach((el, indx) => {
            if (el === "-")
                return;

            newTitle = newTitle.concat(el.replaceAll(",", "").replaceAll(".", "").replaceAll(":", "").replaceAll(";", ""));

            if (indx !== titleFormatted.length - 1)
                newTitle = newTitle.concat("-");

        });

        return newTitle.toLowerCase();
    };

    return newTitleFormatted();
}

export const changeCssClass = () => {

    let element = document.getElementsByClassName("ltool ltool--circle share-multi")[0];
    element.classList.remove("ltool--circle");
    element.classList.remove("ltool");
    element.classList.add("itool")

    element = document.getElementsByClassName("icon icon-google icon--multi")[0];
    element.classList.add("icon--social");

    element = document.getElementsByClassName("ltool ltool--circle share-fb")[0];
    element.classList.remove("ltool");
    element.classList.remove("ltool--circle");
    element.classList.add("itool");

    element = document.getElementsByClassName("icon icon-facebook")[0];
    element.classList.add("icon--social");

    element = document.getElementsByClassName("ltool ltool--circle share-tw")[0];
    element.classList.remove("ltool");
    element.classList.remove("ltool--circle");
    element.classList.add("itool");

    element = document.getElementsByClassName("icon icon-twitter")[0];
    element.classList.add("icon--social");

    element = document.getElementsByClassName("ltool ltool--circle share-in")[0];
    element.classList.remove("ltool");
    element.classList.remove("ltool--circle");
    element.classList.add("itool");

    element = document.getElementsByClassName("icon icon-linkedin")[0];
    element.classList.add("icon--social");
}

export const getArea = (el) => {
    switch (el) {
        case "Fisco":
            return 0;
        case "Diritto":
            return 1;
        case "Lavoro":
            return 2;
        case "PA":
            return 3;
        case "Tecnici":
            return 4;
        default:
            return -1;
    }
}

export const createInputParameters = (facet) => {

    let stringOfPath = "";

    if (facet === undefined || facet === null) return;

    facet.forEach((el, indx) => {
        stringOfPath = stringOfPath.concat(el.path);
        if (indx < facet.length - 1)
            stringOfPath = stringOfPath.concat(",");
    });

    return [{
        name: 'provbiblio',
        type: 'VarChar',
        value: stringOfPath
    }];
}

export const createArrayInputParamsArchive = (filters, wordToSearch, revOrRec, onlyMyBook, isUpdated, year) => {

    let inputParams = [];


    let filtersArray = [
        {
            name: "area_fisco",
            type: "Int",
            value: 0
        },
        {
            name: "area_diritto",
            type: "Int",
            value: 0
        },
        {
            name: "area_lavoro",
            type: "Int",
            value: 0
        },
        {
            name: "area_pa",
            type: "Int",
            value: 0
        },
        {
            name: "area_tecnici",
            type: "Int",
            value: 0
        },
    ];

    if (filters !== undefined && filters !== null) {
        filters.forEach(el => {
            const indx = getArea(el);
            filtersArray[indx].value = 1;
        });
    }

    inputParams.push(...filtersArray);
    if (isUpdated !== undefined)
        inputParams.push({
            name: 'aggiornamento',
            type: 'Int',
            value: isUpdated ? 1 : 0
        });

    if (year !== undefined)
        inputParams.push({
            name: 'anno',
            type: 'Int',
            value: (year === "tutti" || year === null) ? 0 : parseInt(year)
        });

    return inputParams;
}

export const convertArrayForFEArchive = (data) => {

    let arrayToReturn = [];

    data.forEach(el => {

        arrayToReturn.push({
            id: el.IdDocumento,
            idProv: el.idProvvedimento,
            isNew: el?.novita && el.novita === '1' ? true : false,
            isUpdating: el.aggiornamento === '1' ? true : false,
            updated: el.data,
            title: el.titolo,
            auth: el.autore,
            desc: el.abstract,
            url: '',
            img: el.immagine === '' || el.immagine === undefined || el.immagine === null ? '' : createImgLink(el.immagine),
            themeArea: convertThemeArea(el.area_tematica),
            documents: [],
            isFrizzera: el.famiglia === 'Sistema Frizzera' ? 1 : 0,
            edit: el.editore,
            dataDocument: el.data
        });

    })

    return arrayToReturn;
}

export const bodyFormattedNotification = (token, nowTime) => {

    //const date = nowTime.getFullYear().concat("-" + nowTime.getMonth().ToString()).concat("-" + nowTime.getDay().ToString()).concat("T" + nowTime.getHours().ToString()).concat(":" + nowTime.getMinutes().ToString()).concat("Z");
    const date = nowTime.toISOString();

    return {
        "useDefaultFilter": false,
        "query": {
            "$and": [
                {
                    "data_inizio_pubblicazione": {
                        "$lte": {
                            "$date": date.toString()
                        }
                    }
                },
                {
                    "data_fine_pubblicazione": {
                        "$gte": {
                            "$date": date.toString()
                        }
                    }
                },
                {
                    "stato": 0
                }
            ]
        },
        "token": token,
        "encryptParams": null
    }
}

export const bodyFormattedNotificationGet = (token, nowTime) => {

    const date = nowTime.toISOString();

    return {
        "parameters": {
            "appId": config.appId,
            "types": config.types,
            "query": {
                "$and": [
                    {
                        "data_inizio_pubblicazione": {
                            "$lte": {
                                "$date": date
                            }
                        }
                    },
                    {
                        "data_fine_pubblicazione": {
                            "$gte": {
                                "$date": date
                            }
                        }
                    },
                    {
                        "stato": 0
                    }
                ]
            }
        },
        "token": token,
        "encryptParams": null
    };
}

export const bodyFormattedNotificationUpdateState = (token, idDocument, alert) => {

    return {
        "query": {
            "$and": [
                {
                    "data.properties.iddocumento": idDocument
                },
                {
                    "data.tipo": alert === "Nuovo libro" ? "nuovoLibro" : "aggiornamentoFrizzera"
                }
            ]
        },
        "token": token,
        "encryptParams": null
    }

}

export const notificationListForFe = (data) => {

    if (data.Documents === null || data.Documents.length === 0) return []

    let arrayFormated = [];

    data.Documents.forEach((el) => {
        arrayFormated.push({
            id: el._id,
            title: el.titolo,
            alert: el.data.tipo === "nuovoLibro" ? "Nuovo libro" : "Nuovo aggiornamento",
            description: el.testo,
            img: createImgLink(el.data.properties.immagine),
            isRead: el.data.stato === 0 ? false : true,
            idDocument: el.data.properties.iddocumento,
            idProdotto: el.data.properties.idprodotto,
            date: castDateNote(el.data.timestamp.split(" ")[0])
        });
    });

    return arrayFormated;
}

const castDateNote = (date) => {

    const splitted = date.split("/");
    return splitted[0] + " " + getMonth(splitted[1]);
} 
export const formatArea = (data) => {
    if(data === undefined || data === null) return
 
    let arr = [];
    data.Result.ResultSet.Root.forEach(e =>{
        arr.push(e.areatematica.split("\\")[1]);
    })
    return arr;
}