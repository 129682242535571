import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import HeaderReader from "../components/HeaderReader/HeaderReader";
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import LoginModal from "../components/Modals/Login";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import SchedaAutori from "../components/Prodotti/Prodotto/SchedaAutori/SchedaAutori";
import ReaderFoot from "../components/ReaderFoot/ReaderFoot";
import ReaderHead from "../components/ReaderHead/ReaderHead";
import ReaderTitle from "../components/ReaderHead/ReaderTitle";
import ReaderContentContainer from "../components/ReaderMain/ReaderContentContainer";
import ReaderMainContainer from "../components/ReaderMain/ReaderMainContainer";
import { config } from "../constants/Constants";
import { bodyFormattedGetTipoView, bodyFormattedReader, castArrayForFELittleSumm, chooseBody, chooseDocument, chooseURL, FormattedDocumentForFE, viewContent } from "../helper/helperFunctionReader";
import useHttpRequest from "../hooks/http-request";
import useSaveAndDeleteReadingBook from "../hooks/saveAndDeleteReadingBook";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import AuthContext from "../store/auth-context";
import GetDocumentContext from "../store/get-documento";
import isLoadingContext from "../store/isLoadingcontext";
import TokenContext from "../store/token-context";
import SessioneScaduta from "../components/Modals/SessioneScaduta";

const ReaderAuthors = (props) => {

    const { id } = useParams();

    const [tipoDocument, setTipoDocument] = useState(null);
    const [documentReturns, setDocumentReturns] = useState({});
    const [nextChap, setNextChap] = useState({});
    const [prevChap, setPrevChap] = useState({});

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const documentCtx = useContext(GetDocumentContext);
    const sendRqst = useHttpRequest();
    const isLoadingCtx = useContext(isLoadingContext);
    const saveDeleteB = useSaveAndDeleteReadingBook();
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        isLoadingCtx.setIfIsLoading(true);

        documentCtx.getDocumentoFromId(id);

        const tipoDocument = (data) => {

            let typeDocument = null;


            if (data.Result.ResultSet.Root === null) {
                setTipoDocument(typeDocument);
                return;
            }

            const getTipoV = data.Result.ResultSet.Root[0];
            typeDocument = chooseDocument(getTipoV.tipo);

            setTipoDocument(typeDocument);
            const showPremiumContent = viewContent(getTipoV, tokenCtx);
            loadDocument(typeDocument, getTipoV.pdfurl, showPremiumContent, getTipoV.comprato);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedGetTipoView(config.nameList.tipoDocument, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, tipoDocument);

    }, [id, tokenCtx.token]);

    const testataHtml = (id, dataRes, typeDocument, showPremiumContent) => {

        const setTestataDocumentHtml = (data) => {

            let document = data.Result.ResultSet;
            if (document === null) {
                isLoadingCtx.setIfIsLoading(false);
                return;
            }
            document = document.Root[0];

            document = {
                Section: document.sezione,
                ChapterTitle: document.titolo,
                Date: document.data,
                page: document.pagina,
                Author: document.autore,
                TaxType: document.titolo,
                Immagine: document.immagine
            };

            document = { ...document, ...dataRes };
            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent)
            setDocumentReturns(res);
            saveDeleteB.setSaveBook(config.nameList.leggendo, { prop1: parseInt(res.idProvv), prop2: parseInt(res.idDocType), prop3: parseInt(res.id) }, showPremiumContent);
        };

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getTestataHtml, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setTestataDocumentHtml);
    };

    const loadDocument = (typeDocument, pdfUrl, showPremiumContent, isComprato) => {

        const setDocumentJson = (data) => {

            let document = {};

            if (typeDocument !== 'Sistema Frizzera') {
                testataHtml(data.Result.DocumentId, data.Result, typeDocument, showPremiumContent);
                return;
            }

            document = data.Result;

            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent);
            setDocumentReturns(res);
            loadLittleSumm();
        };

        const bodyFormatted = chooseBody(typeDocument, id, tokenCtx.token, isComprato);

        if (bodyFormatted === null)
            return;

        const url = chooseURL(typeDocument);

        const request = {
            url: url,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        }

        sendRqst.sendRequest(request, setDocumentJson);
    };

    const loadLittleSumm = () => {

        const setLittleSummJson = (data) => {

            if (data.Result.ResultSet === null) {
                return;
            }

            const objJson = castArrayForFELittleSumm(data.Result.ResultSet.Root);

            setPrevChap(objJson.prevChap);
            setNextChap(objJson.nextChap);

        }

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getLittleSumm, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setLittleSummJson);
    };

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <HeaderReader page='autori'
                    title={documentReturns.mainTitle}
                    tipoDocument={tipoDocument}
                    img={documentReturns.img}
                    isReader={true}
                    idDoc={documentReturns.id}
                    idProv={documentReturns.idProvv}
                    idDocType={documentReturns.idDocType}
                    isIntegrateInBD={props.isIntegrateInBD}
                />

                <ReaderContentContainer>
                    <ReaderHead>
                        <ReaderTitle title='Autori' />
                        {/*<ReaderHeadGoTo page='summary' />*/}
                    </ReaderHead>

                    <ReaderMainContainer>
                        {/*<ReaderTools />*/}
                        {(documentCtx.descrizione !== undefined && documentCtx.prodotto !== undefined) && <SchedaAutori isReader={true} title={documentReturns.upTitlLeft}></SchedaAutori>}
                        {/*<ReaderTools />*/}
                    </ReaderMainContainer>

                    {/*<ReaderFoot page='summary'/>*/}
                    {tipoDocument !== 'pdf' && <ReaderFoot prevChap={prevChap} nextChap={nextChap} />}
                </ReaderContentContainer>

                <Footer isIntegrateInBD={props.isIntegrateInBD}/>
            </Wrapper>

            <Ricerca isReader={true}
                img={documentReturns.img}
                title={documentReturns.upTitlLeft}
                tipoDocument={tipoDocument}
                id={documentReturns.id}
                idProvvedimento={documentReturns.idProvv}
                idDocType={documentReturns.idDocType} />
        </React.Fragment>
    );
};

export default ReaderAuthors;