import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import DropdownMenuScroll from "./DropdownMenuScroll";
import ListBookmarks from "./DropdownMenuScrollItems/ListBookmarks";

const BookmarksNotification = (props) => {

    const searchWord = useRef(null);
    const [itemsFiltered, setItemsFiltered] = useState(props.bookmarks);
    const [bookmarksShow, setBookmarksShow] = useState(false);

    const filterByWord = (ev) => {
        ev.preventDefault();

        const result = props.bookmarks.filter(val => {
            return val.title.toLowerCase().includes(searchWord.current.value.toLowerCase()) ||
                val.descr.toLowerCase().includes(searchWord.current.value.toLowerCase())
        });

        setItemsFiltered(result);
    }

    let item = {
        name: 'Segnalibri',
        placeholder: 'Cerca tra i segnalibri',
        spanIcon: 'icon icon-bookmark-list',
    };

    useEffect(() => {
        setItemsFiltered(props.bookmarks);
    },[props.bookmarks])

    return (
        <React.Fragment>
            <div className={`dropdown ${bookmarksShow ? "show" : ""}`}>
                <a className={`itool ${props.isFree ? 'is-disabled' : ''}`} role="button" data-toggle="dropdown" onClick={(ev) => {ev.preventDefault(); setBookmarksShow(prev => !prev)}}>
                    <span className={item.spanIcon}></span>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${bookmarksShow ? "show" : ""}`}>
                    <div className="dropdown-menu-head">
                        <div className="dropdown-menu-head-top">
                            <span className="dropdown-menu-head-title">{item.name}</span>
                            {item?.link &&
                                <NavLink className={item.link.class} to={item.link.path}>
                                    {item.link.content}
                                    <span className={item.link.spanIcon}></span>
                                </NavLink>
                            }
                        </div>
                        <div className="search-input">
                            <input className="input input--round input--shadow" type="text" placeholder={item.placeholder} ref={searchWord} onChange={filterByWord} />
                            {/*<button className="search-input-submit" type="submit" onClick={filterByWord}>
                                <span className="icon icon-search"></span>
                        </button>*/}
                        </div>
                    </div>
                    {itemsFiltered?.length > 0 &&
                        <DropdownMenuScroll option={item.name}>
                            {itemsFiltered && itemsFiltered.map((item, index) => (
                                <ListBookmarks item={item} key={Math.random()} ></ListBookmarks>
                            ))}
                        </DropdownMenuScroll>
                    }
                </div>
            </div>
        </React.Fragment >
    )
}

export default BookmarksNotification;