import React from "react";
import { scrollTo } from "../../../helper/helper";
import ScrollUpButton from "../../UI/ScrollUpButton/ScrollUpButton";
import ReaderToolsBookmarks from './ReaderToolsBookmarks';
import ReaderToolsTextSize from './ReaderToolsTextSize';
import ReaderToolsPrint from "./ReaderToolsPrint";

const ReaderToolsMobile = (props) => {

    
    const settingZoom = (cssClass) => {
        props.settingZoom(cssClass);
    };

    const handleSaved = (data) => {
        props.handleSaved(data);
    }

    return (
        <div className="reader-tool d-md-none">
            <ul className="list-inline list-inline-mid">
                <ReaderToolsBookmarks handleSaved={handleSaved} idProv={props.idProv} idDoc={props.idDoc} class="list-inline-item"></ReaderToolsBookmarks>
                <ReaderToolsTextSize settingZoom={settingZoom} class="list-inline-item"></ReaderToolsTextSize>
                <ReaderToolsPrint></ReaderToolsPrint>
            </ul>
            <ScrollUpButton />

        </div>


    );
}

export default ReaderToolsMobile;