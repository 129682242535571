import React from "react";
import Iframe from "react-iframe";
import { useParams } from "react-router";
import SecondResearchHeader from "../components/SecondResearch/SecondResearchHeader";
import { config } from "../constants/Constants";

const FrameNtPro = (props) => {

    const { title, area, idDoc } = useParams();

    const titleWhiteSpace = title.replaceAll("-", " ");
    return (
        <React.Fragment>
            <SecondResearchHeader title={titleWhiteSpace} isNtPro={true} isIntegrateInBD={props.isIntegrateInBD}/>
            <section className="section">
                <div className="container">
                    <div className="row">
                       
                            <div className="page">
                                <Iframe url={config.linkToViewerntpro[0] + area + config.linkToViewerntpro[1] + idDoc}
                                    width="1280px"
                                    height="1680px"
                                    id=""
                                    className=""
                                    display="block"
                                    position="relative" 
                                    styles={{border: "none"}}
                                    />
                            </div>
                        </div>
                        <div className="col-lg-8">
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default FrameNtPro;