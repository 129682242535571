import React from "react";
import ManageThematicArea from "../../../UI/ManageThematicArea/ManageThematicArea";
import InAggiornamento from "../../InAggiornamento/InAggiornamento";

const SchedaProdottoFooter = (props) => {

    return (
        <React.Fragment>
            <div className={props.isManageable ? "card-foot card-foot--tool" : "card-foot card-foot--flex d-none d-md-flex"}>
                <ul className="list-inline list-inline--small">
                <ManageThematicArea themeArea={props.themeArea} titleContainer={props.titleContainer} title={props.title}></ManageThematicArea>
                </ul>
                <InAggiornamento isUpdating={props.isUpdating}></InAggiornamento>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoFooter;