import React from "react";
import { NavLink } from "react-router-dom";
import { splitFisco } from "../../../helper/helper";
import { updateLink } from "../../../helper/TealiumHelper";

const themeArea = (props) => {

    const themeArea = props.themeArea === "Fisco e Imprese" ? "FiscoEImprese" : props.themeArea;

    const classAreaTematica = () => {
        switch (themeArea) {
            case "Contabilità":
                return "clr-con";
            case "FiscoEImprese":
                return "clr-fis";
            case "Diritto":
                return "clr-dir";
            case "Lavoro":
                return "clr-lav";
            case "PA":
                return "clr-pa";
            case "Tecnici":
                return "clr-tec";
            default:
                return "";
        }
    };

    return (
        <React.Fragment>
                <li className="list-inline-item">
                    <span className={classAreaTematica()}>
                        <NavLink className="tag clr bgr" to={"/Area/" + themeArea} onClick={(ev) => {updateLink({ title: props.title, name: "area" }, props.titleContainer, props.title) }}>
                            {props.themeArea}
                        </NavLink>
                    </span>
                </li>
        </React.Fragment>
    );
}

export default themeArea;