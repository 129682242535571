import React from "react";
import { NavLink } from "react-router-dom";

const ListBookmarks = (props) => {

    const item = props.item;

    return (
        <React.Fragment>
            <li className="list-lined-item">
                <div className="card-note card-note--action ">
                    <NavLink to={`/reader/${item.id}`}>
                        <span className="card-note-title">
                            {item.title}
                        </span>
                        <span className="card-note-noted">
                            {item.descr}
                        </span>
                    </NavLink>
                </div>
            </li>
        </React.Fragment>
    );
}

export default ListBookmarks;