import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from "../components/Footer/Footer";
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import ReaderCard from '../components/ReaderMain/ReaderCard';
import ReaderResearchTop from '../components/ReaderMain/ReaderMainResearch/ReaderResearchTop';
import BookPreview from '../components/SecondResearch/BookPreview';
import SecondResearchHeader from '../components/SecondResearch/SecondResearchHeader';
import Spinner from '../components/UI/Spinner/Spinner';
import { config } from '../constants/Constants';
import { convertArrayForFEResearch, convertArrayForFEResearchSecondLevel } from '../helper/castingObject';
import { bodyFormattedResearch } from '../helper/helperResearch';
import useHttpRequest from '../hooks/http-request';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import isLoadingContext from '../store/isLoadingcontext';
import TokenContext from '../store/token-context';

const SecondResearch = (props) => {

    let { idProv, word, title, isFrizzera } = useParams();

    const defaultRevOrRec = ['D', 'data desc'];
    const bookTitle = localStorage.getItem("titleNotSlug");

    const [researchList, setResearchList] = useState(null);
    const [img, setImg] = useState(null);
    const [node, setNode] = useState(null);
    const [isBuyed, setIsBuyed] = useState(false);

    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);
    const loadingCtx = useContext(isLoadingContext);
    const sendRqst = useHttpRequest();

    useEffect(() => {

        if (word === '') {
            return;
        }

        loadingCtx.setIfIsLoading(true);
        const settingNewResList = (data) => {

            const result = convertArrayForFEResearchSecondLevel(data.Result.Documents);
            setResearchList(result);
            let getImg = localStorage.getItem("img");
            getImg = getImg !== null && getImg !== undefined && getImg !== "" ? getImg : config.imgDefaultLibro;
            let getNode = localStorage.getItem("node");
            getNode = getNode !== null && getNode !== undefined && getNode !== "" ? localStorage.getItem("node") : "";

            setImg(getImg);
            setNode(getNode);
            loadingCtx.setIfIsLoading(false, 400);
        };

        const filters = [idProv]

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getResultSearch),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedResearch(filters, word, defaultRevOrRec, tokenCtx, false, false, false, isFrizzera))
            }
        };

        sendRqst.sendRequest(request, settingNewResList, false, false);

    }, [idProv]);

    useEffect(() => {
        
        const id = localStorage.getItem("id");

        if (tokenCtx.token === null || id === 0 || id === undefined) return;

        const settingsBuyOrNot = (data) => {

            if (parseInt(data.Result.ResultSet.Root[0].comprato) > 0) {
                setIsBuyed(true);
                return;
            }

            setIsBuyed(false);
        }

        const bodyFormatted = {
            "parameters": {
                "name": config.nameList.getBuyedOrNot,
                "inputParameters": [{
                    "name": "iddocumento",
                    "type": "Int",
                    "value": id
                }],
                "staticToken": authCtx.isLoggedIn ? "" : config.token.STATIC_TOKEN
            },
            "token": authCtx.isLoggedIn ? tokenCtx.token : ""
        };

        const rqs = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqs, settingsBuyOrNot);

    }, [tokenCtx]);

    /*useEffect(() => {

        if(loadingCtx.isLoading) {
            
            loadingCtx.setIfIsLoading(false, 300);
        }

    },[loadingCtx.isLoading])*/

    useManageBodyClasses();

    return (
        <Wrapper>
            <SecondResearchHeader title={bookTitle}  isIntegrateInBD={props.isIntegrateInBD}/>
            {loadingCtx.isLoading && <Spinner></Spinner>}
            <div className="main-content ">
                <section className="section">
                    <div className="container">

                        {/* Book preview on top (!d-lg) */}
                        <div className="page-preview d-lg-none">
                            <BookPreview img={img} node={node} isBuyed={isBuyed}/>
                        </div>

                        <div className="row">
                            <div className="col-lg-8">
                                <ReaderResearchTop isLevelTwo={true} title={bookTitle} numberDocumentsFind={ researchList?.length} />

                                <ul className="list list--large">
                                    {researchList && researchList.map((item, index) => (
                                        <ReaderCard itemList={item} key={index} />
                                    ))}
                                </ul>

                                {/* Pagination component goes in here */}
                            </div>

                            {/* Book preview on the left (only d-lg) */}
                            <div className="col-lg-4 order-lg-first d-none d-lg-block">
                                <div className="sticky-wrapper">
                                    <div className="sticky sticky--side">
                                        <BookPreview img={img} node={node} isBuyed={isBuyed}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer isIntegrateInBD={props.isIntegrateInBD}/>
        </Wrapper>
    );
}


export default SecondResearch;