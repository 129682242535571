import React from 'react';

const ReaderContentContainer = (props) => {

    return (
        <div className="main-content">
            {props.tipoDocument !== 'pdf' &&
                <div className="reader" data-reader>
                    {props.children}
                </div>
            }
            {props.tipoDocument === 'pdf' &&
                props.children
            }
        </div>
    );
}

export default ReaderContentContainer;