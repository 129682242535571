import React from "react";
import { Link } from "react-router-dom";
import { config } from "../../constants/Constants";
import Book24Link from "../UI/Book24Link/Book24Link";
import Sole24Link from "../UI/Sole24Link/Sole24Link";

const Footer = (props) => {

    return (
        <React.Fragment>
            <footer className="mfoot" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <div className="container">
                    <div className="mfoot-wrapper">
                        <div className="mfoot-nav nav-lined nav-lined--logo">
                            <Book24Link/>
                            <Sole24Link/>
                        </div>
                        <div className="mfoot-nav nav-lined d-print-none">
                            <Link className="nav-link" to="/qr">QR Code</Link>
                            <Link className="nav-link" to="/FAQ">FAQ</Link>
                            <a className="nav-link" href={config.linkScopriDiPiu}>Perché abbonarsi</a>
                        </div>
                    </div>
                </div>

                <div className="mfoot-box">
                    <div className="container-fluid">
                        <p className="mfoot-box-txt">
                            P.I. 00777910159
                            <span className="mfoot-box-line">&nbsp;</span>
                            <a href={config.linkFooter.datiSocietari} target="_blank">Dati societari</a>
                            <br className="d-md-none" /><span className="mfoot-box-line d-none d-md-inline-block">&nbsp;</span>
                            © Copyright Il Sole 24 Ore Tutti i diritti riservati
                            <br className="d-xl-none" />
                            <span className="mfoot-box-line d-none d-xl-inline-block">&nbsp;</span>
                            Per la tua pubblicità sul sito:  
                            <a href={config.linkFooter.websystem} target="_blank"> Websystem</a>
                            <br className="d-sm-block d-md-none" />
                            <span className="mfoot-box-line d-none d-md-inline-block">&nbsp;</span>
                            <a href={config.linkFooter.informativaCookies} target="_blank">Informativa sui cookie</a>
                            <span className="mfoot-box-line">&nbsp;</span>
                            <a href={config.linkFooter.privacyPolicy} target="_blank">Privacy policy</a>
                        </p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;