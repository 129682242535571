import React, { useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
// Components
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import Header from "../components/Header/Header"
import AreaAllListHeader from "../components/UI/AreaAllListHeader/AreaAllListHeader";
import Prodotti from "../components/Prodotti/Prodotti";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import Footer from "../components/Footer/Footer";
import TokenContext from "../store/token-context";
import ManageProductListsContext from "../store/manage-products-list-context";
import { config } from "../constants/Constants";
import { useState } from "react";
import LoginModal from "../components/Modals/Login";
import AuthContext from "../store/auth-context";
import { useNavigate } from "react-router-dom";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import isLoadingContext from "../store/isLoadingcontext";
import Spinner from "../components/UI/Spinner/Spinner";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import Partecipazione from "../components/Modals/Partecipazione";
import { updateView } from "../helper/TealiumHelper";
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const MyBooks = (props) => {
    const handleClose = () => {
        setShowModal(false);
    };
    const navigate = useNavigate();
    const tokenCtx = useContext(TokenContext);
    const productListsCtx = useContext(ManageProductListsContext);
    const authCtx = useContext(AuthContext);
    const loadingCtx = useContext(isLoadingContext);
    const { isBookIntegrate } = useParams();
    const [showSessione, setShowSessione] = useState(false);
    const [showModalPartecipazione, setShowModalPartecipazione] = useState(false);
    const [continuaALeggere, setContinuaALeggere] = useState([]);
    const [giaLetti, setGiaLetti] = useState([]);
    const [daLeggere, setDaLeggere] = useState([]);
    const [piuLetti, setPiuLetti] = useState([]);
    const [showModal, setShowModal] = useState(true);
    if (localStorage.getItem("isLoggedIn") !== "1") {
        navigate('/');
    }
    
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {
        if(!isBookIntegrate) return;
            sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
      }, [isBookIntegrate]);
    useEffect(() => {

        let clearFunct = setTimeout(() => {
            if (!authCtx.isLoggedIn && !localStorage.getItem("isLoggedIn")) {
                navigate('/');
            }
        }, 400);

        return () => {
            clearTimeout(clearFunct);
        };

    }, [authCtx.isLoggedIn]);

    useEffect(() => {

        if (localStorage.getItem("isLoggedIn") !== "1") {
            navigate('/');
            return;
        }

        /*let waitForSettingLoggedIn = setTimeout(() => {

            if (!authCtx.isLoggedIn)
                navigate('/');
        }, 600);

        return () => clearTimeout(waitForSettingLoggedIn);*/
    })

    useEffect(() => {

        loadingCtx.setIfIsLoading(true);

        if (authCtx.username === "Utente") return;

        let loadLists = setTimeout(() => {

            productListsCtx.getFindByQuery(config.nameList.leggendo);
            productListsCtx.getList(config.nameList.daLeggere);
            productListsCtx.getFindByQueryGiaLetto(config.nameList.giaLetti);
            productListsCtx.getList(config.nameList.listPiuLetti);

        }, 200);

        return () => {
            clearTimeout(loadLists);
        };

    }, [tokenCtx.token, authCtx.username]);

    useEffect(() => {
        setContinuaALeggere(productListsCtx.continuaALeggere);
    }, [productListsCtx.continuaALeggere]);

    useEffect(() => {
        setGiaLetti(productListsCtx.giaLetti);
    }, [productListsCtx.giaLetti]);

    useEffect(() => {
        setDaLeggere(productListsCtx.daLeggere);
        loadingCtx.setIfIsLoading(false, 4000);
    }, [productListsCtx.daLeggere]);

    useEffect(() => {
        setPiuLetti(productListsCtx.piuLetti);
    }, [productListsCtx.piuLetti]);


    const updateList = (nameList) => {
        switch (nameList) {
            case "Continua a Leggere":
                productListsCtx.getFindByQuery(config.nameList.leggendo);
                break;
            case "Letti di recente":
                productListsCtx.getList(config.nameList.daLeggere);
                break;
            case "I libri del tuo abbonamento":
                productListsCtx.getFindByQueryGiaLetto(config.nameList.giaLetti);
                break;
        }
    }

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:i-miei-libri`, authCtx, tokenCtx, "book24ore:area-loggato");

    }, [tokenCtx.token, , authCtx.isLoggedIn, window.utag, authCtx.isLoggedIn])

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky"  isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {authCtx.isLoggedIn && <Partecipazione show={showModalPartecipazione} closeModal={(dismiss) => { setShowModalPartecipazione(dismiss.showModalPartecipazione) }}></Partecipazione>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                {loadingCtx.isLoading && <Spinner></Spinner>}
                <AreaAllListHeader
                    title="I miei libri"
                />
                <div className="main-content">
                    {(continuaALeggere.length > 0 && authCtx.isLoggedIn) &&
                        <Prodotti title="Continua a leggere"
                            prodList={continuaALeggere}
                            isSubscription={false}
                            isManageable={true}
                            hasBookmark={true}
                            sectionClassName=""
                            classNameCard="card card--book card--action"
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}
                            updateList={updateList}
                        />
                    }
                    {(daLeggere.length > 0 && authCtx.isLoggedIn) &&
                        <Prodotti title="I libri del tuo abbonamento"
                            prodList={daLeggere}
                            seeAll={true}
                            isSubscription={false}
                            isManageable={false}
                            hasBookmark={false}
                            sectionClassName=""
                            classNameCard="card card--book card--action"
                            dataSlide="4"
                            dataSlideMd="3"
                            notSlick={true}
                            updateList={updateList}
                        />
                    }
                    {(giaLetti.length > 0 && authCtx.isLoggedIn) &&
                        <Prodotti title="Letti di recente"
                            prodList={giaLetti}
                            isSubscription={false}
                            isManageable={true}
                            hasBookmark={false}
                            sectionClassName=""
                            classNameCard="card card--book card--action"
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}
                            notSlick={false}
                            updateList={updateList}
                        />
                    }

                    {piuLetti.length > 0 && <Prodotti title="I più letti di Book24ORE"
                        prodList={piuLetti}
                        isSubscription={false}
                        isManageable={false}
                        hasBookmark={false}
                        sectionClassName=""
                        classNameCard="card card--book "
                        dataSlide="4"
                        dataSlideMd="3"
                        isProfessionalTool={false} />}
                </div>
                <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>

        </React.Fragment>
    );
}

export default MyBooks;