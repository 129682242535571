import React from 'react'
import { Helmet } from 'react-helmet-async'

const MetaTags = ({title="Book24ORE", description="Book24ORE", img="dist/img/og-generica.png", type="website", author="" }) => {

    const url = window.location.href;

    return (
        <Helmet>
            <meta name='description' content={description} />
            
            { /* OGS tags */ }
            <meta property="og:image" content={img}/>
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            { /* Twitter tags */ }
            <meta name="twitter:creator" content={author} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    )
}

export default MetaTags