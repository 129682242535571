import React from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";

const SchedaProdottoHeader = (props) => {
    var img = "";

    if(validURL(encodeURI(props.img))){
        img = props.img
    }else{
        img = props.img === '' ? (props.isSubscription ? config.imgDefaultPacch : config.imgDefaultLibro) : props.img;
    }

    const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));
    const link = !props.goToReaderDocument ? `/Prodotto/${title}/${props.idProduct}/SchedaDescrizione` : `/reader/${props.idForReading}`

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    return (
        <React.Fragment>
            <figure className="card-img ">
                {props.isSubscription &&
                    <NavLink to={`/Pacchetto/${props.idProduct}`} onClick={(ev) => {updateLink({ title: props.title, name: "prodotto" }, props.titleContainer) }}>
                        <img className="card-img-full" src={img} alt="" />
                    </NavLink>
                }
                {!props.isSubscription &&
                    <NavLink to={link} onClick={(ev) => {updateLink({ title: props.title, name: "prodotto" }, props.titleContainer, props.title) }}>
                        <span className="card-img-book">
                            <img src={img} alt="" />
                        </span>
                    </NavLink>
                }
            </figure>
        </React.Fragment>
    );
}

export default SchedaProdottoHeader;