import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import Footer from '../components/Footer/Footer';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import HeaderReader from '../components/HeaderReader/HeaderReader';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import SessioneScaduta from "../components/Modals/SessioneScaduta";
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { config } from '../constants/Constants';
import { bodyFormattedGetTipoView, bodyFormattedReader, chooseBody, chooseDocument, chooseURL, FormattedDocumentForFE, viewContent } from '../helper/helperFunctionReader';
import useHttpRequest from '../hooks/http-request';
import useSaveAndDeleteReadingBook from '../hooks/saveAndDeleteReadingBook';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import isLoadingContext from '../store/isLoadingcontext';
import TokenContext from '../store/token-context';

const Software = (props) => {


    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const navigate = useNavigate();

    const { idDocType, idProv, id } = useParams();
    const [tipoDocument, setTipoDocument] = useState(null);
    const sendRqst = useHttpRequest();
    const saveDeleteB = useSaveAndDeleteReadingBook();
    const isLoadingCtx = useContext(isLoadingContext);
    const [documentReturns, setDocumentReturns] = useState({});

    const [enrichment, setEnrichment] = useState(null);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        if ([tokenCtx.arricchimenti].length === 0) return;

        tokenCtx.arricchimenti.forEach(element => {
            if (element['@idProvvedimento'] === idProv && element['@iddoctype'] === idDocType) {
                let obj = {
                    title: element['@titolo'],
                    text1: element['@descrizione'],
                    text2: element['@avvertenze'],
                    link: element['@link'],
                    nameButton: element['@bottone']
                };
                setEnrichment(obj);
            }
        });

    }, [tokenCtx.arricchimenti]);

    useEffect(() => {

        isLoadingCtx.setIfIsLoading(true);
        const tipoDocument = (data) => {

            let typeDocument = null;


            if (data.Result.ResultSet.Root === null) {
                setTipoDocument(typeDocument);
                return;
            }

            const getTipoV = data.Result.ResultSet.Root[0];
            typeDocument = chooseDocument(getTipoV.tipo);

            setTipoDocument(typeDocument);
            const showPremiumContent = viewContent(getTipoV, tokenCtx);
            loadDocument(typeDocument, getTipoV.pdfurl, showPremiumContent, getTipoV.comprato);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedGetTipoView(config.nameList.tipoDocument, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, tipoDocument);

    }, [id, tokenCtx.token]);

    useEffect(() => {

        if (tokenCtx.token === null || tokenCtx.token === undefined) {
            navigate('/')
        }

    }, [tokenCtx])

    const testataHtml = (id, dataRes, typeDocument, showPremiumContent) => {

        const setTestataDocumentHtml = (data) => {

            let document = data.Result.ResultSet;
            if (document === null) {
                isLoadingCtx.setIfIsLoading(false);
                return;
            }
            document = document.Root[0];

            document = {
                Section: document.sezione,
                ChapterTitle: document.titolo,
                Date: document.data,
                page: document.pagina,
                Author: document.autore,
                TaxType: document.titolo,
                Immagine: document.immagine
            };

            document = { ...document, ...dataRes };
            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent)
            setDocumentReturns(res);
            saveDeleteB.setSaveBook(config.nameList.leggendo, { prop1: parseInt(res.idProvv), prop2: parseInt(res.idDocType), prop3: parseInt(res.id) }, showPremiumContent);
        };

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getTestataHtml, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setTestataDocumentHtml);
    };

    const loadDocument = (typeDocument, pdfUrl, showPremiumContent, isComprato) => {

        const setDocumentJson = (data) => {

            let document = {};

            if (typeDocument !== 'Sistema Frizzera') {
                testataHtml(data.Result.DocumentId, data.Result, typeDocument, showPremiumContent);
                return;
            }

            document = data.Result;

            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent);
            setDocumentReturns(res);
        };

        const bodyFormatted = chooseBody(typeDocument, id, tokenCtx.token, isComprato);

        if (bodyFormatted === null)
            return;

        const url = chooseURL(typeDocument);

        const request = {
            url: url,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        }

        sendRqst.sendRequest(request, setDocumentJson);
    };

    const openInNewTab = () => {
        window.open(enrichment.link);
    }

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <HeaderReader
                    page='summary'
                    title={documentReturns.upTitlLeft}
                    tipoDocument={tipoDocument}
                    img={documentReturns.img}
                    isReader={true}
                    idDoc={documentReturns.id}
                    idProv={documentReturns.idProvv}
                    idDocType={documentReturns.idDocType}
                    isIntegrateInBD={props.isIntegrateInBD}
                />

                <div className="main-content">
                    <section className="section">
                        <div className='container'>
                            {enrichment &&
                                <>
                                    <div className='enrichment-head'>
                                        <h1 className='page-title sole-sans enrichment-title'>{enrichment.title}</h1>
                                        <div className='btn-wlabel'>
                                            <a className="btn btn--black btn--big btn--full btn--shadow" role="button" onClick={openInNewTab}>
                                                {`${enrichment.nameButton ?? "Scarica"}`}
                                            </a>
                                            {/*<NavLink target="_blank" to={`/${}`}></NavLink>*/}
                                        </div>
                                    </div>
                                    {enrichment.text1 &&
                                        <>
                                            <h2 className='section-title ml-0'>Descrizione</h2>
                                            <p className='page-txt'>
                                                {enrichment.text1}
                                            </p>
                                        </>
                                    }
                                    {enrichment.text2 &&
                                        <>
                                            <h2 className='section-title ml-0'>Avvertenze</h2>
                                            <p className='page-txt'>
                                                {enrichment.text2}
                                            </p>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </section>
                </div>

                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca isReader={true}
                    img={documentReturns.img}
                    title={documentReturns.title_book}
                    tipoDocument={tipoDocument}
                    id={id}
                    idProvvedimento={idProv}
                    idDocType={idDocType}
                />
            </Wrapper>
        </React.Fragment>
    )
}

export default Software;