import React from "react";

const Free = () => {

    return(
        <React.Fragment>
            <span className="page-label page-label--green">Free</span>
        </React.Fragment>
    );
}

export default Free;