import React from "react";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import styles from "./SearchForType.module.css";
import { Dropdown } from "react-bootstrap";

const SearchForType = (props) => {

    const { isFromAnotherPage } = useParams();
    const [isActive, setIsActive] = useState(1);
    const returnRightChoice = (searchForCategory) => {

        switch (searchForCategory) {
            case "Tutto":
                setIsActive(1);
                break;
            case "Testo":
                setIsActive(2);
                break;
            case "Titolo":
                setIsActive(3);
                break;
            case "Autore":
                setIsActive(4);
                break;
            default:
                setIsActive(2);
                break;
        }
    };

    const [optChoosed, setOptChoosed] = useState(localStorage.getItem("search category") === null ? "Tutto" : () => {
        if ((isFromAnotherPage === undefined || isFromAnotherPage === null || isFromAnotherPage === "daMieiLibri") && (localStorage.getItem("mantieniIFiltri") === null || localStorage.getItem("mantieniIFiltri") === false)) {
            setIsActive(1);
            return "Tutto";
        }else{
            returnRightChoice(localStorage.getItem("search category"));
            return localStorage.getItem("search category");
        }
        
    });

    const changeResearch = (ev) => {

        const searchForCategory = ev.target.getAttribute('value');

        setOptChoosed(searchForCategory);
        returnRightChoice(searchForCategory);

        localStorage.setItem("search category", searchForCategory);
        props.searchForCategory(searchForCategory);
    };


    return (
        <React.Fragment>
            <Dropdown>
                <Dropdown.Toggle className={`search-multi-box d-none d-lg-block ${props.searchError ? styles.disabled : ""}`} as={"style"}>
                    <label className="search-label">Cerca in</label>
                    <a className={`search-opt ${props.searchError ? styles.disabledText : ""}`} role={"button"} >
                        {optChoosed}
                    </a>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`dropdown-menu`} id="dropdownTarget">
                    <ul className="list list--mid" >

                        <li className="list-item" disabled={true}>
                            <a className={`search-opt ${isActive === 1 ? "is-active" : ""}`} role={"button"} value="Tutto" onClick={changeResearch}>
                                Tutto
                            </a>
                        </li>

                        <li className="list-item">
                            <a className={`search-opt ${isActive === 2 ? "is-active" : ""}`} role={"button"} value="Testo" onClick={changeResearch}>
                                Testo
                            </a>
                        </li>

                        <li className="list-item">
                            <a className={`search-opt ${isActive === 3 ? "is-active" : ""}`} role={"button"} value="Titolo" onClick={changeResearch}>
                                Titolo
                            </a>
                        </li>

                        <li className="list-item">
                            <a className={`search-opt ${isActive === 4 ? "is-active" : ""}`} role={"button"} value="Autore" onClick={changeResearch}>
                                Autore
                            </a>
                        </li>

                    </ul>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    )
}

export default SearchForType;