import React from "react";
import { NavLink } from "react-router-dom";

const GoHome = (props) => {

    return (
        <React.Fragment>
            <li className="list-inline-item" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <NavLink className="itool" role={"button"} to="/">
                    <span className="icon icon-home"></span>
                </NavLink>
            </li>
        </React.Fragment >
    )
};

export default GoHome;