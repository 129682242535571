import React from "react";

const QrCodeError = (props) => {

    const resetQrCode = () => {
        props.resetQrCode({ response: "sleep", message: "" });
        props.count();
    };

    return (
        <React.Fragment>
            <p className="qrpage-text">
                {props.errorMessage}
            </p>
            <a className="btn btn--black btn--big" role="button" onClick={resetQrCode}>Riprova scansione</a>
        </React.Fragment>
    )
}

export default QrCodeError;