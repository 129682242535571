import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { config } from '../../../../constants/Constants';
import ThemeListItem from './ThemeListItem';

const ListAreaTematica = () => {

    const {themeArea: currentArea} = useParams();

    return (
        <React.Fragment>
            <section className="section ">
                <div className="container">
                    <div className="section-head">
                        <h2 className="section-title">Le altre aree tematiche</h2>
                    </div>
                    <div className="row row-cols-md-3">
                        {config.themeAreas.map((area, i) => (
                            <React.Fragment key={i}>
                                {(currentArea !== area.id) &&
                                    <ThemeListItem themeArea={area} currentArea={currentArea}/>
                                }
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>

        </React.Fragment>
    );
}

export default ListAreaTematica;