import React from 'react'

const FaqWrapper = (props) => {

    return (
        <div className={`faqpage ${props.isLight ? 'faqpage--light' : ''}`}>
            <div className="container">
                {props.children}
            </div>
        </div>
    )
}

export default FaqWrapper