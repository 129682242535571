import React from 'react';

const HeaderWrapper = (props) => {

return(
        <React.Fragment>
            <div className={`mhead mhead--${props.classSticky === undefined ? "hp" : "sticky"}`} data-main-header="">
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default HeaderWrapper;