import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../helper/helper';

const ReaderFoot = (props) => {

    return (
        <div className="reader-foot" data-page-footer>

            <div className="reader-nav">
                {Object.keys(props.nextChap).length > 0 &&
                    <NavLink className="reader-nav-btn reader-nav-btn--next" to={`${!isEmpty(props.nextChap) ? "/reader/" + props.nextChap.idDoc : "#"}`}>
                        <span className="reader-nav-label">Capitolo successivo</span>
                        <span className="reader-nav-title">{props.nextChap.text}</span>
                        <span className="icon icon-arrow-right"></span>
                    </NavLink>
                }
                {!(props.page === 'summary') && Object.keys(props.prevChap).length > 0 &&
                    <NavLink className="reader-nav-btn reader-nav-btn--prev order-md-first" to={`${!isEmpty(props.prevChap) ? "/reader/" + props.prevChap.idDoc : "#"}`}>
                        <span className="reader-nav-label">Capitolo precedente</span>
                        <span className="reader-nav-title">{props.prevChap.text}</span>
                        <span className="icon icon-arrow-left"></span>
                    </NavLink>
                }
            </div>
        </div>
    );
}

export default ReaderFoot;