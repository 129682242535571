import React from 'react';
import Footer from "../components/Footer/Footer";
import Ricerca from '../components/Header/Ricerca/Ricerca';
import HeaderReader from '../components/HeaderReader/HeaderReader';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import ReaderHead from '../components/ReaderHead/ReaderHead';
import ReaderHeadResearch from '../components/ReaderHead/ReaderHeadResearch';
import ReaderContentContainer from '../components/ReaderMain/ReaderContentContainer';
import ReaderMainContainer from '../components/ReaderMain/ReaderMainContainer';
import ReaderMainResearch from '../components/ReaderMain/ReaderMainResearch/ReaderMainResearch';
import useManageBodyClasses from '../hooks/useManageBodyClasses';

const ReaderResearch = (props) => {

    useManageBodyClasses();

    return(
        <>
            <Wrapper>
                <HeaderReader page='research' isIntegrateInBD={props.isIntegrateInBD}/>

                <ReaderContentContainer>
                    <ReaderHead>
                        <ReaderHeadResearch/>
                    </ReaderHead>

                    <ReaderMainContainer>
                        <ReaderMainResearch/>
                    </ReaderMainContainer> 
                    
                    {/* Pagination components goes here */}
                </ReaderContentContainer>

                <Footer isIntegrateInBD={props.isIntegrateInBD}/>
            </Wrapper>

            <Ricerca isReader={true}/>
        </>
    );
}


export default ReaderResearch;