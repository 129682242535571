import Modal from 'react-bootstrap/Modal';
import React from "react";
import { useRef } from 'react';
import { useEffect } from 'react';
import { cleanSpan, editDocument, existNoteForThisParagraph, updateNotes } from '../../helper/helperFunctionReader';
import { config } from '../../constants/Constants';
import useContextMenu from '../../hooks/useContextMenu';
import useManangeNoteAndTagOnReader from '../../hooks/useManageNoteAndTagOnReader';

const AddOrUpdateNote = (props) => {

     const { handleClick, cleanFunction } = useContextMenu();

    const noteAndTag = useManangeNoteAndTagOnReader();

    const textArea = useRef();

    let textCompleteToEdit = props.showAddUpdateNote.noteToUpd.textCompleteToEdit;
    let textToEdit = props.showAddUpdateNote.noteToUpd.textToEdit;

    let newRef = props.showAddUpdateNote.noteToUpd?.noteToUpd === undefined ? props.showAddUpdateNote.noteToUpd.text : props.showAddUpdateNote.noteToUpd?.noteToUpd.text;


    const handleClose = () => {
        props.closeModal({ showModal: false, noteToUpd: {} });
    };

    const saveNoteHandle = (ev) => {

        ev.preventDefault();
        let newNote = props.showAddUpdateNote.noteToUpd.noteToUpd;

        let editedText = document.getElementById("textComplete").innerHTML
        
        //const noteToUpd = {};

        //per update
        const noteToUpd = existNoteForThisParagraph(newNote.idNote, props.notes.data.note);

        let updatedNewNote = {};
        let documentToUpd = {};
        let updatedNotes = [];

        if (Object.keys(noteToUpd).length > 0) {
            noteToUpd.text = textArea.current.value;
            updatedNotes = updateNotes(noteToUpd, props.notes);
        } else {
            let objEdit = editDocument(editedText, textToEdit, { start: newNote.startNote, end: newNote.endNote }, newNote.color, noteToUpd, props.document.titleDoc);
            objEdit.note.text = textArea.current.value;
            updatedNewNote = {
                underlinedText: cleanSpan(objEdit.newDocument, { start: objEdit.note.startNote, end: objEdit.note.endNote }, noteToUpd),
                ...objEdit.note,
                ...{
                    startPar: newNote.startPar,
                    endPar: newNote.endPar
                }
            };
            updatedNotes = updateNotes(updatedNewNote, props.notes);
            props.textWithNote.data.documentText = objEdit.newDocument;

            documentToUpd = {
                ...props.textWithNote
            };
        }

        const salvaNota = () => {

            const refreshDocument = () => {
                cleanFunction();
                //handleClose();
                props.refreshDocument({ showModal: false, noteToUpd: {} });
            };

            noteAndTag.saveOrUpdateNote(props.notes, updatedNotes, { prop1: props.document.idProvv, prop2: props.document.id, prop3: props.document.idDocType, prop4: props.document.mainTitle, prop5: props.document.titleDoc }, config.nameList.note, refreshDocument);

        };

        if (Object.keys(documentToUpd).length > 0)
            noteAndTag.updateDocument(documentToUpd, salvaNota);
        else
            salvaNota();

    };

    useEffect(() => {

        if (newRef !== undefined)
            textArea.current.value = newRef;

    }, [newRef]);

    return (
        <React.Fragment>
            <Modal show={props.showAddUpdateNote.showModal} onHide={handleClose}>
                <div className="modal-content">
                    <Modal.Header className="modal-header">
                        <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="icon icon-cross-small"></span>
                        </a>
                        <h3 className="modal-title">{` ${newRef === "" ? "Aggiungi" : "Modifica"} nota`}</h3>
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <textarea className="textarea" ref={textArea}></textarea>
                    </Modal.Body>

                    <div className="modal-footer">
                        <a className="btn btn--txt" role="button" onClick={handleClose}>Annulla</a>
                        <a className="btn btn--black " role="button" onClick={saveNoteHandle}>Salva</a>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default AddOrUpdateNote;