import React from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";
import Novita from "../../../UI/Novita/Novita";

const SchedaProdottoAreaListaTematicaHeader = (props) => {

    const img = props.img === "" ? (props.isSubscription ? config.imgDefaultPacch : config.imgDefaultLibro) : props.img;
    const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));

    return (
        <React.Fragment>

            <div className={`card card--book  ${props.isSubscription ? "card--multi" : ""}`}>
                <div className="card-box">
                    <div className="card-content">
                        <figure className="card-img ">
                            <NavLink to={`${!props.isSubscription ? "/Prodotto/" + title + "/" + props.id + "/SchedaDescrizione" : "/Pacchetto/" + props.id}`}
                                onClick={(ev) => { updateLink({ title: props.title, name: "prodotto" }, props.titleContainer) }}
                            >

                                {props.isSubscription &&
                                    <img className="card-img-full" src={img} alt="" />
                                }
                                {!props.isSubscription &&
                                    <span className="card-img-book">
                                        <img src={img} alt="" />
                                    </span>
                                }
                            </NavLink>
                        </figure>
                        {props.children}
                    </div>
                    {props.isNew && <Novita />}
                </div>
            </div>

        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematicaHeader;