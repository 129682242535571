import React from 'react';

const ReaderMainContainer = (props) => {

    return (
        <React.Fragment>
            <div id='pg-wrapper'>
                <div id="wrapper">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReaderMainContainer;