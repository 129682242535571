import React from "react";
// React-Bootstrap
import Modal from 'react-bootstrap/Modal';
import SearchCardContentPac from "../UI/Research/SearchCard/SearchCardContentPac";

const Pacchetti = (props) => {

    const handleClose = () => {
        props.closeModalPacchettiHandle(false);
    };

    return (
        <Modal show={props.modalPacchetti} onHide={handleClose}>
            <div className="modal-content">
                <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span className="icon icon-cross-small"></span>
                </a>
                <Modal.Header className="modal-header">
                    <h3 className="modal-title">Seleziona l’abbonamento</h3>
                </Modal.Header>

                <Modal.Body className="modal-body">
                    <p className="modal-txt">
                        Questo libro è acquistabile nei seguenti pacchetti di abbonamento. Seleziona quello più adatto a te
                    </p>

                    <div className="modal-cards-wrapper">
                        <ul className="list list--mid">
                            {props.buyOrNot?.map((pac, i) => (
                                <SearchCardContentPac
                                    pac={pac}
                                    key={i}
                                />
                            ))
                            }
                        </ul>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default Pacchetti;