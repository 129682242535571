import React from 'react';

const DropdownMenuScroll = (props) => {

    return (
        <>
            <div className="dropdown-menu-scroll">
                <ul className="list-lined list-lined--large">
                    {props.children}
                </ul>
            </div>
        </>
    );
}

export default DropdownMenuScroll;