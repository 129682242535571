import React, { useState } from 'react'
import NoteList from './NoteList'

const NoteSubTitle = (props) => {

    const [show, setShow] = useState(false);

    const editNote = (editedNote) => {
        props.editNote(editedNote);
    }

    const deleteNote = (noteToDelete) => {
        props.deleteNote(noteToDelete);
    }

    return (
        <li className="list-lined-item">
            <h6 className="note-book-subtitle">
                <button type="button" data-toggle="collapse" data-target={props.item.dataTarget}
                    aria-expanded={`${show ? true : false}`}
                    onClick={() => setShow(!show)}
                    className={`${show ? "" : "collapsed"}`}>
                    {props.item.subTitle}
                    <span className="icon icon-angle-right"></span>
                </button>
            </h6>
            <div className={`collapse ${show ? "show" : ""}`} id={props.item.id}>
                {/*item.noteList.maps(item => ())*/}
                <NoteList noteList={props.item.noteList}
                    idDocumento={props.item.idDocumento}
                    mostUsedTag={props.mostUsedTag}
                    editNote={editNote}
                    deleteNote={deleteNote}
                />
            </div>
        </li>
    )
}

export default NoteSubTitle