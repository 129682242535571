import React from "react";
import { updateLink } from "../../../helper/TealiumHelper";

const GenericBox = (props) => {

    return (
        <React.Fragment>
            <section className="section ">
                <div className="container">
                    <div className="boxcta">
                        <span className="boxcta-txt">
                            {props.text}
                        </span>
                        <div className="boxcta-foot">
                            <a className="btn btn--black btn--icon-right" href={props.scopriDiPiu} onClick={(ev) => {updateLink({ title: "scopri-di-più", name: "" }, "Box cta") }}>
                                Scopri di più <span className="icon icon-arrow-right-small"></span>
                            </a>
                        </div>
                        <figure className="boxcta-img">
                            <img className="img-cover" src={props.imgCover} alt="" />
                        </figure>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default GenericBox;