import React from "react";

const ResearchError = (props) => {

    return (
        <div className="empty">
            <div className="empty-content">
                <h2 className="empty-title empty-title--small">
                    <span className="empty-title-gray">Non ci sono risultati corrispondenti per la ricerca</span> [{props.testo}]
                </h2>

                <ul className="empty-list">

                    <li className="empty-list-item">
                        Assicurarsi che tutte le parole siano state digitate correttamente.
                    </li>

                    <li className="empty-list-item">
                        Provare con parole chiave diverse.
                    </li>

                    <li className="empty-list-item">
                        Provare con parole chiave più generiche.
                    </li>

                </ul>


            </div>

            <figure className="empty-img">
                <img className="img-fluid d-md-none" src="/dist/img/help-ricerca-error--small.png" alt="" />
                <img className="img-fluid d-none d-md-block" src="/dist/img/help-ricerca-error.png" alt="" />
            </figure>

        </div>
    );
};

export default ResearchError;