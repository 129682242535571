import React from 'react'

const PrevArrow = ({onClick, className}) => {
    return (
        <a 
            onClick={onClick} 
            className={`${className} carousel-btn carousel-btn--prev absolute-arrow-left`} 
        >
            <span className="icon icon-arrow-left"></span>
        </a>
    )
}

export default PrevArrow