import React, { useContext, useEffect, useState } from "react";
import { config } from "../../../constants/Constants";
import { bodyFormattedBooks, castArrayForFe } from "../../../helper/helperPacchetto";
import useHttpRequest from "../../../hooks/http-request";
import TokenContext from "../../../store/token-context";
import LibroPacchetto from "./LibroPacchetto/LibroPacchetto";

const LibriPacchetto = (props) => {

    const [libri, setLibri] = useState(null)

    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();

    useEffect(() => {
        const setBooksPac = (data) => {

            if (data.Result.ResultSet === null) return;

            const obj = castArrayForFe(data.Result.ResultSet.Root);

            setLibri(obj.arrayForFe);
            props.settingsIdDocuments(obj.idDocumentsConcat);
        };   
        var  bodyFormatted = null;
        if(parseInt(props.modules)>= 1000){
            bodyFormatted = bodyFormattedBooks(props.modules, false);
        }else{
            bodyFormatted = bodyFormattedBooks(props.pacchetti, true);
        }
        

        const rqs = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqs, setBooksPac);

    }, [tokenCtx.allAssocModPac]);

    return (
        <React.Fragment>
            {libri && libri.map(libro => (
                <LibroPacchetto key={Math.random().toString()} prodotto={libro} isUpdating={libro.isUpdating} titleContainer={props.titleContainer} pacchetto={props.pacchetto}></LibroPacchetto>
            ))
            }
        </React.Fragment>
    );
}

export default LibriPacchetto;