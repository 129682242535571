import React, { useContext, useState } from 'react';
import TreeNode from '../classes/TreeNode';
import { config } from '../constants/Constants';
import { bodyFormattedLeafDocuments, bodyFormattedSumm, castArrayForFE, formattedLeafArrayForFE } from '../helper/helperFunctionGetDocument';
import useHttpRequest from '../hooks/http-request';
import TokenContext from './token-context';

const TreeContext = React.createContext({
    idDocument: null,
    order: null,
    expanded: ["0"],
    index: null,
    navBar: [''],
    array: [],
    selected: "0",
    newLeafArray: [],
    tree: new TreeNode({
        id: "0",
        name: "",
        children: "",
        leaf: false,
        parent: "0",
        livello: 0
    })
})

export const TreeContextProvider = (props) => {

    const [idDocument, setIdDocument] = useState(null);
    const [order, setOrder] = useState(null);
    const [expanded, setExpanded] = useState(["0"]);
    const [navBar, setNavBar] = useState([]);
    const [array, setArray] = useState([]);
    const [selected, setSelected] = useState("0");
    const [newLeafArray, setNewLeafArray] = useState([]);
    const [tree, setTree] = useState({});
    const [elementHtml, setElementHtml] = useState(-1);
    const [goToElementId, setGoToElementId] = useState("-1");


    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);

    const settingsInitialStateConfig = (title, sommario, order, idDocument) => {

        setNavBar([{ el: title.replaceAll("-", " "), id: "0" }])
        setTree(new TreeNode({
            id: "0",
            name: title.replaceAll("-", " "),
            children: sommario,
            leaf: false,
            parent: "0",
            livello: 0
        }));
        setOrder(order);
        setIdDocument(idDocument);
        setElementHtml(-1);
    }

    const handleChangeConfig = (event, nodeId, label) => {

        const node = tree.search(nodeId);

        if (event !== undefined) {

            setElementHtml(event.target.getAttribute("data-value"));
        }

        if (expanded.find(el => el === nodeId && node && node.children.length > 0)) {
            const index = expanded.indexOf(nodeId);
            const newArray = [...expanded];
            newArray.splice(index);
            setExpanded(newArray);

            const indexNavBar = navBar.map(el => el.el).indexOf(node.name);
            const newArrayNavBar = [...navBar];
            if (nodeId === "0") {
                newArrayNavBar.splice(indexNavBar + 1);
                setNavBar(newArrayNavBar);
                setSelected(node.parent);
                return;
            }
            newArrayNavBar.splice(indexNavBar);
            setNavBar(newArrayNavBar);
            setSelected(node.parent);
        }
        else if (!expanded.find(el => el === nodeId) && node && node.children.length > 0) {
            setSelected(nodeId);
            setExpanded([...expanded, nodeId]);
            if (nodeId !== "0") {
                setNavBar([...navBar, { el: node.name, id: nodeId }])
            }
        }
        else if (node && !node.children.length) {

            const settingChildren = (data) => {
                const array = castArrayForFE(data.Result.Nodes);
                setTree(tree.addChildren(array, nodeId));
                setSelected(nodeId);
                setExpanded([...expanded, nodeId]);
                if (nodeId !== "0") {
                    setNavBar([...navBar, { el: node.name, id: nodeId }]);
                    setArray(array);
                }
            };

            const request = {
                url: config.url.BACKEND_SERVICE + config.serviceToCall.getTaxonomy,
                req: {
                    headers: {
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                        'Content-Type': 'application/json',
                        'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                    },
                    body: JSON.stringify(bodyFormattedSumm(order, nodeId, tokenCtx, 'banche dati')),
                    method: "POST"
                }
            };

            sendRqst.sendRequest(request, settingChildren);
        }
    }

    const getDocumentLeafConfig = (event) => {

        const nodeId = event.currentTarget.dataset.value;

        if (event !== undefined) {
            setElementHtml(event.target.getAttribute("data-value"));
        }

        const settingDocumentListFromLeaf = (data) => {
            if (data.Result?.Documents?.length === 0) return;

            setNewLeafArray(formattedLeafArrayForFE(data.Result.Documents));
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getDocumentsByLeaf,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedLeafDocuments(order, nodeId, tokenCtx, 'banche dati')),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, settingDocumentListFromLeaf);
    }

    const setElementHtmlByChangeRouteConfig = (element) => {
        setElementHtml(element);
    }

    const setElementIdConfig = (id) => {
        setGoToElementId(id);
    }

    const contextValue = {
        expanded: expanded,
        navBar: navBar,
        array: array,
        selected: selected,
        tree: tree,
        newLeafArray: newLeafArray,
        idDocument: idDocument,
        elementHtml: elementHtml,
        goToElementId: goToElementId,
        handleChange: handleChangeConfig,
        getDocumentLeaf: getDocumentLeafConfig,
        settingsInitialState: settingsInitialStateConfig,
        setElementHtmlByChangeRoute: setElementHtmlByChangeRouteConfig,
        setElementId: setElementIdConfig
    };

    return <TreeContext.Provider value={contextValue}>{props.children}</TreeContext.Provider>
}

export default TreeContext;