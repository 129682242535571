import React from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import ManageThematicArea from "../../ManageThematicArea/ManageThematicArea";

const SearchCardContentPac = (props) => {

    const pacchetto = props.pac;

    const img = pacchetto.img === "" ? config.imgDefault3 : pacchetto.img;

    return (
        <React.Fragment>
            <li className="list-item">
                <NavLink className="cardsubs" to={`/pacchetto/${pacchetto.node}`}>
                    <figure className="card-img cardsubs-img">
                        <img className="card-img-full" src={img} alt="" />
                    </figure>
                    <div className="cardsubs-content">
                        <h4 className="cardsubs-title">
                            {pacchetto.title}
                        </h4>
                        <ManageThematicArea themeArea={pacchetto.themesArea}/>
                    </div>
                </NavLink>

            </li>
        </React.Fragment>
    )
}

export default SearchCardContentPac;