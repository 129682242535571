import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { updateView } from '../../helper/TealiumHelper';
import { config } from "../../constants/Constants";
import AuthContext from '../../store/auth-context';
import TokenContext from '../../store/token-context';

const PersonalAreaProfil = () => {

    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView("book24ore:area-personale:il-mio-profilo",authCtx, tokenCtx, "book24ore:area-personale");
                
    },[tokenCtx.token, authCtx.isLoggedIn, window.utag])

    return(
        <section className="page-user">
            <div className="container">
            
                <iframe src={`${config.linkProfiloPersonale}`} width="100%" height="2000px" frameBorder="0"></iframe>
            
            </div>
        </section>
    );
}

export default PersonalAreaProfil;