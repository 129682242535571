import React from 'react';

const ReaderBoxesRel = (props) => {

    const item = props.item;

    return(
        <div className="reader-rel">
            <h6 className="reader-rel-title">
                <a href={item.linkPath}><span dangerouslySetInnerHTML={{__html: item.title}}/></a>
            </h6>
            {item.listItems && item.listItems.map((listItem, index) => (                    
                <a className="reader-rel-link " href={listItem.linkPath}>
                    <span dangerouslySetInnerHTML={{__html: listItem.title}} />                    
                </a>                    
            ))}
          
        </div>
    );
}

export default ReaderBoxesRel;