import React from 'react'

const BookFilter = (props) => {

    const bookFilters = props.filterBookArray;
    
    const filteringBook = (ev) => {

        props.filteringBook(ev.currentTarget.id);
    };

    return (
        <ul className="list list--large">
            {bookFilters && bookFilters.map((filter, index) => (
                <li className="list-item" key={index}>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id={filter.id} onClick={filteringBook}/>
                        <label className="custom-control-label d-block" htmlFor={filter.id}>
                            <span className="search-side-label">{filter.name}</span>
                        </label>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default BookFilter