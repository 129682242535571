import React, { useEffect, useState }  from 'react';
import ScrollUpButton from '../../UI/ScrollUpButton/ScrollUpButton';
import ReaderToolsBookmarks from './ReaderToolsBookmarks';
import ReaderToolsTextSize from './ReaderToolsTextSize';
import ReaderToolsPrint from './ReaderToolsPrint';

const ReaderTools = (props) => {

    
    const settingZoom = (cssClass) => {
        props.settingZoom(cssClass);
    };

    const handleSaved = (data) => {
        props.handleSaved(data);
    }

    return (
        <div className="reader-tool d-none d-md-block">
            <div className="sticky-wrapper">
                <div className="sticky">
                    <ul className="list">
                    <ReaderToolsBookmarks handleSaved={handleSaved} idProv={props.idProv} idDoc={props.idDoc} class="list-item"></ReaderToolsBookmarks>
                    <ReaderToolsTextSize settingZoom={settingZoom} class="list-item"></ReaderToolsTextSize>
                    <ReaderToolsPrint></ReaderToolsPrint>
                    </ul>

                </div>
            </div>
            <ScrollUpButton />

        </div>


    );
}

export default ReaderTools;