import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../../../store/auth-context";
import {config} from "../../../../constants/Constants";

const Profilo = (props) => {

    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();

    const logoutHandler = () => {
        if (authCtx.isLoggedIn)
        window.open(config.logout,"_self")
            //authCtx.onLogout(true);
        //navigate('/');
    };

    return (
        <React.Fragment>
            <div className="dropdown" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <a className="itool" role="button" /*data-toggle="dropdown"*/ >
                    <span className="icon icon-user"></span>
                </a>

                <div className={`dropdown-menu dropdown-menu-right ${props.clickButton ? "show" : ""}`}>
                    <div className="dropdown-menu-head">
                        <span className="dropdown-menu-head-title">
                            {authCtx.username}
                        </span>
                    </div>

                    <NavLink className="licon" to="/area-personale/profilo">
                        Il mio profilo
                        <span className="icon icon-user"></span>
                    </NavLink>

                    <NavLink className="licon" to="/area-personale/notifiche">
                        Le mie notifiche
                        <span className="icon icon-notification"></span>
                    </NavLink>

                    <div className="dropdown-menu-foot" onClick={logoutHandler}>
                        <a className="licon" >
                            <div>Log out <span className="icon icon-logout"></span></div>
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Profilo;