import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Prodotti from '../../Prodotti/Prodotti';
import Button from '../../UI/Button/Button';
import ManageProductListsContext from "../../../store/manage-products-list-context";
import { config } from '../../../constants/Constants';
import TokenContext from '../../../store/token-context';
import AuthContext from '../../../store/auth-context';
import { filterFisco, splitFisco } from '../../../helper/helper';
import isLoadingContext from '../../../store/isLoadingcontext';
import Spinner from '../../UI/Spinner/Spinner';

const AreaBody = (props) => {

    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);
    const productListsCtx = useContext(ManageProductListsContext);
    const isLoadingCtx = useContext(isLoadingContext);

    const [ultimeUscite, setUltimeUscite] = useState([]);
    const [proposteDiAbbonamento, setProposteDiAbbonamento] = useState([]);
    const [piuLetti, setPiuLetti] = useState([]);

    useEffect(() => {

        let setList = setTimeout(() => {

            let themeAr = filterFisco(props.themeArea);

            productListsCtx.getList(config.nameList.ultimeUscite, undefined, themeAr);
            productListsCtx.getList(config.nameList.proposteAbbon, undefined, themeAr);
            productListsCtx.getList(config.nameList.listPiuLetti, undefined, themeAr);

            isLoadingCtx.setIfIsLoading(true);

        }, 200);

        return () => {
            clearTimeout(setList);
        };
    }, [props.themeArea, tokenCtx.token]);

    useEffect(() => {
        setUltimeUscite(productListsCtx.ultimeUscite);

        if (productListsCtx.ultimeUscite?.length > 0)
            isLoadingCtx.setIfIsLoading(false, 400);

    }, [productListsCtx.ultimeUscite]);

    useEffect(() => {
        setProposteDiAbbonamento(productListsCtx.proposteDiAbbonamento);
    }, [productListsCtx.proposteDiAbbonamento]);

    useEffect(() => {
        setPiuLetti(productListsCtx.piuLetti);
    }, [productListsCtx.piuLetti]);

    return (
        <React.Fragment>
            {isLoadingCtx.isLoading && <Spinner></Spinner>}
            {ultimeUscite.length > 0 && <Prodotti title="Ultime Uscite"
                prodList={ultimeUscite}
                isSubscription={false}
                isManageable={false}
                sectionClassName=""
                classNameCard="card card--book "
                dataSlide="4"
                dataSlideMd="3"
                isProfessionalTool={false} />}

            {proposteDiAbbonamento.length > 0 && <Prodotti title="Proposte di abbonamento"
                prodList={proposteDiAbbonamento}
                isSubscription={true}
                isManageable={false}
                sectionClassName=""
                classNameCard="card card--book "
                dataSlide="4"
                dataSlideMd="3"
                isProfessionalTool={false}
                isAbbonamento={true} />}

            {piuLetti.length > 0 && <Prodotti title="I più letti"
                prodList={piuLetti}
                isSubscription={false}
                isManageable={false}
                sectionClassName=""
                classNameCard="card card--book card--action"
                dataSlide="4"
                dataSlideMd="3"
                isProfessionalTool={false} />}
            <section class="section section--light">
                <div class="container">
                    <div class="text-center">
                        <NavLink to={`/Area/${props.themeArea}/AllList`} className="btn btn--big btn--black">
                            {`Scopri tutti i prodotti ${splitFisco(props.themeArea)}`}
                        </NavLink>
                        {/*<Button titleButton={`Scopri tutti i prodotti ${splitFisco(props.themeArea)}`}
                            classDiv={"btn btn--big btn--black"}
                            url={`/Area/${props.themeArea}/AllList`}
                            titleContainer={"Scopri tutti i prodotti"}
                            themeArea={splitFisco(props.themeArea)}>
    </Button>*/}
                    </div>
                </div>
            </section>


        </React.Fragment>
    );
}

export default AreaBody;