//import './polyfill/polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthContexProvider } from './store/auth-context';
import { TokenContextProvider } from './store/token-context';
import { IsLoadingContextProvider } from './store/isLoadingcontext';
import { ManageProductListsContextProvider } from './store/manage-products-list-context';
import { GetDocumentContextProvider } from './store/get-documento';
import { TreeContextProvider } from './store/context-tree';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <IsLoadingContextProvider>
    <AuthContexProvider>
      <TokenContextProvider>
        <ManageProductListsContextProvider>
          <GetDocumentContextProvider>
            <TreeContextProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </TreeContextProvider>
          </GetDocumentContextProvider>
        </ManageProductListsContextProvider>
      </TokenContextProvider>
    </AuthContexProvider>
  </IsLoadingContextProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
