import React, { useContext, useEffect, useState } from 'react';
import { config } from '../../constants/Constants';
import useHttpRequest from '../../hooks/http-request';
import NotificationsFilter from './NotificationsFilter';
import useFindByquery from '../../hooks/useFindByQuery';
import TokenContext from '../../store/token-context';
import AuthContext from '../../store/auth-context';
import { updateView } from '../../helper/TealiumHelper';

const PersonalAreaNotifications = () => {
    
    const tokenCtx = useContext(TokenContext);
    
    const loginCtx = useContext(AuthContext);
    const sendRqst = useHttpRequest();
    
    const [checkboxesList, setCheckboxesList] = useState([]);
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [userId, setUserId] = useState('');
    
    const filters = config.filters;

    /* FETCHING FILTERS */
    useEffect(() => {

        if(tokenCtx.token === null) return;

        const afterFetch = (data) => {

            if (data.Result) {

                setAlreadyExist(true);
                setUserId(data.Result.Documents[0]['_id']['$oid']);

                const savedFilters = data.Result.Documents[0].data;
                let savedFiltersArray = [];
                
                for (const filter in savedFilters) {
                    if (savedFilters[filter]) {
                        savedFiltersArray.push(filter)
                    }
                    const checkbox = document.querySelector(`[data-value=${filter}]`);
                    checkbox.checked = savedFilters[filter];
                }
    
                setCheckboxesList(savedFiltersArray);
            }
        };

        /* FETCHING FILTERS REQUEST */
        const query = {
            tipo: "preferenze_utente",
            appId: config.appId,
            userKey: loginCtx.username
        };

        const bodyFormatted = {
            fields: {},
            pageNumber: 1,
            pageSize: 1,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token ? tokenCtx.token : config.token.STATIC_TOKEN,
            encryptParams: ["query.userKey"]
        };
        
        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, afterFetch);
    }, [tokenCtx.token, alreadyExist, loginCtx.username])

    /* SAVING FILTERS */
    const saveFilters = (name, data) => {
        const bodyFormatted = {
            data,
            type: name,
            token: tokenCtx.token
        };
        
        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.save,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };
        
        const afterSave = (data) => {
            setAlreadyExist(true);
        };

        sendRqst.sendRequest(request, afterSave);
    };

    /* UPDATING FILTERS */
    const updateFilters = (name, data) => {
        const bodyFormatted = {
            id: userId,
            data,
            type: name,
            token: tokenCtx.token
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.update,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        const afterUpdate = (data) => {};
        sendRqst.sendRequest(request, afterUpdate);
    }

    /* HANDLE SUBMIT EVENT */
    const handleSubmit = () => {

        let checkedFilters = {};

        checkboxesList.forEach((checkbox) => {
            checkedFilters[checkbox] = true;
        })

        if ((!Object.keys(checkedFilters).length)) {
            checkedFilters = {libri_aggiornamento: false, libri_nuovi: false};
        }

        if (alreadyExist) {
            updateFilters("preferenze_utente", checkedFilters);
            return;
        }
        
        saveFilters("preferenze_utente", checkedFilters);
    }

    /* MANAGE SELECTED FILTERS */
    const addFilter = (filterCheckedOrNot) => {
        
        let index = checkboxesList.indexOf(filterCheckedOrNot);

        if(index === -1){
            setCheckboxesList(prev => [filterCheckedOrNot, ...prev]);
            return;
        }

        let array = [...checkboxesList]
        array.splice(index,1);
        setCheckboxesList(array);
    }
    

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView("book24ore:area-personale:le-mie-notifiche",loginCtx, tokenCtx, "book24ore:area-personale");
                
    },[tokenCtx.token, loginCtx.isLoggedIn, window.utag]);

    return(
        <section className="page-user">
            <div className="container">
                <div className="page-user-head">
                    <h4 className="page-user-title">
                        Filtra
                    </h4>
                    <p className="page-user-txt">
                        Imposta la ricezione degli alert via mail per poter sempre essere informato sui tuoi libri di Book24ORE.
                    </p>
                </div>

                {/* Filters */}
                <div className="row row-cols-1 row-cols-lg-3">
                    {filters && filters.map((filter, i) => (
                        <NotificationsFilter filter={filter} key={i} addFilter={addFilter} />
                    ))}
                </div>

                <div className="page-user-foot">
                    <a className="btn" href="#">
                        Annulla
                    </a>
                    <a className="btn btn--black" href="#" onClick={handleSubmit}>
                        Salva
                    </a>
                </div>
            </div>
        </section>
    );
}

export default PersonalAreaNotifications;