import React, { useContext, useEffect, useRef, useState } from "react";
import QrCodeError from "./QrCodeError";
import QrCodeSuccess from "./QrCodeSuccess";
import QrScanner from "qr-scanner";
import useHttpRequest from "../../hooks/http-request";
import AuthContext from "../../store/auth-context";
import { config } from "../../constants/Constants";
import TokenContext from "../../store/token-context";
import { useParams } from "react-router";

const QrCodeBody = (props) => {

    const qrElement = useRef();
    const { qrCode } = useParams();
    const [isLogged, setIsLogged] = useState(false);
    const [resultQrScann, setResultQrScann] = useState();
    const sendRqs = useHttpRequest();
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const [countQrScann, setCountQrScann] = useState(0);
    //let qrScanner = {};

    const loginHandler = (data) => {

        props.loginHandler(data);
    };

    const resetQrCode = (data) => {

        props.resetQrCode(data);
    };

    const CheckIfOldSubscr = (qr, messagge) => {

        const setMessage = (result) => {

            if (result.Result.ResultSet === null || result.Result.ResultSet === undefined) {
                props.activationSuccess(messagge, false);
                return;
            }
            props.activationSuccess(result.Result.ResultSet.Root[0].message, true);
        }

        const body = {
            parameters: {
                name: config.nameList.getContenutiEsistentiQr,
                inputParameters: [
                    {
                        name: "qrcode",
                        type: "VarChar",
                        value: qr,

                    }
                ]
            },
            token: tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }
        };

        sendRqs.sendRequest(request, setMessage);


    }
    
    const manageDigitalContent = (result) => {

        if (result.data === undefined || result.data === null || result.data === "") props.resetQrCode("error");

        //props.qrScanner.destroy();

        let resultSplitted = result.data.split("|");
        let qrCode = resultSplitted[0];

        if (resultSplitted.length === 1) {
            resultSplitted = result.data.split("/");
            qrCode = resultSplitted[resultSplitted.length - 1];
        }


        const successActivationDigitalProduct = (result) => {

            //const bookActivated = formattedForFe(result);
            //da inserire se è andato a buon fine
            if (result.Result.Success) {
                tokenCtx.loadDynToken();
                CheckIfOldSubscr(resultSplitted[resultSplitted.length - 1], result.Result.messaggio.replaceAll(" 00:00:00", ""));
            } else if (!result.Result.Success) {
                props.resetQrCode({ response: "error", message: result.Result.messaggio });
            } else {
                props.resetQrCode({ response: "error", message: "Sembrerebbe che qualcosa non sia andata a buon fine, ti chiediamo di riprovare, grazie." });
            }
        }


        const body = {
            parameters: {
                "username": authCtx.username,
                "qrcode": qrCode,
                "staticToken": config.token.STATIC_TOKEN
            },
            "token": tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.qrCodeActivation,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            }
        };

        sendRqs.sendRequest(request, successActivationDigitalProduct);
    };

    useEffect(() => {
        if (props.result !== "sleep" || !isLogged || !authCtx.isLoggedIn) return;

        let qrScanner = new QrScanner(qrElement.current, result => setQr(result), { returnDetailedScanResult: true});

        props.settingsQrScanner(qrScanner);
        
    }, [props.result, isLogged, tokenCtx.token, authCtx.isLoggedIn]);
    const setQr = (result) => {
        setResultQrScann(result);
        setCountQrScann(countQrScann+1);
    }
    useEffect(() => {
        
        if(Object.keys(props.qrScanner).length === 0) return;
        
        props.qrScanner.start();

    },[props.qrScanner]);

    useEffect(() => {
        setIsLogged(authCtx.isLoggedIn);
    }, [authCtx.isLoggedIn]);

    useEffect(() => {
        if(!resultQrScann || countQrScann>1) return
        manageDigitalContent(resultQrScann)
        props.qrScanner.stop();
    }, [resultQrScann]);
    const setCount = () => {
        setCountQrScann(0);
  }
    return (
        <React.Fragment>
            {
                props.result === "sleep" && isLogged &&
                <div className="qrpage-scan">
                    <div className="qrpage-scan-content">
                        <video className="qrpage-scan-widget" style={{ background: "gray" }} ref={qrElement}></video>
                    </div>
                </div>
            }
            {
                props.result === "sleep" && !isLogged &&
                <QrCodeSuccess loginHandler={loginHandler} book={props.book} isSuccess={false}></QrCodeSuccess>
            }
            {
                props.result === "success" &&
                <QrCodeSuccess successMessage={props.successMessage} isSuccess={true} isDifferentAbbo={props.isDifferentAbbo}></QrCodeSuccess>
            }
            {
                props.result === 'error' &&
                <QrCodeError resetQrCode={resetQrCode} errorMessage={props.errorMessage} count={setCount}></QrCodeError>
            }
        </React.Fragment>
    );
}

export default QrCodeBody;