import React, { useContext } from "react";
import Notifiche from "./Notifiche/Notifiche";
import Profilo from "./Profilo/Profilo";
import { NavLink } from 'react-router-dom';
import AuthContext from "../../../store/auth-context";
import { useEffect } from "react";
import { useState } from "react";
import useManangeNoteAndTagOnReader from "../../../hooks/useManageNoteAndTagOnReader";
import { config } from "../../../constants/Constants";
import TokenContext from "../../../store/token-context";
import GenericDropdownList from "../../UI/GenericDropdownlist/GenericDropdownList";
import OutsideAlerter from "../../../hooks/outsideClickHook";

const UserAction = (props) => {

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const noteAndTag = useManangeNoteAndTagOnReader();

    const [isDisabled, setIsDisabled] = useState(true);
    const [disabledMyNotes, setDisabledMyNotes] = useState(true);
    const [clickButton, setClickButton] = useState(false);
    const [clickNotifyButton, setClickNotifyButton] = useState(false);
    const [modules, setModules] = useState([]);
    const [arrayToken, setArrayToken] = useState([]);
    const [updateToken, setUpdateToken] = useState(false);

    const url = window.location.href.split("/");

    const showLogin = () => {
        if (!authCtx.isLoggedIn)
            authCtx.showModalLog(true);
    }

    useEffect(() => {

        if (authCtx.isLoggedIn)
            setIsDisabled(false);

    }, [authCtx.isLoggedIn]);

    useEffect(() => {

        let delayFunction = setTimeout(() => {
            const activeElement = (data) => {

                if (data.Result.Documents.length > 0)
                    setDisabledMyNotes(false);
            }

            noteAndTag.getNotes(config.nameList.note, {}, true, activeElement);
        }, 200);

        return () => clearTimeout(delayFunction);

    }, [authCtx.isLoggedIn, tokenCtx.token]);

    const setClick = (ev) => {
        ev.preventDefault();
        setClickButton(prev => !prev);
        setClickNotifyButton(false);
    };

    const setClickNotify = (ev) => {
        ev.preventDefault();
        setClickNotifyButton(prev => !prev);
        setClickButton(false);
    };

    useEffect(() => {

        if (tokenCtx.modulesComplete.length === 0 || (tokenCtx.userType < 3 && tokenCtx.userType > 4))
            return;

        let array = [];

        tokenCtx.modulesComplete.forEach(el => {
            array.push({
                id: el['@id'],
                name: el['@name']
            });
        });

        setModules(array);
    }, [tokenCtx.userType, tokenCtx.modulesComplete]);

    useEffect(() => {


        if (arrayToken.length === 0 && (!authCtx.isLoggedIn || (tokenCtx.userType < 3 || tokenCtx.userType > 4) || !updateToken)) return;

        if (arrayToken.length === 0) {
            tokenCtx.loadDynToken();
            setUpdateToken(false);
            tokenCtx.setSelectedValuesToken([]);
            return;
        }

        tokenCtx.setSelectedValuesToken(arrayToken);
        let modulesSelected = [];

        arrayToken.forEach(el => {
            modulesSelected.push(el.id);
        });

        tokenCtx.loadDynToken(true, modulesSelected, true);
        setUpdateToken(false);
    }, [updateToken, arrayToken]);

    const addElement = (addItem) => {
        setArrayToken(addItem);
        setUpdateToken(true);
    }

    const removeElement = (removeItem) => {
        setArrayToken(removeItem);
        setUpdateToken(true);
    }

    const closeAllMenu = () => {
        setClickButton(false);
        setClickNotifyButton(false);
    }

    return (
        <React.Fragment>
            <div className="mhead-nav">
                <ul className="list-inline list-inline--large">

                    {(authCtx.isLoggedIn && (tokenCtx.userType === 3 || tokenCtx.userType === 4) && modules.length > 0) &&
                        <li className="list-inline-item d-none d-lg-inline-block">
                            <GenericDropdownList
                                dropdownlist={modules}
                                ArraySelectedOptions={tokenCtx.selectedValuesToken}
                                singleSelect={false}
                                showCheckbox={true}
                                addElement={addElement}
                                removeElement={removeElement}
                            />
                        </li>
                    }
                    {url[3] !== "" &&
                        <li className="list-inline-item d-none d-lg-inline-block">
                            <NavLink className="nav-link" to="/">
                                Home
                            </NavLink>
                        </li>
                    }
                    <li className="list-inline-item d-none d-lg-inline-block">
                        <NavLink className="nav-link" to="/ricerca">
                            Ricerca
                        </NavLink>
                    </li>
                    <li className="list-inline-item d-none d-lg-inline-block">
                        <NavLink className="nav-link" to="/qr">
                            Attiva un libro
                        </NavLink>
                    </li>
                    <li className={"list-inline-item d-none d-lg-inline-block"}>
                        <NavLink className={`nav-link${isDisabled || !tokenCtx.isSubscribed ? " is-disabled" : ""}`} to="/miei-libri">
                            I miei libri
                        </NavLink>
                    </li>

                    <li className={"list-inline-item d-none d-lg-inline-block"}>
                        <NavLink className={`nav-link${disabledMyNotes ? " is-disabled" : ""}`} to="/mie-note">
                            Le mie note
                        </NavLink>
                    </li>
                    <OutsideAlerter closeAllMenu={closeAllMenu}>
                        {authCtx.isLoggedIn &&
                            <li className="list-inline-item" onClick={setClickNotify}>
                                <Notifiche clickNotifyButton={clickNotifyButton}></Notifiche>
                            </li>
                        }
                        {!authCtx.isLoggedIn &&
                            <a className="btn btn--icon-left btn--icon-big" href="#modal-login" data-toggle="modal" onClick={showLogin}>
                                Accedi
                                <span className="icon icon-user"></span>
                            </a>
                        }
                        {authCtx.isLoggedIn &&
                            <li className="list-inline-item" onClick={setClick}>
                                <Profilo clickButton={clickButton}></Profilo>
                            </li>
                        }
                    </OutsideAlerter>
                </ul>
            </div>
        </React.Fragment >
    );
};

export default UserAction;