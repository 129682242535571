import React, { useContext }  from "react";
import Modal from 'react-bootstrap/Modal';
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { useState } from "react";

const SessioneScaduta = (props) => {

    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const logoutHandler = () => {
        if (authCtx.isLoggedIn)
            authCtx.onLogout(true);
            localStorage.removeItem("showModalSessione");
            handleClose();
            navigate('/');
    };

    const handleClose = () => {
        props.closeModal({ showModal: false });
    };

    return (
        <Modal show={props.show} onHide={handleClose}>
            <div className="modal" tabIndex="-1" role="dialog" style={{ 'display': 'block' }}>
                <div className="modal-dialog modal-dialog-centered modal-message">
                    <div className="modal-content">
                        <Modal.Header className="modal-header">
                            <h3 className="modal-title">Sessione scaduta</h3>
                        </Modal.Header>

                        <Modal.Body className="modal-body">
                            <p className="modal-txt">
                            La sessione è scaduta. Effettua nuovamente l'accesso per consultare i libri del tuo abbonamento. 
                            <span role="button" onClick={logoutHandler}> Clicca qui</span>
                            </p>
                        </Modal.Body>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SessioneScaduta;