import React from 'react'

const NoteDropdown = (props) => {

    const myTags = props.tags;

    const pressedTag = (ev) => {

        const newTag= ev.currentTarget.dataset.value;
        props.addTag(newTag);
    }

    return (
        <div id="tagMenu" className="dropdown-menu show">
            <div className="dropdown-menu-head">
                <span className="dropdown-menu-head-title">Miei tag</span>
            </div>
            <div className="dropdown-menu-scroll dropdown-menu-scroll--small">
                <ul className="list list--mid">
                    {myTags && myTags.map((tag, index) => (
                        <li className="list-item" key={Math.random()}>
                            <a className="search-opt " role="button" onClick={pressedTag} data-value={tag}>
                                {tag}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default NoteDropdown