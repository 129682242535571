import React from 'react';

const WrapperSchedaProdotto = (props) => {

    return (
        <React.Fragment>
            <div className={`${props.classNameCard} ${props.isSubscription ? "card--multi" : ""}`}>
                <div className="card-box">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
}

export default WrapperSchedaProdotto;