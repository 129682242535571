import React from "react";
import { useContext } from "react";
// React-Bootstrap
import Modal from 'react-bootstrap/Modal';
import AuthContext from "../../store/auth-context";
import TokenContext from "../../store/token-context";

const MessageManageSiac = (props) => {

    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);

    const handleClose = () => {
        tokenCtx.closeMessageManageSiac(false);
        tokenCtx.logoutToken();
        authCtx.onLogout();
    };

    return (
    <Modal show={props.show} onHide={handleClose}>
        <div className="modal-content">
            <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <span className="icon icon-cross-small"></span>
            </a>
            <Modal.Header className="modal-header">
                <h3 className="modal-title modal-title--icon">
                    Accesso non consentito
                    <span className="icon icon-info-o"></span>
                </h3>
            </Modal.Header>

            <Modal.Body className="modal-body">
                <p className="modal-txt">
                    È stato raggiunto il numero massimo di accessi consentiti per questa utenza.
                </p>
            </Modal.Body>
        </div>
    </Modal>
    )
}

export default MessageManageSiac;