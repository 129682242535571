import React from 'react'

const FaqListItem = ({ faq, index }) => {
    return (
        <li className="list-item">
            <div className="faqpage-faq">
                <a className="faqpage-faq-title" role={"button"} data-toggle="collapse" data-target={`#faq-${index + 1}`}>
                    { faq.q }
                    <span className="icon icon-plus"></span>
                </a>
                <div className="collapse" id={`faq-${index + 1}`}>
                    <div className="faqpage-faq-txt-wrapper">
                        <div className="faqpage-faq-txt">
                            <p dangerouslySetInnerHTML={{ __html: faq.a }} />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default FaqListItem