import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { titleUrlSlug } from '../../../helper/helper';
import TokenContext from '../../../store/token-context';
import GetDocumentContext from "../../../store/get-documento";
import isLoadingContext from "../../../store/isLoadingcontext";
import { config } from "../../../constants/Constants";

const chooseURL = (title, idProv, idDocType, title_book, titleSummary, libropdf) => {

    switch (title) {
        case 'Ultimi aggiornamenti':
            return `/reader-updates/${titleUrlSlug(titleSummary)}/`;
        case 'Sommario':
            return `/reader-summary/${titleUrlSlug(titleSummary)}/`;
        case 'software':
            return `/arricchimenti/${idDocType}/${idProv}/`;
        case 'indice':
            return `/index/${titleUrlSlug(title_book)}/`;
        case 'Ricerca':
            return '/ricerca';
        case 'Attiva un libro':
            return "/qr";
        case 'I miei libri':
            return "/miei-libri";
        case 'Le mie note':
            return "/mie-note";
        case 'Descrizione':
            return `/reader-descrizione/${titleUrlSlug(titleSummary)}/`;
        case 'Autori':
            return `/reader-autori/${titleUrlSlug(titleSummary)}/`;
        case 'Versione Pdf':
            return `/reader/${libropdf}`;
        default:
            return '/NotFound';
    }
}

const readerDrawerNotFrizz = [
    {
        "text": "Descrizione",
        "span": "icon icon-info",
        "linkClass": "drawer-link drawer-link--icon",
        "type": "Descrizione",
        "obj": {}
    },
    {
        "text": "Autori",
        "span": "icon icon-edit",
        "linkClass": "drawer-link drawer-link--icon",
        "type": "Autori",
        "obj": {},
    },
    {
        "text": "Sommario",
        "span": "icon icon-index",
        "linkClass": "drawer-link--icon",
        "type": "Sommario",
        "obj": {}
    },   
];

const readerDrawerFrizz = [
    {
        "text": "Descrizione",
        "span": "icon icon-info",
        "linkClass": "drawer-link drawer-link--icon",
        "type": "Descrizione",
        "obj": {}
    },
    {
        "text": "Autori",
        "span": "icon icon-edit",
        "linkClass": "drawer-link drawer-link--icon",
        "type": "Autori",
        "obj": {},
    },
    {
        "text": "Sommario",
        "span": "icon icon-index",
        "linkClass": "drawer-link--icon",
        "type": "Sommario",
        "obj": {},
    },
    {
        "text": "Ultimi aggiornamenti",
        "span": "icon icon-history",
        "linkClass": "drawer-link--icon",
        "type": "Ultimi aggiornamenti",
        "obj": {}
    }];

const MainDrawer = (props) => {

    const id = props?.id ? props.id : 0;
    const tokenCtx = useContext(TokenContext);
    const documentCtx = useContext(GetDocumentContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const location = useLocation();

    let classicDrawer = [
        { "text": "Ricerca" },
        { "text": "Attiva un libro" }
    ];
    const [libroPdf, setLibroPdf] = useState('');
    const [readerDrawer, setReaderDrawer] = useState(props.tipoDocument === 'Sistema Frizzera' ? readerDrawerFrizz : readerDrawerNotFrizz)

    const [drawerList, setDrawerList] = useState([]);

    if (props.tipoDocument === 'pdf') {

        const summaryIndex = readerDrawer.findIndex(object => object.text === "Sommario");
        let newArrayDrawer = [...readerDrawer];

        if (summaryIndex > -1) {
            newArrayDrawer.splice(summaryIndex, 1);
            setReaderDrawer(newArrayDrawer);
        }
    }

    useEffect(() => {

        isLoadingCtx.setIfIsLoading(true);
        if(readerDrawer.findIndex(object => object.text === "Versione Pdf")){
            documentCtx.getLibroPdfConfig(id);
        }
        
        let getProduct = setTimeout(() => {
            documentCtx.getList(config.nameList.tiPotrebbeInteressare, id);
        }, 200);

        return () => {
            clearTimeout(getProduct);
        };
    }, [id]);
    

    useEffect(() => {
   
        if (readerDrawer.map(x => x.text).indexOf("Versione Pdf") !== -1) {
            return;
        }
        if (documentCtx.libroPdf != 0 )
        {
            readerDrawer.push({
                "text": "Versione Pdf",
                "span": "icon icon-pdf",
                "linkClass": "drawer-link drawer-link--icon",
                "type": "Versione Pdf",
                "obj": {},
                "libropdf": documentCtx.libroPdf
            });
        }
    })

    useEffect(() => {

        if (tokenCtx.arricchimenti.length === 0 || readerDrawer[0]?.text === "Arricchimenti") return;

        if (id !== 0 && props.idProvvedimento && props.idDocType) {
            let arricchimento = {};
            if (readerDrawer.map(x => x.text).indexOf("Arricchimenti") !== -1) {
                return;
            }

            tokenCtx.arricchimenti.forEach(element => {

                if (parseInt(element['@idProvvedimento']) === props.idProvvedimento && parseInt(element['@iddoctype']) === props.idDocType) {
                
                    arricchimento = {
                        "text": "Arricchimenti",
                        "span": "icon icon-download",
                        "linkClass": "drawer-link--icon",
                        "type": element['@tipo'],
                        "idProv": element['@idProvvedimento'],
                        "idDocType": element['@iddoctype'],
                        "obj": element,
                    };
                    setReaderDrawer(prev => [...prev, arricchimento]);

                    if (location.pathname.split("/")[1] === "reader")
                        document.body.classList.add("is-drawer-in");
                }
            });
        }

    }, [tokenCtx.arricchimenti, id]);

    useEffect(() => {

        if (props.isReader) {
            setDrawerList(readerDrawer);
            return;
        }

        if (tokenCtx.token !== null)
            classicDrawer = [...classicDrawer, { "text": "I miei libri" }, { "text": "Le mie note" }]

        setDrawerList(classicDrawer);

    }, [props.isReader, readerDrawer, tokenCtx])

    return (
        <div className="drawer-main">
            <ul className="list list--large">
                {props.isReader && 
                    drawerList.map(((item, index) => (
                        <li className="list-item" key={Math.random()}>
                            {props.title &&
                            <NavLink reloadDocument
                                className={`drawer-link ${item.linkClass ? item.linkClass : ''}`}
                                to={`${chooseURL(item.type, item.idProv, item.idDocType, item.obj['@titolo'], props.title, item.libropdf)}${item.type != 'Versione Pdf' ? id : ''}/${item.type === 'indice' ? item.obj['@idindice'] : ''}`}
                                state={{ obj: item.obj }}
                                onClick={() => document.body.classList.remove("is-drawer-in")}
                            >
                                {item.text}
                                {item.span ? <span className={item.span}></span> : ''}
                            </NavLink>
                            }
                        </li>
                    )))
                }
                {!props?.isReader &&
                    drawerList.map(((item, index) => (
                        <li className="list-item" key={Math.random()}>
                            <NavLink reloadDocument
                                className="drawer-link"
                                to={`${chooseURL(item.text)}`}>
                                {item.text}
                            </NavLink>
                        </li>
                    )))
                }
            </ul>
        </div>
    );
}

export default MainDrawer;