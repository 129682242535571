import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
// Helper
import GenericBox from "../Helper/GenericsBox/GenericBox";
import Wrapper from "../Helper/Wrapper/Wrapper";
import PubblicitaBox from "../Helper/PubblicitaBox/PubblicitaBox";
// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Prodotti from "../Prodotti/Prodotti";
import ListAreaTematica from "../Prodotti/ThemeArea/ListAreaTematica/ListAreaTematica";
import Ricerca from "../Header/Ricerca/Ricerca";
import AuthContext from "../../store/auth-context";
import TokenContext from "../../store/token-context";
import { config } from "../../constants/Constants";
import ManageProductListsContext from "../../store/manage-products-list-context";
import LoginModal from "../Modals/Login";
import Spinner from "../UI/Spinner/Spinner";
import isLoadingContext from "../../store/isLoadingcontext";
import MessageManageSiac from "../Modals/MessageManageSiac";
import { updateView } from "../../helper/TealiumHelper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { checkSoleaAuth } from '../../helper/helper';
import { useNavigate } from "react-router-dom";
import SessioneScaduta from "../Modals/SessioneScaduta"
import useHttpRequest from '../../hooks/http-request';

const Home = (props) => {
    const [ultimeUscite, setUltimeUscite] = useState([]);
    const [continuaALeggere, setContinuaALeggere] = useState([]);
    const [strumentiPerProfessionisti, setStrumentiPerProfessionisti] = useState([]);
    const [proposteDiAbbonamento, setProposteDiAbbonamento] = useState([]);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();
    const productListsCtx = useContext(ManageProductListsContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const { isBookIntegrate } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(!isBookIntegrate) return;
            sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
      }, [isBookIntegrate]);
    useEffect(() => {

        let loadLists = setTimeout(() => {

            productListsCtx.getList(config.nameList.ultimeUscite);
            productListsCtx.getList(config.nameList.strumentiProf);
            productListsCtx.getList(config.nameList.proposteAbbon);
            if (authCtx.username !== "Utente") {
                productListsCtx.getFindByQuery(config.nameList.leggendo);
            }

            if (!isLoadingContext.isLoading) isLoadingCtx.setIfIsLoading(true);


        }, 200);

        return () => {
            clearTimeout(loadLists);
        };

    }, [tokenCtx.token, authCtx.username, tokenCtx.userModulesWithId]);

    useEffect(() => {
        setUltimeUscite(productListsCtx.ultimeUscite);
    }, [productListsCtx.ultimeUscite]);

    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });

    useEffect(() => {
        setContinuaALeggere(productListsCtx.continuaALeggere);
        //isLoadingCtx.setIfIsLoading(false);
    }, [productListsCtx.continuaALeggere]);

    useEffect(() => {
        setProposteDiAbbonamento(productListsCtx.proposteDiAbbonamento);
    }, [productListsCtx.proposteDiAbbonamento]);

    useEffect(() => {
        setStrumentiPerProfessionisti(productListsCtx.strumentiPerProfessionisti);
    }, [productListsCtx.strumentiPerProfessionisti]);

    /*const deleteProductList = (idForDelete) => {
        productListsCtx.deleteProdToContinuaALeggere(idForDelete);
    };*/

    useEffect(() => {
        isLoadingCtx.setIfIsLoading(false, 1200);
        
    }, [isLoadingCtx.isLoading]);

    useEffect(() => {
        if (!(document.body.className.includes('is-home'))) {
            document.body.className += ' is-home';
        }
    }, []);

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView("book24ore:home", authCtx, tokenCtx);

    }, [tokenCtx.token, authCtx.isLoggedIn, window.utag])

    useEffect(() => {
        const soleauth = checkSoleaAuth();
        if((soleauth !== undefined && soleauth !== '' && soleauth !== null) && localStorage.getItem("isLoggedIn") === "1" && isLoadingCtx.isLoading){
          navigate(`/miei-libri`);
        }
    
      }, [localStorage.getItem("isLoggedIn")]);
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>"Book24ORE - Il Sole 24 ORE"</title>
                    <meta name="description" content="Book24 ORE, il nuovo servizio in abbonamento del Gruppo 24 ORE: cerca tra oltre 100 titoli firmati Il Sole 24 Ore in diverse aree tematiche consultabili on line." />
                    <link rel="canonical" href={window.location.href} />
                    <meta name="keywords" content="libri, volumi, informazione professionale, edicola, diritto, fisco, lavoro, aziende, pubblica amministrazione" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta property="og:title" content="Book24ORE - Il Sole 24 ORE" />
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content="https://book24.ilsole24ore.com" />
                    <meta property="og:image" content={config.imgGenericOG} />
                    <meta property="og:image:width" content="450" />
                    <meta property="og:image:height" content="370" />
                    <meta property="og:site_name" content="Book24ORE - Il Sole 24 ORE" />
                    <meta property="og:description" content="Book24 ORE, il nuovo servizio in abbonamento del Gruppo 24 ORE: cerca tra oltre 100 titoli firmati Il Sole 24 Ore in diverse aree tematiche consultabili on line." />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Book24ORE - Il Sole 24 ORE" />
                    <meta name="twitter:description" content="Book24 ORE, il nuovo servizio in abbonamento del Gruppo 24 ORE: cerca tra oltre 100 titoli firmati Il Sole 24 Ore in diverse aree tematiche consultabili on line." />
                    <meta name="twitter:image" content={config.imgGenericOG} />
                </Helmet>

                <Wrapper>
                    <Header showSecondRow={true} showLogoSole={true} isDesktop={props.isDesktop} isIntegrateInBD={props.isIntegrateInBD}></Header>
                    {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                    {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                    {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                    {isLoadingCtx.isLoading && <Spinner></Spinner>}
                    <div className="main-content">
                        <Prodotti title="Ultime uscite"
                            prodList={ultimeUscite}
                            isSubscription={false}
                            isManageable={false}
                            hasBookmark={false}
                            sectionClassName=""
                            classNameCard="card card--book "
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}
                            isAllList={false} />
                        {authCtx.isLoggedIn && <Prodotti title="Continua a leggere"
                            prodList={continuaALeggere}
                            isSubscription={false}
                            isManageable={true}
                            hasBookmark={true}
                            sectionClassName="section--light"
                            classNameCard="card card--book card--action"
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}
                            isAllList={false}
                        /*deleteProductList={deleteProductList}*/
                        />}
                        <ListAreaTematica />
                        {!tokenCtx.token && 
                        <GenericBox
                            text="Book24ORE è la biblioteca digitale del Sole 24 Ore con centinaia di libri specializzati e tutti i fascicoli di attualità per i professionisti. Scegli tra i vari pacchetti proposti per area tematica e per tipologia e scopri tutte le funzionalità."
                            scopriDiPiu={config.linkScopriDiPiu}
                            imgCover="dist\img\fascia--scopri-di-piu.png"
                        />
                        }
                        <Prodotti title="Proposte di abbonamento"
                            prodList={proposteDiAbbonamento}
                            isSubscription={true}
                            isManageable={false}
                            hasBookmark={false}
                            sectionClassName=""
                            classNameCard="card card--book "
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}
                            isAllList={false}
                            isAbbonamento={true} />
                        <PubblicitaBox img={config.imgAdv} />
                        <Prodotti title="Strumenti per i professionisti"
                            prodList={strumentiPerProfessionisti}
                            isSubscription={true}
                            isManageable={false}
                            hasBookmark={false}
                            sectionClassName=""
                            classNameCard="card card--tool"
                            dataSlide="3"
                            dataSlideMd="2"
                            isProfessionalTool={true}
                            isAllList={false} />

                    </div>

                    <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                </Wrapper>
                <Ricerca></Ricerca>
            </HelmetProvider>
        </React.Fragment >
    );
}

export default Home;