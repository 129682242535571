import React, { useContext, useEffect, useState } from "react";
import { config } from "../../../constants/Constants";
import useSaveAndDeleteReadingBook from "../../../hooks/saveAndDeleteReadingBook";
import AuthContext from "../../../store/auth-context";

const ReaderToolsBookmarks = (props) => {

    const saveDeleteB = useSaveAndDeleteReadingBook();
    const authCtx = useContext(AuthContext);
    const [isSaved, setIsSaved] = useState(true);

    const handleSaved = () => {

        if (!authCtx.isLoggedIn) {
            return;
        }

        const settingsSaved = (res, name) => {

            setIsSaved(res);
            props.handleSaved({ res: res, name: name, nameMenu: 'bookmarks' });
        }

        const obj = {
            prop1: props.idProv,
            prop2: props.idDoc
        };

        saveDeleteB.getAlreadySaved(config.nameList.segnalibro, obj, props.buyedOrFreeContent, true, settingsSaved);

    };

    useEffect(() => {

        let callingHttp = setTimeout(() => {
            const setWhenResIsBack = (res) => {
                setIsSaved(res);
            }

            const obj = {
                prop1: props.idProv,
                prop2: props.idDoc
            };


            if (authCtx.isLoggedIn && props?.idDoc) {
                saveDeleteB.getAlreadySaved(config.nameList.segnalibro, obj, props.buyedOrFreeContent, false, setWhenResIsBack);
            }
        }, 400);

        return () => {
            clearTimeout(callingHttp);
        }

    }, [authCtx.isLoggedIn, props.idDoc]);

    return (
        <React.Fragment>
            <li className={props.class}>
                <a className={`itool ${(!authCtx.isLoggedIn || isSaved) ? 'is-active' : ''}`} role="button" data-toggle-class="is-toast-in" onClick={handleSaved}>
                    <span className="icon icon-bookmark"></span>
                </a>
            </li>
        </React.Fragment>
    );
}

export default ReaderToolsBookmarks;