import React from 'react';
import { useNavigate } from 'react-router-dom';

const SecondResearchHeader = (props) => {

    const navigate = useNavigate();

    const isNtPro = props.isNtPro;
    const isNotReader = localStorage.getItem("isReader") === null;

    const goTo = () => {

        localStorage.removeItem("img");
        localStorage.removeItem("titleNotSlug")
        const id = localStorage.getItem("idDocument") === "undefined" || localStorage.getItem("idDocument") === null ? localStorage.getItem("id") : localStorage.getItem("idDocument");
        
        if (!isNotReader) {
            localStorage.removeItem("word");
            localStorage.removeItem("isReader");
            localStorage.removeItem("id");
        }
        
        if (props.isNtPro) {
            navigate(-1);
        }

        navigate(isNotReader ? "/ricerca/1" : "/reader/" + id);
    }

    return (
        <div className="mhead mhead--sticky" data-main-header style={ isNtPro ? { "marginBottom":'0px !important'} : {}}>
            <div className="mhead-top" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <div className="container">
                    <div className="mhead-modal">
                        <h2 className="mhead-modal-title">
                            {props.title}
                        </h2>
                        <a className="mhead-modal-close" onClick={goTo} role="button">
                            <span className="icon icon-close"></span>
                            <span className="mhead-modal-close-label">Chiudi</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecondResearchHeader;