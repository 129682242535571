import React, { useContext, useEffect, useRef, useState } from 'react';
import { config } from "../../../constants/Constants";
const ReaderCorrelati = (props) => {

  const idDoc = props.id;

  const listCorrelati = props.listCorrelati;
  const url = document.location.ancestorOrigins[0] + "/private/default.aspx";

    return (
        <React.Fragment>
           { listCorrelati && listCorrelati.length !== 0 &&
            <div className="reader-box-wrapper">
              <div className="reader-box">
                <h5 className="reader-box-title">Correlati in evidenza</h5>
                <ul className="list list--large">
                {listCorrelati.map(((item, index) => (
                    <li className="list-item" key={item.idDocumento}>
                      
                        <a target="_parent" className="bdcard" href={url + "#showdoc/" + item.idDocumento + "/?ref=book24"}>
                <div className="ncard-fam ">
                  <h4 className="h-meta">
                    
                      <span className="upper">{item.famiglia}</span>
                      <span className="pipe">|</span>
                      <span className="serif italic">{item.sottoFamiglia}</span>
                    
                  </h4>
                </div>



                <div className="ncard">
                  
                    <h3 className="ncard-title " dangerouslySetInnerHTML={{ __html: item.text1 }} />
                  
                  
                    <p className="ncard-subtitle" dangerouslySetInnerHTML={{ __html: item.text2 }} />
                  
                  <p className="ncard-text"  dangerouslySetInnerHTML={{ __html: item.abstract }} />
                  
                </div>
                </a>

                      
                    </li>
                )))
              }
                </ul>
              </div>
            </div>
          }
        </React.Fragment>
    );
}

export default ReaderCorrelati;