import { useContext, useState } from "react";
import { config } from "../constants/Constants";
import { convertArrayBookmarksForFe } from "../helper/castingObject";
import AuthContext from "../store/auth-context";
import TokenContext from "../store/token-context";
import useHttpRequest from "./http-request";

const useGetAndManageBookMarksAndNotes = () => {

    const [result, setResult] = useState();

    const loginCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();

    const getBookmarksConfig = (name, obj, genericFunc) => {

        const getBookmarksList = (documentsId) => {

            const settingsListBookmarks = (data) => {
                
                let res = data.Result
                if(res === null) genericFunc([]);

                res = res.ResultSet.Root;

                const arrayForFe = convertArrayBookmarksForFe(res);

                genericFunc(arrayForFe);
            };

            const bodyFormatted = {
                "parameters": {
                    "name": config.nameList.getInformationBookmarks,
                    "inputParameters": [{
                        "name": "documenti",
                        "type": "VarChar",
                        "value": documentsId
                    }],
                    "staticToken": config.token.STATIC_TOKEN
                },
                "token": ""
            };

            const rqs = {
                url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
                req: {
                    headers: {
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                        'Content-Type': 'application/json',
                        'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                    },
                    body: JSON.stringify(bodyFormatted),
                    method: 'POST'
                }
            };

            sendRqst.sendRequest(rqs, settingsListBookmarks);
        }
        
        const bookmarksGetting = (data) => {

            let documentsId = "";

            data.Result?.Documents?.forEach((element,index) => {

                if(element.data.iddocumento === undefined) return; 

                documentsId = documentsId.concat(element.data.iddocumento);
                
                if (data.length - 1 !== index) 
                    documentsId = documentsId.concat(",");

            });
            getBookmarksList(documentsId);

        };

        if (tokenCtx.token === null) return true;

        let query = {
            tipo: name,
            appId: config.appId,
            "data.idprovvedimento": obj.prop2
        };

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, bookmarksGetting, false);
    };

    return {
        getBookmarks: getBookmarksConfig,
        result: result
    }
}

export default useGetAndManageBookMarksAndNotes;