import React from "react";
// React-Bootstrap
import Modal from 'react-bootstrap/Modal';


const Partecipazioni = (props) => {

    const handleClose = () => {
        props.closeModal({ showModal: false });
    };

    return (
        <Modal show={props.show} onHide={handleClose}>
            <div className="modal" tabIndex="-1" role="dialog" style={{ 'display': 'block' }}>
                <div className="modal-dialog modal-dialog-centered modal-message">
                    <div className="modal-content">
                        <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="icon icon-cross-small"></span>
                        </a>
                        <Modal.Header className="modal-header">
                            <h3 className="modal-title">Partecipa alla presentazione di Valore24 Business plan</h3>
                        </Modal.Header>

                        <Modal.Body className="modal-body">
                            <p className="modal-txt">
                                Scopri come gestire la pianificaione finanziaria e il controllo di gestione in maniera semplice, intuitiva e veloce.
                            </p>
                        </Modal.Body>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Partecipazioni;