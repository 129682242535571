import React from "react";
import { ChooseAreaTematica } from "../../Helper/ChooseAreaTematica/ChooseAreaTematica";
import { splitFisco, themeAreaDescription } from "../../../helper/helper"; 

const AreaHeader = (props) => {

    //chiamata per ritornare le info dell'area tematica

    let areaImg = `/dist/img/${props.themeArea}-themearea.png`;

    let infoAreaTematica = {
        title: splitFisco(props.themeArea),
        text: themeAreaDescription(props.themeArea),
        img: areaImg
    }; 

    let classToUse = ChooseAreaTematica(infoAreaTematica.title);
    
    return (
        <React.Fragment>
            <div className={`heading heading--img ${classToUse}`}> 
                <div className="heading-wrapper bgr">
                    <div className="container">
                        <h1 className="heading-title clr">
                            {infoAreaTematica.title}
                        </h1>
                        <p className="heading-txt clr">
                            {infoAreaTematica.text}
                        </p>
                    </div>
                    <figure className="heading-img">
                        <img className="img-cover" src={infoAreaTematica.img} alt=""/>
                    </figure>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AreaHeader;