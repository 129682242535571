import React from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";
import ManageThematicArea from "../../../UI/ManageThematicArea/ManageThematicArea";
import Novita from "../../../UI/Novita/Novita";
import InAggiornamento from "../../InAggiornamento/InAggiornamento";
import ListaAutori from "../../ListaAutori/ListaAutori";

const LibroPacchetto = (props) => {

    const img = props.prodotto.img === "" ? config.imgDefaultLibro : props.prodotto.img;
    const title = titleUrlSlug(props.prodotto?.title?.split("/")[0].replaceAll(" ", "_"));
    
    return (
        <React.Fragment>
            <li className="list-item">
                <div className="card card--hor  ">
                    <div className="card-box">
                        <div className="card-content">
                            <figure className="card-img ">
                                <NavLink to={"/Prodotto/" + title + "/" + props.prodotto.id + "/SchedaDescrizione"} onClick={(ev) => {updateLink({ title: props.prodotto.title, name: "prodotto" }, props.titleContainer, props.pacchetto) }}>

                                    <span className="card-img-book">
                                        <img src={img} alt="" />
                                    </span>

                                </NavLink>
                            </figure>


                            <div className="card-body">
                                <div className="card-body-main">
                                    <h4 className="card-title">
                                        <NavLink to={"/Prodotto/" + title + "/" + props.prodotto.id + "/SchedaDescrizione"} onClick={(ev) => {updateLink({ title: props.prodotto.title, name: "prodotto" }, props.titleContainer, props.pacchetto) }}>
                                            {props.prodotto.title}
                                        </NavLink>
                                    </h4>

                                    <p className="card-auth">
                                        <ListaAutori listAutori={props.prodotto.listAutori}></ListaAutori>
                                    </p>
                                    <p className="card-time">
                                        {`${props.isUpdating || props.isUpdating === "1" ? "Aggiornato il: " : "Pubblicato il: "}`} {props.prodotto.aggiornato}
                                    </p>
                                </div>

                                <div className="card-foot card-foot--flex d-none d-md-flex">
                                    <ul className="list-inline list-inline--small">
                                        <ManageThematicArea themeArea={props.prodotto.themeArea}></ManageThematicArea>
                                    </ul>
                                    <InAggiornamento isUpdating={props.prodotto.isUpdating}></InAggiornamento>
                                </div>
                            </div>
                        </div>
                        {props.prodotto.isNew && <Novita />}
                    </div>
                </div>
            </li>
        </React.Fragment>
    );
}

export default LibroPacchetto;