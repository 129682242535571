import React, { useEffect, useState } from 'react'
import CurrentLevel from './CurrentLevel'
import Expander from './Expander'
import { useParams } from 'react-router'
import { useContext } from 'react'
import { TreeItem, TreeView } from '@material-ui/lab'
import TreeNode from '../../classes/TreeNode'
import TreeContext from '../../store/context-tree'
import useContextMenu from '../../hooks/useContextMenu'



const BDTree = (props) => {

    const [index, setIndex] = useState(null);
    const treeCtx = useContext(TreeContext);
    const { getMobileOperatingSystem } = useContextMenu();
    const { id, order, title } = useParams();


    const [expanded, setExpanded] = useState(["0"]);
    const [selected, setSelected] = useState("0");
    const [updateTree, setUpdateTree] = useState(false);
    const [tree, setTree] = useState(new TreeNode({
        id: "0",
        name: title.replaceAll("-", " "),
        children: props.sommario,
        leaf: false,
        parent: "0",
        livello: 0
    })
    );

    useEffect(() => {

        if (treeCtx.idDocument === id) {
            setUpdateTree(true);
            return;
        }

        treeCtx.settingsInitialState(title, props.sommario, order, id);

    }, [id]);

    useEffect(()=> {

        if(treeCtx.goToElementId === "-1" || treeCtx.goToElementId === undefined || tree.children.length === 0) 
            return;

        const element = document.getElementById(treeCtx.goToElementId);
        element.scrollIntoView();
        window.scroll(0, 0);        

    },[treeCtx.goToElementId, tree])

    useEffect(() => {

        if (Object.keys(treeCtx.tree).length === 0) return;

        setTree(treeCtx.tree);

    }, [treeCtx.tree]);

    useEffect(() => {

        setSelected(treeCtx.selected);
    }, [treeCtx.selected]);

    useEffect(() => {
        
        setExpanded(treeCtx.expanded);
    }, [treeCtx.expanded]);

    useEffect(() => {

        if (Object.keys(treeCtx.tree).length === 0 || tree.children.length > 0) {
            return;
        }

        treeCtx.handleChange(undefined, "0", undefined);

    }, [tree])

    useEffect(() => {

        if (!updateTree || tree.children.length === 0) {
            return
        }

        const element = document.querySelectorAll(`[data-value='${treeCtx.elementHtml}']`);
        if(element[0] === undefined) return;
        element[0].scrollIntoView();
        window.scroll(0, 0);
        setUpdateTree(false);

    }, [updateTree, tree])

    const handleChange = (event, nodeId, label) => {
        treeCtx.handleChange(event, nodeId, label);
    };

    const getDocumentLeaf = (event) => {
        treeCtx.getDocumentLeaf(event);
    }
    const setExpandedLast = (event) => {
        const isMobile = getMobileOperatingSystem();
        if(!isMobile) return;
        setExpanded([]);
    }
    const dubleFunction = (event) => {
        getDocumentLeaf(event);
        setExpandedLast(event);
    }
    const createItemFromTree = (fromTree) => {

        const name = fromTree.name;

        if (fromTree.children.length) {
            return (
                <React.Fragment>
                    
                    <TreeItem
                        key={fromTree.id}
                        nodeId={fromTree.id}
                        id={fromTree.id}
                        label={
                            <li className="list-lined-item sole-sans" style={{display: 'block' }}>
                                <a className="bdindex-tree-link" role={"button"}>
                                    <span className="icon icon-angle-right icon--status"></span>
                                    <span className="icon icon-folder"></span>
                                    {name}
                                </a>
                            </li>
                        }
                    >
                        <ul className="bdindex-list">
                            {fromTree.children.length > 0 &&
                                fromTree.children.map((child) => createItemFromTree(child)
                                )}
                        </ul>
                    </TreeItem>
                </React.Fragment >
            );
        }
        return (
            <React.Fragment>
                {
                    fromTree.leaf &&
                    <li key={fromTree.id} className="list-lined-item sole-sans last-level" style={{display: 'block' }}>
                        <a className="bdindex-tree-link" role={"button"} onClick={dubleFunction} data-value={fromTree.id}>
                            <span className="icon icon-angle-right icon--status"></span>
                            <span className="icon icon-folder"></span>
                            {fromTree.name}
                        </a>
                    </li>
                }
                {
                    !fromTree.leaf &&
                    <TreeItem
                        key={fromTree.id}
                        nodeId={fromTree.id}
                        id={fromTree.id}
                        label={
                            <li className="list-lined-item sole-sans " style={{display: 'block' }}>
                                <a className="bdindex-tree-link" role={"button"}>
                                    <span className="icon icon-angle-right icon--status"></span>
                                    <span className="icon icon-folder"></span>
                                    {name}
                                </a>
                            </li>
                        }
                    />
                }
            </React.Fragment >
        );
    };

    return (
        <React.Fragment>
            <div className="col-lg-4 bdindex-tree">
                <div className="bdindex-scroll">
                    <TreeView
                        selected={selected}
                        onNodeSelect={handleChange}
                        expanded={expanded}
                    >
                        {createItemFromTree(tree)}
                    </TreeView>
                </div>
                <Expander />
            </div>
        </React.Fragment>
    );
};

export default BDTree;