import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import GoHome from '../UI/GoHome/GoHome';
import BookmarksNotification from './BookmarksNotification';
import NotesNotification from './NotesNotification';

const HeaderRightIcon = (props) => {

    const [notes, setNotes] = useState([]);

    useEffect(() => {

        if (!props.notesForMenu || props.notesForMenu.length === 0) return;

        let notesMerge = []
        props.notesForMenu.forEach(el => {
            notesMerge = [...notesMerge, ...el.data.note];
        });
        
        setNotes(notesMerge);

    }, [props.notesForMenu]);

    return (
        <React.Fragment>
            <GoHome isIntegrateInBD={props.isIntegrateInBD}></GoHome>
            {props.bookmarks !== null &&
                <li className="list-inline-item">
                    <BookmarksNotification bookmarks={props.bookmarks}></BookmarksNotification>
                </li>
            }
            {notes.length > 0 && props.tipoDocument === 'html' &&
                <li className="list-inline-item">
                    <NotesNotification notes={notes} notesForMenu={props.notesForMenu}></NotesNotification>
                </li>
            }
        </React.Fragment>
    );
}

export default HeaderRightIcon;