import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const SearchCardFooterItem = (props) => {

    const prodotto = props.prodotto;

    const [isPDF, setIsPDF] = useState(0);

    useEffect(() => {

        props.resultBuyOrNot.forEach(element => {

            if (element.idProv.toString() === props.idProv.toString()) {
                setIsPDF(element.isPDF);
            }
        });
    },[props.resultBuyOrNot]);

    return(
        <li className="list-lined-item">
            <h6 className="card-search-title">
                <button 
                    className={(props.itemId === 0) ? '' : 'collapsed'}
                    type="button" data-toggle="collapse" 
                    data-target={`#res-${props.resultId}-${props.itemId}`} 
                    aria-expanded={(props.itemId === 0) ? 'true' : 'false'} >

                    {prodotto.editore}
                    <span className="icon icon-angle-right"></span>
                </button>
            </h6>
            <div 
                className={`collapse ${(props.itemId === 0) ? 'show' : ''}`} 
                id={`res-${props.resultId}-${props.itemId}`} 
                data-parent="#res-card-1">

                <p className="card-search-txt">
                   {prodotto.abstract}
                </p>
                <NavLink className="card-search-goto" to={`/reader/${prodotto.idDocumento}`}>
                    {`${isPDF === 1 ? "Vai al libro" : "Vai al capitolo"}`} <span className="icon icon-arrow-right-small"></span>
                </NavLink>
            </div>
        </li>
    );
}

export default SearchCardFooterItem;