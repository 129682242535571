import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from "../components/Footer/Footer";
import Ricerca from '../components/Header/Ricerca/Ricerca';
import HeaderReader from '../components/HeaderReader/HeaderReader';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import ReaderFoot from '../components/ReaderFoot/ReaderFoot';
import ReaderHead from '../components/ReaderHead/ReaderHead';
import ReaderHeadGoTo from '../components/ReaderHead/ReaderHeadGoTo';
import ReaderHeadSwitchDocPerApprofondire from '../components/ReaderHead/ReaderHeadSwitchDocPerApprofondire';
import ReaderMeta from '../components/ReaderHead/ReaderMeta';
import ReaderTitle from '../components/ReaderHead/ReaderTitle';
import ReaderContentContainer from '../components/ReaderMain/ReaderContentContainer';
import ReaderMain from '../components/ReaderMain/ReaderMain';
import RenderParagraphs from '../components/ReaderMain/RenderParagraphs';
import ReaderMainContainer from '../components/ReaderMain/ReaderMainContainer';
import ReaderTools from '../components/ReaderMain/ReaderTools/ReaderTools';
import ReaderBoxTop from '../components/ReaderMain/ReaderBoxes/ReaderBoxTop';
import { config } from '../constants/Constants';
import { bodyFormattedProvvedimenti, bodyFormattedGetTipoView, bodyFormattedReader, castArrayForFELittleSumm, chooseBody, chooseDocument, chooseURL, FormattedDocumentForFE, viewContent } from '../helper/helperFunctionReader';
import { bodyFormattedRelDoc, bodyFormattedRefDoc } from '../helper/helperFunctionGetDocument';
import useHttpRequest from '../hooks/http-request';
import TokenContext from '../store/token-context';
import ReaderBlockMask from '../components/ReaderMain/ReaderBlockMask/ReaderBlockMask';
import AuthContext from '../store/auth-context';
import LoginModal from '../components/Modals/Login';
import Toast from '../components/UI/Toast/toast';
import useSaveAndDeleteReadingBook from '../hooks/saveAndDeleteReadingBook';
import { useReactToPrint } from 'react-to-print';
import isLoadingContext from '../store/isLoadingcontext';
import Spinner from '../components/UI/Spinner/Spinner';
import ReaderDropdownNote from '../components/ReaderMain/ReaderDropdownNote';
import useManangeNoteAndTagOnReader from '../hooks/useManageNoteAndTagOnReader';
import AddOrUpdateNote from '../components/Modals/AddOrUpdateNote';
import AddOrUpdateTags from '../components/Modals/AddOrUpdateTags';
import ConfirmResp from '../components/Modals/ConfirmResp';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import useGetAndManageBookMarksAndNotes from '../hooks/getAndManageBookmarksAndNotes';
import ReaderToolsMobile from '../components/ReaderMain/ReaderTools/ReaderToolsMobile';
import ManageProductListsContext from '../store/manage-products-list-context';
import ViewerPdfExpress from '../components/ViewerPdf/ViewerPdfExpress';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { updateView } from '../helper/TealiumHelper';
import { titleUrlSlugForTealium, getCookie } from '../helper/helper';
import SessioneScaduta from "../components/Modals/SessioneScaduta";
import SchedaPerApprofondire from './SchedaPerApprofondire';
import ReaderBoxesBottom from '../components/ReaderMain/ReaderBoxes/ReaderBoxesBottom';
import ReaderLine from '../components/UI/ReaderLine/ReaderLine';
const Reader = (props) => {

    const { id } = useParams();

    const [tipoDocument, setTipoDocument] = useState(null);
    const [zoomText, setZoomText] = useState("");
    const [updatedList, setUpdateList] = useState("all");
    const [bookmarks, setBookmarks] = useState(null);
    const [refreshBookmarks, setRefreshBookmarks] = useState(false);
    const [littleSumm, setLittleSumm] = useState([]);
    const [documentReturns, setDocumentReturns] = useState({});
    const [buyedOrFreeContent, setBuyedOrFreeContent] = useState(true);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [nextChap, setNextChap] = useState({});
    const [prevChap, setPrevChap] = useState({});
    const [notes, setNotes] = useState({ data: { note: [] } });
    const [notesForMenu, setNotesForMenu] = useState([]);
    const [textWithNote, setTextWithNote] = useState(null);
    const [getTipo, setGetTipo] = useState({});
    const [showAddUpdateNote, setShowAddUpdateNote] = useState(false);
    const [showAddUpdateTagNote, setShowAddUpdateTagNote] = useState(false);
    const [showDeleteNote, setShowDeleteNote] = useState(false);
    const [tagMostUsed, setTagMostUsed] = useState([]);
    const [rerender, setRerender] = useState(0);
    const [rightToastIn, setRightToastIn] = useState({ message: "", classHeader: "", iconBody: "" });
    const [normaCommentata, setNormaCommentata] = useState({});

    const token = getCookie("plus_token_forDomain");
    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const productListsCtx = useContext(ManageProductListsContext);
    const sendRqst = useHttpRequest();
    const saveDeleteB = useSaveAndDeleteReadingBook();
    const noteAndTag = useManangeNoteAndTagOnReader();
    const getBooksAndNotes = useGetAndManageBookMarksAndNotes();
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const { isBookIntegrate } = useParams();
    const [listNormaCommentata, setListNormaCommentata] = useState([]);
    const [listCorrelati, setListCorrelati] = useState([]);
    const [listDocReferences, setDocReferences] = useState([]);
    const [listDisIntegrative, setListDisIntegrative] = useState([]);
    const [listRiferimenti, setRiferimenti] = useState([]);

    useEffect(() => {
        //tokenCtx.loadProductFromToken(token);
    
        getRiferimenti(id);
    }, [id, listDocReferences]);
    
    const getRiferimenti = (idDocument) => {
    
      const riferimenti = (data) => {
    
          let riferimenti = null;
          if (data.Result.ResultSet !== null)
          riferimenti = data.Result.referencesFamiglia;
    
          setRiferimenti(riferimenti);
      }
    
      const bodyFormatted = bodyFormattedRefDoc(idDocument, listDocReferences, token);
    
      
      const request = {
          url: config.url.BACKEND_SERVICE + config.serviceToCall.getCountReferences,
          req: {
              headers: {
                  'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                  'Content-Type': 'application/json-patch+json',
                  'accept': "*/*",
                  'jsonorb-addcache': config.jsonorb.jsonorb_addCache,
    
              },
              body: JSON.stringify(bodyFormatted),
              method: 'POST'
          }
      };
    
      sendRqst.sendRequest(request, riferimenti);
    };
    useEffect(() => {
        //tokenCtx.loadProductFromToken(token);
    
        getDispIntegrative(id);
    }, [id]);
    
    const getDispIntegrative = (idDocument) => {
    
      const dispIntegrative = (data) => {

          let dispIntegrative = null;
          if (data.Result.ResultSet !== null)
          dispIntegrative = data.Result.Documents;
    
          setListDisIntegrative(dispIntegrative);
      }
      const row = "2";
    
      const bodyFormatted = bodyFormattedRelDoc(idDocument, row, token);
    
      
      const request = {
          url: config.url.BACKEND_SERVICE + config.serviceToCall.getDisposizioni,
          req: {
              headers: {
                  'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                  'Content-Type': 'application/json-patch+json',
                  'accept': "*/*",
                  'jsonorb-addcache': config.jsonorb.jsonorb_addCache,
    
              },
              body: JSON.stringify(bodyFormatted),
              method: 'POST'
          }
      };
    
      sendRqst.sendRequest(request, dispIntegrative);
    };

    useEffect(() => {
        //tokenCtx.loadProductFromToken(token);

        getCorrelati(id);
    }, [id]);

    const getCorrelati = (idDocument) => {

        const correlatiEvidenza = (data) => {

            let correlatiEvidenza = null;
            if (data.Result.ResultSet !== null)
                correlatiEvidenza = data.Result.Documents;

            setListCorrelati(correlatiEvidenza);

            let docReferences = [];

            correlatiEvidenza.forEach(element => {
                docReferences.push(element.idDocumento)
            });
            setDocReferences(docReferences)
        }
        const row = "2";

        const bodyFormatted = bodyFormattedRelDoc(idDocument, row, token);


        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getRelatedDocuments,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'accept': "*/*",
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache,

                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, correlatiEvidenza);
    };

    useEffect(() => {
        getNormaCommentata(id);
    }, [id, tokenCtx.allProvvedimenti]);

    const getNormaCommentata = (idDocument) => {
        tokenCtx.loadProductFromToken(token);
        const normaCommentata = (data) => {

            let normaCommentata = null;
            if (data.Result.ResultSet !== null)
                normaCommentata = data.Result.ResultSet.Root;

            setListNormaCommentata(normaCommentata);
        }

        const provv = tokenCtx.allProvvedimenti;

        const bodyFormatted = bodyFormattedProvvedimenti(idDocument, provv, config.nameList.getNormaCommentata, token);


        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, normaCommentata);
    };
    useEffect(() => {
        if (!isBookIntegrate) return;
        sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
    }, [isBookIntegrate]);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        /*if (localStorage.getItem("token") !== null || localStorage.getItem("token") !== undefined && (!authCtx.isLoggedIn && tokenCtx.token !== undefined && tokenCtx.token !== null)) {
            return;
        }*/

        isLoadingCtx.setIfIsLoading(true);


            if (authCtx.isLoggedIn && tokenCtx.token !== undefined && tokenCtx.token !== null) {
                productListsCtx.getFindByQueryGiaLetto(config.nameList.giaLetti);
            }

            const tipoDocument = (data) => {

                let typeDocument = null;


                if (data.Result.ResultSet.Root === null) {
                    setTipoDocument(typeDocument);
                    return;
                }
                const getTipoV = data.Result.ResultSet.Root[0];
                typeDocument = chooseDocument(getTipoV.tipo);

                setTipoDocument(typeDocument);
                setGetTipo(getTipoV);
                const showPremiumContent = viewContent(getTipoV, tokenCtx);
                setBuyedOrFreeContent(showPremiumContent);
                loadDocument(typeDocument, getTipoV.pdfurl, showPremiumContent, getTipoV.comprato, getTipoV);
            };

            const request = {
                url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
                req: {
                    headers: {
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                        'Content-Type': 'application/json',
                        'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                    },
                    body: JSON.stringify(bodyFormattedGetTipoView(config.nameList.tipoDocument, id, tokenCtx)),
                    method: "POST"
                }
            };

            sendRqst.sendRequest(request, tipoDocument);


        

    }, [id]);

    const loadDocument = (typeDocument, pdfUrl, showPremiumContent, isComprato, getTipoV) => {

        const setDocumentJson = (data) => {

            let document = {};

            if (typeDocument !== 'Sistema Frizzera') {
                testataHtml(data.Result.DocumentId, data.Result, typeDocument, showPremiumContent, pdfUrl, getTipoV);
                //isLoadingCtx.setIfIsLoading(false, 0);
                return;
            }

            document = data.Result;

            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent);
            if (getTipoV.free === "1") {
                updateView(`book24ore:reader:${titleUrlSlugForTealium(res.mainTitle)}:anteprima`, authCtx, tokenCtx, `book24ore:reader-anteprima`);
            }
            else if (getTipoV.comprato === "1" && tokenCtx.token !== null) {
                updateView(`book24ore:reader:${titleUrlSlugForTealium(res.mainTitle)}:paid`, authCtx, tokenCtx, `book24ore:reader-paid`);
            }
            setDocumentReturns(res);
            if (authCtx.isLoggedIn && tokenCtx.token !== null && tokenCtx.token !== undefined) {
                saveDeleteB.setSaveBook(config.nameList.leggendo, { prop1: parseInt(res.idProvv), prop2: parseInt(res.idDocType), prop3: parseInt(res.id) }, showPremiumContent);
            }

            loadLittleSumm();
            if (typeDocument === 'Sistema Frizzera') {
                var pacchetti = "4553";
                settingNormaCommentataConfig(data.Result.DocumentId, pacchetti);
            }
        };

        if (typeDocument === 'pdf' && pdfUrl !== null) {
            setPdfDoc(pdfUrl);
        }

        const bodyFormatted = chooseBody(typeDocument, id, tokenCtx.token, isComprato, pdfUrl);

        if (bodyFormatted === null)
            return;

        const url = chooseURL(typeDocument);

        const request = {
            url: url,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        }

        sendRqst.sendRequest(request, setDocumentJson);
    };

    const settingNormaCommentataConfig = (id, pacchetti) => {

        const settingNormaCommentata = (data) => {

            let normaCommentata = null;

            if (data.Result.ResultSet !== null)
                normaCommentata = data.Result.ResultSet.Root[0];

            setNormaCommentata(normaCommentata);
        };

        const bodyFormatted = {
            "parameters": {
                "name": config.serviceDocumenti.normaCommentata,
                "inputParameters": [{
                    "name": "iddocumento",
                    "type": "Int",
                    "value": id
                }, {
                    "name": "idpacchetti",
                    "type": "VarChar",
                    "value": pacchetti
                }],
                "staticToken": authCtx.isLoggedIn ? "" : config.token.STATIC_TOKEN
            },
            "token": authCtx.isLoggedIn ? tokenCtx.token : ""
        };


        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, settingNormaCommentata);
    };

    const loadLittleSumm = () => {

        const setLittleSummJson = (data) => {

            if (data.Result.ResultSet === null) {
                setLittleSumm([]);
                return;
            }

            const objJson = castArrayForFELittleSumm(data.Result.ResultSet.Root);

            setLittleSumm(objJson.summ);
            setPrevChap(objJson.prevChap);
            setNextChap(objJson.nextChap);

            saveIsLastChapter(objJson.nextChap);


        }

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getLittleSumm, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setLittleSummJson);
    };

    const saveFinishedToRead = () => {

        const finishToSave = (data) => {
            productListsCtx.getFindByQueryGiaLetto(config.nameList.giaLetti);
        }

        const bodyFormatted = {
            data: {
                iddocumento: documentReturns.id,
                idprovvedimento: documentReturns.idProvv,
                iddoctype: documentReturns.idDocType
            },
            type: config.nameList.giaLetti,
            token: tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.save,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, finishToSave);
    }

    const testataHtml = (id, dataRes, typeDocument, showPremiumContent, pdfUrl, getTipoV) => {

        const setTestataDocumentHtml = (data) => {

            let document = data.Result.ResultSet;
            if (document === null) {
                isLoadingCtx.setIfIsLoading(false, 0);
                return;
            }
            document = document.Root[0];

            document = {
                Section: document.sezione,
                ChapterTitle: document.titolo,
                Date: document.data,
                page: document.pagina,
                Author: document.autore,
                TaxType: document.titolo,
                Immagine: document.immagine,
                MainTitle: document.sezione
            };

            document = { ...document, ...dataRes };

            const settingsFinalResult = (data) => {

                let documentText = '';

                if (data !== null && data.Result.Documents !== undefined) {
                    documentText = data.Result.Documents[0].data.documentText;
                    setTextWithNote(data.Result.Documents[0]);
                } else if (data !== null && data.Result.data !== undefined && data.Result.data.documentText !== '') {
                    documentText = data.Result.data.documentText;
                    setTextWithNote(data.Result);
                }

                document = { ...document, documentText: documentText };

                const settingsNote = (data) => {
                    //const res = formattedNoteArrayForFE(data);
                    if (data.Result !== null) {
                        let index = -1;

                        data.Result.Documents.forEach((el, indx) => {
                            if (document.DocumentId === el.data.iddocumento)
                                index = indx;
                        });

                        setNotes(index !== -1 ? data.Result.Documents[index] : { data: { note: [] } });
                        setNotesForMenu(data.Result.Documents);
                    }
                    else {
                        setNotes({ data: { note: [] } });
                        setNotesForMenu([])
                    }
                    isLoadingCtx.setIfIsLoading(false, 200);
                };

                noteAndTag.getNotes(config.nameList.note, { prop1: parseInt(document.ProvvId), prop2: parseInt(document.DocumentId), prop3: parseInt(document.DocTypeId) }, showPremiumContent, settingsNote);

                const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent, pdfUrl);

                if (getTipoV.free === "1") {
                    updateView(`book24ore:reader:${titleUrlSlugForTealium(res.mainTitle)}:anteprima`, authCtx, tokenCtx, `book24ore:reader-anteprima`);
                }
                else if (getTipoV.comprato === "1" && tokenCtx.token !== null) {
                    updateView(`book24ore:reader:${titleUrlSlugForTealium(res.mainTitle)}:paid`, authCtx, tokenCtx, `book24ore:reader-paid`);
                }

                setDocumentReturns(res);

                saveDeleteB.setSaveBook(config.nameList.leggendo, { prop1: parseInt(res.idProvv), prop2: parseInt(res.idDocType), prop3: parseInt(res.id) }, showPremiumContent);
                loadLittleSumm();
            }

            if (typeDocument === 'html' && authCtx.isLoggedIn) {
                noteAndTag.getDocumentEditWithNotes(config.nameList.documentiConNote, { prop1: parseInt(document.ProvvId), prop2: parseInt(document.DocumentId), prop3: parseInt(document.DocTypeId), prop4: document.TestoDoc }, showPremiumContent, settingsFinalResult)
                settingsFinalResult({ Result: { data: { documentText: '' } } });
            }
            else {
                settingsFinalResult({ Result: { data: { documentText: '' } } });
            }
        };

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getTestataHtml, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setTestataDocumentHtml);
    };

    const refreshDocument = (obj) => {

        setShowAddUpdateNote(obj);
        setShowAddUpdateTagNote(obj);
        setShowDeleteNote(obj);
        setRerender(prev => prev + 1);
    }

    const saveIsLastChapter = (nextChap) => {

        if (tokenCtx.token === null || tokenCtx.token === undefined || authCtx.username === "Utente" || productListsCtx.saveIsFinishToRead === undefined || tipoDocument === 'pdf' || documentReturns.id === undefined)
            return;

        let index = productListsCtx.saveIsFinishToRead === null ? -1 : productListsCtx.saveIsFinishToRead.map(x => x.idDoc).indexOf(documentReturns.id);

        if (Object.keys(nextChap).length === 0 && index === -1) {
            saveFinishedToRead();
        }
    };

    useEffect(() => {

        if (documentReturns.id === undefined && documentReturns.idProvv === undefined && !refreshBookmarks) return;

        const settingsList = (res) => {

            setBookmarks(res);
            setRefreshBookmarks(false);
        };

        if (updatedList === "bookmarks" || updatedList === "all")
            getBooksAndNotes.getBookmarks(config.nameList.segnalibro, { iddocumento: props.idDoc, idprovvedimento: props.idProv }, settingsList);


    }, [tokenCtx.token, documentReturns.id, documentReturns.idProvv, refreshBookmarks]);

    /*const handlePrint = useReactToPrint({
        content: () => printComponent.current,
    });*/

    const handleSaved = (data) => {

        if (data.name === "close") {
            props.handleSaved(false);
            return;
        }

        props.handleSaved(true);

        if (data.name === "deleted") {
            setRightToastIn({ message: "Pagina rimossa dai tuoi segnalibri", classHeader: "toastn--remove", iconBody: "icon-remove" });
        } else {
            setRightToastIn({ message: "Pagina salvata nei tuoi segnalibri", classHeader: "", iconBody: "icon-bookmark-list" });
        }

        setUpdateList(data.nameMenu);
        setRefreshBookmarks(true);
    }

    const settingZoom = (data) => {
        setZoomText(data);
    };

    const savePdfToLastPage = () => {

        if (tokenCtx.token === null || tokenCtx.token === undefined || authCtx.username === "Utente" || productListsCtx.saveIsFinishToRead === undefined) return;

        let index = productListsCtx.saveIsFinishToRead === null ? -1 : productListsCtx.saveIsFinishToRead.map(x => x.idDoc).indexOf(documentReturns.id);

        if (index === -1) {
            saveFinishedToRead();
        }
    }

    useEffect(() => {

        window.scrollTo(0, 0);
    }, [id]);

    useEffect(() => {
        isLoadingCtx.setIfIsLoading(false, 200);
    }, [littleSumm]);

    /*useEffect(() => {
        isLoadingCtx.setIfIsLoading(false, 1200);
    }, [documentReturns]);*/

    useEffect(() => {

        const settingMostUsedTag = (data) => {

            if (data.Result.Documents.length === 0) return;

            setTagMostUsed(data.Result.Documents);
        }

        noteAndTag.getGenericTag(config.nameList.note, { prop1: "data.note.tag" }, settingMostUsedTag);
    }, []);

    useManageBodyClasses();

    return (
        <React.Fragment>
            {
                <React.Fragment>
                    {isLoadingCtx.isLoading && <Spinner></Spinner>}
                    <Wrapper>
                        {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                        {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                        {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                        <HeaderReader
                            isFree={props.isFree}
                            isReader={true}
                            page='mainReader'
                            title={documentReturns.mainTitle}
                            idDoc={documentReturns.id}
                            idProv={documentReturns.idProvv}
                            idDocType={documentReturns.idDocType}
                            littleSumm={littleSumm}
                            tipoDocument={tipoDocument}
                            img={documentReturns.img}
                            updatedList={updatedList}
                            notes={notes}
                            notesForMenu={notesForMenu}
                            bookmarks={bookmarks}
                            testo={""}
                            isIntegrateInBD={props.isIntegrateInBD}
                        /*setIsActiveHambMenu={setIsActiveHambMenu(prev => !prev)}
                        isActiveHambMenu={isActiveHambMenu}*/
                        />


                        <ReaderContentContainer tipoDocument={tipoDocument}>
                            {tipoDocument !== 'pdf' &&
                                <ReaderHead>
                                    <ReaderMeta isLink={true} document={documentReturns} getTipo={getTipo} />
                                    <ReaderTitle
                                        title={documentReturns.titleDoc}
                                        page='mainReader'
                                        prevChap={prevChap}
                                        nextChap={nextChap}
                                        getTipo={getTipo}
                                    />
                                    <ReaderMeta isSpan={true} document={documentReturns} getTipo={getTipo} />
                                    {tipoDocument !== 'pdf' && <ReaderHeadGoTo page='mainReader' littleSumm={littleSumm} />}
                                    {(tipoDocument !== 'pdf' && getCookie("plus_token_forDomain")) && props.isIntegrateInBD && ((listNormaCommentata && listNormaCommentata.length>0) || (listRiferimenti && listRiferimenti.length>0) || (listCorrelati && listCorrelati.length>0) || (listDocReferences && listDocReferences.length>0) || (listDisIntegrative && listDisIntegrative.length>0)) && <ReaderHeadSwitchDocPerApprofondire id={id} showPerApprofondire={props.perApprofondire} />}
                                </ReaderHead>
                            }
                            {(props.perApprofondire && getCookie("plus_token_forDomain")) &&
                                <SchedaPerApprofondire id={id} approfondire={props.perApprofondire} listNormaCommentata={listNormaCommentata} listCorrelati={listCorrelati} listRiferimenti={listRiferimenti} listDocReferences={listDocReferences} listDisIntegrative={listDisIntegrative}></SchedaPerApprofondire>
                            }
                            {!props.perApprofondire &&
                                <ReaderMainContainer zoomText={zoomText} tipoDocument={tipoDocument}>
                                    {(tipoDocument !== 'pdf' && documentReturns !== undefined) &&
                                        <ReaderToolsMobile
                                            idDoc={documentReturns.id}
                                            idProv={documentReturns.idProvv}
                                            buyedOrFreeContent={buyedOrFreeContent}
                                            settingZoom={settingZoom}
                                            handleSaved={handleSaved}
                                        />
                                    }

                                    {tipoDocument === 'Sistema Frizzera' && <ReaderBoxTop document={documentReturns} />}
                                    {tipoDocument === 'pdf' &&
                                        <>
                                            {buyedOrFreeContent &&
                                                /*<ViewerPdf pdfUrl={pdfDoc}
                                                    pdfDoc={documentReturns.binaryDoc}
                                                    savePdfToLastPage={savePdfToLastPage}
                                                ></ViewerPdf>*/
                                                <ViewerPdfExpress
                                                    pdfDoc={documentReturns.binaryDoc}
                                                    savePdfToLastPage={savePdfToLastPage} />
                                            }
                                        </>
                                    }
                                    <RenderParagraphs>
                                        {
                                            tipoDocument !== 'pdf' && documentReturns.paragraphs !== undefined &&
                                            documentReturns.paragraphs.map((par, index) => (
                                                <ReaderMain
                                                    isFree={props.isFree}
                                                    paragraph={par}
                                                    buyedOrFreeContent={buyedOrFreeContent}
                                                    title={documentReturns.mainTitle}
                                                    img={documentReturns.img}
                                                    idProvv={documentReturns.idProvv}
                                                    idDocType={documentReturns.idDocType}
                                                    idDocument={documentReturns.id}
                                                    index={index}
                                                    key={index}
                                                    activatedCtxMenu={tipoDocument === 'html'}
                                                    getTipo={getTipo}
                                                    normaCommentata={normaCommentata}
                                                />
                                            ))
                                        }
                                    </RenderParagraphs>
                                    {normaCommentata != null && normaCommentata.iddocumento != undefined && !sessionStorage.getItem("isBookIntegrate") &&
                                        <>
                                            <ReaderLine></ReaderLine>
                                            <ReaderBoxesBottom
                                                normaCommentata={normaCommentata}
                                            />
                                        </>
                                    }
                                    {(tipoDocument !== 'pdf' && documentReturns !== undefined) &&
                                        <ReaderTools
                                            idDoc={documentReturns.id}
                                            idProv={documentReturns.idProvv}
                                            buyedOrFreeContent={buyedOrFreeContent}
                                            settingZoom={settingZoom}
                                            handleSaved={handleSaved}

                                        />
                                    }
                                </ReaderMainContainer>
                            }
                            {!buyedOrFreeContent &&
                                <ReaderBlockMask title={documentReturns.mainTitle}
                                    img={documentReturns.img}
                                    idProvv={documentReturns.idProvv}
                                    idDocType={documentReturns.idDocType}></ReaderBlockMask>
                            }

                            {tipoDocument === 'html' && documentReturns?.paragraphs !== undefined &&
                                <ReaderDropdownNote document={documentReturns}
                                    notes={notes}
                                    textWithNote={textWithNote}
                                    refreshDocument={refreshDocument}
                                    addOrUpdateNote={(note) => setShowAddUpdateNote(note)}
                                    addOrUpdateNoteTag={(note) => setShowAddUpdateTagNote(note)}
                                    deleteNote={(note) => setShowDeleteNote(note)} />
                            }
                            {tipoDocument !== 'pdf' && <ReaderFoot prevChap={prevChap} nextChap={nextChap} />}
                            {(showAddUpdateNote !== undefined && showAddUpdateNote.showModal) &&
                                <AddOrUpdateNote showAddUpdateNote={showAddUpdateNote}
                                    document={documentReturns}
                                    closeModal={(dismiss) => { setShowAddUpdateNote(dismiss) }}
                                    notes={notes}
                                    textWithNote={textWithNote}
                                    refreshDocument={refreshDocument} />
                            }
                            {(showAddUpdateTagNote !== undefined && showAddUpdateTagNote.showModal) &&
                                <AddOrUpdateTags showAddUpdateTagNote={showAddUpdateTagNote}
                                    document={documentReturns}
                                    closeModal={(dismiss) => { setShowAddUpdateTagNote(dismiss) }}
                                    notes={notes}
                                    textWithNote={textWithNote}
                                    refreshDocument={refreshDocument}
                                    tagMostUsed={tagMostUsed}
                                />
                            }
                            {(showDeleteNote !== undefined && showDeleteNote.showModal) &&
                                <ConfirmResp showDeleteNote={showDeleteNote}
                                    document={documentReturns}
                                    closeModal={(dismiss) => { setShowDeleteNote(dismiss) }}
                                    title="Elimina Nota"
                                    message="Sei sicuro di voler eliminare la nota?"
                                    notes={notes}
                                    textWithNote={textWithNote}
                                    refreshDocument={refreshDocument}
                                />
                            }

                        </ReaderContentContainer>
                        <Footer isIntegrateInBD={props.isIntegrateInBD} />

                    </Wrapper>
                </React.Fragment>
            }
            <Ricerca isReader={true}
                img={documentReturns.img}
                title={documentReturns.mainTitle}
                tipoDocument={tipoDocument}
                id={documentReturns.id}
                idProvvedimento={documentReturns.idProvv}
                idDocType={documentReturns.idDocType}
                testo={""}
            /*setIsActiveHambMenu={setIsActiveHambMenu(prev => !prev)}
            isActiveHambMenu={isActiveHambMenu}*/
            />
            {authCtx.isLoggedIn && <Toast message={rightToastIn.message} handleSaved={handleSaved} classHeader={rightToastIn.classHeader} iconBody={rightToastIn.iconBody}></Toast>}
        </React.Fragment>
    );
}

export default Reader;