import React from 'react';
import { ChooseAreaTematica } from '../../Helper/ChooseAreaTematica/ChooseAreaTematica';

const AreaAllListHeader = (props) => {

    let classToUse = ChooseAreaTematica(props.themeArea);

    return (
        <React.Fragment>
            <div className={`heading heading--small ${classToUse} ${props.isPersonalArea ? 'heading--user' : ''}`}  >
                <div className={`heading-wrapper ${props.themeArea ? 'bgr' : ''}`}>
                    <div className="container">
                        <h1 className={`heading-title ${props.themeArea ? 'clr' : ''}`}>
                            {`${props.themeArea ? props.themeArea : props.title}`}
                        </h1>
                        {props.children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AreaAllListHeader;