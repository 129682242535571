import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";

const BuyCardContent = (props) => {

    let buyOrNot = [];

    const { isFromAnotherPage } = useParams();

    const navigate = useNavigate();

    props.resultBuyOrNot.forEach(element => {

        if (element.idProv.toString() === props.idProv.toString()) {
            buyOrNot.push(element);
        }
    });

    const showModalPacchettiHandle = () => {
        props.showModalPacchettiHandle(
            {
                showModal: true,
                settingBuyOrNot: buyOrNot
            }
        );
    }

    const goTo = () => {
        updateLink({ title: props.prodotto.title, name: "tutti-i-risultati" }, props.titleContainer)
        localStorage.setItem("img", props.img);
        localStorage.setItem("node", buyOrNot[0].node);
        localStorage.setItem("id", props.prodotto.id);
        localStorage.setItem("titleNotSlug", props.title);


        navigate(`/ricerca/level2/${props.idProv}/${props.testo}/${titleUrlSlug(props.title)}/${props.isFrizzera}`)
    }

    return (
        <React.Fragment>
            {isFromAnotherPage !== "daMieiLibri" &&
                <li className="list-inline-item">
                    <a className="btn " role={"button"} onClick={goTo}>
                        Tutti i risultati
                    </a>
                </li>
            }
            {buyOrNot.length === 0 &&
                <li className="list-inline-item">
                    <NavLink className="btn btn--black" to={``}>
                        PAC. VEN. NON. DEF.
                    </NavLink>
                </li>
            }
            {(buyOrNot.length === 1 && buyOrNot[0].buy === '1') &&
                <li className="list-inline-item">
                    <NavLink className="btn btn--black" to={`/pacchetto/${buyOrNot[0].node}`} onClick={(ev) => { updateLink({ title: props.prodotto.title, name: "acquista" }, props.titleContainer) }}>
                        Acquista
                    </NavLink>
                </li>
            }
            {(buyOrNot.length === 1 && buyOrNot[0].buy === '0') &&
                <li className="list-inline-item">
                    <NavLink className="btn btn--black" to={`/reader/${props.prodotto.id}`} onClick={(ev) => { updateLink({ title: props.prodotto.title, name: "leggi" }, props.titleContainer) }}>
                        Leggi
                    </NavLink>
                </li>
            }
            {(buyOrNot.length > 1) &&
                <li className="list-inline-item">
                    <a className="btn btn--black" onClick={showModalPacchettiHandle}>
                        Acquista
                    </a>
                </li>
            }
        </React.Fragment>
    );
}

export default BuyCardContent;