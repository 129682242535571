import React from 'react'

const Expander = () => {

    const expandedBDIndx = () => {
        let expandOrCollapse = document.body.getElementsByClassName("bdindex");

        if(expandOrCollapse[0].className.includes("is-expanded")){
            expandOrCollapse[0].classList.remove("is-expanded");
            return;
        }

        expandOrCollapse[0].classList.add("is-expanded");
    };

    return (
        <a className="bdindex-tree-expander" role={"button"} /*data-toggle-class="is-expanded"*/ onClick={expandedBDIndx}>
            <span className="bdindex-tree-expander-icon"></span>
        </a>
    )
}

export default Expander