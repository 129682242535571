import React from 'react';
import ReaderCard from '../ReaderCard';
import ReaderResearchTop from './ReaderResearchTop';

const ReaderMainResearch = () => {

    const researchList = [
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
            isUpdated: true
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
            isUpdated: true
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
            isUpdated: true
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
        },
        {
            category: 'Soggetti',
            pipe: '1. Iva europea e imposta nazionale',
            title: '1.1 L\'armonizzazione dell \'imposta a livello europeo',
            paragraph: 'Primi 300 caratteri - dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec…',
            isUpdated: true
        },
    ]

    return(
        <>
            <ReaderResearchTop/>
            <div className="reader-uplist">
                <ul className="list list--large">
                    {researchList && researchList.map((item, index) => (
                        <ReaderCard itemList={item} key={index}/>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default ReaderMainResearch;