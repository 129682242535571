import React from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../constants/Constants";

const QrCodeSuccess = (props) => {

    //const img = props.img === undefined ? config.imgDefaultLibro : props.img;

    const navigate = useNavigate();

    const loginHandler = (ev) => {
        ev.preventDefault();

        props.loginHandler(true);
    }
    const goToMyBook = () => {
        navigate("/miei-libri")
    }

    return (
        <React.Fragment>
            <div className="qrpage-book">
                {props.isSuccess &&
                    <>
                        {/*<figure className="card-img ">
                        <NavLink to={`/prodotto/${props.id}`}>
                        <span className="card-img-book">
                        <img src={img} alt="" />
                        </span>
                        </NavLink>
                    </figure>*/}
                        <p className="qrpage-text"  dangerouslySetInnerHTML={{ __html: props.successMessage }}></p>
                        {!props.isDifferentAbbo &&
                            <p className="qrpage-text">
                                Vai nella pagina i <a className="page-link" role={"button"} onClick={goToMyBook}>Tuoi Libri</a> e comincia a sfruttare subito il tuo abbonamento.
                            </p>
                        }
                    </>
                }
                {!props.isSuccess &&
                    <>
                        <a className="btn btn--black btn--big btn--full" role="button" onClick={loginHandler}>Accedi</a>
                        <a className="qrpage-link" href={config.linkRegistration} target="_blank">
                            Non hai un account? <strong>Registrati qui</strong>
                        </a>
                    </>
                }
            </div>
        </React.Fragment>
    );
}

export default QrCodeSuccess;