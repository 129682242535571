import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { bodyFormattedNotification, bodyFormattedNotificationGet, notificationListForFe } from "../../../../helper/helper";
import useHttpRequest from "../../../../hooks/http-request";
import TokenContext from "../../../../store/token-context";
import NotificationsList from "./NotificationsList";

const Notifiche = (props) => {

    const [notificationNumber, setNotificationNumber] = useState(0);
    const [notificationList, setNotificationList] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const sendRequest = useHttpRequest();
    const tokenCtx = useContext(TokenContext);

    useEffect(() => {

        if (tokenCtx.token === null || tokenCtx.token === undefined) return;

        const settingsTotNotification = (data) => {

            if (data.Result !== undefined && data.Result !== null) {
                setNotificationNumber(data.Result.count);
            }
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.countNotification,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedNotification(tokenCtx.token, new Date())),
                method: 'POST'
            }
        };

        sendRequest.sendRequest(request, settingsTotNotification);

    }, [tokenCtx.token, refresh]);

    useEffect(() => {
        
        if (tokenCtx.token === null || tokenCtx.token === undefined) return;

        const settingsTotNotification = (data) => {

            const notificationListFe = notificationListForFe(data.Result);

            if (notificationListFe.length > 0) {
                setNotificationList(notificationListFe);
            }
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getNotification,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedNotificationGet(tokenCtx.token, new Date())),
                method: 'POST'
            }
        };

        sendRequest.sendRequest(request, settingsTotNotification);
    }, [tokenCtx.token, refresh]);

    const refreshCountAndNotification = () => {
        setRefresh(prev => !prev);
    }

    return (
        <React.Fragment>
            <div className="dropdown">
                <a className="itool" role={"button"} /*data-toggle="dropdown"*/>
                    <span className="icon icon-notification"></span>
                    <span className="badge">{notificationNumber}</span>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${props.clickNotifyButton ? "show" : ""}`}>
                    <div className="dropdown-menu-head">
                        <div className="dropdown-menu-head-top">
                            <span className="dropdown-menu-head-title">
                                Notifiche
                            </span>

                            <Link className="card-search-goto" to="/notifiche">
                                Vedi tutte
                                <span className="icon icon-arrow-right-small"></span>
                            </Link>
                        </div>
                    </div>

                    <NotificationsList isLarge={false} notificationList={notificationList} refreshCountAndNotification={refreshCountAndNotification} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Notifiche;