import { useContext } from "react"
import { config } from "../constants/Constants";
import AuthContext from "../store/auth-context";
import TokenContext from "../store/token-context";
import useHttpRequest from "./http-request";

const useManangeNoteAndTagOnReader = () => {

    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);
    const loginCtx = useContext(AuthContext)

    const trueSave = (name, data, genericFunc) => {

        const savedData = (data) => {
            if (data.err === null || data.err === undefined) genericFunc(data);

            else genericFunc(null);
        }

        const bodyFormatted = {
            data,
            type: name,
            token: tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.save,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, savedData, false);
    }

    const setDeleteConfig = (obj, genericFunc) => {

        const finishToDelete = (data) => {

            genericFunc(data);
        }

        const bodyFormatted = {
            token: tokenCtx.token,
            id: obj.prop1
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.delete,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, finishToDelete, false);
    };

    const getDocumentEditWithNotes = (name, obj, blockOrNotContent, genericFunc) => {

        const saveObj = (data) => {

            if (data.Result !== null) return genericFunc(data);

            trueSave(name, { idprovvedimento: obj.prop1, iddocumento: obj.prop2, iddoctype: obj.prop3, documentText: obj.prop4 }, genericFunc);
        };

        if (tokenCtx.token === null || !blockOrNotContent) return;

        let query = {
            tipo: name,
            appId: config.appId,
            "data.idprovvedimento": obj.prop1,
            "data.iddocumento": obj.prop2,
            "data.iddoctype": obj.prop3
        };

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, saveObj, false);
    };

    const getNotesConfig = (name, obj, blockOrNotContent, genericFunc) => {

        const settingsNote = (data) => {

            genericFunc(data);
        }

        if (tokenCtx.token === null || !blockOrNotContent) return;

        let query = {
            tipo: name,
            appId: config.appId,
        };

        if (Object.keys(obj).length > 0) {
            query = {
                ...query,
                "data.idprovvedimento": obj.prop1,
                //"data.iddocumento": obj.prop2,
                "data.iddoctype": obj.prop3
            }
        }

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, settingsNote, false);
    };

    const getGenericTagConfig = (name, obj, genericFunc) => {

        const settingsNote = (data) => {

            genericFunc(data);
        }

        if (tokenCtx.token === null) return;

        let query = {
            tipo: name,
            appId: config.appId,
        };

        /*if(Object.keys(obj).length > 0) {
            query = {
                ...query,
                "": obj.prop1,
            }
        }*/

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            distinctField: obj.prop1,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, settingsNote, false);
    };

    const updateDocumentConfig = (documentToUpd, genericFunc) => {

        const updatedDocument = (data) => {

            genericFunc(data);
        }

        if (tokenCtx.token === null) return;

        const bodyFormatted = {
            id: documentToUpd._id.$oid,
            type: documentToUpd.tipo,
            data: documentToUpd.data,
            token: tokenCtx.token
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.update,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, updatedDocument, false);

    }

    const saveOrUpdateNoteConfig = (oldNotes, updatedNotes, idOfDocument, name, genericFunc) => {

        if (oldNotes.data.note.length === 0) {
            const objToSave = {
                ...idOfDocument,
                prop6: updatedNotes
            };
            saveNewNoteConfig(objToSave, name, genericFunc);
            return;
        }

        oldNotes.data.note = updatedNotes;
        updateNotesConfig(oldNotes, name, genericFunc);
    }

    const saveNewNoteConfig = (obj, name, genericFunc) => {

        const savedData = (data) => {
            if (data.err === null || data.err === undefined) genericFunc(data);

            else genericFunc(null);
        }

        const bodyFormatted = {
            data: {
                idprovvedimento: obj.prop1,
                iddocumento: obj.prop2,
                iddoctype: obj.prop3,
                bookTitle: obj.prop4,
                documentTitle: obj.prop5,
                note: obj.prop6
            },
            type: name,
            token: tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.save,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, savedData, false);

    }

    const updateNotesConfig = (oldNotes, name, genericFunc) => {

        const updatedNotes = (data) => {
            genericFunc(data);
        }

        if (tokenCtx.token === null) return;

        const bodyFormatted = {
            id: oldNotes._id.$oid,
            type: name,
            data: oldNotes.data,
            token: tokenCtx.token
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.update,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, updatedNotes, false);
    }

    return {
        getDocumentEditWithNotes: getDocumentEditWithNotes,
        updateDocument: updateDocumentConfig,
        saveOrUpdateNote: saveOrUpdateNoteConfig,
        getNotes: getNotesConfig,
        getGenericTag: getGenericTagConfig,
        updateNotes: updateNotesConfig,
        setDelete: setDeleteConfig
    };
}

export default useManangeNoteAndTagOnReader;