import React from "react";

const Paragraph = (props) => {

    const srcImg = props?.img?.includes('via.placeholder') ? '/dist/img/placeholder-autore.png' : props.img;

    const description = props.text === "" ? "Biografia non disponibile" : props.text;

    return (
        <React.Fragment> 
            {props.title && 
                <h2 className={props.classTitle}>
                    {props.title}
                </h2>
            }
            {description && 
                <p className={props.classPar}>
                    {description}
                </p>
            }
            {props.viewImg &&
                <figure className="page-authbox-img">
                    <img className="img-cover" src={srcImg} alt="" />
                </figure>}
        </React.Fragment>
    );
}

export default Paragraph;