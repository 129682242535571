import React from "react";
import { Modal } from "react-bootstrap";
import { config } from "../../constants/Constants";
import useContextMenu from "../../hooks/useContextMenu";
import useManangeNoteAndTagOnReader from "../../hooks/useManageNoteAndTagOnReader";

const ConfirmResp = (props) => {

    const { cleanFunction } = useContextMenu();

    const noteAndTag = useManangeNoteAndTagOnReader();

    const handleClose = () => {
        props.closeModal({ showModal: false, noteToUpd: {} });
    };

    const deleteNote = (ev) => {

        ev.preventDefault();

        let notaToDelete = props.showDeleteNote.noteToUpd.noteToUpd;

        let spanToReplace = "";
        let buttonToReplace = "";
        let newArrayNote = [];
        props.notes.data.note.forEach((element) => {
            if (element.idNote === notaToDelete.idNote) {
                spanToReplace = element.startSpan;
                buttonToReplace = element.startButton;
                return;
            }
            newArrayNote.push(element);
        });

        let textWithNote = props.textWithNote; 
        let documentText = textWithNote.data.documentText;
        documentText = documentText.replace(spanToReplace, '<span style="white-space: pre-wrap">');
        documentText = documentText.replace(buttonToReplace, '');
        textWithNote.data.documentText = documentText;

        const finishUpdateDocument = () => {

            const refreshNote = () => {
                cleanFunction();
                //handleClose();
                props.refreshDocument({ showModal: false, noteToUpd: {} });
            };

            if (newArrayNote.length === 0) {
                noteAndTag.setDelete({ prop1: props.notes._id.$oid }, refreshNote);
            } else {
                props.notes.data.note = newArrayNote;
                noteAndTag.updateNotes(props.notes, config.nameList.note, refreshNote);
            }
        }

        if (newArrayNote.length === 0) {
            noteAndTag.setDelete({ prop1: textWithNote._id.$oid }, finishUpdateDocument);
        } else {
            noteAndTag.updateDocument(textWithNote, finishUpdateDocument);
        }
    }

    return (
        <React.Fragment>
            <Modal show={props.showDeleteNote.showModal} onHide={handleClose}>
                <div className="modal-content">
                    <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span className="icon icon-cross-small"></span>
                    </a>

                    <Modal.Header className="modal-header">
                        <h3 className="modal-title">{props.title}</h3>
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <p className="modal-txt">
                            {props.message}
                        </p>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <a className="btn btn--txt" role="button" onClick={handleClose}>Annulla</a>
                        <a className="btn btn--black " role="button" onClick={deleteNote}>Elimina</a>
                    </Modal.Footer>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default ConfirmResp;