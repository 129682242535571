import React from 'react'
import BookFilter from './BookFilter/BookFilter'
import ColorFilter from './ColorFilter/ColorFilter'
import FilterWrapper from './FilterWrapper'
import TagFilter from './TagFilter/TagFilter'
import TagFilterByNotes from './TagFilterByNotes'

const FiltersNotes = (props) => {

    let filterBookArray = [];

    props.notFilteredNotes.forEach(element => {
        filterBookArray.push({
            id: element.idprovvedimento,
            name: element.title
        })
    });

    const filteringBook = (idToFilter) => {

        props.filteringBook(idToFilter)
    }

    const filteringByColor = (colorToFilter) => {

        props.filteringByColor(colorToFilter);
    }

    const filteringByTag = (tagToFilter) => {

        props.filteringByTag(tagToFilter);
    }

    const filteringForNotes = (isFilter) => {
        props.filteringForNotes(isFilter);
    }


    return (
        <>
            {/*<FilterWrapper title="Filtra per note">
                <TagFilterByNotes filteringForNotes={filteringForNotes}></TagFilterByNotes>
    </FilterWrapper>*/}
            <FilterWrapper title="Filtra per tag">
                <TagFilter filteringByTag={filteringByTag} mostUsedTag={props.mostUsedTag} />
            </FilterWrapper>

            <FilterWrapper title="Filtra per libro">
                <BookFilter filterBookArray={filterBookArray} filteringBook={filteringBook} />
            </FilterWrapper>

            <FilterWrapper title="Filtra per colore">
                <ColorFilter filteringByColor={filteringByColor} />
            </FilterWrapper>
        </>
    )
}

export default FiltersNotes