import React from "react";
import Notifica from "./Notifica/Notifica";

const NotificationsList = (props) => {

    const notificationList = props.isLarge ? props?.notificationList : props?.notificationList.slice(0,4);

    const refreshCountAndNotification = () => {
        props.refreshCountAndNotification();
    }

    return (
        <React.Fragment>
            <ul className={`list ${props.isLarge ? 'list--large card-alert-list' : ''}` }>
                {notificationList?.length > 0 && notificationList.map(notification => (
                    <Notifica
                        isLarge={props.isLarge}
                        notification={notification}
                        key={notification.id}
                        refreshCountAndNotification= {refreshCountAndNotification}
                    ></Notifica>
                ))}
            </ul>
        </React.Fragment>
    );
};

export default NotificationsList;