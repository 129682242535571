import { useContext, useState } from "react";
import { config } from "../constants/Constants";
import AuthContext from "../store/auth-context";
import TokenContext from "../store/token-context";
import useHttpRequest from "./http-request";
import { bodyFormattedReader } from '../helper/helperFunctionReader';

const useSaveAndDeleteReadingBook = () => {

    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);
    const [result, setResult] = useState(false);
    const loginCtx = useContext(AuthContext)
    const [giaLetti, setGiaLetti] = useState(false);
    const setSaveConfig = (name, obj, blockOrNotContent) => {

        const saveObj = (data) => {

            if (data.Result !== null) {
                if (data.Result.Documents[0].data.iddocumento === obj.prop3)
                    return;
                aggiungiPiuLetti(obj.prop3)
                updateContinuaALeggere(data.Result.Documents[0], obj.prop3);
                return;
            }

            const result = trueSave(name, { idprovvedimento: obj.prop1, iddoctype: obj.prop2, iddocumento: obj.prop3 });

            setResult(result);
        };

        if (tokenCtx.token === null || !blockOrNotContent) return;

        let query = {
            tipo: name,
            appId: config.appId,
            "data.idprovvedimento": obj.prop1,
            "data.iddoctype": obj.prop2
        };

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, saveObj, false);
    };
    const aggiungiPiuLetti = (idDocument) => {

        const setPiuLetti = (data) => {
            if (data.Result.ResultSet === null) {
                setGiaLetti(true);
                return;
            }
        }

        const bodyFormatted = bodyFormattedReader(idDocument, config.nameList.getAggiungiPiuLetti, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setPiuLetti);
    };

    const updateContinuaALeggere = (dataToUpd, idDocument) => {

        const updatedDocument = (data) => {

            setResult(data.Result);
        }

        if (tokenCtx.token === null) return;

        dataToUpd.data.iddocumento = idDocument;
        const bodyFormatted = {
            id: dataToUpd._id.$oid,
            type: dataToUpd.tipo,
            data: dataToUpd.data,
            token: tokenCtx.token
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.update,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, updatedDocument, false);

    }

    const trueSave = (name, data, genericFunc) => {

        const savedData = (data) => {
            if (data.err === null || data.err === undefined) genericFunc(true, "saved");

            else genericFunc(false, "saved");
        }

        const bodyFormatted = {
            data,
            type: name,
            token: tokenCtx.token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.save,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, savedData, false);
    }

    const setDeleteConfig = (array, obj, genericFunc) => {

        let index = array.map(val => val.data.iddocumento).indexOf(parseInt(obj.prop2));
        let toDelete = array[index];

        const finishToDelete = (data) => {

            if (data.err === null || data.err === undefined) genericFunc(false, "deleted");

            else genericFunc(true, "deleted");
        }

        const bodyFormatted = {
            token: tokenCtx.token,
            id: toDelete._id.$oid
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.delete,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, finishToDelete, false);

    };

    const getAlreadySavedConfig = (name, obj, blockOrNotContent, wantToSave, genericFunc) => {

        const saveObj = (data) => {

            const checkIfExist = (array, prop) => {

                let res = false;

                if (array === undefined || array === null) return res;

                array.forEach(element => {

                    if (element.data.iddocumento == parseInt(prop)) res = true;

                });

                return res;
            };

            if (data.Result === null && !wantToSave) return genericFunc(false);

            const res = checkIfExist(data?.Result?.Documents, obj.prop2);

            if (!wantToSave) {
                return genericFunc(res, "");
            }

            if (!res) {
                trueSave(name, { idprovvedimento: obj.prop1, iddocumento: obj.prop2 }, genericFunc);
                return;
            }

            setDeleteConfig(data?.Result?.Documents, obj, genericFunc);
        };

        if (tokenCtx.token === null) return true;

        let query = {
            tipo: name,
            appId: config.appId,
            "data.idprovvedimento": obj.prop1
        };

        const bodyFormatted = {
            excludeUserKey: false,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token,
            encryptParams: ["query.userKey"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, saveObj, false);
    };

    return {
        setSaveBook: setSaveConfig,
        setDeleteBook: setDeleteConfig,
        getAlreadySaved: getAlreadySavedConfig,
        result: result
    };
}

export default useSaveAndDeleteReadingBook;