import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Spinner.module.css'

const BackDrop = () => {
    return <div className={classes.backdrop}></div>
}

const SpinnerBody = () => {
    return (
        <div className={classes.spinner}>
            <div className="loader">
                <svg width="84" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" d="M0 0h84v120H0z" />
                    <path fill="#EEFCF3" d="M3 3h78v114H3z" />
                    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="78" height="114">
                        <path fill="#EEFCF3" d="M3 3h78v114H3z" />
                    </mask>
                    <g mask="url(#a)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.595 118.197h7.45V96.966h-7.45v21.231Zm7.45-21.232h-7.45V89.66" fill="#D8F8E3" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.144 118.197h7.45V96.966h-7.45v21.231Zm7.45-28.538v7.306h-7.45" fill="#99E6B4" />
                        <path d="M59.196 124.81c16.024 0 29.015-12.937 29.015-28.896 0-15.959-12.99-28.896-29.015-28.896-16.024 0-29.014 12.938-29.014 28.896 0 15.959 12.99 28.896 29.014 28.896Z" fill="#99E6B4" /><path fillRule="evenodd" clipRule="evenodd" d="M39.39 94.627c.14-.21.264-.432.37-.663l.008-.015h-.001a4.813 4.813 0 0 0-.46-4.775 1.57 1.57 0 0 1-.385-1.026h3.175c0 .358-.126.69-.335.958.21-.265.338-.597.338-.96H66.8v8.716H38.146l1.244-2.235Z" fill="#fff" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M37.53 106.076v-6.779l2.645 1.541c-.041.174-.81 2.218-.81 2.218l.285.226c.62-.394 1.15-.259 1.87.456.609.605 2.244 1.02 2.244 1.02-.174.17-1.306 1.446-2.81 1.446 0 0 .706.475.737.742.11.952-.286 2.208-.286 2.943 0 1.316 1.088 2.382 2.43 2.382h7.288c2.683 0 4.858 2.134 4.858 4.765H34.093a19.14 19.14 0 0 0 3.437-10.96ZM44.958 74.735h6.082c14.639 0 26.507 11.63 26.523 25.981v16.32h-16.64v-16.29h-.001c-.023-5.333-4.438-9.65-9.882-9.65-6.287 0-11.383-4.997-11.383-11.163 0-2.87 2.373-5.198 5.3-5.198Z" fill="#19AE4A" />
                        <path fill="#99E6B4" d="M9 18h66v6H9zM18.59 28h48v6h-48z" />
                    </g>
                </svg>
                <svg width="84" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" d="M0 0h84v120H0z" />
                    <path fill="#F0F9FF" d="M3 3h78v114H3z" />
                    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="78" height="114">
                        <path fill="#F0F9FF" d="M3 3h78v114H3z" />
                    </mask>
                    <g mask="url(#a)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M31.654 90.597c0-12.225 9.95-22.135 22.225-22.135 12.274 0 22.224 9.91 22.224 22.135 0 12.224-9.95 22.134-22.224 22.134-12.275 0-22.225-9.91-22.225-22.134Z" fill="#52AEFF" /><path fillRule="evenodd" clipRule="evenodd" d="M36.321 90.597c0-9.657 7.86-17.486 17.558-17.486 9.696 0 17.557 7.828 17.557 17.486 0 9.656-7.86 17.485-17.557 17.485S36.32 100.253 36.32 90.597Z" fill="#fff" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M64.442 90.612c0-7.168 5.835-12.98 13.033-12.98 7.198 0 13.032 5.812 13.032 12.98 0 7.169-5.835 12.979-13.032 12.979-7.198 0-13.033-5.81-13.033-12.979Z" fill="#158BFF" /><path fillRule="evenodd" clipRule="evenodd" d="M95.453 105.717c-14.545 0-23.528-7.62-46.772-7.62-23.245 0-30.684 17.999-45.229 17.999h92v-10.379Z" fill="#99D3FF" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M26.492 114.818c-2.053 1.626-5.178 2.927-8.911 2.927-6.905 0-12.597-3.624-14.883-9.711h-4.246v-4.74H1.72c-.047-.464-.047-.743-.047-1.301 0-.371 0-.743.047-1.208h-3.267v-4.692h4.246c2.286-6.087 8.118-9.944 14.79-9.944 3.546 0 5.925.79 8.024 2.091L22.9 93.816c-1.353-.93-3.266-1.348-5.412-1.348-2.66 0-5.366 1.069-6.998 3.624h11.383l-2.192 4.693H9.09c-.047.465-.047.79-.047 1.208 0 .372 0 .837.047 1.301H18.7l-2.1 4.74H10.77c1.587 2.184 4.06 3.252 6.812 3.252 2.38 0 4.339-.697 5.692-1.765l3.22 5.297Z" fill="#52AEFF" />
                        <path fill="#99D3FF" d="M9 18h66v6H9zM18.59 28h48v6h-48z" />
                    </g>
                </svg>
                <svg width="84" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" d="M0 0h84v120H0z" /><path fill="#FFF0F0" d="M3 3h78v114H3z" />
                    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="78" height="114">
                        <path fill="#FFF0F0" d="M3 3h78v114H3z" />
                    </mask>
                    <g mask="url(#a)">
                        <path d="M84.142 70.851H54.978v46.743h29.164V70.851Z" fill="#fff" />
                        <path d="M63.275 123.593c16.288 0 29.49-13.15 29.49-29.37s-13.203-29.37-29.49-29.37-29.49 13.15-29.49 29.37 13.203 29.37 29.49 29.37Z" fill="#F6B1B1" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M62.622 98.983c.263-.41 2.022-4.293 2.504-5.148.482-.856.178-2.92-.122-4.325-.152-.708-.113-1.195-.113-1.195H87.61V117.936l-.958 3.633-3.828 1.738c0-3.119-2.692-6.121-5.357-7.294-2.665-1.173-4.835-1.544-7.19-1.544-2.356 0-2.852.124-3.905-.74-1.054-.865-.653-2.596-.356-3.165.297-.568.16-.75.045-1.296-.114-.546-.547-1.046-.776-1.228-.228-.182-.205-.591-.114-.887.091-.295.365-.682.594-.682 0 0-.708-.114-.982-.409a1.206 1.206 0 0 1-.343-.705c-.022-.159.234-.638.378-.901s.343-.659.21-1.45c-.132-.791-1.015-.835-1.412-.857-.398-.022-1.28-.286-1.59-.902-.309-.615-.066-1.231.596-2.264Z" fill="#FEFEFE" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.92 114.998h35.727V88.971H15.92v26.027Z" fill="#FFF0F0" /><path fillRule="evenodd" clipRule="evenodd" d="M50.139 88.323a2.602 2.602 0 0 1 2.607 2.597v22.138h4.063a3.894 3.894 0 0 1-3.757 3.884l-.146.003H14.664a3.895 3.895 0 0 1-3.903-3.887h4.062l.001-22.138a2.602 2.602 0 0 1 2.607-2.597H50.14Zm-.887 2.546H18.318c-.335 0-.61.25-.648.574l-.004.075-.001 21.54h13.411c0 .402.308.733.701.773l.08.004h3.856l.08-.004a.78.78 0 0 0 .697-.694l.004-.079h13.41v-21.54a.65.65 0 0 0-.576-.644l-.076-.005Z" fill="#F05C5C" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M98.911 88.46v-1.214H63.785l-5.087 1.213h40.213ZM63.785 87.205h3.393v-10.09h-3.393v10.09Z" fill="#fff" /><path fillRule="evenodd" clipRule="evenodd" d="M95.475 76.844a17.555 17.555 0 0 0-14.127-7.09c-9.708 0-17.563 7.823-17.563 17.492h31.06" fill="#F05C5C" />
                        <path fill="#F6B1B1" d="M9 18h66v6H9zM18.59 28h48v6h-48z" />
                    </g>
                </svg>
                <svg width="84" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" d="M0 0h84v120H0z" />
                    <path fill="#FFF0E6" d="M3 3h78v114H3z" />
                    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="78" height="114">
                        <path fill="#FFF0E6" d="M3 3h78v114H3z" />
                    </mask>
                    <g mask="url(#a)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M31.577 116.046h42.338v-22.25H31.577v22.25Z" fill="#421B00" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.948 96.386h8.074v-2.59h-8.074v2.59ZM47.218 96.386h8.074v-2.59h-8.074v2.59Z" fill="#FF8E3D" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M48.692 116.048h5.126V96.386h-5.126v19.662Zm10.719 0h5.126V96.387H59.41v19.661Zm-20.354 0h5.126V96.387h-5.126v19.661Zm-9.635 0h5.126V96.386h-5.126v19.662Zm40.707 0h5.126V96.386H70.13v19.662Z" fill="#FFE1CC" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M57.936 96.386h8.074v-2.59h-8.074v2.59ZM37.583 96.386h8.074v-2.59h-8.074v2.59ZM68.655 96.386h8.074v-2.59h-8.074v2.59Z" fill="#FF8E3D" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 117h78l-.14-13c-20.286 0-32.608 6.5-53.592 6.5C22.92 110.5 8 117 8 117Z" fill="#FDB681" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 93.987h9.785L52.34 73.255l24.553 20.732h9.663l-34.216-28.89L18 93.986Z" fill="#FF8E3D" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.07 93.987h50.537L52.34 72.65l-25.27 21.336Z" fill="#FFE1CC" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M40.927 82.429c1.41 1.403 1.276 3.812 1.276 3.812s-2.419.133-3.828-1.27c-1.41-1.404-1.276-3.813-1.276-3.813s2.418-.133 3.828 1.27ZM64.77 75.593a6.325 6.325 0 0 0 1.276-.974c2.47-2.46 2.236-6.68 2.236-6.68s-4.238-.233-6.707 2.226a6.526 6.526 0 0 0-1.213 1.706l4.408 3.722Z" fill="#FDB681" />
                        <path fill="#FDB681" d="M9 18h66v6H9zM19 28h48v6H19z" />
                    </g>
                </svg>
                <svg width="84" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" d="M0 0h84v120H0z" />
                    <path fill="#EBFCFF" d="M3 3h78v114H3z" />
                    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="78" height="114">
                        <path fill="#EBFCFF" d="M3 3h78v114H3z" />
                    </mask>
                    <g mask="url(#a)">
                        <mask id="b" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="-20" y="95" width="101" height="22">
                            <path d="M-19.503 95.639H80.994v20.457H-19.503V95.639Z" fill="#fff" />
                        </mask>
                        <g mask="url(#b)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M94.05 109.019c-14.524 0-23.494-13.727-46.708-13.727-23.213 0-30.642 20.646-45.167 20.646h91.876v-6.919Z" fill="#A2EAF6" />
                        </g>
                        <mask id="c" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="36" y="68" width="45" height="49">
                            <path d="M36.611 68.442h44.383v47.654H36.61V68.442Z" fill="#fff" />
                        </mask>
                        <g mask="url(#c)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M80.994 116.096H36.666l-.055-33.765 13.954-13.889h30.429v47.654Z" fill="#DBFAFF" />
                        </g>
                        <path fillRule="evenodd" clipRule="evenodd" d="M36.611 82.33h30.382v33.766H36.611V82.331Z" fill="#3FABC0" />
                        <mask id="d" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="36" y="68" width="45" height="15">
                            <path d="M36.666 68.442h44.328v13.889H36.666V68.442Z" fill="#fff" />
                        </mask>
                        <g mask="url(#d)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M36.666 82.33h30.382l13.946-13.888h-30.43L36.667 82.331Z" fill="#6ED1E2" />
                        </g>
                        <path fillRule="evenodd" clipRule="evenodd" d="M47.922 116.096h7.76V99.214h-7.76v16.882Z" fill="#FEFEFE" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M29.907 95.832c-1.69 1.683-4.59 1.524-4.59 1.524s-.16-2.888 1.53-4.571c1.69-1.683 4.59-1.524 4.59-1.524s.16 2.888-1.53 4.571ZM12.905 91.458c1.69 1.683 1.53 4.57 1.53 4.57s-2.9.16-4.59-1.523c-1.69-1.683-1.53-4.571-1.53-4.571s2.9-.16 4.59 1.524Z" fill="#6ED1E2" /><path fill="#A2EAF6" d="M9 18h66v6H9zM18.59 28h48v6h-48z" />
                    </g>
                </svg>
            </div>
        </div>
    )
}

const Spinner = () => {
    return (
        <>
            {ReactDOM.createPortal(<BackDrop></BackDrop>, document.getElementById('backdrop-root'))}
            {ReactDOM.createPortal(<SpinnerBody></SpinnerBody>, document.getElementById('spinner-root'))}
        </>
    )
}

export default Spinner;