import React, { useState } from 'react'
import ColorObject from './ColorObject';

const ColorFilter = (props) => {

    const colorFilters = [
        { className: "itool noted--s", value: "seppia" },
        { className: "itool noted--y", value: "yellow" },
        { className: "itool noted--p", value: "pink" },
        { className: "itool noted--v", value: "violet" }
    ]

    const filteringByColor = (color) => {
        props.filteringByColor(color);
    }

    return (
        <ul className="list-inline list-inline--large">
            {colorFilters && colorFilters.map((filter, index) => (
                <ColorObject filter={filter} filteringByColor={filteringByColor} key={index}></ColorObject>
            ))}
        </ul>
    )
}

export default ColorFilter