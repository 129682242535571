import React, { useContext } from "react";
import TreeContext from "../../store/context-tree";

const BDNavbarElement = (props) => {

    const treeCtx = useContext(TreeContext);

    return (
        <React.Fragment>
            <li className="list-inline-item bdindex-nav-list-item">
                <a className="bdindex-nav-link " role="button" onClick={ev => {
                    ev.preventDefault();
                    treeCtx.setElementId(props.el.id);
                }}>
                    {props.el.el}
                </a>
            </li>
        </React.Fragment>
    );
}

export default BDNavbarElement;