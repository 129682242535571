import React from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../constants/Constants";
import { createImgLink } from "../../../helper/helper";
import { updateLink } from "../../../helper/TealiumHelper";
import AuthContext from "../../../store/auth-context";

const StickyFoot = (props) => {

    const img = createImgLink(props.img);
    const authCtx = useContext(AuthContext);
    return (
        <React.Fragment>
            <div className="page-preview d-lg-none">
                <figure className="card-img ">
                    {!props.isPacchetto &&
                        <NavLink to={`/reader/${props.isBuyed ? props.idDocument : props.preview}`}>

                            <span className="card-img-book">
                                <img src={props.img === "" ? config.imgDefaultLibro : img} alt="" />
                            </span>

                        </NavLink>
                    }
                    {props.isPacchetto &&
                        <NavLink to={((!props.isBuyed || !authCtx.isLoggedIn) && props.link !== null) ? props.link : ""}>
                            <img className="card-img-full" src={props.img === "" ? config.imgDefaultPacch : img} alt="" />
                        </NavLink>
                    }
                </figure>

                {(!props.isBuyed || !authCtx.isLoggedIn) &&
                <div className="page-stickyfoot">
                    <div className="row small-gutters">
                        
                            <div className="col-6">
                                <NavLink className="btn btn--big btn--full" to={`/reader/${props.preview}`}>
                                    Leggi Anteprima
                                </NavLink>
                            </div>
                        
                        <div className="col-6">
                            {!props.isPacchetto && !props.isBuyed &&
                                <div className="btn-wlabel">
                                    <NavLink className="btn btn--black btn--big btn--full btn--shadow" role={"button"} to={`/pacchetto/${props.pack}`}>
                                        Acquista
                                    </NavLink>
                                </div>
                            }
                            {(props.isPacchetto && (!props.isBuyed || !authCtx.isLoggedIn)) &&
                                <a className="btn btn--big btn--full btn--black" href={`${props.link === null ? '' : props.link}`} onClick={(ev) => {updateLink({ title: "acquista", name: "" }, "scheda", props.pacchetto) }}>
                                    Acquista
                                </a>
                            }

                        </div>
                    </div>
                </div>
                }
            </div>
        </React.Fragment>
    );
}

export default StickyFoot;