import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../../../constants/Constants";
import { bodyFormattedNotificationUpdateState } from "../../../../../helper/helper";
import useHttpRequest from "../../../../../hooks/http-request";
import TokenContext from "../../../../../store/token-context";

const Notifica = (props) => {
    
    const sendRequest = useHttpRequest();
    const tokenCtx = useContext(TokenContext);

    const notificationReaded = () => {

        if (tokenCtx.token === null || tokenCtx.token === undefined) return;

        const settingsTotNotification = (data) => {

            props.refreshCountAndNotification();
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.updateStateNotification,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedNotificationUpdateState(tokenCtx.token, props.notification.idDocument, props.notification.alert)),
                method: 'POST'
            }
        };

        sendRequest.sendRequest(request, settingsTotNotification);
    }

    return (
        <li className="list-item">
            <div className={`card card-alert  ${props.isLarge ? '' : 'card-alert--small'} ${props.notification.isRead ? 'is-read' : ''}`}>
                <div className="card-box">
                    <div className="card-content">
                        <figure className="card-img ">

                            <span className="card-img-book">
                                <img src={props.notification.img} alt="" />
                            </span>

                        </figure>
                        <div className="card-body">
                            <span className="card-alert-label">
                                {props.notification.alert}
                                {props.isLarge && <span className="card-alert-label-date">{props.notification.date}</span>}
                            </span>
                            <h4 className="card-title">
                                <NavLink to={`/reader/${props.notification.idDocument}`} onClick={notificationReaded}>
                                    {props.notification.title}
                                </NavLink>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default Notifica;