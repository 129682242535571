import React from 'react';

const ReaderResearchTop = (props) => {

    const documentFind = props.numberDocumentsFind !== null ? props.numberDocumentsFind : 0;
    
    return(
        <div className="search-top">
            <span className="search-top-info">
                {props.isLevelTwo ? 
                    <>{documentFind} risultati per <strong>{props.title}</strong></>
                    :
                    <>Circa 1000 risultati</>
                }
            </span>

            {!props.isLevelTwo && 
                <a className="btn btn--icon-right" href="#">
                    Cerca in tutti i libri
                    <span className="icon icon-arrow-right"></span>
                </a>
            }
        </div>
    );
}

export default ReaderResearchTop;