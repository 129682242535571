import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateView } from "../../../../helper/TealiumHelper";
import AuthContext from "../../../../store/auth-context";
import GetDocumentContext from "../../../../store/get-documento";
import TokenContext from "../../../../store/token-context";
import ListLink from "../../../UI/ListLink/ListLink";
import CreateTreeView from "./CreateTreeView/CreateTreeView";
import IFramePDF from "./IFramePDF/IFramePDF";


const Sommario = (props) => {

    const [sommario, setSommario] = useState([]);
    const [typeSumm, setTypeSumm] = useState("");
    const [pdfSumm, setPdfSumm] = useState(undefined);
    const { isBookIntegrate } = useParams();
    let { id } = useParams();

    const getDocumentCtx= useContext(GetDocumentContext);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    useEffect(() => {
        if(!isBookIntegrate) return;
            sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
      }, [isBookIntegrate]);
    useEffect(() => {
        let reload = setTimeout(() => {
            getDocumentCtx.getDocumentoFromId(id)
        },200);
        
        return () => {
            clearTimeout(reload);
        };
    }, [id]);

    useEffect(() => {

        setSommario(getDocumentCtx.sommarioArray);

    },[getDocumentCtx.sommarioArray]);

    useEffect(() => {

        setTypeSumm(getDocumentCtx.typeSumm);

    }, [getDocumentCtx.typeSumm]);

    useEffect(() => {

        setPdfSumm(getDocumentCtx.pdfSumm);
        
    }, [getDocumentCtx.pdfSumm]);

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:prodotto:${titleUrlSlug(getDocumentCtx.prodotto?.title.toLowerCase())}:sommario`,authCtx, tokenCtx, "book24ore:prodotto");
                
    },[tokenCtx.token, authCtx.isLoggedIn, window.utag])

    return (
        <div className="reader-summary">
            {(typeSumm === config.serviceToCall.getSummarySmart.split("/")[1] && sommario.length > 0) && <ListLink key={Math.random()} listSommario={sommario} typeSumm={getDocumentCtx.typeSumm}></ListLink>}
            {(typeSumm === config.serviceToCall.getTaxonomy.split("/")[1] && sommario.length > 0) && 
                <CreateTreeView sommario={sommario}></CreateTreeView>
            }
            {(typeSumm === config.getPDF && pdfSumm !== undefined) && <IFramePDF pdf_summ={pdfSumm}></IFramePDF>}
        </div>
    );
}

export default Sommario;