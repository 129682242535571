import React from 'react'
import { NavLink } from 'react-router-dom'
import { updateLink } from '../../../../helper/TealiumHelper'

const ThemeListItem = ({themeArea}) => {

    return (
        <div className="col-md-6 col-lg-4">
            <div className={themeArea.class}>
                <NavLink className="cardarea clr brd bgr" to={themeArea.link} onClick={(ev) => {updateLink({ title: themeArea.name, name: "" }, "le altre aree tematiche") }}>
                    {themeArea.name}
                    <img className="cardarea-img" src={themeArea.imgPath} alt="" />
                </NavLink>
            </div>
        </div>
    )
}

export default ThemeListItem