import React, { useContext, useState } from "react";
import { config } from "../constants/Constants";
import { converObjectForFE, exportAutoriForFE, exportToArrayForFE } from "../helper/castingObject";
import useHttpRequest from "../hooks/http-request";
import TokenContext from "./token-context";
import { bodyFormatted, getRequestSumm, castArrayForFE } from "../helper/helperFunctionGetDocument";
import { mustToken } from "../helper/helper";

const GetDocumentContext = React.createContext({
    id: 0,
    prodotto: undefined,
    document: '',
    typeSumm: '',
    indexSumm: '',
    pdfSumm: '',
    tiPotrebbeInteressare: [],
    sommario: []
});


export const GetDocumentContextProvider = (props) => {

    const [id, setId] = useState();
    const [pdfSumm, setPdfSumm] = useState();
    const [prodotto, setProdotto] = useState(undefined);
    const [document, setDocument] = useState('');
    const [autori, setAutori] = useState([]);
    const [tiPotrebbeInteressare, setTiPotrebbeInteressare] = useState([]);
    const [typeSumm, setTypeSumm] = useState("");
    const [sommarioArray, setSommarioArray] = useState([]);
    const [indexSumm, setIndexSumm] = useState([]);
    const [libroPdf, setLibroPdf] = useState([]);

    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);


    const getDocumentoFromIdConfig = (id, withDynamicToken = false) => {

        const settingProdotto = (data) => {

            if (data.Result.ResultSet === null) {
                getDocumentoFromIdConfig(id, true)
            }

            const productForFe = converObjectForFE(data.Result.ResultSet.Root[0]);
            setProdotto(productForFe);
            setId(productForFe.idDocument);
            setTypeSumm(productForFe.type_summ);

            settingDescrizioneConfig(productForFe);
            settingAutoriConfig(productForFe.idDocument);
            setIndexSumm(productForFe.ind_summ);
            getSommarioConfig(productForFe);
            settingLibroPdfConfig(productForFe.idDocument);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted(config.serviceDocumenti.documento, id, tokenCtx, withDynamicToken)),
                method: "POST"
            }
        };
        sendRqst.sendRequest(request, settingProdotto);
    };

    const settingDescrizioneConfig = (document) => {


        if (document.type_desc !== 'GetDocument') {
            setDocument(document.descrizione);
            return;
        }

        const descrizione = (data) => {
            setDocument(data.Result.TestoDoc);
        };

        const bodyFormatted = {
            documentId: document.idDocument,
            parameters: {
                reference: "false",
                staticToken: mustToken('') ? '' : config.token.STATIC_TOKEN,
            },
            token: mustToken('') ? tokenCtx.token : '',
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getDocumento,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, descrizione);
    };

    const settingAutoriConfig = (id) => {

        const settingAutori = (data) => {

            let autoriForFe = [];

            if (data.Result.ResultSet !== null)
                autoriForFe = exportAutoriForFE(data.Result.ResultSet.Root);

            setAutori(autoriForFe);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted(config.serviceDocumenti.autori, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, settingAutori);
    };

    const settingLibroPdfConfig = (id) => {

        const settingLibroPdf = (data) => {

            let libropdf = 0;

            if (data.Result.ResultSet !== null)
                libropdf = data.Result.ResultSet.Root[0].iddocumento;

            setLibroPdf(libropdf);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted(config.serviceDocumenti.libroPdf, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, settingLibroPdf);
    };

    const getListConfig = (name, id) => {

        const setList = (data) => {
            const listForFE = exportToArrayForFE(name, data.Result.ResultSet.Root);

            setTiPotrebbeInteressare(listForFE);
        };


        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted(config.nameList.tiPotrebbeInteressare, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, setList);

    };

    const getSommarioConfig = (productForFe, withDynamicToken = false) => {

        if (productForFe.type_summ === config.getPDF) {
            setPdfSumm(productForFe.pdf_summ);
            return;
        }

        const settingSommarioArray = (data) => {

            if (productForFe.type_summ === config.serviceToCall.getTaxonomy.split("/")[1]){
                if(data.Result.CountNodes === 0){
                    getSommarioConfig(productForFe, true);
                }
                setSommarioArray(castArrayForFE(data.Result.Nodes));
            }
            else {
                if (data.Result.Documents[0].DocsFound === 0) {
                    getSommarioConfig(productForFe, true);
                }
                setSommarioArray(data.Result.Documents[0].Documents);
            }

        };

        const request = getRequestSumm(productForFe.id, productForFe.type_summ, productForFe.ind_summ, productForFe.node_indx_summ, productForFe.idProv, productForFe.idDocType, tokenCtx, withDynamicToken);

        sendRqst.sendRequest(request, settingSommarioArray);
    };

    const docCtxValue = {
        getDocumentoFromId: getDocumentoFromIdConfig,
        settingDescrizione: settingDescrizioneConfig,
        settingAutori: settingAutoriConfig,
        getList: getListConfig,
        getLibroPdfConfig: settingLibroPdfConfig,
        sommarioArray: sommarioArray,
        tiPotrebbeInteressare: tiPotrebbeInteressare,
        id: id,
        prodotto: prodotto,
        descrizione: document,
        autori: autori,
        typeSumm: typeSumm,
        indexSumm: indexSumm,
        pdfSumm: pdfSumm,
        libroPdf: libroPdf
    };

    return <GetDocumentContext.Provider value={docCtxValue}>{props.children}</GetDocumentContext.Provider>
}

export default GetDocumentContext;