import React, { useRef } from 'react';
import Slider from 'react-slick';
import { config } from '../../constants/Constants';
import ProdottiWrapper from './ProdottiWrapper/ProdottiWrapper';
import SchedaProdotto from "./SchedaProdotto/SchedaProdotto";
import SchedaStrumentiPerProfessionisti from "./SchedaStrumentiPerProfessionisti/SchedaStrumentiPerProfessionisti";
import CarouselItem from '../CarouselNoSlick/CarouselItem';
import { useContext } from 'react';
import ManageProductListsContext from '../../store/manage-products-list-context';
import PrevArrow from '../UI/CarouselNavigator/PrevArrow';
import NextArrow from '../UI/CarouselNavigator/NextArrow';

const Prodotti = (props) => {

    const schedaProdCar = useRef();
    const productListsCtx = useContext(ManageProductListsContext)

    const deleteProductList = (idForDelete) => {
        productListsCtx.deleteProdToContinuaALeggere(idForDelete);

        if(props.updateList !== undefined)
        {
            props.updateList(props.title);
        }
        //props.deleteProductList(idForDelete);
    };

    const next = () => {
        schedaProdCar?.current?.slickNext();        
    };

    const previous = () => {
        schedaProdCar?.current?.slickPrev();
    };

 
    const settings = {
        className: "row carousel scrolling slick-dotted ",
        slide:"col carousel-slide scrolling-item",
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: !props.isProfessionalTool ? 4 : 3,
        slidesToScroll: !props.isProfessionalTool ? 4 : 3,
        arrows: true,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1539,
                settings: {
                    slidesToShow: !props.isProfessionalTool ? 4 : 3,
                    slidesToScroll: !props.isProfessionalTool ? 4 : 3
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: !props.isProfessionalTool ? 3 : 2,
                    slidesToScroll: !props.isProfessionalTool ? 3 : 2,
                }
            },
            {
                breakpoint: 767,
                settings: "unslick"
            }
        ]
    };

    let windowWidth = window.innerWidth;

    if (windowWidth > 766 && windowWidth < 1024) {
        settings.slidesToScroll = !props.isProfessionalTool ? 3 : 2
        settings.slidesToShow = !props.isProfessionalTool ? 3 : 2
    } else if (windowWidth > 1023) {
        settings.slidesToScroll = !props.isProfessionalTool ? 4 : 3
        settings.slidesToShow = !props.isProfessionalTool ? 4 : 3
    }

    const goToReaderDocument = props.title === "Continua a leggere";

    return (
        <React.Fragment>
            {props.prodList.length > 0 &&
                <ProdottiWrapper sectionClassName={props.sectionClassName} title={props.title} next={next} previous={previous} seeAll={props.seeAll}>
                    {!props.isProfessionalTool && !props.notSlick &&
                        <Slider ref={schedaProdCar} {...settings}>
                            {!props.isProfessionalTool &&
                                props.prodList.map((prod, index) => (
                                    <SchedaProdotto
                                        key={index}
                                        idProduct={prod.id}
                                        idForDelete={prod.idForDelete}
                                        isNew={prod.isNew}
                                        isUpdating={prod.isUpdating}
                                        title={prod.title}
                                        img={prod.img === '' ? "" : prod.img}
                                        listAutori={prod.auth}
                                        themeArea={prod.themeArea}
                                        idForReading={prod.idForReading}
                                        isManageable={props.isManageable}
                                        hasBookmark={props.hasBookmark}
                                        isSubscription={props.isSubscription}
                                        classNameCard={props.classNameCard}
                                        deleteProductList={deleteProductList}
                                        isAbbonamento= {props.isAbbonamento}
                                        goToReaderDocument= {goToReaderDocument}
                                        titleContainer = {props.title}
                                    ></SchedaProdotto>
                                ))
                            }
                        </Slider>
                    }

                    {props.notSlick && 
                        <div className="row scrolling" data-slide={props.dataSlide} data-slide-md={props.dataSlideMd} data-dots>
                            {props.prodList && props.prodList.map((item, index) => (
                                <CarouselItem 
                                    key={index}
                                    item={item} 
                                    index={index} 
                                    isManageable={props.isManageable}
                                    hasBookmark={props.hasBookmark}
                                    isSubscription={props.isSubscription}
                                    classNameCard={props.classNameCard}
                                    isAbbonamento= {props.isAbbonamento}
                                    deleteProductList={deleteProductList}
                                    goToReaderDocument= {goToReaderDocument}
                                    titleContainer = {props.title}
                                />
                            ))}
                        </div>
                    }
                        
                    {props.isProfessionalTool &&
                        <Slider ref={schedaProdCar} {...settings}>
                            {props.isProfessionalTool &&
                                props.prodList.map((prod, index) => (
                                    <SchedaStrumentiPerProfessionisti
                                        key={index}
                                        idProduct={prod.id}
                                        img={prod.img === '' ? config.imgDefault2 : prod.img}
                                        title={prod.title}
                                        description={prod.description}
                                        url={prod.url}
                                        titleContainer = {props.title}
                                    />
                                ))
                            }
                        </Slider>
                    }
                </ProdottiWrapper>
            }
        </React.Fragment >
    );
}

export default Prodotti;