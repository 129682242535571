import React from "react";
import { NavLink } from "react-router-dom";
import { titleUrlSlug } from "../../../helper/helper";
import { updateLink } from "../../../helper/TealiumHelper";

const GestioneListaContinuaALeggere = (props) => {

  const deleteBook = (ev) => {
    ev.preventDefault();
    
    props.removeBook(props.idForDelete);

    updateLink({ title: props.title, name: "rimuovi" }, props.titleContainer);
  }

  const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));

  return (
    <React.Fragment>
      <div className="card-action">

        <NavLink className="card-action-link" to={`/Prodotto/${title}/${props.idProduct}/SchedaDescrizione`} role="button" onClick={(ev) => {updateLink({ title: props.title, name: "info" }, props.titleContainer) }}>
          <span className="icon icon-info"></span>
          info
        </NavLink>

        <a className="card-action-link" onClick={deleteBook} role="button">
          <span className="icon icon-remove"></span>
          rimuovi
        </a>

      </div>
    </React.Fragment>
  );
}

export default GestioneListaContinuaALeggere;