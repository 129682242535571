import React from 'react'

const NextArrow = ({onClick, className}) => {
    return (
        <a 
            onClick={onClick} 
            className={`${className} carousel-btn carousel-btn--next absolute-arrow-right`}
        >
            <span className="icon icon-arrow-right"></span>
        </a>
    )
}

export default NextArrow