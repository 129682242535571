import React from 'react'
import { NavLink } from 'react-router-dom'

const Sole24Link = () => {
    return (
        <NavLink className="nav-link d-none d-md-block" to="/">
            <img className="nav-logo" src="/dist/img/ilsole24ore.svg" alt="il Sole 24 ORE" />
        </NavLink>
    )
}

export default Sole24Link