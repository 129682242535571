import React from "react";
import { NavLink } from "react-router-dom";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";
import ListaAutori from "../../ListaAutori/ListaAutori";

const SchedaProdottoBody = (props) => {

    const d = new Date(props.dataDocument);
    let year = d.getFullYear();
    const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));
    const link = !props.goToReaderDocument ? `/Prodotto/${title}/${props.idProduct}/SchedaDescrizione` : `/reader/${props.idForReading}`;

    return (
        <React.Fragment>
            <div className="card-body-main">
                <h4 className="card-title">
                    {props.isFromAnotherPage !== "daMieiLibri" &&
                        <NavLink to={`${!props.isSubscription ? link : "/Pacchetto/" + props.idProduct}`} onClick={(ev) => { updateLink({ title: props.title, name: "prodotto" }, props.titleContainer, props.title) }}>
                            {props.title}
                        </NavLink>
                    }
                    {props.isFromAnotherPage === "daMieiLibri" &&
                        <NavLink to={`${!props.isSubscription && year > 2020 ? "/Prodotto/" + title + "/" + props.idProduct + "/SchedaDescrizione" : "/reader/" + props.idProduct}`}
                            onClick={(ev) => {
                                updateLink({ title: props.title, name: "prodotto" }, props.titleContainer, props.title);
                                localStorage.setItem("mantieniIFiltri", true);
                            }}>
                            {props.title}
                        </NavLink>
                    }
                </h4>
                <p className="card-auth">
                    <ListaAutori isAbbonamento={props.isAbbonamento} listAutori={props.listAutori}></ListaAutori>
                </p>
                <p className="card-time">
                    {props.data}
                </p>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoBody;