import { useContext } from "react";
// React Query
import { useQuery } from "@tanstack/react-query";

import { mustToken, settingArrayParams } from "../helper/helper";
import { config } from '../constants/Constants';
import TokenContext from "../store/token-context";

const useFetchQuery = (key, themeArea, rows, configQuery="", doc=undefined) => {
  
  const tokenCtx = useContext(TokenContext);
  
  // queryFn: the function that will get the data. It must returns a promise.
  const fetchData = async ({ queryKey }) => {

    // removing the themeArea from the queryKey in order to pass the rigth parameter to the request
    const name = queryKey[0].split('--')[0];
    const arrayInputParams = settingArrayParams(doc, themeArea, rows);
    
    const bodyFormatted = {
      parameters: {
        'name': name,
        'inputParameters': arrayInputParams,
        'staticToken': mustToken(name) ? '' : config.token.STATIC_TOKEN,
      },
      'token': mustToken(name) ? tokenCtx.token : '',
    };

    const request = {
      url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
      req: {
        headers: {
          'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
          'Content-Type': 'application/json',
          'jsonorb-addcache': config.jsonorb.jsonorb_addCache
        },
        body: JSON.stringify(bodyFormatted),
        method: 'POST'
      }
    };
    
    const response = await fetch(request.url, {
      method: request.req.method,
      body: request.req.body,
      headers: request.req.headers 
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  };

  return useQuery(
    key, 
    fetchData, 
    // Query options
    { 
      onSuccess: configQuery.onSuccess,
      onError: configQuery.onError,
      keepPreviousData: true,
      // for dependent queries: as long as the dependence value is false the query does not start
      enabled: configQuery.dependence ? !!configQuery.dependence : true
    }
    );
  };
  
  export default useFetchQuery;
  
  // 'key' is always an array with one or more values. The name of the query is required and must be the first value.
  // All of this values are passed in the queryFn as the queryKey.
  //
  // 'themeArea' is a string contains the theme area.
  //
  // 'rows' is a number: the number of results to get.
  //
  // 'configQuery' is an empty string by default, but you can pass in different options by using an object.
  // 'onSuccess' and 'onError' are functions.