import React, { useContext, useEffect, useState } from "react";
import { config } from "../../../constants/Constants";
import { titleUrlSlugForTealium } from "../../../helper/helper";
import { bodyFormattedAcquista } from "../../../helper/helperResearch";
import { updateLink, updateView } from "../../../helper/TealiumHelper";
import useHttpRequest from "../../../hooks/http-request";
import AuthContext from "../../../store/auth-context";
import TokenContext from "../../../store/token-context";



const ReaderBlockMask = (props) => {

    const [urlAcquista, setUrlAcquista] = useState();
    const [titolo, setTitolo] = useState();

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();
    
    const img = props.img !== "" ? props.img : config.imgDefaultLibro;

    const showLoginModal = () => {
        authCtx.showModalLog(true);
    };

    useEffect(() => {

        if(props.idProvv === undefined || props.idDocType === undefined) return;

        const settingUrlAndPacchetto = (data) => {

            const result = data.Result.ResultSet?.Root[0];
            
            if(result === undefined || result === null) return;

            setTitolo(result.titolo);
            setUrlAcquista(result.url);

        };

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getProdotti),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedAcquista(props.idProvv, props.idDocType, tokenCtx))
            }
        };
        
        sendRqst.sendRequest(request, settingUrlAndPacchetto);
        
    });


    useEffect(() => {
 
        if (props.title === undefined) return;

        updateView(`book24ore:reader:${titleUrlSlugForTealium(props.title)}:bloccato`, authCtx, tokenCtx, `book24ore:reader-bloccato`);
        
    }, [props.title]);

    return (
        <React.Fragment>
            <div className="lock-wrapper">
                <div className="container">
                    <div className="lock">
                        <div className="lock-head">
                            <h2 className="lock-title">
                                Continua a leggere
                                <span className="lock-title-book">
                                    {props.title}
                                </span>
                            </h2>
                            <p className="lock-txt">
                                Questo libro fa parte del pacchetto <strong>{titolo}</strong>
                            </p>
                        </div>
                        <div className="lock-content">
                            <a className="btn btn--big btn--black lock-buy" href={urlAcquista} onClick={(ev) => {updateLink({ title: props.title, name: "acquista" }, "paywall", props.title) }}>
                                Acquista il pacchetto su Shopping24
                            </a>

                            <div className="lock-info">
                                <div className="lock-info-content">
                                    <h4 className="lock-info-title">
                                        Con Book24ORE otterrai
                                    </h4>
                                    <ul className="list">

                                        <li className="list-item">
                                            <span className="lock-info-feature">
                                                <span className="icon icon-check"></span>
                                                Un’area personale con tutti i tuoi libri
                                            </span>
                                        </li>

                                        <li className="list-item">
                                            <span className="lock-info-feature">
                                                <span className="icon icon-check"></span>
                                                Possibilità di evidenziare e aggiungere note nei libri con area dedicata per organizzarle
                                            </span>
                                        </li>

                                        <li className="list-item">
                                            <span className="lock-info-feature">
                                                <span className="icon icon-check"></span>
                                                Libri sempre in aggiornamento con contenuti speciali
                                            </span>
                                        </li>

                                    </ul>
                                    <a className="lock-info-link" href="https://24oreprofessionale.ilsole24ore.com/prodotti/book24ore">
                                        Perché abbonarsi
                                    </a>
                                </div>

                                <figure className="card-img ">
                                    <a href="#">

                                        <span className="card-img-book">
                                            <img src={img} alt="" />
                                        </span>

                                    </a>
                                </figure>

                            </div>
                        </div>
                        {!authCtx.isLoggedIn && <div className="lock-foot">
                            <p className="lock-foot-txt">
                                Ha già acquistato il libro?
                            </p>
                            <a className="btn" onClick={showLoginModal}>
                                Accedi
                            </a>
                        </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ReaderBlockMask;