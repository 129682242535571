import React from "react";
import Link from "./Link/Link";

const ListLink = (props) => {

    return (
        <React.Fragment>
            <h4 className="page-h4"></h4>
            <ul className="list list--large page-list">
            {props.listSommario.map(sommario => (<Link
                    key={Math.random()}
                    title={sommario.Text1}
                    url={sommario.DocumentId}
                    typeSumm={props.typeSumm}
                    isLeaf= {props.isLeaf}
                    isFree={sommario.isFree}
                    isUpdated={sommario.isUpdated}
                />
                ))}
            </ul>
        </React.Fragment>
    );
}

export default ListLink;