import React from "react";

const SearchEmpty = () => {

    return (
        <div className="empty">
            <div className="empty-content">
                <h2 className="empty-title ">
                    Inizia la tua ricerca su Book24ORE
                </h2>

                <p className="empty-txt">
                    Inserisci una parola chiave nella barra di ricerca per trovare le informazioni che ti interessano
                </p>


            </div>

            <figure className="empty-img">
                <img className="img-fluid d-md-none" src="/dist/img/help-ricerca--small.png" alt="" />
                <img className="img-fluid d-none d-md-block" src="/dist/img/help-ricerca.png" alt="" />
            </figure>

        </div>
    );
}

export default SearchEmpty;