import React, { useState } from "react";

const ReaderToolsPrint = (props) => {

    const Print = () =>{     
        var html = "";
        html +='<!DOCTYPE html>';
        html +='<html lang="en">';
        html +='<head>';
        html +='<meta charset="utf-8" />';
        html +=' <meta name="viewport" content="width=device-width, initial-scale=1">';
        html +='  <meta http-equiv="X-UA-Compatible" content="IE=edge">';
        html +='  <title>Book24</title>';
        html +='  <link rel="stylesheet" href="/dist/css/style-biblioteca24.css" />';
        html +='</head>';
        html +='<body>';
        html += document.getElementById("wrapper").innerHTML + '</body></html>';

        var _iframe = document.createElement("IFRAME");
        document.getElementsByTagName('body')[0].appendChild(_iframe);

        var docif = _iframe.contentWindow.document;
        docif.write(html);
        docif.close();

        _iframe.contentWindow.focus();
        setTimeout(function () {
            _iframe.contentWindow.print();
            setTimeout(function () {
                document.getElementsByTagName('body')[0].removeChild(_iframe);
            }, 1000);
        }, 1000);

    };
    
    return (
        <React.Fragment>
            {
                <li className="list-item">
                    <a className="itool " role="button" data-toggle="tooltip" data-placement="left" title="" data-original-title="Stampa" onClick={Print}>
                        <span className="icon icon-print"></span>
                    </a>
                </li>
            }
        </React.Fragment>
    );
}

export default ReaderToolsPrint;