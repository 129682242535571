import React from "react";
import { NavLink } from "react-router-dom";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateLink } from "../../../../helper/TealiumHelper";
import ListaAutori from "../../ListaAutori/ListaAutori";

const SchedaProdottoAreaListaTematicaBody = (props) => {

    const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));

    return (
        <React.Fragment>
            <div className="card-body">
                <div className="card-body-main">
                    <h4 className="card-title">
                        <NavLink to={`${!props.isSubscription ? "/Prodotto/" + title + "/" + props.idProduct + "/SchedaDescrizione" : "/Pacchetto/" + props.idProduct}`}
                            onClick={(ev) => { updateLink({ title: props.title, name: "prodotto" }, props.titleContainer) }}>
                            {props.title}
                        </NavLink>
                    </h4>

                    <p className="card-auth">
                        <ListaAutori listAutori={props.listAutori} />
                    </p>
                </div>
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematicaBody;