import React from "react";

const Novita = () => {

    //useScript('/dist/js/app.js');

    return (
        <React.Fragment>
            <span className="card-news">Novità</span>
        </React.Fragment>
    )
}

export default Novita;