import React from 'react';

const ReaderMainContainer = (props) => {

    return (
        <>
            {
                props.tipoDocument !== 'pdf' &&
                <div className={"reader-main " + props.zoomText}>
                    <div className="container">
                        <div className="reader-container">
                            {props.children}
                        </div>
                    </div>
                </div>
            }
            {
                props.tipoDocument === 'pdf' &&
                <div className='pdfreader'>
                    {props.children}
                </div>
            }
        </>
    );
}

export default ReaderMainContainer;