import React from 'react'

const ScrollUpButton = () => {
    return (
        <a className="itool itool--dark reader-tool-top" role="button" onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}>
            <span className="icon icon-arrow-up"></span>
        </a>
    )
}

export default ScrollUpButton