import React, { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { titleUrlSlugForTealium } from '../../helper/helper';
import { updateView } from '../../helper/TealiumHelper';
import AuthContext from '../../store/auth-context';
import TokenContext from '../../store/token-context';
import ReaderBlockMask from './ReaderBlockMask/ReaderBlockMask';
import ReaderBoxesBottom from './ReaderBoxes/ReaderBoxesBottom';
import ReaderLine from '../UI/ReaderLine/ReaderLine';   

const ReaderMain = (props, ref) => {
//const ReaderMain = (props) => {

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const title = props.paragraph.title;
    const regex = /(<span class="titolo-paragrafo".*?<\/span>)/mi;
    const textPar = props.paragraph.textPar?.replace(regex, '');

    const [idDocument, setIdDocument] = useState(-1);

    useEffect(() => {

        if (props.activatedCtxMenu) {
            document.getElementById("readerMain").addEventListener('contextmenu', event => event.preventDefault());
        }

    }, [])

    useEffect(() => {
        var element = document.querySelectorAll("a.btn.btn-light.btn-full");
        
        
        if(document.querySelector('.expandible.expandible-sm')){
            document.querySelector('.expandible.expandible-sm').replaceWith(...document.querySelector('.expandible.expandible-sm').childNodes);
        }
        /*if(document.querySelector('.table-wrap')){
            document.querySelector('.table-wrap').replaceWith(...document.querySelector('.table-wrap').childNodes);
        }*/
        if(document.querySelector('.panel-collapse.collapse.in')){
            document.querySelector('.panel-collapse.collapse.in').replaceWith(...document.querySelector('.panel-collapse.collapse.in').childNodes);
        }

        Array.prototype.forEach.call( element, function( node ) {
            node.parentNode.removeChild( node );
        });
        

    }, [])
    // fix the pointers to the paragraphs
    useEffect(() => {
        const pointers = document.querySelectorAll('p.reader-note a[href^="#"]');

        const handleClickNote = (e, id) => {
            e.preventDefault();
            const para = document.querySelector(`span.reader-txt span#textComplete a[name="${id}"], span.reader-txt span#textComplete a[id="${id}"]`);
            para.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        pointers.forEach((pointer) => {
            const id = pointer.hash.split('#')[1];
            pointer.addEventListener('click', (e) => handleClickNote(e, id))
        })
    }, [])

    // fix the pointers to the notes
    useEffect(() => {
        const pointers = document.querySelectorAll('span.reader-txt span#textComplete a[href^="#"]');

        const handleClickPara = (e, id) => {
            e.preventDefault();
            const note = document.querySelector(`p.reader-note a[name="${id}"], p.reader-note a[id="${id}"]`);
            note.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        pointers.forEach((pointer, i) => {
            const id = pointer.hash.split('#')[1];
            pointer.addEventListener('click', (e) => handleClickPara(e, id))
        })

    }, []);

    /*useEffect(() => {

        if (Object.keys(props.getTipo).length === 0 || props.title === undefined || !props.buyedOrFreeContent || idDocument === props.idDocument) return;

        setIdDocument(props.idDocument);

    }, [props.title, props.getTipo]);

    useEffect(() => {

        if(props.idDocument === undefined) 
            return;

        if (props.getTipo.free === "1") {
            updateView(`book24ore:reader:${titleUrlSlugForTealium(props.title)}:anteprima`, authCtx, tokenCtx, `book24ore:reader-anteprima`);
            return;
        }
        if (props.getTipo.comprato === "1") {
            updateView(`book24ore:reader:${titleUrlSlugForTealium(props.title)}:paid`, authCtx, tokenCtx, `book24ore:reader-paid`);
            return;
        }

    }, []);*/

    return (
        <React.Fragment>
            <div id="readerMain" className={"reader-entry has-gotonav"}>
                <h3 className="reader-txt-title">
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </h3>
                {props.paragraph.novitaCount === 0 &&
                    <span className="reader-txt">
                        <span id="textComplete" dangerouslySetInnerHTML={{ __html: textPar }} />
                    </span>
                }
                {props.paragraph.novitaCount !== 0 &&
                    <div className="reader-box-wrapper" data-target id="reader-update">
                        <div className="reader-box reader-box--update">
                            <span className="page-label">Aggiornato</span>
                            <span className="reader-txt">
                                <span dangerouslySetInnerHTML={{ __html: textPar }} />
                            </span>
                        </div>
                    </div>
                }
                {props.paragraph.noteBE && authCtx.isLoggedIn &&
                    <div className="reader-box-wrapper">
                        <div id="noteComplete" className="reader-box">
                            <h5 className='reader-box-title'>NOTE</h5>
                            <ul className="list">

                                <li className="list-item">
                                    <p className="reader-note">
                                        <span id="note-id-exclude" dangerouslySetInnerHTML={{ __html: props.paragraph.noteBE }} />
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                }
                {props.normaCommentata != null && props.normaCommentata.iddocumento != undefined && !sessionStorage.getItem("isBookIntegrate") &&
                    <> 
                    <ReaderLine></ReaderLine>
                    <ReaderBoxesBottom
                        normaCommentata={props.normaCommentata}
                    />
                    </>
                }
                {/*props.activatedCtxMenu && <ReaderDropdownNote paragraphToEdit = {props.paragraph.textPar}></ReaderDropdownNote>*/}
            </div>

        </React.Fragment>
    );
}

export default ReaderMain;