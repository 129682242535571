import React from "react";
import { Outlet } from "react-router-dom";
import AreaBody from "./AreaBody/AreaBody";
import AreaHeader from "./AreaHeader/AreaHeader";

const AreaComponent = (props) => {

    return (
        <React.Fragment>
            <div className="main-content">
                <AreaHeader themeArea={props.themeArea}></AreaHeader>
                <section>
                    <AreaBody themeArea={props.themeArea}></AreaBody>
                </section>
                <div>
                    <Outlet></Outlet>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AreaComponent;