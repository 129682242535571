import React from "react";
import { useState } from "react";

const ReaderToolsTextSizeMenu = (props) => {

    const [isActive, setIsActive] = useState(2);

    const settingZoom = (ev) => {

        switch (ev.target.getAttribute('value')) {
            case ("small"):
                setIsActive(1);
                props.settingZoom("set-text-small");
                break;
            case ("normal"):
                setIsActive(2);
                props.settingZoom("");
                break;
            case ("big"):
                setIsActive(3);
                props.settingZoom("set-text-big");
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <div className={`dropdown-menu dropdown-menu-right ${props.show ? "show" : ""}`}>
                <ul className="list list--mid">

                    <li className="list-item">
                        <a className={"licon " + (isActive === 1 ? "is-active" : "")} role="button" value="small" onClick={settingZoom}>
                            Piccolo
                            <span className="icon icon-text-size icon--t-small"></span>
                        </a>
                    </li>

                    <li className="list-item">
                        <a className={"licon " + (isActive === 2 ? "is-active" : "")} role="button" value="normal" onClick={settingZoom}>
                            Medio
                            <span className="icon icon-text-size "></span>
                        </a>
                    </li>

                    <li className="list-item">
                        <a className={"licon " + (isActive === 3 ? "is-active" : "")} role="button" value="big" onClick={settingZoom}>
                            Grande
                            <span className="icon icon-text-size icon--t-big"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default ReaderToolsTextSizeMenu;