import React from "react";
import { Link } from "react-router-dom";
import { updateLink } from "../../../helper/TealiumHelper";

const SchedaStrumentiPerProfessionisti = (props) => {

    /*<Link to={`/Pacchetto/${props.idProduct}`}>*/


    return (
        <React.Fragment>
            <div className="col carousel-slide scrolling-item scrolling-item--large">

                <div className="card card--tool ">
                    <div className="card-box">
                        <div className="card-content">
                            <a href={props.url} onClick={(ev) => {updateLink({ title: props.title, name: "" }, props.titleContainer) }}>
                                <figure className="card-img">
                                    <span className="card-img-tool">
                                        <img className="card-img-full" src={props.img} alt="" />
                                    </span>
                                </figure>
                            </a>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <a href={props.url} onClick={(ev) => {updateLink({ title: props.title, name: "" }, props.titleContainer) }}>
                                        {props.title}
                                    </a>
                                </h4>
                                <p className="card-txt">
                                    {props.description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SchedaStrumentiPerProfessionisti;