import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Book24Link = ({settings}) => {

    const navigate = useNavigate();

    const closeMenu = (ev) => {
        ev.preventDefault();
        window.document.body.classList.remove("is-drawer-in");
        navigate("/");
    }

    return (
        <a className="nav-link" /*to="/"*/ {...settings} onClick={closeMenu} type='button'>
            <img className="nav-logo nav-logo--main" src="/dist/img/book24ore.svg" alt="Book24ORE" />
            <span className="nav-logo-bg"></span>
        </a>
    )
}

export default Book24Link