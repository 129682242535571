import React from 'react';

const ReaderHeadResearch = () => {

    return(
        <>
            <a className="reader-goto reader-goto--left" href="#">
                Torna alla lettura
                <span className="icon icon-arrow-left-small"></span>
            </a>

            <div className="search-multi">
                <div className="search-multi-box">
                    <label className="search-label">Cerca</label>
                    <input className="input search-multi-input" type="text" placeholder="Inserisci parola chiave" defaultValue="Testo cercato"/>
                </div>
                
                
                <button className="btn btn--big btn--round btn--black btn--icon-left d-none d-lg-block" type="submit">
                    Cerca <span className="icon icon-search"></span>
                </button>
                <button className="itool itool--black d-lg-none" type="submit">
                    <span className="icon icon-search"></span>
                </button>
            </div>
        </>

    );
}

export default ReaderHeadResearch;