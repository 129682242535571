import React from 'react';

const Pagination = ({ currentPage, setCurrentPage, pages, isResearch=false, isSection=true}) => {

    return(
        <div className={`${isResearch ? "search-foot" : ""} ${isSection ? "section-foot text-center" : ""}`}>
            <nav className="pager">
                <ul className="list-inline d-none d-md-block">
                    <li className="list-inline-item" 
                        onClick={() => currentPage !== 1 && setCurrentPage((page) => page - 1)}>

                        <a className={`pager-link ${currentPage === 1 ? "disabled" : ""}`} href="#">
                            <span className="icon icon-arrow-left"></span>
                        </a>
                    </li>

                    {pages && pages.map((page, i) => (
                        <li className="list-inline-item" onClick={() => setCurrentPage(page)} key={i}>
                            <a className={`pager-link ${currentPage === page ? "current" : ""}`} href="#">{page}</a>
                        </li>
                    ))}

                    <li className="list-inline-item" 
                        onClick={() => currentPage !== pages.length && setCurrentPage((page) => page + 1)}>

                        <a className={`pager-link ${currentPage === pages.length ? "disabled" : ""}`} href="#">
                            <span className="icon icon-arrow-right"></span>
                        </a>
                    </li>
                </ul>

                <div className="pager-mobile d-md-none">
                    <a  href="#"
                        className={`itool itool--big btn--shadow ${currentPage === 1 ? "disabled" : ""}`} 
                        onClick={() => currentPage !== 1 && setCurrentPage((page) => page - 1)}>

                        <span className="icon icon-chevron-left"></span>
                    </a>

                    <a  href="#" 
                        className={`itool itool--big btn--shadow ml-auto ${currentPage === pages.length ? "disabled" : ""}`}   
                        onClick={() => currentPage !== pages && setCurrentPage((page) => page + 1)}>

                        <span className="icon icon-chevron-right"></span>
                    </a>
                </div>
            </nav>
        </div>
    );
}

export default Pagination;
