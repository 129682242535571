import React from 'react'
import NoteSubTitle from './NoteSubTitle'

const Note = (props) => {

    const editNote = (editedNote) => {
        props.editNote(editedNote);
    };

    const deleteNote = (noteToDelete) => {
        props.deleteNote(noteToDelete);
    }

    return (
        <div className="note-book">
            <h2 className="page-title note-book-title">
                {props.title}
            </h2>
            <div className="note-book-list">
                <ul className="list-lined list-lined--large">
                    {props.subTitleList && props.subTitleList.map((item, index) => (
                        <NoteSubTitle item={item}
                            key={Math.random()}
                            mostUsedTag={props.mostUsedTag}
                            editNote={editNote}
                            deleteNote={deleteNote}
                        />
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Note