import React from "react";

const PubblicitaBox = (props) => {

    return (
        <React.Fragment>
            {props.img !== "" &&
                <div className="abox abox--fullwidth section d-none d-lg-block">
                    <img src={props.img} alt="" className="img-full" />
                </div>
            }
        </React.Fragment>
    );
}

export default PubblicitaBox;