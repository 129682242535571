import React, { useContext, useState, useEffect } from 'react'
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import UtilityWrapper from '../components/UtilityWrapper/UtilityWrapper';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import TokenContext from '../store/token-context';
import SessioneScaduta from "../components/Modals/SessioneScaduta"
const Offline = (props) => {

    const authCtx = useContext(AuthContext);
    const tokenCtx= useContext(TokenContext);

    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky" isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal> }
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <UtilityWrapper>
                    <figure className="errorpage-img">
                        <img className="img-fluid" src="/dist/img/offline.svg" alt=""/>
                    </figure>
                    <h1 className="errorpage-title">
                        <span className="errorpage-title-light">Oops! </span>
                        Sei Offline
                    </h1>
                    <p className="errorpage-text">
                        Nessuna connessione disponibile. Accedi ad una rete Internet per visualizzare correttamente tutti i contenuti di Book24ORE
                    </p>
                    <a className="btn btn--black btn--big" href="#">Ricarica pagina</a>
                </UtilityWrapper>

                <Ricerca></Ricerca>

                <div className="offline-wrapper">
                    <div className="container">
                        <p className="offline">
                            <span className="icon icon-offline"></span>
                            Nessuna connessione
                            <span className="offline-status">Ultimo aggiornamento: 28 Ottobre</span>
                        </p>
                    </div>
                </div>
                
            </Wrapper>
        </React.Fragment>
    )
}

export default Offline;