import React from "react";
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { useRef } from "react";
import { useEffect, useState } from "react";
import { config } from "../../constants/Constants";

const ViewerPdfExpress = (props) => {

    const headerReader = document.querySelector('.mhead.mhead--sticky.mhead--reader');
    headerReader.classList.add('mb-0');
    const instance = useRef();
    const viewer = useRef(null);
    const [count, setCount] = useState(0);

    const base64string = `data:application/pdf;base64,${props.pdfDoc}`

    function base64ToBlob(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: 'application/pdf' });
    };

    useEffect(() => {
        if (instance.current) {
          instance.current.loadDocument(base64ToBlob(props.pdfDoc))
        }
      }, [props.pdfDoc])

    useEffect(() => {
        if (!props.pdfDoc) return;
            WebViewer({
                path: '/webviewer/lib',
                licenseKey: config.licenseKeyPdfExpress,
                css: '../../index.css',
                disabledElements: [
                    'contextMenuPopup',
                    'selectToolButton',
                    'marqueeToolButton',
                    'panToolButton',
                    'themeChangeButton'
                ]
            }, viewer.current).then(instance => {
                instance.current = instance
                instance.UI.loadDocument(base64ToBlob(props.pdfDoc), { filename: 'myfile.pdf' });
                const Feature = instance.UI.Feature;
                const LayoutMode = instance.UI.LayoutMode;
                const iframeDoc = instance.UI.iframeWindow.document;
                instance.UI.setLanguage('it');
                instance.UI.disableFeatures([instance.UI.Feature.Annotations])
                //instance.UI.disableFeatures([Feature.Print]);
                instance.UI.disableFeatures([Feature.Download]);
                instance.UI.disableFeatures([Feature.PageNavigation]);
                instance.UI.disableFeatures([Feature.Theme]);
                instance.UI.setLayoutMode(LayoutMode.Continuous);
                instance.UI.setMinZoomLevel('25%');
                instance.UI.setZoomLevel('100%');
                instance.UI.setMaxZoomLevel('200%');
                instance.UI.disableReplyForAnnotations();
                instance.UI.disableFadePageNavigationComponent();
                instance.UI.setPrintQuality(2);
                instance.disableElements(['documentControl']);

                var currentPageNumber = 1;

                instance.updateElement('printButton', {
                    onClick: () => {
                        printCurrentPage();
                    }
                })

                function printCurrentPage() {
                    instance.UI.printInBackground({
                        pagesToPrint: [currentPageNumber],
                        includeComments: false,
                        includeAnnotations: false,
                        maintainPageOrientation: true,
                        onProgress: function (pageNumber, htmlElement) { },
                    });
                }

                /* Styles the viewer */
                const header = iframeDoc.querySelector('.Header');
                header.style.cssText = 'background-color: #fff8f0;';

                const icons = iframeDoc.querySelectorAll('.Icon');
                icons.forEach(icon => {
                    icon.style.cssText = 'color: #3d3d3d;';
                });

                const dividers = iframeDoc.querySelectorAll('.divider');
                dividers.forEach(divider => {
                    divider.style.cssText = 'background-color: #efcca9;';
                })


                const docContainer = iframeDoc.querySelector('.DocumentContainer');
                docContainer.style.cssText = 'background-color: rgb(61, 61, 61); padding-top: 30px; padding-bottom:30px';

                const zoomOverlayButton = iframeDoc.querySelector('div.CustomElement[data-element=zoomOverlayButton]');
                zoomOverlayButton.style.cssText = 'background: transparent;';

                const zoomOverlay = iframeDoc.querySelector('div.CustomElement[data-element=zoomOverlayButton] div.zoom-overlay');
                zoomOverlay.style.cssText = 'background: #fff; border: 1px solid #efcca9;';
                /* end styles */

                const { documentViewer } = instance.Core;
                documentViewer.addEventListener('documentLoaded', () => {
                    // perform document operations
                });

                documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
                    currentPageNumber = pageNumber;
                    const totalPages = instance.getPageCount();

                    if (pageNumber === totalPages) {
                        props.savePdfToLastPage();
                    }

                });
            })
        
    }, [props.pdfDoc]);


    return (
        <React.Fragment>
            <div className="pdfreader-main h-80vh">
                <div className="pdfreader-content h-80vh" ref={viewer}></div>
            </div>
        </React.Fragment>
    )
}

export default ViewerPdfExpress;