import React, { useContext, useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import LoginModal from "../components/Modals/Login";
import SessioneScaduta from "../components/Modals/SessioneScaduta";
import QrCodeBody from "../components/QrCode/QrCodeBody";
import QrCodeHeader from "../components/QrCode/QrCodeHeader";
import QrCodeWrapper from "../components/QrCode/QrCodeWrapper";
import AuthContext from "../store/auth-context";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import TokenContext from "../store/token-context";
import { updateView } from "../helper/TealiumHelper";

const QrCode = (props) => {

    const [result, setResult] = useState("sleep");
    const [bookActivated, setBookActivated] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isDifferentAbbo, setIsDifferentAbbo] = useState(false);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    const loginHandler = (data) => {

        authCtx.showModalLog(data)
    };

    const activationSuccess = (data, isDiffAbb) => {

        setResult("success");
        setSuccessMessage(data);
        setIsDifferentAbbo(isDiffAbb)
    };

    const resetQrCode = (data) => {
console.log("qui")
        setResult(data.response);
        setErrorMessage(data.message);
    }

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView("book24ore:attiva-libro", authCtx, tokenCtx, "book24ore:attiva-libro");

    }, [tokenCtx.token, authCtx.isLoggedIn, window.utag]);

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Header showSecondRow={false} classSticky={"sticky"} isIntegrateInBD={props.isIntegrateInBD}></Header>
            {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal} />}
            {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
            {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
            <QrCodeWrapper>
                <QrCodeHeader result={result} titleBook={bookActivated.titleBook} isDifferentAbbo={isDifferentAbbo} />
                <QrCodeBody loginHandler={loginHandler}
                    result={result}
                    resetQrCode={resetQrCode}
                    activationSuccess={activationSuccess}
                    isDifferentAbbo={isDifferentAbbo}
                    book={bookActivated}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    qrScanner={props.qrScanner} 
                    settingsQrScanner={props.settingsQrScanner}
                />
            </QrCodeWrapper>
            <Ricerca />
            <Footer isIntegrateInBD={props.isIntegrateInBD}/>
        </React.Fragment>
    )
}

export default QrCode;