import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { browserName, browserVersion, isDesktop } from 'react-device-detect';
import { getCookie } from './helper/helper';
// Pages
import Offline from './Pages/Offline';
import MyBooks from './Pages/MyBooks';
import Prodotto from "./Pages/Prodotto";
import Pacchetto from './Pages/Pacchetto';
import Area from './Pages/Area';
import AllList from './Pages/AllList';
import Notifications from './Pages/Notifications';
import PersonalArea from './Pages/PersonalArea';
import Research from './Pages/Research';
import SchedaPerApprofondire from './Pages/SchedaPerApprofondire';
import Reader from './Pages/Reader';
import ReaderSummary from './Pages/ReaderSummary';
import ReaderUpdates from './Pages/ReaderUpdates';
import ReaderResearch from './Pages/ReaderResearch';
import OldBrowser from './Pages/OldBrowser';
import NotFound from './Pages/NotFound';
import SecondResearch from './Pages/SecondResearch';
import QrCode from './Pages/QrCode';
import Notes from './Pages/Notes';
import FAQ from './Pages/FAQ';
import BDIndex from './Pages/BDIndex';
import Software from './Pages/Software';
// Components
import Home from './components/Home/Home.js';
import SchedaSommario from './components/Prodotti/Prodotto/SchedaSommario/SchedaSommario';
import SchedaAutori from "./components/Prodotti/Prodotto/SchedaAutori/SchedaAutori";
import SchedaDescrizione from './components/Prodotti/Prodotto/SchedaDescrizione/SchedaDescrizione';
import AuthContext from './store/auth-context';
import TokenContext from './store/token-context';
import { checkSoleaAuth } from './helper/helper';
import Spinner from './components/UI/Spinner/Spinner';
import MetaTags from './components/Helmet/MetaTags';
import { config } from './constants/Constants';
import FrameNtPro from './Pages/FrameNtPro';
import axios from 'axios';
import ReaderAuthors from './Pages/ReaderAuthors';
import ReaderDescription from './Pages/ReaderDescription';

const queryClient = new QueryClient();

function App() {

  const authCtx = useContext(AuthContext);
  const tokenCtx = useContext(TokenContext);
  const location = useLocation();
  const [isLoading, setIfIsLoading] = useState(true);
  const [isIntegrateInBD, setIsIntegrateInBD] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [ip, setIp] = useState(null);
  const [isDesktopDevice, setIsDesktopDevice] = useState(true);
  const [qrScanner, setQrScanner] = useState({});
  const token = getCookie("plus_token_forDomain");


  const isOldBrowser = function () {

    switch (browserName) {
      case "Chrome":
        if (browserVersion < 78) return true;
      case "Firefox":
        if (browserVersion < 70) return true;
      case "Trident":
        if (browserVersion < 44) return true;
      case "Edge":
        if (browserVersion < 44) return true;
      case "Safari":
        if (browserVersion < 13) return true;
      case "Mobile Safari":
        if (browserVersion < 13) return true;
      default:
        return false;

    }

  }

  const handleSaved = (save) => {
    document.body.classList.add("is-toast-in");
    setIsSaved(!isSaved);
  }

  const getUserIp = async () => {
    const res = await axios.get(config.ip);
    setIp(res.data.ip);
  }

  const settingsQrScanner = (qrCode) => {
    setQrScanner(qrCode);
  }

  useEffect(() => {
    getUserIp();
  }, []);

  useEffect(() => {

    if (!authCtx.isLoggedIn) return;

    let loadProds = setTimeout(() => {

      if (authCtx.isLoggedIn) {
        tokenCtx.loadProduct();
      }
      setIfIsLoading(false);

    }, 400);

    return () => {
      clearTimeout(loadProds);
    };
  }, [authCtx.isLoggedIn]);

  useEffect(() => {
    if(!sessionStorage.getItem("isBookIntegrate")) return;
        setIsIntegrateInBD(true);
  }, [sessionStorage.getItem("isBookIntegrate")]);

  useEffect(() => {
    if (ip === null) return;

    tokenCtx.setIpConf(ip);
  }, [ip]);

  useEffect(() => {

    let auth = setTimeout(() => {
      const soleauth = checkSoleaAuth();
      if (soleauth === undefined || soleauth === '' || soleauth === null) {
        setIfIsLoading(false)
      }
      if ((soleauth !== undefined && soleauth !== '' && soleauth !== null) && localStorage.getItem("isLoggedIn") === null) {
        setIfIsLoading(true);
        authCtx.loginUserProfile();
      }
      if (soleauth !== '' && localStorage.getItem("isLoggedIn") !== null && localStorage.getItem("token") === null) {
        if (localStorage.getItem("userLoggedWithoutToken")) {
          setIfIsLoading(false);
        } else {
          localStorage.removeItem("isLoggedIn");
        }
      }
      if (soleauth !== '' && localStorage.getItem("isLoggedIn") === "1" && localStorage.getItem("token") !== null) {
        if (authCtx.username === 'Utente') {
          authCtx.loginUserProfile();
        }
        localStorage.removeItem("userLoggedWithoutToken");
        setIfIsLoading(false);
      }
    }, 200);

    return () => {
      clearTimeout(auth);
    };

  });

  useEffect(() => {
    let delayedClose = setTimeout(() => {
      document.body.classList.remove('is-toast-in');
    }, 1000);

    return () => {
      clearTimeout(delayedClose);
    }
  }, [isSaved]);

  useEffect(() => {

    window.top.postMessage("book24", "*");

    if (location.pathname === "/qr" || Object.keys(qrScanner).length === 0) return;

    qrScanner.destroy();

  }, [location.pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <script
          src={config.socialLoginUrl}
          integrity=""
          crossorigin="anonymous"
          async
        ></script>
        {/*<!-- Tealium sync script -->*/}
        <script src={config.scriptTealium}></script>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <MetaTags />
        {/*<div className={`App ${isSaved ? 'is-toast-in' : ''}`}>*/}
        <div className="App ">
          <Routes>
            <Route path="/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} {!isOldBrowser() && <Home isDesktop={isDesktop} isIntegrateInBD={isIntegrateInBD}></Home>} {isOldBrowser() && <OldBrowser></OldBrowser>}</>} />
            <Route path="/" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} {!isOldBrowser() && <Home isDesktop={isDesktop} isIntegrateInBD={isIntegrateInBD}></Home>} {isOldBrowser() && <OldBrowser></OldBrowser>}</>} />
            <Route path="/miei-libri" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <MyBooks isIntegrateInBD={isIntegrateInBD}></MyBooks> </>} />
            <Route path="/miei-libri/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <MyBooks isIntegrateInBD={isIntegrateInBD}></MyBooks> </>} />
            <Route path="/Prodotto/:nomeLibro/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Prodotto isIntegrateInBD={isIntegrateInBD}></Prodotto> </>}>
              <Route path="/Prodotto/:nomeLibro/:id/SchedaDescrizione/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SchedaDescrizione></SchedaDescrizione></>} />
              <Route path="/Prodotto/:nomeLibro/:id/SchedaDescrizione" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SchedaDescrizione></SchedaDescrizione></>} />
              <Route path="/Prodotto/:nomeLibro/:id/SchedaAutori" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SchedaAutori></SchedaAutori></>} />
              <Route path="/Prodotto/:nomeLibro/:id/SchedaSommario" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SchedaSommario></SchedaSommario></>} />
              <Route path="/Prodotto/:nomeLibro/:id/SchedaSommario/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SchedaSommario></SchedaSommario></>} />
            </Route>
            <Route path="/Pacchetto/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Pacchetto isIntegrateInBD={isIntegrateInBD}></Pacchetto></>} />
            <Route path="/Area/:themeArea" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Area isIntegrateInBD={isIntegrateInBD}></Area></>} />
            <Route path="/Area/:themeArea/AllList" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <AllList isIntegrateInBD={isIntegrateInBD}></AllList></>} />
            <Route path="/ricerca" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Research isIntegrateInBD={isIntegrateInBD}></Research></>} />
            <Route path="/ricerca/:isFromAnotherPage/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Research isIntegrateInBD={isIntegrateInBD}></Research></>} />
            <Route path="/ricerca/:isFromAnotherPage" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Research isIntegrateInBD={isIntegrateInBD}></Research></>} />
            <Route path="/ricerca/level2/:idProv/:word/:title/:isFrizzera" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <SecondResearch isIntegrateInBD={isIntegrateInBD}></SecondResearch></>} />
            <Route path="/notifiche" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Notifications isIntegrateInBD={isIntegrateInBD}></Notifications></>} />
            <Route path="/area-personale/:section" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <PersonalArea isIntegrateInBD={isIntegrateInBD}></PersonalArea></>} />
            <Route path="/reader/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Reader handleSaved={handleSaved} perApprofondire={false} isIntegrateInBD={isIntegrateInBD}></Reader></>} />
            <Route path="/reader/:id/:isBookIntegrate" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Reader handleSaved={handleSaved} perApprofondire={false} isIntegrateInBD={isIntegrateInBD}></Reader></>} />
            <Route path="/reader/:id/SchedaPerApprofondire" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Reader handleSaved={handleSaved} perApprofondire={true} isIntegrateInBD={isIntegrateInBD}></Reader></>} />
            {/*<Route path="/reader-free" element={<>{isLoading && <Spinner></Spinner>} <Reader isFree={true}></Reader></>} />*/}
            <Route path="/reader-descrizione/:title/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <ReaderDescription isIntegrateInBD={isIntegrateInBD}></ReaderDescription></>} />
            <Route path="/reader-autori/:title/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <ReaderAuthors isIntegrateInBD={isIntegrateInBD}></ReaderAuthors></>} />
            <Route path="/reader-summary/:title/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <ReaderSummary isIntegrateInBD={isIntegrateInBD}></ReaderSummary></>} />
            <Route path="/reader-updates/:title/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <ReaderUpdates isIntegrateInBD={isIntegrateInBD}></ReaderUpdates></>} />
            <Route path="/reader-research" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <ReaderResearch isIntegrateInBD={isIntegrateInBD}></ReaderResearch></>} />
            <Route path="/index/:title/:id/:order" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <BDIndex isIntegrateInBD={isIntegrateInBD}></BDIndex></>} />
            <Route path="/ntPro/:title/:area/:idDoc" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <FrameNtPro isIntegrateInBD={isIntegrateInBD}></FrameNtPro></>} />
            <Route path="/arricchimenti/:idDocType/:idProv/:id" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Software isIntegrateInBD={isIntegrateInBD}></Software></>} />
            <Route path="/FAQ" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <FAQ isIntegrateInBD={isIntegrateInBD}></FAQ></>} />
            <Route path="/notes" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Notes isIntegrateInBD={isIntegrateInBD}></Notes></>} />
            <Route path="/offline" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Offline isIntegrateInBD={isIntegrateInBD}></Offline></>} />
            <Route path="*" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <NotFound isIntegrateInBD={isIntegrateInBD}></NotFound></>} />
            <Route path="/qr" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <QrCode qrScanner={qrScanner} settingsQrScanner={settingsQrScanner} isIntegrateInBD={isIntegrateInBD}></QrCode></>} />
            <Route path="/qr/:qrCode" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <QrCode qrScanner={qrScanner} settingsQrScanner={settingsQrScanner} isIntegrateInBD={isIntegrateInBD}></QrCode></>} />
            <Route path="/mie-note" element={<>{isLoading && localStorage.getItem("token") && <Spinner></Spinner>} <Notes isIntegrateInBD={isIntegrateInBD}></Notes></>} />
          </Routes>
        </div>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;