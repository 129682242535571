import React, { useContext } from 'react';
import GestioneListaContinuaALeggere from '../GestioneListaContinuaALeggere/GestioneListaContinuaALeggere.js';
import Novita from '../../UI/Novita/Novita.js';
import Bookmark from '../../UI/Bookmark/Bookmark';
import SchedaProdottoHeader from './SchedaProdottoHeader/SchedaProdottoHeader.js';
import SchedaProdottoBody from './SchedaProdottoBody/SchedaProdottoBody.js';
import SchedaProdottoFooter from './SchedaProdottoFooter/SchedaProdottoFooter.js';
import WrapperSchedaProdotto from './WrapperSchedaProdotto/WrapperSchedaProdotto.js';
import { createImgLink } from "../../../helper/helper";
import { config } from '../../../constants/Constants.js';

const SchedaProdotto = (props) => {
    var img = ""
    const removeBook = (idForDelete) => {
        props.deleteProductList(idForDelete);
    };

    if(validURL(encodeURI(props.img))){
        img = props.img
    }else{
        img = !props.isAbbonamento ? createImgLink(props.img) : config.imgLinkPacchetti + props.img + ".PNG";
    }
    
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }
    return (
        <React.Fragment>
            <div className="col carousel-slide scrolling-item ">
                <WrapperSchedaProdotto classNameCard={props.classNameCard} isSubscription={props.isSubscription} isAllList={props.isAllList}>
                    <div className="card-content">
                        <SchedaProdottoHeader isSubscription={props.isSubscription} img={img} idProduct={props.idProduct} title={props.title} goToReaderDocument={props.goToReaderDocument} idForReading={props.idForReading} titleContainer={props.titleContainer}></SchedaProdottoHeader>

                        <div className="card-body">
                            <SchedaProdottoBody
                                isSubscription={props.isSubscription}
                                title={props.title}
                                idProduct={props.idProduct}
                                listAutori={props.listAutori}
                                isAbbonamento={props.isAbbonamento}
                                goToReaderDocument={props.goToReaderDocument} 
                                idForReading={props.idForReading}
                                titleContainer={props.titleContainer}
                            ></SchedaProdottoBody>

                            <SchedaProdottoFooter
                                themeArea={props.themeArea}
                                isUpdating={props.isUpdating}
                                titleContainer={props.titleContainer}
                                title={props.title}
                            ></SchedaProdottoFooter>


                        </div>
                    </div>

                    {props.isManageable && <GestioneListaContinuaALeggere removeBook={removeBook} idProduct={props.idProduct} idForDelete={props.idForDelete} title={props.title} titleContainer={props.titleContainer}></GestioneListaContinuaALeggere>}

                    {props.isNew && <Novita />}

                    {props.hasBookmark && <Bookmark />}

                </WrapperSchedaProdotto>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdotto;