import React from 'react'
import { NavLink } from 'react-router-dom'

const NoteHead = ({ date, idDoc }) => {
    return (
        <div className="note-head">
            <span className="note-time">
                {date}
            </span>
            <NavLink className="card-search-goto" to={`/reader/${idDoc}`}>
                Vai al libro <span className="icon icon-arrow-right-small"></span>
            </NavLink>
        </div>
    )
}

export default NoteHead