import React from 'react';
import ReaderNormaCommentata from "../components/ReaderMain/ReaderPerApprofondire/ReaderNormaCommentata";
import ReaderDisposizioniIntegrative from "../components/ReaderMain/ReaderPerApprofondire/ReaderDisposizioniIntegrative";
import CorrelatiRiferimenti from "../components/ReaderMain/ReaderPerApprofondire/CorrelatiRiferimenti";
import ReaderRiferimenti from "../components/ReaderMain/ReaderPerApprofondire/ReaderRiferimenti";

const SchedaPerApprofondire = (props) => {

  const idDoc = props.id;

  return (
    <React.Fragment>
      <div className="reader-main">
        <div className="container">
          <div className="reader-container">
            <ReaderNormaCommentata id={idDoc} approfondire={props.approfondire} listNormaCommentata={props.listNormaCommentata}></ReaderNormaCommentata>
            <ReaderDisposizioniIntegrative id={idDoc} listDisIntegrative={props.listDisIntegrative}></ReaderDisposizioniIntegrative>
            <ReaderRiferimenti id={idDoc} approfondire={props.approfondire} listRiferimenti={props.listRiferimenti}></ReaderRiferimenti>
            <CorrelatiRiferimenti id={idDoc} listCorrelati={props.listCorrelati} listDocReferences={props.listDocReferences}></CorrelatiRiferimenti>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SchedaPerApprofondire;