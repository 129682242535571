import { config } from "../constants/Constants";
import { addSubjects } from "./helper";

export const bodyFormattedResearch = (filters, wordToSearch, revOrRec, tokenCtx, onlyMyBook, isUpdated, isFirstLevel, isFrizzera, year, auth, changeCategory) => {

    const onlyMyB = onlyMyBook === "true" || onlyMyBook === true;

    let queryExt = config.Research.queryExtFirstPiece;

    if (isFirstLevel) {
        const materia = filters.length === 0 ? '' : "materia:(".concat(addSubjects(filters)).concat(")");
        queryExt = queryExt.concat(materia !== '' ? 'AND '.concat(materia) : '');
        if (isUpdated) {
            queryExt = queryExt.concat(config.Research.queryExtIsUpdated);
        }
        if (year !== "tutti") {
            if (year > 2019)
                queryExt = queryExt.concat('AND ').concat(`data:[${year}-01-01T00:00:00Z TO ${year}-12-31T23:59:59Z]`);
            else
                queryExt = queryExt.concat('AND ').concat(`numero:${year}`);
        }
    } else {
        const idProv = filters.length === 0 ? '' : `${isFrizzera === "1" ? 'idperiodico' : 'idprovvedimento'}:(${filters[0]})`;
        queryExt = queryExt.concat(idProv !== '' ? 'AND '.concat(idProv) : '');
    }

    let parameters = {
        parameters:
        {
            "useNLPR": false,
            "excludeNotSearchablePackets": true,
            "order": revOrRec[0],
            "orderLucene": revOrRec[1],
            "groups": isFirstLevel ? true : false,
            "invokePush": false,
            "queryLucene": "",
            "queryWord": wordToSearch,
            "queryExt": queryExt,
            "extraFacet": "",
            "didYouMean": false,
            "rows": 100,
            "start": 0,
            "enableQD": false,
            "modulo24Embedded": false,
            "addQueryExtBuca": false,
            "use_pcpa": false,
            "pacchettiRicercaEstremi": "",
            "excludeNotSearchablePacketsEstremi": false,
            "getNovitaCount": false,
            "staticToken": (!onlyMyB || !isFirstLevel) ? config.token.STATIC_TOKEN : '',
            "queryBiblioteca": true,
            "coreDocType": (isFirstLevel) ? changeCategory : null
        },
        "token": (onlyMyB && isFirstLevel) ? tokenCtx.token : ''
    };
    if (changeCategory === 4) {
        parameters.parameters.rows = 200
    }
    if (changeCategory === 3) {
        parameters = {
            parameters:
            {
                ...parameters.parameters,
                facet: "provbiblio",
                facetMinCount: 1,
                facetSortIndex: true,
                hierarchical: false,
                facetPrefix: 0
            },
            token: parameters.token
        }
    }

    return parameters;
}

export const bodyFormattedAcquista = (idProvvedimenti, idDocType, token) => {

    const tokenNotExist = token.token === undefined || token.token === null || token.token === "";
    return {
        parameters: {
            name: config.nameList.getBuyOrNot,
            inputParameters: [
                {
                    name: "idprovvedimento",
                    type: "VarChar",
                    value: idProvvedimenti
                },
                {
                    name: "iddoctype",
                    type: "VarChar",
                    value: idDocType
                },
                {
                    name: "bypass",
                    type: "Int",
                    value: tokenNotExist ? 1 : 0
                }
            ],
            staticToken: tokenNotExist ? config.token.STATIC_TOKEN : ""
        },
        token: tokenNotExist ? "" : token.token
    }
}