import React from 'react';
import MiniHeader from './MiniHeader/MiniHeader';
import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import HeaderFirstRowMinified from './HeaderFirstRowMinified/HeaderFirstRowMinified';
import HeaderFirstRow from './HeaderFirstRow/HeaderFirstRow';
import HeaderSecondRow from './HeaderSecondRow/HeaderSecondRow';

const Header = (props) => {

    
    return (
        <React.Fragment>
            <HeaderWrapper classSticky={props.classSticky}>
                {(props.showSecondRow && props.isDesktop) &&
                    <HeaderFirstRow isIntegrateInBD={props.isIntegrateInBD}></HeaderFirstRow>
                }
                {!props.showSecondRow &&
                    <HeaderFirstRowMinified showLogoSole={props.showLogoSole} isIntegrateInBD={props.isIntegrateInBD}></HeaderFirstRowMinified>
                }

                {(props.showSecondRow && props.isDesktop) && <HeaderSecondRow></HeaderSecondRow>}
            </HeaderWrapper>
            {props.showSecondRow && <MiniHeader showLogoSole={props.showLogoSole} isIntegrateInBD={props.isIntegrateInBD}></MiniHeader>}
        </React.Fragment>
    );
}

export default Header;