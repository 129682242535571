import React, { useState, useEffect } from "react";
import { updateLink } from "../../../helper/TealiumHelper";
import ThemeArea from "../../Prodotti/ThemeArea/ThemeArea";

const ManageThematicArea = (props) => {

    const [areaTematicaList, setAreaTematica] = useState(props.themeArea.length > 1 ? props.themeArea.slice(0, 2) : props.themeArea);
    const [expandAreaTematiche, setExpandAreaTematiche] = useState(false);

    const ShowAllAreeTematiche = (event) => {
        event.stopPropagation();
        setExpandAreaTematiche((prevExpandAreaTematiche) => {
            return !prevExpandAreaTematiche;
        });
    };

    useEffect(() => {
        let ShowOrHideAreaTematicaList = setTimeout(() => {
            setAreaTematica(expandAreaTematiche ? props.themeArea : props.themeArea.slice(0, 2));
        }, 20);

        return () => {
            clearTimeout(ShowOrHideAreaTematicaList);
        };
    }, [props.themeArea, expandAreaTematiche]);

    return (
        <React.Fragment>
            {
                areaTematicaList.map(prod => (<ThemeArea
                    key={prod.id}
                    themeArea={prod.titleArea}
                    titleContainer={props.titleContainer}
                    title={/*window.location.pathname.split("/")[1] === "ricerca" ? props.title : prod.titleArea*/props.title}
                />
                ))
            }

            {
                props.themeArea.length > 2 &&
                <li className="list-inline-item">
                    <span className="tag tag--small clr" onClick={ShowAllAreeTematiche}>
                        {!expandAreaTematiche ? '+' : '-'}{props.themeArea.length - 2}
                    </span>
                </li>
            }
        </React.Fragment>
    );
}

export default ManageThematicArea;