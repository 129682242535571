import React from "react";
import { Modal } from "react-bootstrap";

const DeleteNoteModal = (props) => {

    const handleClose = () => {
        props.closeModal();
    };

    const deleteNote = (ev) => {
        ev.preventDefault();

        props.deleteNote();
    }
    
    return (
        <React.Fragment>
            <Modal show={props.showModal} onHide={handleClose}>
                <div className="modal-content">
                    <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span className="icon icon-cross-small"></span>
                    </a>

                    <Modal.Header className="modal-header">
                        <h3 className="modal-title">{props.title}</h3>
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <p className="modal-txt">
                            {props.message}
                        </p>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <a className="btn btn--txt" role="button" onClick={handleClose}>Annulla</a>
                        <a className="btn btn--black " role="button" onClick={deleteNote}>Elimina</a>
                    </Modal.Footer>
                </div>
            </Modal>
        </React.Fragment>
    )

}

export default DeleteNoteModal;