import React from 'react';
import { config } from '../../constants/Constants';
import { cleanSpan, editDocument, existNoteForThisParagraph, selectColor, updateNotes } from '../../helper/helperFunctionReader';
import { v4 as uuidv4 } from 'uuid';
import useContextMenu from '../../hooks/useContextMenu';
import useManangeNoteAndTagOnReader from '../../hooks/useManageNoteAndTagOnReader';
import { getDateNow } from '../../helper/helper';

const ReaderDropdownNote = (props) => {

    const { anchorPoint, show, textToEdit, textCompleteToEdit, range, handleClick, id, cleanFunction, textToCopy, disable } = useContextMenu();

    const noteAndTag = useManangeNoteAndTagOnReader();

    const copyToClipboard = (ev) => {

        ev.preventDefault();

        handleClick();
        navigator.clipboard.writeText(textToCopy);
    };

    const addTag = (ev) => {
        ev.preventDefault();

        cleanFunction();

        const color = "seppia";

        let editedText = document.getElementById("textComplete").innerHTML;
        if(document.getElementById("noteComplete")) editedText = editedText.concat(editedText = editedText.concat(document.getElementById("noteComplete").innerHTML));

        if (!editedText.includes(textCompleteToEdit)) return;

        const start = editedText.indexOf(textCompleteToEdit);

        const paragraphNote = {
            startPar: start,
            endPar: start + textCompleteToEdit.length
        };

        const noteToUpd = existNoteForThisParagraph(id, props.notes.data.note);

        if (Object.keys(noteToUpd).length > 0) {
            props.addOrUpdateNoteTag({ showModal: true, noteToUpd: { noteToUpd: noteToUpd } });
            return;
        }

        let idNote = Object.keys(noteToUpd).length === 0 ? uuidv4() : noteToUpd.idNote;

        let colorToInsert = selectColor(color);
        let initialSpan = `<span id="${id}" class="noted noted--${colorToInsert[0]}" style="white-space: pre-wrap" />`;
        let finalSpan = `${colorToInsert[1]}`;
        let forEdit = `<button id="${id}" class="icon icon-edit" name="buttonEdit"></button>`;

        let nota = {
            textCompleteToEdit: textCompleteToEdit,
            textToEdit: textToEdit,
            noteToUpd: {
                idNote: idNote,
                ...paragraphNote,
                startNote: range.start,
                endNote: range.end,
                startSpan: initialSpan,
                endSpan: finalSpan,
                color: color,
                text: '',
                tag: [],
                startButton: forEdit,
                date: getDateNow(),
                titleNote: props.document.titleDoc
            }
        };
        
        props.addOrUpdateNoteTag({ showModal: true, noteToUpd: nota });
    };

    const addNote = (ev) => {

        ev.preventDefault();

        cleanFunction();

        const color = "seppia";

        let editedText = document.getElementById("textComplete").innerHTML;
        if(document.getElementById("noteComplete")) editedText = editedText.concat(editedText = editedText.concat(document.getElementById("noteComplete").innerHTML));

        if (!editedText.includes(textCompleteToEdit)) return;

        const start = editedText.indexOf(textCompleteToEdit);

        const paragraphNote = {
            startPar: start,
            endPar: start + textCompleteToEdit.length
        };

        const noteToUpd = existNoteForThisParagraph(id, props.notes.data.note);

        if (Object.keys(noteToUpd).length > 0) {
            props.addOrUpdateNote({ showModal: true, noteToUpd: { noteToUpd: noteToUpd } });
            return;
        }

        let idNote = Object.keys(noteToUpd).length === 0 ? uuidv4() : noteToUpd.idNote;

        let colorToInsert = selectColor(color);
        let initialSpan = `<span id="${id}" class="noted noted--${colorToInsert[0]}" name="buttonEdit" style="white-space: pre-wrap" />`;
        let finalSpan = `${colorToInsert[1]}`;
        let forEdit = `<button id="${id}" class="icon icon-edit" name="buttonEdit"></button>`;

        let nota = {
            textCompleteToEdit: textCompleteToEdit,
            textToEdit: textToEdit,
            noteToUpd: {
                idNote: idNote,
                ...paragraphNote,
                startNote: range.start,
                endNote: range.end,
                startSpan: initialSpan,
                endSpan: finalSpan,
                color: color,
                text: '',
                tag: [],
                startButton: forEdit,
                date: getDateNow(),
                titleNote: props.document.titleDoc
            }
        };

        
        
        props.addOrUpdateNote({ showModal: true, noteToUpd: nota });
    };

    const addColor = (ev) => {

        ev.preventDefault();

        const color = ev.target.getAttribute('value');

        let editedText = document.getElementById("textComplete").innerHTML;
        //editedText = editedText.concat(editedText = editedText.concat(document.getElementById("noteComplete").innerHTML));

        if (!editedText.includes(textCompleteToEdit)) return;

        const start = editedText.indexOf(textCompleteToEdit);

        const paragraphNote = {
            startPar: start,
            endPar: start + textCompleteToEdit.length
        };

        let updatedNotes = [];

        //const noteToUpd = {}; 

        //per update
        const noteToUpd = existNoteForThisParagraph(id, props.notes.data.note);
        //editedText = cleanDocument(noteToUpd, editedText, noteToUpd.startNote, noteToUpd.endNote, noteToUpd.spanStart, noteToUpd.spanEnd);

        const objEdit = editDocument(editedText, textToEdit, range, color, noteToUpd, props.document.titleDoc);

        const newNote = {
            underlinedText: cleanSpan(objEdit.newDocument, range, noteToUpd),
            ...objEdit.note,
            ...paragraphNote
        }

        updatedNotes = updateNotes(newNote, props.notes);
        //updateNota

        props.textWithNote.data.documentText = objEdit.newDocument;

        const documentToUpd = {
            ...props.textWithNote
        };

        const salvaNota = (data) => {

            const refreshDocument = () => {
                cleanFunction();
                props.refreshDocument({ prop1: props.document.idProvv, prop2: props.document.id, prop3: props.document.idDocType });
            };

            noteAndTag.saveOrUpdateNote(props.notes, updatedNotes, { prop1: props.document.idProvv, prop2: props.document.id, prop3: props.document.idDocType, prop4: props.document.mainTitle, prop5: props.document.titleDoc }, config.nameList.note, refreshDocument);
        }

        noteAndTag.updateDocument(documentToUpd, salvaNota);
    };

    const deleteNote = (ev) => {
        ev.preventDefault();
        
        cleanFunction();
        
        if (id === null) return;

        const noteToUpd = existNoteForThisParagraph(id, props.notes.data.note);

        if (Object.keys(noteToUpd).length > 0) {
            props.deleteNote({ showModal: true, noteToUpd: { noteToUpd: noteToUpd } });
            return;
        }
    }

    return (
        <React.Fragment>
            {show &&
                <div id="dropdownmenu">
                    <span className="dropdown-menu dropdown-menu--context show" style={{ top: anchorPoint.y, left: anchorPoint.x, position: 'absolute' }}>
                        <span className="list-inline list-inline--large list-inline--nowrap">
                            <span className="list-inline-item">
                                <a className={`itool itool--small noted--s ${disable ? 'is-disabled' : ''}`} role="button" value="seppia" onClick={addColor}>
                                    <span className="icon icon-check"></span>
                                </a>
                            </span>
                            <span className="list-inline-item">
                                <a className={`itool itool--small noted--y ${disable ? 'is-disabled' : ''}`} role="button" value="yellow" onClick={addColor}>
                                    <span className="icon icon-check"></span>
                                </a>
                            </span>
                            <span className="list-inline-item">
                                <a className={`itool itool--small noted--p ${disable ? 'is-disabled' : ''}`} role="button" value="pink" onClick={addColor}>
                                    <span className="icon icon-check"></span>
                                </a>
                            </span>
                            <span className="list-inline-item">
                                <a className={`itool itool--small noted--v ${disable ? 'is-disabled' : ''}`} role="button" value="violet" onClick={addColor}>
                                    <span className="icon icon-check"></span>
                                </a>
                            </span>
                        </span>
                        <a className={`licon `} role="button" onClick={copyToClipboard} name="buttonEdit">
                            Copia testo <span className="icon icon-copy"></span>
                        </a>
                        <a className={`licon ${disable ? 'is-disabled' : ''}`} role="button" onClick={addNote} name="buttonEdit">
                            Aggiungi nota <span className="icon icon-note"></span>
                        </a>
                        <a className={`licon ${disable ? 'is-disabled' : ''}`} role="button" onClick={addTag} name="buttonEdit">
                            Aggiungi tag <span className="icon icon-tag"></span>
                        </a>
                        <span className={`dropdown-menu-foot`}>
                            <a className={`licon ${id === null ? 'is-disabled' : ''}`} role="button" onClick={deleteNote} >
                                Elimina
                                <span className="icon icon-delete"></span>
                            </a>
                        </span>
                    </span>
                </div>
            }
        </React.Fragment>
    );
}

export default ReaderDropdownNote;