import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TokenContext from "../../../store/token-context";
import useHttpRequest from "../../../hooks/http-request";
import { config } from "../../../constants/Constants";
import { bodyFormattedRelDoc } from '../../../helper/helperFunctionGetDocument';
import { getCookie } from '../../../helper/helper';

import ReaderCorrelati from "./ReaderCorrelati";
import ReaderRiferimenti from "./ReaderRiferimenti";
const CorrelatiRiferimenti = (props) => {

  const idDoc = props.id;


    return (
        <React.Fragment>
            <ReaderCorrelati id={idDoc} listCorrelati={props.listCorrelati}></ReaderCorrelati>
            <ReaderRiferimenti id={idDoc} listDocReferences={props.listDocReferences}></ReaderRiferimenti>
        </React.Fragment>
    );
}

export default CorrelatiRiferimenti;