import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TokenContext from "../../../store/token-context";
import useHttpRequest from "../../../hooks/http-request";
import { config } from "../../../constants/Constants";
import { bodyFormattedRefDoc } from '../../../helper/helperFunctionGetDocument';
import { getCookie } from '../../../helper/helper';

const ReaderRiferimenti = (props) => {
  const idDoc = props.id;
  const listRiferimenti = props.listRiferimenti;
  const url = document.location.ancestorOrigins[0] + "/private/default.aspx";

    return (
        <React.Fragment>
          { listRiferimenti && listRiferimenti.length !== 0 &&
  <div className="reader-box-wrapper">
    <div className="reader-box">
  <h5 className="reader-box-title">Riferimenti</h5>
  {listRiferimenti.map(((item, index) => (
    <div className="reader-rel" key={index}>
      <h6 className="reader-rel-title">
        {item.nomeFamiglia}
      </h6>
      <ul className="list-lined">
      {item.sottoFamiglie.map(((sottofam, index) => (
          <li className="list-lined-item" key={sottofam.documentId}>
            <a target="_parent" className="reader-rel-link reader-rel-link--counter" href={url + "#references/[0/" + idDoc + "]/[152/" + encodeURIComponent(item.nomeFamiglia) + "/" + encodeURIComponent(sottofam.nome) + "]"}>
              {sottofam.nome}
              <span className="reader-rel-counter">({sottofam.numDocsRiferiti})</span>
            </a>
          </li>
      )))
      }
      </ul>
    </div>
  )))
  }
</div>

  </div>
}
        </React.Fragment>
    );
}

export default ReaderRiferimenti;