import React from 'react';
import { NavLink } from 'react-router-dom';

const ReaderCard = ({ itemList }) => {

    const isNotReader = localStorage.getItem("isReader") === null;

    return (
        <li className="list-item">
            <NavLink className="bdcard" to={`/reader/${itemList.id}`} /*target={`${isNotReader ? '_blank' : ''}`}*/>
                <div className="ncard-fam ">
                    <h4 className="h-meta">
                        {itemList.category}
                        {itemList.pipe &&
                            <>
                                {itemList.category !== undefined && <span className="pipe">|</span>}
                                {itemList.pipe}
                            </>
                        }
                    </h4>
                </div>
                <div className="ncard">
                    <h3 className="ncard-title serif">
                        {itemList.title}
                        {itemList.isUpdated &&
                            <span className="page-label">Aggiornato</span>
                        }
                    </h3>
                    <p className="ncard-text">
                        {itemList.paragraph}
                    </p>
                </div>
            </NavLink>
        </li>
    );
}

export default ReaderCard;