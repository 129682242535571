import { config } from '../constants/Constants';
import { getAreaTheme } from '../helper/choosingAreaThemes'
import { createImgLink } from './helper';

export const exportToArrayForFE = (name, data, documenti = undefined) => {

    if (data === null || data === undefined) {
        return [];
    }

    let arrayProducts = [];


    switch (name) {
        case (config.nameList.ultimeUscite):
        case (config.nameList.continuaALegg):
        case (config.nameList.proposteAbbon):
        case (config.nameList.listPiuLetti):
        case (config.nameList.tiPotrebbeInteressare):
        case (config.nameList.giaLetti):
        case (config.nameList.daLeggere):
            data.forEach((el) => {
                let index = documenti !== undefined && documenti.hasOwnProperty("idToDelete") ? documenti.idToDelete.map(data => data.idProv).indexOf(parseInt(el?.idprovvedimento)) : -1;
                let idDocForReading = documenti !== undefined && documenti.hasOwnProperty("idDoc") ? documenti.idDoc.map(data => data.idProv).indexOf(parseInt(el?.idprovvedimento)) : -1;
                arrayProducts.push({
                    id: el.IdDocumento,
                    isNew: el.novita === '0' ? false : true,
                    isUpdating: el.aggiornamento === '0' ? false : true,
                    title: el.titolo,
                    auth: el.autore,
                    img: el.immagine === '0' ? '' : el.immagine,
                    themeArea: convertThemeArea(el.area_tematica),
                    idForDelete: index !== -1 ? documenti.idToDelete[index].idToDelete : null,
                    idForReading:  idDocForReading !== -1 ? documenti.idDoc[idDocForReading].idDoc : null
                });
            });
            break;
        case (config.nameList.strumentiProf):
            data.forEach((el) => {
                arrayProducts.push({
                    id: el.iddocumento,
                    description: el.testo,
                    title: el.titolo,
                    url: el.link === '' ? '' : el.link,
                    img: el.image === '0' ? '' : el.image,
                    ordine: el.ordine
                });
            });
            break;
        default:
            break;
    }
    return arrayProducts;
}
export const convertArrayForFEBuyOrNot = (data) => {

    let arrayFEBuyOrNot = [];
    
    if (data === null) return [];

    data.forEach(element => {
            arrayFEBuyOrNot.push({
                idProv: element?.idprovvedimento,
                idDocType: element?.iddoctype,
                node: element?.nodo,
                buy: element?.acquista,
                themesArea: convertThemeArea(element.area_tematica),
                img: validURL(encodeURI(element.immagine)) ? element.immagine : element.immagine !== "" ? (config.imgLinkPacchetti + element.immagine + ".PNG") :  config.imgDefaultPacch,
                title: element.titolo,
                isPDF: parseInt(element.isPDF)
            });
    });

    return arrayFEBuyOrNot;
}
function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
export const convertArrayForFEResearch = (data) => {

    let arrayToReturn = [];
    let arrayIdProv = [];
    let arrayIdDocType = [];

    if (data.length === 0)
        return arrayToReturn;

    data.forEach((el) => {

        const prod = {
            editore: el.editore,
            abstract: el.abstract,
            idDocumento: el.idDocumento
        };

        const documents = el.documents !== undefined && el.documents !== null ? [...el.documents, prod] : [prod];

        arrayToReturn.push({
            id: el.idDocumento,
            idProv: el.idProvvedimento,
            isNew: el?.novita && el.novita === '1' ? true : false,
            isUpdating: el.aggiornamento === '1' ? true : false,
            updated: el.data,
            title: el.text1,
            auth: el.text2,
            desc: el.abstract,
            url: el.url === '' ? '' : el.url,
            img: el.Immagine === '' || el.Immagine === undefined || el.Immagine === null ? '' : el.Immagine,
            themeArea: convertThemeArea(el.argomento),
            documents: documents,
            isFrizzera: el.famiglia === 'Sistema Frizzera' ? 1 : 0,
            edit: el.editore,
            dataDocument: el.data
        });
        arrayIdProv.push(el.idProvvedimento);
        arrayIdDocType.push(el.idDocType);
    });

    return {
        arrayToReturn: arrayToReturn,
        arrayIdProv: arrayIdProv,
        arrayIdDocType: arrayIdDocType
    };
}

export const convertArrayForFEResearchSecondLevel = (data) => {

    let arrayToReturn = [];
    if (data.length === 0)
        return arrayToReturn;

    data.forEach((el) => {
        arrayToReturn.push({
            id: el.idDocumento,
            idProv: el.idProvvedimento,
            isNew: el?.novita && el.novita === '1' ? true : false,
            isUpdating: el.aggiornamento === '1' ? true : false,
            updated: el.data,
            title: el.editore,
            pipe: '',
            category: el.prodotto !== '' ? el.prodotto : el.text1,
            paragraph: el.abstract,
        });
    });

    return arrayToReturn;
}

export const converObjectForFE = (document) => {

    return {
        idDocument: document.IdDocumento,
        title: document.titolo,
        autori: document.autore,
        img: document.immagine === '' || document.immagine === '0' ? config.imgDefaultLibro : document.immagine,
        areaTematicaList: convertThemeArea(document.area_tematica),
        isUpdating: document.aggiornamento,
        type_desc: document.tipo_descrizione,
        descrizione: document.descrizione,
        aggiornato: document.data,
        type_summ: document.tipo_sommario,
        ind_summ: document.indice_sommario,
        node_indx_summ: document.nodo_indice_sommario,
        idDocType: document.iddoctype,
        idProv: document.idprovvedimento,
        pdf_summ: document.sommario_pdf,
        preview: document.anteprima,
        pack: document.nodo_pacchetto !== null ? document.nodo_pacchetto.split(",") : [],
        packString: document.nodo_pacchetto !== null ? document.nodo_pacchetto : ""
    };

}

export const exportAutoriForFE = (autori) => {

    if (autori === null || autori === undefined) {
        return [];
    }

    let arrayProducts = [];

    autori.forEach((el) => {
        arrayProducts.push({
            idauth: el.idautore,
            auth: el.autore,
            surn: el.cognome,
            name: el.nome,
            bio: el.biografia === null ? config.bioMancante : el.biografia,
            img: el.foto === null ? '//via.placeholder.com/160x160' : el.foto //createImg(el.foto, el.idautore),
        });
    });

    return arrayProducts;
}


const createImg = (img, idAutore) => {

    if (img === "" || idAutore === "") return "";

    return img.concat(idAutore).concat(".JPG");
}

export const convertThemeArea = (themesArea) => {

    let convertedThemesArea = [];

    const splittedThemesArea = themesArea.split('');

    splittedThemesArea.forEach(function (el, index) {
        if (el === '0')
            return;
        convertedThemesArea.push({
            id: index,
            titleArea: getAreaTheme(index)
        });
    });

    return convertedThemesArea;
}

export const convertArrayBookmarksForFe = (data) => {


    const arrayFE = [];

    data.forEach(el => {

        arrayFE.push({
            id: el.IdDocumento,
            title: el.titolo,
            descr: el.abstract,
        });
    });

    return arrayFE;
};

export const convertArrayNotesForFe = (data) => {


    const arrayFE = [];

    data.forEach(el => {

        arrayFE.push({
            id: el.IdDocumento,
            title: el.titolo,
            noted: "",
            descr: el.abstract,
            text: "",
        });
    });

    return arrayFE;
};

export const convertThemeAreaForLink = (theme) => {

    switch (theme) {
        case "Fisco e Imprese":
            return "fisco";
        case "Diritto":
            return "diritto";
        case "PA":
            return "pa";
        case "Tecnici":
            return "pa";
        case "Lavoro":
            return "biblavoro";
    }
}