import { config } from "../constants/Constants"
import { convertThemeArea } from "./castingObject";
import { castDate, createImgLink, filterFisco } from "./helper";

export const bodyFormattedGetSchedaPacchetto = (id) => {
    return {
        "parameters": {
            "name": config.nameList.getSchedaPac,
            "inputParameters": [{
                "name": "nodo",
                "type": "Int",
                "value": id
            }],
            "staticToken": config.token.STATIC_TOKEN
        },
        "token": ""
    }
};

export const bodyFormattedBooks = (pacchetti, isPacchetti) => {
    var obj = {
        "parameters": {
            "name": config.nameList.getLibriPacc,
            "inputParameters": [{
                "name": "idpacchetti",
                "type": "VarChar",
                "value": isPacchetti ? pacchetti : ""
            }],
            "staticToken": config.token.STATIC_TOKEN
        },
        "token": ""
    }
    if(!isPacchetti){
        var el = {
            "name": "modulocruscotto",
            "type": "VarChar",
            "value": pacchetti
        }
        obj.parameters.inputParameters.push(el);
    }
    return obj
};

export const bodyFormattedGetTiPotrbbIntPacc = (areaTematica, idDocs) => {
    return {
        "parameters": {
            "name": config.nameList.getLibriInteress,
            "inputParameters": [
                {
                    "name": "area",
                    "type": "VarChar",
                    "value": areaTematica
                },
                {
                    "name": "documenti",
                    "type": "VarChar",
                    "value": idDocs
                }
            ],
            "staticToken": config.token.STATIC_TOKEN
        },
        "token": ""
    }
};

export const castObjForFe = (obj) => {

    var img = "";
    if(validURL(encodeURI(obj.immagine))){
        img = obj.immagine;
    }else{
        img = obj.immagine !== "" ? config.imgLinkPacchetti + obj.immagine + ".PNG" : "";
    }
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }
    return {
        title: obj.titolo,
        text: obj.descrizione,
        img: img,
        areaTematicaList: convertThemeArea(obj.area_tematica),
        link: obj.link,
        module: obj.modulo
    };
};

export const castArrayForFe = (array) => {

    let arrayForFe = [];
    let arrayIdDocument = '';

    array.forEach((element, index) => {
        arrayForFe.push({
            id: element.IdDocumento,
            title: element.titolo,
            listAutori: element.autore,
            img: element.immagine === "" ? "" : createImgLink(element.immagine),
            themeArea: convertThemeArea(element.area_tematica),
            isUpdating: element.aggiornamento === "0" ? false : true,
            isNew: element.novita === "0" ? false : true,
            aggiornato: castDate(element.data)
        });

        arrayIdDocument = arrayIdDocument.concat(element.IdDocumento);
        if (array.length - 1 !== index) 
            arrayIdDocument = arrayIdDocument.concat(",");
    });

    return {
        arrayForFe:arrayForFe,
        idDocumentsConcat: arrayIdDocument
    };
};

export const castArrayTiPotrIntForFe = (array) => {

    let arrayForFe = [];

    array.forEach(element => {
        arrayForFe.push({
            id: element.IdDocumento,
            title: element.titolo,
            auth: element.autore,
            img: element.immagine === "" ? "" : element.immagine,
            isNew: element.novita === "0" ? false : true,
            themeArea: convertThemeArea(element.area_tematica),
            isUpdating: element.aggiornamento === "0" ? false : true,
        });
    });

    return arrayForFe;
};

export const castThemeAreaInString = (themeArea) => {

    let themeAreaCasted = "";

    themeArea.forEach((element, index) => {
        let elementUpd = filterFisco(element.titleArea);
        themeAreaCasted = themeAreaCasted.concat(elementUpd).concat(index === themeArea.length - 1 ? "" : ",");
    });

    return themeAreaCasted;
}