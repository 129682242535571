export function  getAreaTheme (index) {
    switch(index){
        case 0:
            return "Contabilità";
        case 1:
            return "Fisco e Imprese";
        case 2:
            return "Diritto";
        case 3:
            return "Lavoro";
        case 4:
            return "PA";
        case 5:
            return "Tecnici";
        default:
            return "";
    }
}