import React, { useRef } from 'react'
import { useState } from 'react';
import NoteDropdown from '../NoteDropdown/NoteDropdown';

const NoteFooter = (props) => {


    const tags = props.tags;
    const [show, setShow] = useState(false);
    const newTag = useRef(null);


    const deleteTag = (ev) => {
        ev.preventDefault();

        const tagToDelete = ev.currentTarget.dataset.value;


        let array = [...tags];
        let index = array.indexOf(tagToDelete);
        array.splice(index);
        props.editNoteTag(array);
    }

    const addTag = (dataTag) => {

        //setTags(prev => [...prev, tagToAdd]);

        setShow(false);
        const newTagNote = [...tags, dataTag];
        newTag.current.value = null;

        props.editNoteTag(newTagNote);

    }

    const insertTagByEnter = (ev) => {

        if (ev.which !== 13) return;

        addTag(newTag.current.value);
        setShow(false)
    }

    const showMenuTag = (e) => {

        if (e.currentTarget === e.target) {
            setShow(true)
        }

    }

    const hideMenuTag = (e) => {

        window.setTimeout(() => {
            setShow(false);
        }, 200)
    }

    return (

        <div className="note-tag">
            <ul className="list-inline">
                {tags && tags.map((tag =>
                    <li className="list-inline-item" key={Math.random()}>

                        <span className="tag tag--del">
                            {tag}
                            <a className="tag-del" role="button" onClick={deleteTag} data-value={tag}>
                                <span className="icon icon-cross-small"></span>
                            </a>
                        </span>

                    </li>
                ))}
                <li className="list-inline-item">
                    <div className="dropdown">
                        <input id="tag" className="input input--clear" type="text" placeholder="Aggiungi Tag…"
                            /*onFocus={() => setShow(true)}*/
                            onKeyUp={insertTagByEnter}
                            onFocus={showMenuTag}
                            onBlur={hideMenuTag}
                            ref={newTag}
                            maxLength={60}
                        />
                    </div>
                </li>
                {show && <NoteDropdown tags={props.mostUsedTag} addTag={addTag} />}
            </ul>
            <span className="icon icon-tag"></span>
        </div>

    )
}

export default NoteFooter