import React from "react";
import Book24Link from "../../UI/Book24Link/Book24Link";
import Sole24Link from "../../UI/Sole24Link/Sole24Link";
import UserAction from "../UserAction/UserAction";

const HeaderFirstRowMinified = (props) => {

    const openHambMenu = (ev) => {
        ev.preventDefault();

        document.body.classList.add("is-drawer-in");
    }

    return (
        <React.Fragment>
            <div className="mhead-top" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <div className="container">
                    <div className="mhead-wrapper">
                        <a className="mhead-menu" role="button" data-toggle-class="is-drawer-in" onClick={openHambMenu}>
                            <span className="icon icon-menu"></span>
                            <span className="mhead-menu-label">Naviga</span>
                        </a>

                        <div className="mhead-nav nav-lined nav-lined--logo">
                            <Book24Link/>
                            {props.showLogoSole && <Sole24Link/>}
                        </div>
                        
                        <UserAction></UserAction>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderFirstRowMinified;