import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import LoginModal from "../components/Modals/Login";
import SchedaProdottoAreaListaTematicaList from "../components/Prodotti/SchedaProdottoAreaListaTematicaList/SchedaProdottoAreaListaTematicaList";
import AreaAllListHeader from "../components/UI/AreaAllListHeader/AreaAllListHeader";
import { config } from "../constants/Constants";
import AuthContext from "../store/auth-context";
import ManageProductListsContext from "../store/manage-products-list-context";
import TokenContext from "../store/token-context";
import useFetchQuery from "../hooks/useFetchQuery";
import { exportToArrayForFE } from "../helper/castingObject";
import Loader from "../components/UI/Loader/Loader";
import Pagination from "../components/UI/Research/Pagination/Pagination";
import { filterFisco, splitFisco, titleUrlSlug } from "../helper/helper";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import Prodotti from "../components/Prodotti/Prodotti";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import { updateView } from "../helper/TealiumHelper";
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const AllList = (props) => {

    let { themeArea } = useParams();

    const [tArea, setTArea] = useState(splitFisco(themeArea));
    const [allProductByAreaTematica, setAllProductByAreaTematica] = useState([]);
    const [allAbboByAreaTematica, setAllAbboByAreaTematica] = useState([])
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const tokenCtx = useContext(TokenContext);
    const authCtx = useContext(AuthContext);
    const productListsCtx = useContext(ManageProductListsContext);

    useEffect(() => {
        setTArea(splitFisco(themeArea));
    }, [themeArea]);

    useEffect(() => {
        setAllProductByAreaTematica(productListsCtx.allList);
    }, [productListsCtx.allList]);

    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    // Fetching the data and pagination with react query
    const { data, error, isLoading } = useFetchQuery([`${config.nameList.ultimeUscite}--${themeArea}`], filterFisco(themeArea), 200, { dependence: themeArea && tokenCtx.token });
    const prods = data?.Result?.ResultSet?.Root;

    const [currentPage, setCurrentPage] = useState(1);
    const [prodPerPage, setProdPerPage] = useState(36);

    const indexOfLastProd = currentPage * prodPerPage;
    const indexOfFirstProd = indexOfLastProd - prodPerPage;
    const currentProds = prods?.slice(indexOfFirstProd, indexOfLastProd);

    let pages = [];

    for (let i = 1; i <= Math.ceil(prods?.length / prodPerPage); i++) {
        pages.push(i);
    }

    const arrayProduct = exportToArrayForFE(config.nameList.ultimeUscite, currentProds);

    useEffect(() => {

        let setList = setTimeout(() => {

            let themeAr = filterFisco(themeArea);

            productListsCtx.getList(config.nameList.proposteAbbon, undefined, themeAr);

        }, 200);

        return () => {
            clearTimeout(setList);
        };
    }, [tArea, tokenCtx.token]);

    useEffect(() => {
        setAllAbboByAreaTematica(productListsCtx.proposteDiAbbonamento);
    }, [productListsCtx.proposteDiAbbonamento]);

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:area:${titleUrlSlug(splitFisco(themeArea).toLowerCase())}:lista`,authCtx, tokenCtx, "book24ore:area-lista");
                
    },[tokenCtx.token, authCtx.isLoggedIn, authCtx.isLoggedIn])

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky={"sticky"}  isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <AreaAllListHeader themeArea={splitFisco(tArea)}></AreaAllListHeader>

                {isLoading && <Loader themeArea={tArea} />}
                {arrayProduct.length > 0 && <SchedaProdottoAreaListaTematicaList allProducts={arrayProduct} allProductsAbbo={allAbboByAreaTematica}></SchedaProdottoAreaListaTematicaList>}

                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pages={pages}
                    isSection={true}
                ></Pagination>

                <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    );
}

export default AllList;