import React from "react";
import useScript from "../../../hooks/useScript";
import { config } from "../../../constants/Constants";

const ListaAutori = (props) => {

    let auth= <>{props.listAutori}</>;

    if(!props.isAbbonamento)
    {
        auth = <>Autori: {props.listAutori}</>
    }

    return (
        <React.Fragment>
            {auth}
        </React.Fragment>
    );
}

export default ListaAutori;