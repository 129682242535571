import React from 'react';

const SchedaProdottoAreaListaTematicaWrapper = (props) => {

    return (
        <React.Fragment>
            <section className="section ">
                <div className="container">
                    {props.children}
                </div>
            </section>
        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematicaWrapper;