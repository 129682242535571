import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { titleUrlSlug } from "../../../../helper/helper";

const NavBarSchede = (props) => {

    let { id } = useParams();

    const title = titleUrlSlug(props.title?.split("/")[0].replaceAll(" ", "_"));
    
    return (
        <React.Fragment>
            <div className="tab">
                <ul className="list-inline list-inline--large">

                    <li className="list-inline-item">
                        <NavLink className={(state) => "tab-link " + (state.isActive ? "is-active" : "")} defaultChecked={true} id="Descrizione" to={`/Prodotto/${title}/${id}/SchedaDescrizione`}>
                            Descrizione
                        </NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink className={(state) => "tab-link " + (state.isActive ? "is-active" : "")} id="Autori" to={`/Prodotto/${title}/${id}/SchedaAutori`}>
                            Autori
                        </NavLink>
                    </li>
                    <li className="list-inline-item">
                        <NavLink className={(state) => "tab-link " + (state.isActive ? "is-active" : "")} id="Sommario" to={`/Prodotto/${title}/${id}/SchedaSommario`}>
                            Sommario
                        </NavLink>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

export default NavBarSchede;