import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";

const QrCodeHeader = (props) => {

    const [isLogged, setIsLogged] = useState(false);
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        setIsLogged(authCtx.isLoggedIn);
    }, [authCtx.isLoggedIn])

    return (
        <React.Fragment>
            {props.result === "sleep" &&
                <>
                    <h1 className="qrpage-title">
                        Scansione QR Code
                    </h1>
                    {isLogged &&
                        < p className="qrpage-text">
                            Accetta l’utilizzo della camera del tuo computer e scansione il QR code presente nel tuo libro.
                        </p>
                    }
                    {!isLogged &&
                        <p className="qrpage-text">
                            Accedi o Registrati al sole 24 Ore per avviare la scansione del tuo QR code.
                        </p>
                    }
                </>
            }
            {
                props.result === 'success' &&
                <>
                    <h1 className="qrpage-title">
                        Scansione effettuata con successo
                    </h1>
                    {!props.isDifferentAbbo &&
                        <p className="qrpage-text">
                            Potrai leggere online il libro attraverso il servizio Book24ORE.
                        </p>
                    }
                    {/*<p className="qrpage-text">
                        Potrai leggere <strong>[{props.titleBook}]</strong> online attraverso il servizio Biblioteca24.
                        <br />Accedi o Registrati al sole 24 Ore per iniziare la tua esperienza.
            </p>*/}
                </>
            }
            {
                props.result === 'error' &&
                <>
                    <h1 className="qrpage-title">
                        Ci dispiace, scansione non riuscita
                    </h1>
                    <p className="qrpage-text">
                        Sembrerebbe che qualcosa non sia andata a buon fine, ti chiediamo di riprovare, grazie.
                    </p>
                </>
            }
        </React.Fragment >
    )
}

export default QrCodeHeader;