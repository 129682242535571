import { v4 as uuidv4 } from "uuid";
import { config } from "../constants/Constants";
import { castDate, createImgLink, getDateNow, getMonth, getYearAndMonth } from "./helper";
import { createArrayInputParams } from "./helperFunctionGetDocument";

export const bodyFormattedReader = (id, name, tokenCtx) => {

    return {
        parameters: {
            name: name,
            inputParameters: [{
                "name": "iddocumento",
                "type": "Int",
                "value": id
            }],
            staticToken: tokenCtx.token !== null ? '' : config.token.STATIC_TOKEN,
        },
        token: tokenCtx.token !== null ? tokenCtx.token : '',
    };
}

export const bodyFormattedProvvedimenti = (id, provv, name, tokenCtx) => {

    return {
        parameters: {
            name: name,
            inputParameters: [{
                "name": "iddocumento",
                "type": "Int",
                "value": id
            },
            {
                "name": "provvedimenti",
                "type": "NVarChar",
                "value": provv
            }],
            staticToken: tokenCtx !== null ? '' : config.token.STATIC_TOKEN,
        },
        token: tokenCtx !== null ? tokenCtx : '',
    };
}
export const bodyFormattedGetTipoView = (name, id, tokenCtx) => {

    const isUserHaveToken = localStorage.getItem("token") !== null;

    return {
        parameters: {
            name: name,
            inputParameters: createArrayInputParams(id),
            staticToken: !isUserHaveToken ? config.token.STATIC_TOKEN : '',
        },
        token: isUserHaveToken ? tokenCtx.token : ''
    };

};

export const chooseDocument = (type) => {
    switch (type) {
        case ("1"):
            return "pdf";
        case ("2"):
            return "html";
        case ("3"):
            return "Sistema Frizzera";
        default:
            return "null";
    }
}

export const bodyPDF = (id, token, isComprato, pdfUrl) => {
    return {
        documentId: parseInt(id),
        parameters: {
            references: false,
            getBinaryDoc: true,
            getPdfLibro: pdfUrl?.length > 0 ? true : false,
            checkUserPackages: true,
            staticToken: token === null || isComprato !== "1" ? config.token.STATIC_TOKEN : ""
        },
        token: token !== null && isComprato === "1" ? token : ""
    };
}

export const bodyHTML = (id, token, isComprato) => {

    return {
        documentId: parseInt(id),
        parameters: {
            references: false,
            staticToken: token === null || isComprato !== "1" ? config.token.STATIC_TOKEN : ""
        },
        token: token !== null && isComprato === "1" ? token : ""
    };

}

export const bodyFrizzera = (id, token, isComprato) => {

    return {
        parameters: {
            documentId: parseInt(id),
            source: "direct",
            textParagraphs: true,
            staticToken: token === null || isComprato !== "1" ? config.token.STATIC_TOKEN : ""
        },
        token: token !== null && isComprato === "1" ? token : ""
    };
}

export const chooseBody = (typeDocument, id, token, isComprato, pdfUrl) => {

    switch (typeDocument) {
        case ("pdf"):
            return bodyPDF(id, token, isComprato, pdfUrl);
        case ("html"):
            return bodyHTML(id, token, isComprato);
        case ("Sistema Frizzera"):
            return bodyFrizzera(id, token, isComprato);
        default:
            return null;
    };
}

export const chooseURL = (typeDocument) => {

    let url = config.url.BACKEND_SERVICE;

    switch (typeDocument) {
        case ("pdf"):
            return url.concat(config.serviceToCall.getDocumento);
        case ("html"):
            return url.concat(config.serviceToCall.getDocumento);
        case ("Sistema Frizzera"):
            return url.concat(config.serviceToCall.getProdottiFrizzera);
        default:
            return url;
    }
}

export const FormattedDocumentForFE = (document, typeDocument, showPremiumContent, pdfUrl) => {

    switch (typeDocument) {
        case ("pdf"):
            return objectForFePdf(document, showPremiumContent, pdfUrl);
        case ("html"):
            return objectForFeHtml(document, showPremiumContent);
        case ("Sistema Frizzera"):
            return objectForFeFrizzera(document, showPremiumContent);
        default:
            return {};
    }

}

const objectForFePdf = (document, showPremiumContent, pdfUrl) => {

    return {
        id: document.DocumentId,
        upTitlLeft: document.Section,
        upTitlRight: document.SubSection,
        titleDoc: encodeURI(document.ChapterTitle),
        paragraphs: [{}],
        novitaCountTot: 0,
        auth: document.Author,
        upd: castDate(document.Date),
        title_book: document.ChapterTitle,
        binaryDoc: showPremiumContent ? pdfUrl?.length > 0 ? document.BinaryPdfDoc : document.BinaryDoc : '',
        img: createImgLink(document.Immagine),
        idProvv: document.ProvvId,
        idDocType: document.DocTypeId,
        mainTitle: document.MainTitle === null ? document.ChapterTitle : document.MainTitle
    };
}

const objectForFeHtml = (document, showPremiumContent) => {
    return {
        id: document.DocumentId,
        upTitlLeft: document.Section,
        upTitlRight: '',
        titleDoc:  encodeURI(document.ChapterTitle),
        paragraphs: [
            {
                documentId: document.DocumentId,
                title: document.Title,
                novitaCount: 0,
                textPar: showPremiumContent ? (document.documentText === '' ? document.TestoDoc : document.documentText) : document.Abstract,
                noteBE: document.Nota
            }
        ],
        novitaCountTot: 0,
        auth: document.Author,
        upd: castDate(document.DateDocument),
        title_book: document.ChapterTitle,
        img: createImgLink(document.Immagine),
        idProvv: document.ProvvId,
        idDocType: document.DocTypeId,
        mainTitle: document.MainTitle,
    };
}

const objectForFeFrizzera = (document, showPremiumContent) => {

    const viewFreeContent = {
        documentId: document.DocumentId,
        title: encodeURI(document.ChapterTitle),
        novitaCount: 0,
        textPar: document.Abstract
    };

    const paragraphs = createArrayParagraphs(document.Paragraphs, showPremiumContent, viewFreeContent);

    return {
        id: document.DocumentId,
        upTitlLeft: document.Section,
        upTitlRight: document.SubSection,
        titleDoc: document.ChapterTitle,
        paragraphs: paragraphs.paragraphs,
        novitaCountTot: paragraphs.novitaCountTot,
        auth: document.Author,
        upd: castDate(document.Date),
        nav: '', //da aggiungere
        title_book: document.TaxType,
        img: document.Immagine,
        idProvv: document.IdProvvedimento,
        idDocType: document.IdDocType,
        mainTitle: document.Titolo
    };

}

const createArrayParagraphs = (paragraphs, showPremiumContent, viewFreeContent) => {

    let array = [];
    let novitaCountTot = 0;

    if (!showPremiumContent) {

        array.push(viewFreeContent);

        return {
            paragraphs: array,
            novitaCountTot: novitaCountTot
        };
    }

    paragraphs.forEach(element => {
        novitaCountTot += element.NovitaCount;

        array.push({
            documentId: element.DocumentId,
            title: element.Title,
            novitaCount: element.NovitaCount,
            textPar: element.Text
        });
    });

    return {
        paragraphs: array,
        novitaCountTot: novitaCountTot
    };
}

export const castArrayForFELittleSumm = (littleSumm) => {

    let array = [];
    let next = {};
    let prev = {};

    littleSumm.forEach(element => {
        switch (element.tipo) {
            case "2":
                prev = {
                    idDoc: element.iddocumento,
                    text: element.titolo,
                    isUpdated: element.aggiornato === "0" ? false : true,
                };
                break;
            case "3":
                next = {
                    idDoc: element.iddocumento,
                    text: element.titolo,
                    isUpdated: element.aggiornato === "0" ? false : true,
                };
                break;
            default:
                array.push({
                    idDoc: element.iddocumento,
                    text: element.titolo,
                    isUpdated: element.aggiornato === "0" ? false : true
                });
                break;
        }
    });

    return {
        prevChap: prev,
        nextChap: next,
        summ: array,
    };
}

export const viewContent = (getTipoV, tokenCtx) => {

    if (tokenCtx.token !== null && getTipoV.comprato === "1") return true;

    return getTipoV.free === "1";
}

export const formattedUpdateListForFE = (updatesList) => {

    let arrayForFE = [];
    let _ = require('underscore');

    let alldates = _.groupBy(updatesList, function (obj) {
        return getYearAndMonth(obj.data);
    });

    let months = Object.keys(alldates);

    const translateForFE = (array) => {

        let arrayTranslated = [];

        array.forEach(el => {
            arrayTranslated.push({
                id: el.iddocumento,
                title: el.titolo,
                paragraph: el.abstract,
                pipe: el.sezione
            });
        })

        return arrayTranslated;
    };

    months.forEach(el => {
        arrayForFE.push({
            title: el,
            list: translateForFE(alldates[el])
        })
    });

    return arrayForFE;

}

export const selectColor = (value) => {

    switch (value) {
        case "seppia":
            return config.color.seppia;
        case "yellow":
            return config.color.yellow;
        case "pink":
            return config.color.pink;
        case "violet":
            return config.color.violet;
        default:
            return;
    }

}

export const getTagHtml = (string, indx) => {

    let count = 0;

    for (let i = indx; i < string.length; i++) {
        count++;

        if (string[i] === ">") {
            break;
        }
    }

    return string.substring(indx, (indx + count));
}

const editTextToEdit = (textToEdit, initialSpan, finalSpan) => {

    let aggiungiSpan = 0;

    for (let i = 0; i < textToEdit.length; i++) {

        if (textToEdit[i] === "<") {
            let tagHtml = getTagHtml(textToEdit, i);

            if (tagHtml !== "" && textToEdit[i + 1] === "/" && isNotParticularTag(tagHtml)) {

                if (aggiungiSpan === 0 && tagHtml !== "</p>") {

                    let newIndex = i + tagHtml.length;

                    textToEdit = textToEdit.slice(0, newIndex) + initialSpan + textToEdit.slice(newIndex)

                    newIndex = newIndex + initialSpan.length;

                    if (textToEdit[newIndex] === "<" || i === 0)
                        newIndex--;

                    i = newIndex;
                    aggiungiSpan++;
                    continue;
                } else if (aggiungiSpan === 0 && tagHtml === "</p>") {
                    i = i + tagHtml.length;
                    continue;
                }

                if ((i + tagHtml.length) === textToEdit.length) {
                    textToEdit = textToEdit.slice(0, (i)) + finalSpan + textToEdit.slice((i));
                } else {
                    textToEdit = textToEdit.slice(0, i) + finalSpan + textToEdit.slice(i);
                }

                let newIndex = i + finalSpan.length;


                if (textToEdit[newIndex] === "<" || i === 0)
                    newIndex--;

                i = newIndex;
                aggiungiSpan--;

            } else if (tagHtml !== "" && textToEdit[i + 1] !== "/" && isNotParticularTag(tagHtml)) {

                if (i === 0 && !tagHtml.startsWith("<p") && !tagHtml.startsWith("<li")) {
                    textToEdit = textToEdit.slice(0, i) + initialSpan + textToEdit.slice(i);
                    i = i + tagHtml.length + initialSpan.length - 1;
                    aggiungiSpan++;
                    continue;
                }

                if (aggiungiSpan > 0 && !tagHtml.startsWith("<p")) {

                    textToEdit = textToEdit.slice(0, (i)) + finalSpan + textToEdit.slice((i))

                    let newIndex = i + finalSpan.length;

                    if (textToEdit[newIndex] === "<" || i === 0)
                        newIndex--;

                    i = newIndex;
                    aggiungiSpan--;
                    continue;
                }

                if ((i - tagHtml.length) <= 0) {
                    textToEdit = textToEdit.slice(0, (i + tagHtml.length)) + initialSpan + textToEdit.slice((i + tagHtml.length));
                } else {
                    textToEdit = textToEdit.slice(0, (i + tagHtml.length)) + initialSpan + textToEdit.slice((i + tagHtml.length));
                }

                let newIndex = i + tagHtml.length + initialSpan.length;


                if (textToEdit[newIndex] === "<" || i === 0)
                    newIndex--;

                i = newIndex;
                aggiungiSpan++;
            }
        } else if (i === 0) {
            textToEdit = textToEdit.slice(0, i) + initialSpan + textToEdit.slice(i);
            i = i + initialSpan.length - 1;
            aggiungiSpan++;
        } else if (i === (textToEdit.length - 1) && textToEdit[i] !== ">") {
            textToEdit = textToEdit.slice(0, i + 1) + finalSpan + textToEdit.slice(i + 1);
            i = i + finalSpan.length;
            aggiungiSpan--;
        }
    }

    for (let i = 0; i < aggiungiSpan; i++) {
        textToEdit = textToEdit.slice(0, textToEdit.length) + finalSpan + textToEdit.slice(textToEdit.length + 1);
    }

    return textToEdit;
}

export const editDocument = (editedText, textToEdit, range, color, noteToUpd, titleDoc) => {

    let newDocument = editedText;

    let existNote = Object.keys(noteToUpd).length > 0;

    let id = existNote ? noteToUpd.idNote : uuidv4();
    let colorToInsert = selectColor(color);
    let initialSpan = `<span id="${id}" class="noted noted--${colorToInsert[0]}" name="buttonEdit" style="white-space: pre-wrap">`;
    let finalSpan = `${colorToInsert[1]}`;
    let forEdit = `<button id="${id}" class="icon icon-edit" name="buttonEdit"></button>`;

    if (!existNote) {

        const newParag = editTextToEdit(textToEdit, initialSpan, finalSpan);

        newDocument = newDocument.slice(0, range.start) + newDocument.slice(range.end);

        newDocument = newDocument.slice(0, range.start) + newParag + forEdit + newDocument.slice(range.start);

        range.end = range.start + newParag.length + forEdit.length;

    } else {
        newDocument = newDocument.replaceAll(noteToUpd.startSpan, initialSpan);
    }

    return {
        newDocument: newDocument,
        note: {
            idNote: id,
            startNote: range.start,
            endNote: range.end,
            startSpan: initialSpan,
            endSpan: finalSpan,
            color: color,
            text: existNote ? noteToUpd.text : '',
            tag: existNote ? noteToUpd.tag : [],
            date: getDateNow(),
            startButton: forEdit,
            titleNote: titleDoc
        }
    }
}

export const cleanDocument = (noteToUpd, editedText, startNote, endNote, spanStart, spanEnd) => {

    let newDocument = editedText;

    if (Object.keys(noteToUpd).length === 0) return newDocument;

    newDocument = newDocument.slice(0, startNote) + "" + newDocument.slice(startNote);
    const newEnd = endNote - spanStart.length;
    newDocument = newDocument.slice(0, endNote) + "" + newDocument.slice(endNote);

    return newDocument;
}

export const isNotParticularTag = (string) => {

    if (string === "<ul>" || string.startsWith("<ul"))
        return false;
    else if (string === "</ul>")
        return false;
    else if (string === "<td>" || string.startsWith("<td"))
        return false;
    else if (string === "</td>")
        return false;
    else if (string === "<table>" || string.startsWith("<table"))
        return false;
    else if (string === "</table>")
        return false;
    else if (string === "<tbody>" || string.startsWith("<tbody"))
        return false
    else if (string === "</tbody>")
        return false;
    else if (string === "<tr>" || string.startsWith("<tr"))
        return false;
    else if (string === "</tr>")
        return false;
    else if (string === "<div>" || string.startsWith("<div"))
        return false;
    else if (string === "</div>")
        return false;

    return true;
}


export const beginTagInString = (string) => {

    if (string.startsWith("<p"))
        return "p";
    else if (string.startsWith("<b"))
        return "b";
    else if (string.startsWith("<i"))
        return "i";
    else if (string.startsWith("<li"))
        return "li";
    else if (string.startsWith("<ul"))
        return "ul";
    else if (string.startsWith("<div"))
        return "div";
    else if (string.startsWith("<span"))
        return "span";
    else if (string.startsWith("<table"))
        return "table;"
    else if (string.startsWith("<tbody"))
        return "tbody";
    else if (string.startsWith("<tr"))
        return "tr";
    else if (string.startsWith("<td>"))
        return "td";
    else if (string.startsWith("<h1"))
        return "h1";
    else if (string.startsWith("<h2"))
        return "h2";
    else if (string.startsWith("<h3"))
        return "h3";
    else if (string.startsWith("<h4"))
        return "h4";
    else if (string.startsWith("<h5"))
        return "h5";
    else if (string.startsWith("<h6"))
        return "h6";
    else if (string.startsWith("<a"))
        return "a"
    else if (string.startsWith("<em"))
        return "em";
    else
        return "";
}

export const endTagInString = (string) => {

    if (string.endsWith("</p>"))
        return "p";
    else if (string.endsWith("</b>"))
        return "b";
    else if (string.endsWith("</i>"))
        return "i";
    else if (string.endsWith("</li>",))
        return "li";
    else if (string.endsWith("</ul>",))
        return "ul";
    else if (string.endsWith("</div>",))
        return "div";
    else if (string.endsWith("</span>"))
        return "span";
    else if (string.endsWith("</table>"))
        return "table";
    else if (string.endsWith("</tbody>"))
        return "tbody";
    else if (string.endsWith("</tr>"))
        return "tr";
    else if (string.endsWith("</td>"))
        return "td";
    else if (string.endsWith("</h1>"))
        return "h1";
    else if (string.endsWith("</h2>"))
        return "h2";
    else if (string.endsWith("</h3>"))
        return "h3";
    else if (string.endsWith("</h4>"))
        return "h4";
    else if (string.endsWith("</h5>"))
        return "h5";
    else if (string.endsWith("</h6>"))
        return "h6";
    else if (string.endsWith("</a>"))
        return "a"
    else if (string.endsWith("</em>"))
        return "em";
    else
        return "";
}

export const existNoteForThisParagraph = (id, notes) => {

    if (notes === null || id === null) return {};

    let noteToUpd = {}
    notes.forEach(el => {
        if (id === el.idNote)
            noteToUpd = el;
    });

    return noteToUpd;
};

export const updateNotes = (newNote, arrayNotes) => {

    let arrayNotesOld = { ...arrayNotes };
    let indexToUpd = -1;

    if (Object.keys(arrayNotesOld).length === 0) return [newNote];

    //if(arrayNotesOld?.data === undefined) arrayNotesOld
    arrayNotesOld.data.note.forEach((el, index) => {

        if (el.idNote === newNote.idNote) indexToUpd = index;
    });

    if (indexToUpd !== -1) {
        arrayNotesOld = arrayNotesOld.data.note.filter((item, index) => { return index !== indexToUpd });
    }
    else {
        return [...arrayNotesOld.data.note, newNote];
    }

    if (arrayNotesOld.length !== 0)
        return [...arrayNotesOld, newNote];

    return [newNote];
};

export const cleanSpan = (toClean, range, noteToUpd) => {

    if (noteToUpd.underlinedText !== undefined && noteToUpd.underlinedText !== null && noteToUpd.underlinedText !== "")
        return noteToUpd.underlinedText;

    let substringToClean = toClean.substring(range.start, range.end);

    substringToClean = substringToClean.replace(/<\/?span[^>]*>/g, "");

    return substringToClean.replace(/<\/?button[^>]*>/g, "");
} 