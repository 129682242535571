import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { config } from '../../../../constants/Constants';
import { titleUrlSlug } from '../../../../helper/helper';
import { updateLink } from '../../../../helper/TealiumHelper';
import Pacchetti from '../../../Modals/Pacchetti';
import SchedaProdottoBody from '../../../Prodotti/SchedaProdotto/SchedaProdottoBody/SchedaProdottoBody';
import ManageThematicArea from '../../ManageThematicArea/ManageThematicArea'
import BuyCardContent from './BuyCardContent';

const SearchCardContent = (props) => {

    const { isFromAnotherPage } = useParams();
    const [modalPacchetti, setModalPacchetti] = useState(false);
    const [buyOrNot, setBuyOrNot] = useState(null);
    const prodotto = props.prodotto;
    const img = prodotto.img !== '' ? prodotto.img : config.imgDefaultLibro;

    const showModalPacchettiHandle = (val) => {
        setModalPacchetti(val.showModal);
        setBuyOrNot(val.settingBuyOrNot);
    };

    const closeModalPacchettiHandle = (val) => {
        setModalPacchetti(val);
    }

    return (
        <React.Fragment>
            {
                modalPacchetti && <Pacchetti
                    buyOrNot={buyOrNot}
                    closeModalPacchettiHandle={closeModalPacchettiHandle}
                    modalPacchetti={modalPacchetti} />
            }

            <div className="card-content">

                <figure className="card-img ">
                    <NavLink to={`/Prodotto/${titleUrlSlug(prodotto?.title)}/${prodotto.id}/SchedaDescrizione`}
                        onClick={(ev) => {
                            updateLink({ title: prodotto.title, name: "prodotto" }, "card");
                            if (isFromAnotherPage === "daMieiLibri")
                                localStorage.setItem("mantieniIFiltri", true)
                        }}>
                        <span className="card-img-book">
                            <img src={img} alt="" />
                        </span>
                    </NavLink>
                </figure>

                <div className="card-body">
                    <SchedaProdottoBody
                        title={prodotto.title}
                        idProduct={prodotto.id}
                        listAutori={prodotto.auth}
                        dataDocument={prodotto.dataDocument}
                        document={prodotto.documents[0]}
                        data={prodotto.datas}
                        isFromAnotherPage={props.isFromAnotherPage}
                        titleContainer={"card"}
                    ></SchedaProdottoBody>

                    <div className="card-foot ">
                        <ul className="list-inline list-inline--small">
                            <ManageThematicArea themeArea={prodotto.themeArea} title={prodotto.title} titleContainer="card" />
                        </ul>
                        <ul className="list-inline list-inline--large card-foot-btn">
                            {props.resultBuyOrNot && isFromAnotherPage !== "daMieiLibri" &&
                                <>
                                    <BuyCardContent
                                        resultBuyOrNot={props.resultBuyOrNot}
                                        showModalPacchettiHandle={showModalPacchettiHandle}
                                        idProv={prodotto.idProv}
                                        prodotto={prodotto}
                                        img={img}
                                        testo={props.testo}
                                        title={prodotto.title}
                                        isFrizzera={prodotto.isFrizzera}
                                        titleContainer={"card"}
                                    />
                                </>
                            }
                        </ul>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default SearchCardContent;