import React, { useState } from "react";
import ReaderToolsTextSizeMenu from "./ReaderToolsTextSizeMenu";

const ReaderToolsTextSize = (props) => {

    const settingZoom = (zoom) => {
        props.settingZoom(zoom);
        setShow(false);
    };

    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <li className={show ? props.class + "show" : props.class}>
                <a className="itool " role={"button"} data-toggle="dropdown" onClick={() => {setShow(prev => !prev)}}>
                    <span className="icon icon-text-size"></span>
                </a>
                <ReaderToolsTextSizeMenu settingZoom={settingZoom} show={show}></ReaderToolsTextSizeMenu>
            </li>
        </React.Fragment>
    );
}

export default ReaderToolsTextSize;