import React from 'react'

const FaqCard = ({card}) => {
  return (
    <div className="col-md">
        <figure className="faqpage-feature-img">
            <img className="img-fluid" src={card.img} alt=""/>
        </figure>
        <h4 className="faqpage-feature-title">
            {card.title}
        </h4>
        <p className="faqpage-feature-txt">
            {card.text}
        </p>
    </div>
  )
}

export default FaqCard