import React from "react";

const Updated = () => {

    return(
        <React.Fragment>
            <span className="page-label">Aggiornato</span>
        </React.Fragment>
    );
}

export default Updated;