import React from "react";
import { updateLink } from "../../../../helper/TealiumHelper";
import ManageThematicArea from "../../../UI/ManageThematicArea/ManageThematicArea";

import InAggiornamento from "../../InAggiornamento/InAggiornamento";

const SchedaProdottoAreaListaTematicaFooter = (props) => {

    return (
        <React.Fragment>
            <div className="card-foot card-foot--flex d-none d-md-flex">
                <ul className="list-inline list-inline--small">
                    <ManageThematicArea themeArea={props.themeArea} titleContainer={props.titleContainer}></ManageThematicArea>
                </ul>
                <InAggiornamento isUpdating={props.isUpdating}></InAggiornamento>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematicaFooter;