export const ChooseAreaTematica = (themeArea) => {

    switch (themeArea) {
        case "Contabilità":
            return "clr-con";
        case "Fisco e Imprese":
            return "clr-fis";
        case "Diritto":
            return "clr-dir";
        case "Lavoro":
            return "clr-lav";
        case "PA":
            return "clr-pa";
        case "Tecnici":
            return "clr-tec";
        default:
            return "";
    }
}