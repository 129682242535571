import React, { useState, useEffect, useRef } from "react";
import { createImgLink } from "../../../helper/helper";

import {
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    LinkedinShareButton, LinkedinIcon,
    EmailShareButton, EmailIcon
} from 'react-share';
import { config } from "../../../constants/Constants";

const CondivisioneProdotto = (props) => {

    const pageUrl = window.location.href;
    const imgUrl = createImgLink(props.img) === config.imgDefaultLibro ? `${config.url.API_URL_SITO}${config.imgDefaultLibro}` : createImgLink(props.img);
    const [showModalShare, setShowModalShare] = useState(false);
    const ref = useRef();

   /* useEffect(() => {
        const handleClickOutside = (event) => {
          if (!ref.current.contains(event.target)) {
            setShowModalShare(false);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
      }, [ref]);*/

    return (
        <React.Fragment>
            <div className="share">
                <a className="itool itool--clear" role={"button"} data-toggle="dropdown" aria-expanded="true" ref={ref} onClick={() => setShowModalShare(!showModalShare)}>
                    <span className="icon icon-share"></span>
                </a>
                <div id="dropdown-share" className={`dropdown-menu dropdown-menu-right ${showModalShare ? "show" : ""}`} x-placement="bottom-end" style={{ position: "absolute", willChange: "transform", "top": "0px", "left": "0px", "transform": "translate3d(-76px, 32px, 0px)" }}>
                    <div className="share-links">

                        <FacebookShareButton url={pageUrl} className="itool itool--small itool--clear">
                            <span className="icon icon-facebook"></span>
                        </FacebookShareButton>

                        <TwitterShareButton url={pageUrl} className="itool itool--small itool--clear">
                            <span className="icon icon-twitter"></span>
                        </TwitterShareButton>

                        <LinkedinShareButton url={pageUrl} className="itool itool--small itool--clear">
                            <span className="icon icon-linkedin"></span>
                        </LinkedinShareButton>

                        <EmailShareButton url={pageUrl} className="itool itool--small itool--clear">
                            <span className="icon icon-mail"></span>
                        </EmailShareButton>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CondivisioneProdotto;