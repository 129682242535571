import React from "react";
import { NavLink } from "react-router-dom";

const ListNotes = (props) => {

    const item = props.item;
    return (
        <React.Fragment>
            <li className="list-lined-item">
                <NavLink className="card-note" to="/mie-note">
                    <span className="card-note-title">
                        {props.titleNote}
                    </span>
                    <span className={`card-note-noted noted--${item.color[0]}`}>
                        <div dangerouslySetInnerHTML={{ __html: item.underlinedText }} />
                        {/**/}
                    </span>
                    {item.text &&
                        <span className="card-note-txt">
                            {item.text}
                            <span className="icon icon-note"></span>
                        </span>
                    }
                </NavLink>
            </li>
        </React.Fragment>
    );
}

export default ListNotes;