import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import useHttpRequest from "../../../../hooks/http-request";
import Free from "../../Free/Free";
import Updated from "../../Updated/Updated";

const Link = (props) => {

    const navigate = useNavigate();
    const sendRequest = useHttpRequest();

    const chooseUrl = () => {

        if(props.url !== 0) 
            navigate(`/reader/${props.url}`);

        const goTo = (data) => {
            navigate(`/reader/${data.Result.ResultSet.Root[0].IdDocumento}`);
        }

        const bodyFormatted = {
            "parameters": {
                "name": config.nameList.getDocumentoSommario,
                "inputParameters": [{
                    "name": "idindice",
                    "type": "Int",
                    "value": props.indexSumm
                },
                {
                    "name": "idnodo",
                    "type": "Int",
                    "value": props.fromTree.id
                }],
                "staticToken": config.token.STATIC_TOKEN
            },
            "token": ""
        }

        const rqst = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        }

        sendRequest.sendRequest(rqst, goTo);
    }

    return (
        <React.Fragment>
            <li className="list-item">
                {
                    /*<NavLink className="page-link" to={`/reader/${props.url}`} target="_blank">
                        {props.title}
                    </NavLink>*/
                }
                <a className="page-link" role={"button"} onClick={chooseUrl}>
                    {props.title}
                </a>
                {props.isFree && <Free></Free>}
                {props.isUpdated && <Updated></Updated>}
            </li>
        </React.Fragment>
    );
};

export default Link;