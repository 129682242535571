import React from 'react';
import ReaderBoxesRel from './ReaderBoxesRel';

const ReaderBox = (props) => {

    return(
        <div className="reader-box">
            <h5 className="reader-box-title"><div dangerouslySetInnerHTML={{__html: props.title}}/></h5>
                   
            {props.items && props.items.map((item, index) => (
                <ReaderBoxesRel item={item} key={index}/>
            ))}
        </div>
    );
}

export default ReaderBox;