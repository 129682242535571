import React from 'react'

const TagFilter = (props) => {

    const filteringByTag = (ev) => {

        const tagToFilter = ev.currentTarget.id;

        props.filteringByTag(tagToFilter);
    };

    return (
        <div className="tag-list">
            {props.mostUsedTag && props.mostUsedTag.map((filter, index) => (
                <div className="tag-list-action" key={index}>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id={filter} onClick={filteringByTag}/>
                        <label className="custom-control-label" htmlFor={filter}>
                            <span className="tag">
                                {filter}
                            </span>
                        </label>
                    </div>
                    {/*<div className="tag-list-action-btn">
                        <a className="itool itool--clear" href="#">
                            <span className="icon icon-edit"></span>
                        </a>
                        <a className="itool itool--clear" href="#">
                            <span className="icon icon-delete"></span>
                        </a>
            </div>*/}
                </div>
            ))}
        </div>
    )
}

export default TagFilter