import React from 'react';
import { createImgLink } from '../../helper/helper';
import SchedaProdottoBody from '../Prodotti/SchedaProdotto/SchedaProdottoBody/SchedaProdottoBody';
import SchedaProdottoFooter from '../Prodotti/SchedaProdotto/SchedaProdottoFooter/SchedaProdottoFooter';
import SchedaProdottoHeader from '../Prodotti/SchedaProdotto/SchedaProdottoHeader/SchedaProdottoHeader';
import GestioneListaContinuaALeggere from '../Prodotti/GestioneListaContinuaALeggere/GestioneListaContinuaALeggere'
import Novita from '../UI/Novita/Novita'
import Bookmark from '../UI/Bookmark/Bookmark'

const CarouselItem = (props) => {

    const { index, item } = props;

    const removeBook = () => {
        props.deleteProductList(props.idForDelete);
    };

    const img = createImgLink(item.img);

    return (
        <div className={`col-md-4 col-lg-3  scrolling-item ${index === 3 ? 'd-md-none d-lg-block' : ''} ${index >= 4 ? 'd-none' : ''}`}>
            <div className={props.classNameCard}>
                <div className="card-box">
                    <div className="card-content">
                        <SchedaProdottoHeader isSubscription={props.isSubscription} img={img} title={item.title} idProduct={item.id} goToReaderDocument={props.goToReaderDocument} idForReading={item.idForReading} titleContainer = {props.titleContainer}/>

                        <div className="card-body">
                            <SchedaProdottoBody
                                isSubscription={item.isSubscription}
                                title={item.title}
                                idProduct={item.id}
                                listAutori={item.auth}
                                isAbbonamento={item.isAbbonamento}
                                goToReaderDocument={props.goToReaderDocument} 
                                idForReading={props.idForReading}
                                titleContainer = {props.titleContainer}
                            ></SchedaProdottoBody>

                            <SchedaProdottoFooter
                                themeArea={item.themeArea}
                                isUpdating={item.isUpdating}
                                title={item.title}
                                titleContainer = {props.titleContainer}
                            ></SchedaProdottoFooter>
                        </div>
                    </div>

                    {props.isManageable && <GestioneListaContinuaALeggere removeBook={removeBook} title={item.title} titleContainer = {props.titleContainer}></GestioneListaContinuaALeggere>}

                    {props.isNew && <Novita />}

                    {props.hasBookmark && <Bookmark />}
                </div>
            </div>
        </div>
    );
}

export default CarouselItem;