import React, { useContext, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
// React-Bootstrap
import Modal from 'react-bootstrap/Modal';
import { config } from "../../constants/Constants";
import { NavLink } from "react-router-dom";
import useScript from "../../hooks/useScript";
import { useEffect } from "react";
import { changeCssClass } from "../../helper/helper";


const LoginModal = (props) => {

    const [error, setError] = useState(null);
    //useScript(config.socialLoginUrl);

    const handleClose = () => {
        setError(null);
        autCtx.showModalLog(false);
    };

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();
    const rememberMeInputRef = useRef();

    const autCtx = useContext(AuthContext);

    const loginHandler = (event) => {
        event.preventDefault();

        const username = usernameInputRef.current.value;
        const password = passwordInputRef.current.value;
        const rememberMe = rememberMeInputRef.current.checked ? 1 : 0;

        const settingsError = (error) => {
            setError(error.Description);
        }

        setError(null);
        autCtx.onLogin(username, password, rememberMe, settingsError, true);
    };

    useEffect(() => {
        try {
            window.showSocialButtons("CO", "", "home2019.css");
            changeCssClass();

        } catch (err) {

        }
    })

    const pageUrl = window.location.href;


    const [isPwdShown, setIsPwdShown] = useState(false);

    const showPwd = () => {
        const inputHtml = document.querySelector('input#login-password');
        inputHtml.type = 'text'
        setIsPwdShown(true);
    }

    const hidePwd = () => {
        const inputHtml = document.querySelector('input#login-password');
        inputHtml.type = 'password'
        setIsPwdShown(false);
    }

    return (
        <React.Fragment>
            <Modal show={autCtx.showLoginModal} onHide={handleClose}>
                <div className="modal" tabIndex="-1" role="dialog" style={{ 'display': 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-login">
                        <div className="modal-content modal-content--light">

                            <Modal.Header className="modal-header">
                                <span className="modal-header-spacer"></span>
                                <div className="mhead-logo">
                                    <span className="nav-link">
                                        <img className="nav-logo nav-logo--main" src="/dist/img/book24ore.svg" alt="Book24ORE" />
                                        <span className="nav-logo-bg"></span>
                                    </span>
                                </div>
                                <button onClick={handleClose} role="button" className="itool" data-dismiss="modal" aria-label="Close">
                                    <span className="icon icon-close"></span>
                                </button>
                            </Modal.Header>

                            <Modal.Body className="modal-body">
                                <div className="text-center">
                                    <h3 className="modal-title modal-title--big">Accedi per continuare</h3>
                                    <p className="modal-txt">
                                        Inserisci username e password per accedere ai servizi del Sole 24 Ore
                                    </p>
                                </div>

                                <div className="modal-form">
                                    <form onSubmit={loginHandler}>
                                        <div className="form-group form-group--labelin">
                                            <input className="input" type="text" id="login-username" placeholder="" ref={usernameInputRef} onChange={() => setError(null)} />
                                            <label className="label" htmlFor="login-username" >Username o email</label>
                                        </div>

                                        <div className="form-group form-group--labelin form-group--toggle">
                                            <input className="input" type="password" id="login-password" placeholder="" ref={passwordInputRef} onChange={() => setError(null)} />
                                            <label className="label" htmlFor="login-password">Password</label>
                                            {!isPwdShown &&
                                                <button className="input-btntoggle" onClick={showPwd} type="button">Mostra</button>
                                            }
                                            {isPwdShown &&
                                                <button className="input-btntoggle" onClick={hidePwd} type="button">Nascondi</button>
                                            }
                                        </div>

                                        <div className="form-group form-group--help">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="remember_me" ref={rememberMeInputRef} />
                                                <label className="custom-control-label" htmlFor="remember_me">
                                                    Ricordami
                                                </label>
                                            </div>
                                            <NavLink
                                                className="label ml-auto"
                                                to={`${config.forgotPassword}${pageUrl}`}
                                                target="_blank">
                                                Recupera password
                                            </NavLink>
                                        </div>

                                        <button type="submit" className="btn btn--big btn--black btn--full">Accedi</button>


                                    </form>
                                    {error !== null &&
                                        <div className="modal-soclogin">
                                            <p className="modal-txt modal-txt--big" htmlFor="login-error" style={{ color: "red" }}>
                                                <span>{error}</span>
                                            </p>
                                        </div>
                                    }

                                    <div id="socialLogin"></div>
                                </div>
                            </Modal.Body>

                            <Modal.Footer className="modal-footer">
                                <p className="modal-txt modal-txt--big">
                                    Non hai un account?
                                    <a href={config.linkRegistration} target="_blank">Registrati&nbsp;qui</a>
                                </p>
                            </Modal.Footer>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default LoginModal;