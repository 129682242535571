
import React from 'react'

const UtilityWrapper = (props) => {

  return (
    <div className="main-content ">
        <div className="errorpage">
            <div className="container">
                {props.children}
            </div>
        </div>
    </div>
  )
}

export default UtilityWrapper;