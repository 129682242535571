import React from "react";

const FooterFilters = (props) => {

    const clearFilters = () => {
        props.clearFilters();
    }

    const hideMenu = () => {
        document.body.classList.remove('is-search-panel-in');
    }

    return (
        <React.Fragment>
            <a className="search-label" role="button" onClick={clearFilters}>
                Cancella filtri
            </a>
            <a className="btn btn--black" role="button" onClick={hideMenu}>
                Mostra risultati
            </a>
        </React.Fragment>
    );
}

export default FooterFilters;