import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet, NavLink, useLocation } from "react-router-dom";
// Components
import InAggiornamento from "../components/Prodotti/InAggiornamento/InAggiornamento";
import NavBarSchede from "../components/Prodotti/Prodotto/NavBarSchede/NavBarSchede";
import Header from "../components/Header/Header";
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import Footer from "../components/Footer/Footer";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import Prodotti from "../components/Prodotti/Prodotti";
import SchedaProdottoSpecifica from "../components/Prodotti/SchedaProdottoSpecifica/SchedaProdottoSpecifica";
import CondivisioneProdotto from "../components/Prodotti/Condivisione Prodotto/CondivisioneProdotto";
import ManageThematicArea from "../components/UI/ManageThematicArea/ManageThematicArea";
import LoginModal from "../components/Modals/Login";
import SessioneScaduta from '../components/Modals/SessioneScaduta';
import AuthContext from "../store/auth-context";
import GetDocumentContext from "../store/get-documento";
import { config } from "../constants/Constants";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import isLoadingContext from "../store/isLoadingcontext";
import Spinner from "../components/UI/Spinner/Spinner";
import StickyFoot from "../components/Prodotti/SchedaProdottoSpecifica/StickyFoot";
import useHttpRequest from "../hooks/http-request";
import TokenContext from "../store/token-context";
import Pacchetti from "../components/Modals/Pacchetti";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { createImgLink, splitFisco, titleUrlSlug } from "../helper/helper";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import { updateView } from "../helper/TealiumHelper";

const Prodotto = (props) => {


    const [tiPotrebbeInteressare, setTiPotrebbeInteressare] = useState([]);
    const [isBuyed, setIsBuyed] = useState(undefined);
    const [prodotto, setProdotto] = useState(undefined);
    const [modalPacchetti, setModalPacchetti] = useState(false);
    const [buyOrNot, setBuyOrNot] = useState(false);

    const { id } = useParams();

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const documentCtx = useContext(GetDocumentContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const location = useLocation();
    const sendRqst = useHttpRequest();
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        isLoadingCtx.setIfIsLoading(true);

        let getProduct = setTimeout(() => {
            documentCtx.getDocumentoFromId(id);
            documentCtx.getList(config.nameList.tiPotrebbeInteressare, id);
        }, 200);

        return () => {
            clearTimeout(getProduct);
        };
    }, [id]);

    useEffect(() => {
        setTiPotrebbeInteressare(documentCtx.tiPotrebbeInteressare)

    }, [documentCtx.tiPotrebbeInteressare]);

    useEffect(() => {

        if (documentCtx.prodotto !== undefined)
            setProdotto(documentCtx.prodotto);

    }, [documentCtx.prodotto]);

    useEffect(() => {

        window.scrollTo(0, 0);
    }, [id]);

    useEffect(() => {
        if (tokenCtx.token === null || !authCtx.isLoggedIn) {
            setIsBuyed(false);
            return;
        }

        const settingsBuyOrNot = (data) => {

            if (parseInt(data.Result.ResultSet.Root[0].comprato) > 0) {
                setIsBuyed(true);
                return;
            }

            setIsBuyed(false);
        }

        const bodyFormatted = {
            "parameters": {
                "name": config.nameList.getBuyedOrNot,
                "inputParameters": [{
                    "name": "iddocumento",
                    "type": "Int",
                    "value": id
                }],
                "staticToken": authCtx.isLoggedIn ? "" : config.token.STATIC_TOKEN
            },
            "token": authCtx.isLoggedIn ? tokenCtx.token : ""
        };

        const rqs = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqs, settingsBuyOrNot);

    }, [tokenCtx.token, authCtx.isLoggedIn]);

    const showModalPacchettiHandle = (val) => {
        setModalPacchetti(val.showModal);
        setBuyOrNot(val.settingBuyOrNot)
    };

    const closeModalPacchettiHandle = (val) => {
        setModalPacchetti(val);
    }

    useEffect(() => {

            isLoadingCtx.setIfIsLoading(false, 1000);

    }, [isLoadingCtx.isLoading])

    useManageBodyClasses();

    return (
        <React.Fragment>
            <HelmetProvider>
                {prodotto !== undefined &&
                    <Helmet>
                        <title>{prodotto.title} | Book24ORE</title>
                        <link rel="canonical" href={window.location.href} />
                        <meta name="description" content={prodotto.descrizione.length >= 300 ? prodotto.descrizione.substr(0, 300) : prodotto.descrizione} />
                        <meta name="keywords" content="libri, volumi, informazione professionale, edicola, diritto, fisco, lavoro, aziende, pubblica amministrazione" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta property="og:title" content={prodotto.title.length >= 95 ? prodotto.title.substr(0, 90) : prodotto.title} />
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content={config.url.API_URL_SITO + location.pathname.replace("/", "")} />
                        <meta property="og:image" content={createImgLink(prodotto.img)} />
                        <meta property="og:image:width" content="450" />
                        <meta property="og:image:height" content="370" />
                        <meta property="og:site_name" content={prodotto.title.length >= 95 ? prodotto.title.substr(0, 90) : prodotto.title} />
                        <meta property="og:description" content={prodotto.descrizione.length >= 300 ? prodotto.descrizione.substr(0, 300) : prodotto.descrizione} />
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content="Book24ORE - Il Sole 24 ORE" />
                        <meta name="twitter:description" content={prodotto.descrizione.length >= 300 ? prodotto.descrizione.substr(0, 300) : prodotto.descrizione} />
                        <meta name="twitter:image" content={createImgLink(prodotto.img)} />
                    </Helmet>
                }
                <Wrapper>
                    <Header showSecondRow={false} classSticky="sticky"  isIntegrateInBD={props.isIntegrateInBD}></Header>
                    {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                    {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                    {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                    {isLoadingCtx.isLoading && <Spinner></Spinner>}
                    {
                        modalPacchetti && <Pacchetti
                            buyOrNot={buyOrNot}
                            closeModalPacchettiHandle={closeModalPacchettiHandle}
                            modalPacchetti={modalPacchetti} />
                    }
                    {prodotto !== undefined &&
                        <div className="main-content">

                            <section className="section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="page">
                                                <div className="page-head" data-page-header>
                                                    {prodotto.type_summ === "GetPdf" &&
                                                        <NavLink to={`/reader/${prodotto.idDocument}`}>
                                                            <h1 className="page-title">
                                                                {prodotto.title}
                                                            </h1>
                                                        </NavLink>
                                                    }

                                                    {prodotto.type_summ !== "GetPdf" &&
                                                        <NavLink to={`/reader/${prodotto.preview === "0" ? prodotto.idDocument : prodotto.preview}`}>
                                                            <h1 className="page-title">
                                                                {prodotto.title}
                                                            </h1>
                                                        </NavLink>
                                                    }

                                                    <p className="page-auth">
                                                        Autori: {prodotto.autori}
                                                    </p>
                                                    <ul className="list-inline list-inline--small">

                                                        <ManageThematicArea themeArea={prodotto.areaTematicaList}></ManageThematicArea>

                                                        <InAggiornamento isUpdating={prodotto.isUpdating}></InAggiornamento>


                                                    </ul>

                                                    <p className="page-time">
                                                        {`${prodotto.isUpdating === "1" ? "Aggiornato il: " : "Pubblicato il: "} ${prodotto.aggiornato}`}
                                                    </p>

                                                    <CondivisioneProdotto img={prodotto.img} title={prodotto.title}></CondivisioneProdotto>
                                                    <StickyFoot
                                                        isPacchetto={false}
                                                        prezzoMensile={prodotto.prezzoMensile}
                                                        prezzoAnnuale={prodotto.prezzoAnnuale}
                                                        img={prodotto.img}
                                                        idDocument={prodotto.idDocument}
                                                        preview={prodotto.preview}
                                                        pack={prodotto.pack}
                                                        isBuyed={isBuyed}
                                                    />

                                                    <NavBarSchede title={prodotto.title}></NavBarSchede>
                                                </div>
                                                <div>
                                                    <Outlet />
                                                </div>
                                            </div>
                                        </div>
                                        <SchedaProdottoSpecifica
                                            isPacchetto={false}
                                            prezzoMensile={prodotto.prezzoMensile}
                                            prezzoAnnuale={prodotto.prezzoAnnuale}
                                            img={prodotto.img}
                                            preview={prodotto.preview}
                                            idDocument={prodotto.idDocument}
                                            pack={prodotto.pack}
                                            packString={prodotto.packString}
                                            isBuyed={isBuyed}
                                            showModalPacchettiHandle={showModalPacchettiHandle}
                                            titleContainer={"scheda"}
                                            titleTealium={prodotto.title}
                                        />

                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {tiPotrebbeInteressare.length > 0 &&
                        <Prodotti
                            title="Ti potrebbe interessare"
                            prodList={tiPotrebbeInteressare}
                            isSubscription={false}
                            isManageable={false}
                            sectionClassName=""
                            classNameCard="card card--book "
                            dataSlide="4"
                            dataSlideMd="3"
                            isProfessionalTool={false}></Prodotti>
                    }
                    <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                    <Ricerca></Ricerca>
                </Wrapper>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default Prodotto;