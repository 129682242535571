import React, { useContext, useEffect, useState } from 'react'
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import AreaAllListHeader from '../components/UI/AreaAllListHeader/AreaAllListHeader';
import SearchBar from '../components/UI/Research/SearchBar/SearchBar';
import SearchResults from '../components/UI/Research/SearchResults/SearchResults';
import SearchFilters from '../components/UI/Research/SearchFilters/SearchFilters';
import LoginModal from '../components/Modals/Login';
import SessioneScaduta from "../components/Modals/SessioneScaduta";
import AuthContext from '../store/auth-context';
import useHttpRequest from '../hooks/http-request';
import { config } from '../constants/Constants';
import TokenContext from '../store/token-context';
import { convertArrayForFEBuyOrNot, convertArrayForFEResearch } from '../helper/castingObject';
import { bodyFormattedAcquista, bodyFormattedResearch } from '../helper/helperResearch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../components/UI/Spinner/Spinner';
import isLoadingContext from '../store/isLoadingcontext';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import { convertArrayForFEArchive, getCookie, createArrayInputParamsArchive, createInputParameters } from '../helper/helper';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { updateView } from '../helper/TealiumHelper';

const deleteLocalStorage = () => {
    localStorage.removeItem("filters");
    localStorage.removeItem("revOrRec");
    localStorage.removeItem("isUpdated");
    localStorage.removeItem("year");
    localStorage.removeItem("search category");
}

const returnRightCategory = (category) => {

    switch (category) {
        case "Tutto":
            return 1;
        case "Testo":
            return 2;
        case "Titolo":
            return 3;
        case "Autore":
            return 4;
        default:
            return 1;
    }
}

const Research = (props) => {
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { isFromAnotherPage } = useParams();
    const { isBookIntegrate } = useParams();
    const [resultResearch, setResultResearch] = useState(null);
    const [resultBuyOrNot, setResultBuyOrNot] = useState(null);
    const [wordToSearch, setWordToSearch] = useState('');
    const [revOrRec, setRevOrRec] = useState(isFromAnotherPage === "daMieiLibri" ? ['D', 'data desc'] : ['', 'score desc']);
    const [filters, setFilters] = useState([]);
    const [onlyMyBook, setOnlyMyBook] = useState(isFromAnotherPage === "daMieiLibri");
    const [isUpdated, setIsUpdated] = useState(false);
    const [year, setYear] = useState("tutti");
    const [changeCategory, setChangeCategory] = useState(1);
    const sendRqst = useHttpRequest();
    const isLoadingCtx = useContext(isLoadingContext);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        if ((isFromAnotherPage === undefined || isFromAnotherPage === null || isFromAnotherPage === "daMieiLibri") && (localStorage.getItem("mantieniIFiltri") === null || localStorage.getItem("mantieniIFiltri") === false)) {

            deleteLocalStorage();
            localStorage.removeItem("word");
            setWordToSearch('');
            setResultResearch(null);

            return;
        }

        if (localStorage.getItem("MyBook"))
            setOnlyMyBook(localStorage.getItem("MyBook") === "true");
        if (localStorage.getItem("filters"))
            setFilters(localStorage.getItem("filters").split(","));
        if (localStorage.getItem("revOrRec"))
            setRevOrRec(localStorage.getItem("revOrRec"));
        if (localStorage.getItem("word"))
            setWordToSearch(localStorage.getItem("word"));
        if (localStorage.getItem("isUpdated"))
            setIsUpdated(localStorage.getItem("isUpdated"));
        if (localStorage.getItem("year"))
            setYear(localStorage.getItem("year"));
        if (localStorage.getItem("search category"))
            setChangeCategory(returnRightCategory(localStorage.getItem("search category")))

        localStorage.removeItem("mantieniIFiltri");

    }, [isFromAnotherPage]);

    const updateResearchList = (filter) => {

        if (filter === 'MyBook') {
            setOnlyMyBook(!onlyMyBook);
            localStorage.setItem("MyBook", !onlyMyBook);
            return;
        }

        if (filter === 'isUpdated') {
            setIsUpdated(!isUpdated);
            localStorage.setItem("isUpdated", !isUpdated);
            return;
        }

        if (filters.find(el => el === filter)) {
            const index = filters.indexOf(filter);
            const newArray = [...filters];
            newArray.splice(index, 1);
            setFilters(newArray);
            localStorage.setItem("filters", newArray);
        } else {
            const newArray = [...filters, filter];
            setFilters(newArray);
            localStorage.setItem("filters", newArray);
        }
    };

    const mostRecOrMostRev = (value) => {
        localStorage.setItem("revOrRec", value);
        setRevOrRec(value);
    }

    const addNewWord = (word) => {
        if (word.trim() === '') {
            setResultResearch(null);
            localStorage.removeItem("word", word);
        }
        localStorage.setItem("word", word);
        setWordToSearch(word);
    };

    const addSearchCategory = (searchForCategory) => {

        const category = returnRightCategory(searchForCategory)
        setChangeCategory(category);
    }

    const getBuyOrNot = (arrayIdProv, arrayIdDocType, arrayToReturn) => {

        const buyOrNot = (data) => {

            const buyOrNot = convertArrayForFEBuyOrNot(data.Result.ResultSet.Root);

            setResultBuyOrNot(buyOrNot);
            setResultResearch(arrayToReturn);
            updateView(`book24ore:ricerca:risultato`, authCtx, tokenCtx, "book24ore:ricerca", wordToSearch);
        };

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getProdotti),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedAcquista(arrayIdProv, arrayIdDocType, tokenCtx))
            }
        };

        sendRqst.sendRequest(request, buyOrNot);
    };

    const changeYear = (year) => {
        localStorage.setItem("year", year);
        setYear(year);
    }

    const clearFilters = () => {

        deleteLocalStorage();
        setOnlyMyBook(false);
        setIsUpdated(false);
        setRevOrRec(['', 'score desc']);
        setYear("tutti");
        setChangeCategory(1)
        setFilters([]);
    }
    useEffect(() => {
        if(!isBookIntegrate) return;
            sessionStorage.setItem("isBookIntegrate", isBookIntegrate)
        //SETIsIntegrateInBD(true);
      }, [isBookIntegrate]);

    useEffect(() => {

        if (resultResearch === null || resultResearch === undefined || resultResearch.length === 0)
            return;
        isLoadingCtx.setIfIsLoading(false, 600);

    }, [resultResearch]);

    useEffect(() => {
        let wordSaved = localStorage.getItem("word");

        if ((location.pathname === "/ricerca" || location.pathname === "/ricerca/daMieiLibri" || location.pathname === "/ricerca/daMieiLibri/fisco") && wordSaved !== null) {
            navigate("/ricerca/1");
            //navigate(0);
        }

        if (wordSaved === undefined || wordSaved === null) {
            if (isFromAnotherPage !== "daMieiLibri" || (isFromAnotherPage === "daMieiLibri" && (!authCtx.isLoggedIn && localStorage.getItem("isLoggedIn") === null))) {

                if (window.utag === null || window.utag === undefined)
                    return;

                updateView(`book24ore:ricerca:inizia`, authCtx, tokenCtx, "book24ore:ricerca");

                return;
            } else {
                isLoadingCtx.setIfIsLoading(true);
                archive();
                return;
            }
        }
        const isNotSetting = ((wordSaved === undefined || wordSaved === null) || (isFromAnotherPage === undefined || isFromAnotherPage === null));

        if (wordToSearch === '' && (isNotSetting)) {
            isLoadingCtx.setIfIsLoading(false, 200);
            return;
        }

        const settingNewResList = (data) => {

            if (changeCategory === 3) {
                ricercaPerTitolo(data.Result.Facets);
                //return;
            }

            let arraysForFe = convertArrayForFEResearch(data.Result.Documents);

            if (arraysForFe.length === 0) {
                setResultBuyOrNot(null);
                updateView(`book24ore:ricerca:nessun-risultato`, authCtx, tokenCtx, "book24ore:ricerca", wordToSearch);
                setResultResearch([]);
                isLoadingCtx.setIfIsLoading(false, 400);
                return;
            }


            getBuyOrNot(arraysForFe.arrayIdProv.toString(), arraysForFe.arrayIdDocType.toString(), arraysForFe.arrayToReturn);
        };

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getResultSearch),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedResearch(filters, wordToSearch ? wordToSearch : wordSaved, revOrRec, tokenCtx, onlyMyBook, isUpdated, true, undefined, year, authCtx, changeCategory))
            }
        };

        if (((wordToSearch !== '' && !isLoadingCtx.isLoading) || (wordSaved !== '' && wordToSearch == ''))) {
            isLoadingCtx.setIfIsLoading(true);
            sendRqst.sendRequest(request, settingNewResList, false, false);
        }

    }, [filters, wordToSearch, revOrRec, onlyMyBook, isUpdated, year, authCtx.isLoggedIn, window.utag, changeCategory, tokenCtx.token]);


    const archive = () => {

        isLoadingCtx.setIfIsLoading(true);

        const settingsResult = (data) => {

            let arrayResult = [];

            if (data.Result.ResultSet !== null)
                arrayResult = convertArrayForFEArchive(data.Result.ResultSet.Root);

            setResultResearch(arrayResult);
            isLoadingCtx.setIfIsLoading(false, 400);
        }

        let filters = localStorage.getItem("filters") ? localStorage.getItem("filters").split(",") : [];

        const bodyFormatted = {
            parameters: {
                name: config.nameList.archivio,
                inputParameters: createArrayInputParamsArchive(filters, wordToSearch, revOrRec, onlyMyBook, localStorage.getItem("isUpdated"), localStorage.getItem("year")),
            },
            token: tokenCtx.token ? tokenCtx.token : getCookie("plus_token")
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, settingsResult, false, false)
    }

    const ricercaPerTitolo = (facet) => {

        const settingsResult = (data) => {

            let arrayResult = [];

            if (data.Result.ResultSet !== null)
                arrayResult = convertArrayForFEArchive(data.Result.ResultSet.Root);

            setResultResearch(arrayResult);
            isLoadingCtx.setIfIsLoading(false, 400);
        }

        const bodyFormatted = {
            parameters: {
                name: config.nameList.ricercaPerTitolo,
                inputParameters: createInputParameters(facet),
                staticToken: authCtx.isLoggedIn && onlyMyBook ? "" : config.token.STATIC_TOKEN
            },
            token: authCtx.isLoggedIn && onlyMyBook ? tokenCtx.token : ""
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, settingsResult, false, false)

    }

    useManageBodyClasses();

    return (
        <React.Fragment>

            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky"  isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                {isLoadingCtx.isLoading && <Spinner></Spinner>}
                <div className="main-content">
                    <AreaAllListHeader title="Ricerca" />
                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="search">

                                        <SearchBar addNewWord={addNewWord} searchForCategory={addSearchCategory} isBack={isFromAnotherPage} placeHolder="Inserisci parola chiave"></SearchBar>
                                        <SearchResults
                                            updateResearchList={mostRecOrMostRev}
                                            resultResearch={resultResearch}
                                            testo={wordToSearch}
                                            isBack={isFromAnotherPage}
                                            resultBuyOrNot={resultBuyOrNot}
                                            isFromAnotherPage={isFromAnotherPage}
                                                /*isLoading={isLoading}*/ />

                                        {/* Pagination component goes in here */}
                                    </div>
                                </div>
                                <div className="col-lg-4 order-lg-first">
                                    <SearchFilters
                                        isLoggedIn={authCtx.isLoggedIn}
                                        addOrRemoveFilter={updateResearchList}
                                        testo={wordToSearch}
                                        resultResearch={resultResearch}
                                        isBack={isFromAnotherPage}
                                        isResearch={true}
                                        clearFilters={clearFilters}
                                        changeYear={changeYear}
                                        searchForCategory={addSearchCategory}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default Research;
