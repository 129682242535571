import React, { useEffect, useRef, useState } from "react";
import DeleteNoteModal from "../Modals/DeleteNoteModal";
import NoteFooter from "./NoteFooter";
import NoteHead from "./NoteHead";

const NoteSingle = (props) => {

    let editedNota = useRef(null);

    const [note, setNote] = useState(props.note);
    const [showDelete, setShowDelete] = useState(false);


    const editNoteTag = (newTags) => {

        let newNote = { ...note };

        newNote.tags = newTags;
        setNote(newNote);
    }

    const deleteNote = (ev) => {
        ev.preventDefault();

        setShowDelete(true);
        //props.deleteNote({ note, idDoc: props.idDocumento });
    }

    const trueDelete = () => {
        props.deleteNote({ note, idDoc: props.idDocumento });
    }

    const saveNote = (ev) => {
        ev.preventDefault();

        let newNote = { ...note, idDoc: props.idDocumento };

        if (editedNota.current.textContent !== null) {

            newNote.note = editedNota.current.textContent;
        }

        props.editNote(newNote);
    }

    return (
        <React.Fragment>
            <DeleteNoteModal deleteNote={trueDelete} title="Elimina Nota" message="Sicuro di voler eliminate la nota?" showModal={showDelete} closeModal={() => setShowDelete(false)}></DeleteNoteModal>
            <li className="list-item">
                <div className={note.color} id={note.idNote}>
                    <NoteHead date={note.date} idDoc={props.idDocumento} />

                    <span className="reader-txt">
                        <div dangerouslySetInnerHTML={{ __html: note.text }}></div>
                    </span>

                    <div className="note-textarea note-textarea--empty">
                        <p
                            className="input input--clear textarea"
                            data-placeholder="Aggiungi nota…"
                            contentEditable="true"
                            style={{ height: 20 }}
                            ref={editedNota}
                            suppressContentEditableWarning={true}
                        >{note.note}</p>
                        <span className="icon icon-note"></span>
                    </div>

                    <div className="note-foot">
                        <NoteFooter tags={note.tags} mostUsedTag={props.mostUsedTag} editNoteTag={editNoteTag} />
                        <div className="note-foot-buttons">
                            <a className="btn btn--outline" role="button" onClick={saveNote}>
                                Salva
                            </a>
                            <a className="btn btn--outline" role="button" onClick={deleteNote}>
                                Elimina
                            </a>
                        </div>
                    </div>
                </div>
            </li>
        </React.Fragment>
    )
}

export default NoteSingle;