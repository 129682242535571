import React, { useContext } from "react";
// Components
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import Header from "../components/Header/Header"
import AreaAllListHeader from "../components/UI/AreaAllListHeader/AreaAllListHeader";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import Footer from "../components/Footer/Footer";
import LoginModal from "../components/Modals/Login";
import AuthContext from "../store/auth-context";
import SearchFilters from "../components/UI/Research/SearchFilters/SearchFilters";
import SearchBar from "../components/UI/Research/SearchBar/SearchBar";
import Note from "../components/Note/Note";
import NoteEmpty from "../components/NoteEmpty/NoteEmpty";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import useManangeNoteAndTagOnReader from "../hooks/useManageNoteAndTagOnReader";
import { config } from "../constants/Constants";
import { filterByBook, filterByWord, filterNoteByColor, filterNoteByTag, formattedNoteArrayForFE, isChanged } from "../helper/helper";
import Spinner from "../components/UI/Spinner/Spinner";
import TokenContext from "../store/token-context";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import { updateView } from "../helper/TealiumHelper";
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const Notes = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [resultsNotes, setResultsNotes] = useState([]);
    const [notFilteredNotes, setNotFilteredNotes] = useState([]);
    const [idBookFilters, setIdBookFilters] = useState([]);
    const [colorFilters, setColorFilters] = useState([]);
    const [tagFilters, setTagFilters] = useState([]);
    const [wordFilter, setWordFilter] = useState("");
    const [noteWithText, setNoteWithText] = useState(false);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [mostUsedTag, setMostUsedTag] = useState([]);
    const [refresh, setRefresh] = useState(true);

    const [resultsNotesForEdit, setResultsNotesForEdit] = useState([]);

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const noteAndTag = useManangeNoteAndTagOnReader();
    const navigate = useNavigate();

    if (localStorage.getItem("isLoggedIn") !== "1") {
        navigate('/');
    }

    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });

    useEffect(() => {

        let clearFunct = setTimeout(() => {
            if (!authCtx.isLoggedIn) {
                navigate('/');
            }
        }, 400);

        return () => {
            clearTimeout(clearFunct);
        };

    }, [authCtx.isLoggedIn]);

    useEffect(() => {

        if (!refresh) return;

        setIsLoading(true);

        const settingsNote = (data) => {

            if (data.Result === null) {
                setResultsNotesForEdit([]);
                setNotFilteredNotes([]);
                setResultsNotes([]);
                setRefresh(false);
                setIsLoading(false);
                return;
            }

            const res = formattedNoteArrayForFE(data.Result.Documents);

            if (res.length > 0) {
                setResultsNotesForEdit(data.Result.Documents);
                setNotFilteredNotes(res);
                setResultsNotes(res);
                getMostUsedTag();
            }

            setRefresh(false);
            setIsLoading(false);
        };

        noteAndTag.getNotes(config.nameList.note, {}, true, settingsNote);

    }, [refresh]);

    const getMostUsedTag = () => {

        const settingMostUsedTag = (data) => {

            if (data.Result.Documents.length === 0) return;

            setMostUsedTag(data.Result.Documents);
        }

        noteAndTag.getGenericTag(config.nameList.note, { prop1: "data.note.tag" }, settingMostUsedTag);
    }

    const editNote = (editedNote) => {

        const refreshNote = (data) => {

            setRefresh(true);
        };

        let index = resultsNotesForEdit.map(val => val.data.iddocumento).indexOf(editedNote.idDoc);
        let noteToUpd = { ...resultsNotesForEdit[index] };

        noteToUpd.data.note.forEach(element => {
            if (element.idNote === editedNote.idNote) {
                element.tag = [...editedNote.tags];
                element.text = editedNote.note;
            }
        });

        noteAndTag.updateNotes(noteToUpd, config.nameList.note, refreshNote);
    }

    const deleteNote = (notaToDelete) => {

        let index = resultsNotesForEdit.map(val => val.data.iddocumento).indexOf(notaToDelete.idDoc);
        let noteToUpd = { ...resultsNotesForEdit[index] };

        let spanToReplace = "";
        let buttonToReplace = "";
        let newArrayNote = [];
        noteToUpd.data.note.forEach((element) => {
            if (element.idNote === notaToDelete.note.idNote) {
                spanToReplace = element.startSpan;
                buttonToReplace = element.startButton;
                return;
            }
            newArrayNote.push(element);
        });

        const replaceTextForDeleteNote = (data) => {

            let documentText = data.Result.Documents[0].data.documentText;
            documentText = documentText.replace(spanToReplace, '<span>');
            documentText = documentText.replace(buttonToReplace, '<span>');
            data.Result.Documents[0].data.documentText = documentText;

            const finishUpdateDocument = (data) => {

                const refreshNote = (data) => {

                    setRefresh(true);
                };

                if (newArrayNote.length === 0) {
                    noteAndTag.setDelete({ prop1: noteToUpd._id.$oid }, refreshNote);
                } else {
                    noteToUpd.data.note = newArrayNote;
                    noteAndTag.updateNotes(noteToUpd, config.nameList.note, refreshNote);
                }
            }

            if (newArrayNote.length === 0) {
                noteAndTag.setDelete({ prop1: data.Result.Documents[0]._id.$oid }, finishUpdateDocument);
            } else {
                noteAndTag.updateDocument(data.Result.Documents[0], finishUpdateDocument);
            }
        }

        noteAndTag.getDocumentEditWithNotes(config.nameList.documentiConNote, { prop1: parseInt(noteToUpd.data.idprovvedimento), prop2: parseInt(noteToUpd.data.iddocumento), prop3: parseInt(noteToUpd.data.iddoctype), prop4: "" }, true, replaceTextForDeleteNote)
    }

    const filteringBook = (idToFilter) => {

        const indx = idBookFilters.indexOf(idToFilter);

        if (indx === -1) {
            setIdBookFilters(prev => [...prev, idToFilter]);
            return;
        }

        let array = [...idBookFilters];
        array.splice(indx, 1);
        setIdBookFilters(array);
    };

    const filteringByColor = (color) => {

        const indx = colorFilters.indexOf(color);

        if (indx === -1) {
            setColorFilters(prev => [...prev, color]);
            return;
        }

        let array = [...colorFilters];
        array.splice(indx, 1);
        setColorFilters(array);
    }

    const filteringByTag = (tagToFilter) => {

        const indx = tagFilters.indexOf(tagToFilter);

        if (indx === -1) {
            setTagFilters(prev => [...prev, tagToFilter]);
            return;
        }

        let array = [...tagFilters];
        array.splice(indx, 1);
        setTagFilters(array);
    }

    const filteringForNotes = (isFilter) => {
        setNoteWithText(isFilter);
    }

    const filteringByWord = (wordToFilter) => {

        if (wordToFilter === wordFilter) return;

        setWordFilter(wordToFilter);
    };

    useEffect(() => {

        let delayedFunctionFiltes = setTimeout(() => {

            if (colorFilters.length === 0 && idBookFilters.length === 0 && tagFilters.length === 0 && !noteWithText && wordFilter === "" && notFilteredNotes.length !== 0) {
                setResultsNotes(notFilteredNotes);
                return;
            }

            let newFilteredArray = notFilteredNotes;

            if (colorFilters.length > 0) {
                newFilteredArray = filterNoteByColor(newFilteredArray, colorFilters);
            }
            if (tagFilters.length > 0) {
                newFilteredArray = filterNoteByTag(newFilteredArray, tagFilters);
            }
            if (idBookFilters.length > 0) {
                newFilteredArray = filterByBook(newFilteredArray, idBookFilters);
            }
            if (wordFilter !== "") {
                newFilteredArray = filterByWord(newFilteredArray, wordFilter)
            }

            if (isChanged(newFilteredArray, resultsNotes)) {
                setResultsNotes(newFilteredArray);
            }
        }, 200);

        return () => {

            clearTimeout(delayedFunctionFiltes);
        };

    }, [colorFilters, tagFilters, idBookFilters, noteWithText, wordFilter])

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView("book24ore:le-mie-note", authCtx, tokenCtx, "book24ore:area-loggato");

    }, [tokenCtx.token, authCtx.isLoggedIn, window.utag]);

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky" isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <div className="main-content ">
                    <AreaAllListHeader title="Le mie note" />
                    {/*isLoading && <Spinner></Spinner>*/}

                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="search">
                                        {<SearchBar placeHolder="Cerca tra le note" addNewWord={filteringByWord} />}

                                        {/*(resultsNotes.length === 0 || !resultsNotes) &&
                                            <NoteEmpty />*/}
                                        {resultsNotes && resultsNotes.length !== 0 &&
                                            <div className="search-results">
                                                {resultsNotes.map((note, index) => (
                                                    <Note title={note.title}
                                                        key={Math.random()}
                                                        subTitleList={note.subTitleList}
                                                        resultsNotesForEdit={resultsNotesForEdit}
                                                        mostUsedTag={mostUsedTag}
                                                        editNote={editNote}
                                                        deleteNote={deleteNote} />
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 order-lg-first">
                                    <SearchFilters
                                        isNotes={true}
                                        resultsNotes={resultsNotes}
                                        notFilteredNotes={notFilteredNotes}
                                        mostUsedTag={mostUsedTag}
                                        filteringBook={filteringBook}
                                        filteringByColor={filteringByColor}
                                        filteringByTag={filteringByTag}
                                        filteringForNotes={filteringForNotes}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>

        </React.Fragment>
    );
}

export default Notes;