import React from 'react';
import ReaderBox from './ReaderBox';


const ReaderBoxesBottom = (props) => {

    const normaCommentata = props.normaCommentata;
    const itemsBox1 = [
        {
            title: normaCommentata.Text1,
            linkPath: '/ntPro/Norma-commentata/bibfisco/' + normaCommentata.iddocumento,
            listItems: [
                {
                    title: normaCommentata.Text2,
                    linkPath: '/ntPro/Norma-commentata/bibfisco/' + normaCommentata.iddocumento,
                }
            ]
        }
    ];

    const itemsBox2 = [
        {
            title: 'Approfondimenti',
            linkPath: '#',
            listItems: [
                {
                    title: 'Operazioni imponibili',
                    linkPath: '#',
                    spanCounter: '999'
                },
                {
                    title: 'Operazioni imponibili',
                    linkPath: '#',
                    spanCounter: '999'
                }
            ]
        },
        {
            title: 'Giurisprudenza',
            linkPath: '#',
            listItems: [
                {
                    title: 'Operazioni imponibili',
                    linkPath: '#',
                    spanCounter: '999'
                },
                {
                    title: 'Operazioni imponibili',
                    linkPath: '#',
                    spanCounter: '999'
                }
            ]
        }
    ];

    return(
        <div className="reader-box-wrapper">
            <div className="row">
                <div className="col-md-6">
                    <ReaderBox title='Norma Commentata' items={itemsBox1} />
                    {/*<ReaderBox title='Approfondimenti' items={itemsBox2}/>*/}
                </div>
                {/*
                <div className="col-md-6">
                    <div className="reader-box">
                        <h5 className="reader-box-title">Correlati in evidenza</h5>
                        <ul className="list-lined">
                            <li className="list-lined-item">
                                <a className="ncard-slim" href="#">
                                    <span className="ncard-fam">
                                        <span className="h-meta">
                                            <span className="serif italic">famiglia 2° livello</span>
                                        </span>
                                    </span>
                                    <span className="ncard">
                                        <span className="ncard-title">Tipo parte provvedimento</span>
                                        <span className="ncard-subtitle">Rubrica o oggetto - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                    </span>
                                </a>
                            </li>
                            <li className="list-lined-item">
                                <a className="ncard-slim" href="#">
                                    <span className="ncard-fam">
                                        <span className="h-meta">
                                            <span className="serif italic">famiglia 2° livello</span>
                                        </span>
                                    </span>
                                    <span className="ncard-pre">
                                        <span className="ncard-pre-text">
                                            <span className="strong">Ente</span>
                                            <span className="pipe">|</span>
                                            <span className="light">Organo</span>
                                            <span className="pipe">|</span>
                                            <span className="strong">Tipo provvedimento n. - estensione n.</span>
                                            <span className="pipe">|</span>
                                            <time>data</time>
                                        </span>
                                    </span>
                                    <span className="ncard">
                                        <span className="ncard-title">Tipo parte provvedimento</span>
                                        <span className="ncard-subtitle">Rubrica o oggetto - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                    </span>
                                </a>
                            </li>
                            <li className="list-lined-item">
                                <a className="ncard-slim" href="#">
                                    <span className="ncard-fam">
                                        <span className="h-meta">
                                            <span className="serif italic">famiglia 2° livello</span>
                                        </span>
                                    </span>
                                    <span className="ncard-pre">
                                        <span className="ncard-pre-text">
                                            <span className="strong">Ente</span>
                                            <span className="pipe">|</span>
                                            <span className="light">Organo</span>
                                            <span className="pipe">|</span>
                                            <span className="strong">Tipo provvedimento n. - estensione n.</span>
                                            <span className="pipe">|</span>
                                            <time>data</time>
                                        </span>
                                    </span>
                                    <span className="ncard">
                                        <span className="ncard-title">Tipo parte provvedimento</span>
                                        <span className="ncard-subtitle">Rubrica o oggetto - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
    */}
            </div>
        </div>
    );
}

export default ReaderBoxesBottom;