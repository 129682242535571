import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Ricerca from "../components/Header/Ricerca/Ricerca";
import Wrapper from "../components/Helper/Wrapper/Wrapper";
import LibriPacchetto from "../components/Prodotti/LibriPacchetto/LibriPacchetto";
import Prodotti from "../components/Prodotti/Prodotti";
import SchedaProdottoSpecifica from "../components/Prodotti/SchedaProdottoSpecifica/SchedaProdottoSpecifica";
import ManageThematicArea from "../components/UI/ManageThematicArea/ManageThematicArea";
import Paragraph from "../components/UI/Paragraph/Paragraph";
import LoginModal from "../components/Modals/Login";
import AuthContext from "../store/auth-context";
import { NavLink, useParams } from "react-router-dom";
import { bodyFormattedGetSchedaPacchetto, bodyFormattedGetTiPotrbbIntPacc, castArrayTiPotrIntForFe, castObjForFe, castThemeAreaInString } from "../helper/helperPacchetto";
import useHttpRequest from "../hooks/http-request";
import { config } from "../constants/Constants";
import TokenContext from "../store/token-context";
import Spinner from "../components/UI/Spinner/Spinner";
import isLoadingContext from "../store/isLoadingcontext";
import useManageBodyClasses from "../hooks/useManageBodyClasses";
import StickyFoot from "../components/Prodotti/SchedaProdottoSpecifica/StickyFoot";
import MessageManageSiac from "../components/Modals/MessageManageSiac";
import { updateView } from "../helper/TealiumHelper";
import { titleUrlSlug, titleUrlSlugForTealium } from "../helper/helper";
import SessioneScaduta from "../components/Modals/SessioneScaduta";

const Pacchetto = (props) => {

    const { id } = useParams();
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const sendRqst = useHttpRequest();
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    const [pacchetto, setPacchetto] = useState(null);
    const [pacchetti, setPacchetti] = useState(null);
    const [isBuyed, setIsBuyed] = useState(false);
    const [tiPotrebbeInteressare, setTiPotrebbeInteressare] = useState(null);
    const [idDocuments, setIdDocuments] = useState(null);

    useEffect(() => {

        let functionDelay = setTimeout(() => {
            isLoadingCtx.setIfIsLoading(true);
            const setHeaderPacchetto = (data) => {

                if (data.Result.ResultSet === null) return;

                const pacchetto = castObjForFe(data.Result.ResultSet.Root[0]);

                setPacchetto(pacchetto);
                updateView(`book24ore:pacchetto:${titleUrlSlugForTealium(pacchetto.title)}`,authCtx, tokenCtx, "book24ore:pacchetto");

                if (tokenCtx.allAssocModPac.length === 0) {
                    tokenCtx.loadProduct();
                }
            };
            const bodyFormatted = bodyFormattedGetSchedaPacchetto(id);

            const rqs = {
                url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
                req: {
                    headers: {
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                        'Content-Type': 'application/json',
                        'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                    },
                    body: JSON.stringify(bodyFormatted),
                    method: 'POST'
                }
            };

            sendRqst.sendRequest(rqs, setHeaderPacchetto);
        }, 300)

        return () => {
            clearTimeout(functionDelay);
        };

    }, [id, tokenCtx.allAssocModPac]);

    useEffect(() => {

        if (tokenCtx.allAssocModPac.length === 0 || pacchetto === null) return;

        const arrayAssociazioneModuli = tokenCtx.allAssocModPac;
        arrayAssociazioneModuli.forEach(element => {
            if(pacchetto.module>=1000) {
                setPacchetti(pacchetto.module) 
                return;
            }
            if (element["@modulo"] === pacchetto.module) {
                setPacchetti(element["@pacchetto"]);
            }
        });
    
    }, [tokenCtx.allAssocModPac, pacchetto]);

    useEffect(() => {

        if (pacchetto === null || idDocuments === null) return;

        const setListInteressare = (data) => {

            isLoadingCtx.setIfIsLoading(false);
            if (data.Result.ResultSet === null) return;

            setTiPotrebbeInteressare(castArrayTiPotrIntForFe(data.Result.ResultSet.Root));
        };

        const castAreaTems = castThemeAreaInString(pacchetto.areaTematicaList);

        const bodyFormatted = bodyFormattedGetTiPotrbbIntPacc(castAreaTems, idDocuments);

        const rqs = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqs, setListInteressare);

    }, [pacchetto, idDocuments]);

    const settingsIdDocuments = (idDocuments) => {
        if (idDocuments === undefined) return;

        setIdDocuments(idDocuments);
    };

    useEffect(() => {
        if (pacchetto === null || tokenCtx.modulesComplete.length === 0) return;

        let namePacchetto = '';
        tokenCtx.modulesComplete.forEach(el => {
            if (el['@id'] === pacchetto.module)
                namePacchetto = el['@name'];
        });

        setIsBuyed(tokenCtx.userModules.some(el => el === namePacchetto));

    }, [tokenCtx.userModules, pacchetto]);

    /*useEffect(() => {

        if(window.utag === null || window.utag === undefined || pacchetto === null) return;

        updateView(`book24ore:pacchetto:${titleUrlSlugForTealium(pacchetto.title)}`,authCtx, tokenCtx, "book24ore:pacchetto");
                
    },[tokenCtx.token, window.utag, authCtx.isLoggedIn, pacchetto])*/

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky"  isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                {isLoadingCtx.isLoading && <Spinner></Spinner>}
                {pacchetto !== null &&
                    <section className="section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="page">
                                        <div className="page-head" data-page-header>
                                            <h1 className="page-title">
                                                {pacchetto.title}
                                            </h1>
                                            <ul className="list-inline list-inline--small">
                                                <ManageThematicArea themeArea={pacchetto.areaTematicaList}></ManageThematicArea>
                                            </ul>

                                            <StickyFoot
                                                isPacchetto={true}
                                                link={pacchetto.link}
                                                img={pacchetto.img}
                                                isBuyed={isBuyed}
                                                pacchetto={pacchetto.title}
                                            />


                                        </div>
                                        <Paragraph
                                            key={Math.random()}
                                            classPar="page-txt"
                                            text={pacchetto.text}
                                            typeText="par"
                                        />
                                        <Paragraph
                                            key={Math.random()}
                                            classTitle="page-subtitle"
                                            title="Libri compresi nel pacchetto"
                                            typeText="subtit"
                                        />
                                        <ul className="list list--large">
                                            {pacchetti && <LibriPacchetto idPacchetto={pacchetto.id} modules={pacchetto.module} pacchetti={pacchetti} settingsIdDocuments={settingsIdDocuments} titleContainer="Libri compresi" pacchetto={pacchetto.title}></LibriPacchetto>}
                                        </ul>

                                    </div>
                                </div>

                                <SchedaProdottoSpecifica
                                    isPacchetto={true}
                                    link={pacchetto.link}
                                    img={pacchetto.img}
                                    isBuyed={isBuyed}
                                    titleContainer={"scheda"}
                                    titleTealium={pacchetto.title}
                                />
                            </div>
                        </div>
                    </section>
                }
                {tiPotrebbeInteressare && <Prodotti
                    title="Ti potrebbe interessare"
                    prodList={tiPotrebbeInteressare}
                    isSubscription={false}
                    isManageable={false}
                    sectionClassName=""
                    classNameCard="card card--book "
                    dataSlide="4"
                    dataSlideMd="3"
                    isProfessionalTool={false}></Prodotti>}
                <Footer  isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    );
}

export default Pacchetto;