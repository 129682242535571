import React from 'react';
import Bookmark from '../../Bookmark/Bookmark';
import Novita from '../../Novita/Novita';
import SearchCardContent from '../SearchCard/SearchCardContent';
import SearchCardFooter from '../SearchCard/SearchCardFooter';

const SearchResult = (props) => {

   return (
        <li className="list-item">
            <div className="card card--hor  ">
                <div className="card-box">
                    
                        <>
                            <SearchCardContent prodotto={props.prodotto} testo={props.testo} resultBuyOrNot={props.resultBuyOrNot} isFromAnotherPage={props.isFromAnotherPage}></SearchCardContent>

                            {props?.prodotto?.isNew && <Novita />}
                            {props?.prodotto?.hasBookmark && <Bookmark />}

                            {(props.prodotto?.documents?.length > 0 && props.isFromAnotherPage !== "daMieiLibri") && <SearchCardFooter resultId={props.prodotto.id} documents={props.prodotto.documents} resultBuyOrNot={props.resultBuyOrNot} idProv={props.prodotto.idProv}></SearchCardFooter>}
                        </>
                    
                </div>
            </div>
        </li>
    );
}

export default SearchResult;