import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import CarouselItem from '../components/CarouselNoSlick/CarouselItem';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import UtilityWrapper from '../components/UtilityWrapper/UtilityWrapper';
import { config } from '../constants/Constants';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import ManageProductListsContext from '../store/manage-products-list-context';
import TokenContext from '../store/token-context';
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const NotFound = (props) => {

    const authCtx = useContext(AuthContext);
    const tokenCtx= useContext(TokenContext);
    const productCtx = useContext(ManageProductListsContext);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [carouselList, setCarouselList] = useState([]);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useEffect(() => {

        if(productCtx.ultimeUscite.length === 0)
            productCtx.getList(config.nameList.ultimeUscite);
        else
            setCarouselList(productCtx.ultimeUscite);

    }, [productCtx.ultimeUscite]);

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky" isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <UtilityWrapper>
                    <h1 className="errorpage-title">
                        <span className="errorpage-title-light">Oops! </span>
                        Pagina non trovata
                    </h1>
                    <p className="errorpage-text">
                        Spiacenti, non riusciamo a trovare la pagina che stai cercando, possiamo suggerirti le ultime uscite oppure tornare alla Homepage
                    </p>
                    <section className="section">
                        <div className="container">
                            <div className="row scrolling">
                                {carouselList && carouselList.map((item, index) => (
                                    <CarouselItem item={item} index={index} key={index} classNameCard="card card--book"/>
                                ))}
                            </div>
                        </div>
                    </section>
                    <NavLink className="btn btn--black btn--big" to="/">Torna alla Homepage</NavLink>
                </UtilityWrapper>

                <Footer isIntegrateInBD={props.isIntegrateInBD}/>

                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default NotFound;