import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { castForLogin, removeCookieSoleAuth, checkSoleaAuth, removeCookie } from "../helper/helper";
import { config } from '../constants/Constants';
import useHttpRequest from "../hooks/http-request";
import isLoadingContext from "./isLoadingcontext";
import { updateLink } from "../helper/TealiumHelper";

const AuthContext = React.createContext({
    isLoggedIn: false,
    isSoleAuth: false,
    username: '',
    email: '',
    error: null,
    isLoading: false,
    showLoginModal: false,
    redirectUrl: '',
    onLogout: () => { },
    onLogin: (email, password, rememberMe) => { },
});

export const AuthContexProvider = (props) => {

    const [username, setUsername] = useState('Utente');
    const [email, setEmail] = useState("");
    const [isSoleAuth, setIsSoleAuth] = useState(checkSoleaAuth());
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState(null);
    const isLoadingCtx = useContext(isLoadingContext);

    const response = useHttpRequest();

    const logoutHandler = (isClicked = false) => {
        setIsLoggedIn(false);
        setEmail("");
        localStorage.removeItem("email");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userLoggedWithoutToken");
        localStorage.removeItem("userType");
        removeCookieSoleAuth("SoleAuth", "", -1, config.domain);
        setIsSoleAuth(undefined);
        setUsername('Utente');
        removeCookieSoleAuth("SoleRememberMe", "", window.location.href.split("/")[2]);
        removeCookie("plus_token", "", window.location.href.split("/")[2]);
        if (isClicked) {
            updateLink({ title: "", name: "" }, "user-logout:", "logout");
        }
    };

    const loginUserProfile = () => {

        const soleAuht = checkSoleaAuth();

        if (soleAuht === undefined || soleAuht === '' || soleAuht === null) {
            return;
        }


        const completeUrl = castForLogin({
            siteCode: config.siteCode
        });

        const settingInformationUser = (data) => {

            if (!data.Success) {
                logoutHandler();
                return;
            }

            setUsername(data.Result.username);
            setEmail(localStorage.getItem("email"));
            localStorage.setItem('isLoggedIn', 1);

            setIsLoggedIn(true);
            setIsSoleAuth(checkSoleaAuth());
        };

        const request = {
            url: config.url.API_URL_GETPROFILO.concat(completeUrl),
            req: {
                method: 'GET',
                headers:
                {
                    'appKey': config.appKey,
                    'Content-Type': 'application/json; charset=utf-8',
                    'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0'
                }
            }
        };

        const resp = response.sendRequest(request, settingInformationUser, true, true);

        /*if (response.error !== '') {
            if(userNotHaveToken(response.error)){
            localStorage.setItem('isLoggedIn',1);
            setIsLoggedIn(true);
            setIsSoleAuth(checkSoleaAuth());
                localStorage.setItem("userLoggedWithoutToken", 1);
            }
            else{
                logoutHandler();
            }
        }*/

        return resp;
    };

    const loginHandler = (email, password, rememberMe = 0, genericFunction, isClick = false) => {

        isLoadingCtx.setIfIsLoading(true);
        const getSoleAuth = (data) => {

            localStorage.setItem("email", email);

            if (data.Exception !== undefined) {
                genericFunction(data.Exception)
            }

            const toAppendUrl = castForLogin({
                UserIdentity: data.Result.res,
                RURL: window.location.href
            });

            const request = {
                url: config.url.API_URL_PROFILO.concat(toAppendUrl),
                req: {
                    method: 'GET',
                    headers: {}
                }
            };

            const logged = (url) => {

                if (isClick) {
                        updateLink({ title: "", name: "" }, `user-login:${email}`, "login");
                }

                window.location.href = url;
            };

            response.sendRequest(request, logged);
        };

        const toAppendUrl = castForLogin({
            username: email,
            password: password,
            RememberMe: rememberMe,
            siteCode: config.appId
        });

        const request = {
            url: config.url.API_URL_LOGIN_CENTRALE.concat(toAppendUrl),
            req: {
                method: 'GET',
                headers:
                {
                    'AppKey': config.appKey,
                    'Content-Type': 'application/json; charset=utf-8',
                    'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0'
                }
            }
        };

        response.sendRequest(request, getSoleAuth, false, true);

    };

    const settingShowLoginModalConf = (isShow) => {
        setShowLoginModal(isShow);
    };

    const loginSocialProfile = (provider, data) => {
        setProvider(provider);
        setProfile(data);
    };

    const contextValue = {
        isLoggedIn: isLoggedIn,
        isSoleAuth: isSoleAuth,
        error: error,
        showLoginModal: showLoginModal,
        username: username,
        redirectUrl: redirectUrl,
        email: email,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        showModalLog: settingShowLoginModalConf,
        checkSoleaAuth: checkSoleaAuth,
        loginUserProfile: loginUserProfile,
        loginSocialProfile: loginSocialProfile
    };

    useEffect(() => {

        let checkSoleaAuthExp = setTimeout(() => {


            if (localStorage.getItem("isLoggedIn")) {
                setIsLoggedIn(true);
                loginUserProfile();
            }

        }, 120000);

        return () => {
            clearTimeout(checkSoleaAuthExp);
        }
    });

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
};

export default AuthContext;