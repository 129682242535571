import React from 'react';

const ReaderMeta = (props) => {

    const auth = props.document.auth === null || props.document.auth === undefined || props.document.auth === "";
    return (
        <p className="reader-meta">
            {props.isLink &&
                <>
                    <a className="reader-meta-part reader-meta-subh" href="#">
                        {props.document.upTitlLeft}
                    </a>
                    {(props.document.upTitlRight !== '' && props.document.upTitlRight !== undefined) &&
                        <a className="reader-meta-part reader-meta-subh" href="#">
                            {props.document.upTitlRight}
                        </a>
                    }
                </>
            }
            {props.isSpan && Object.keys(props.document).length !== 0 &&
                <>
                    {(props.document.upd !== "" || props.document.upd !== undefined) &&
                        <span className="reader-meta-part reader-meta-time">
                            {`${props.getTipo.sempreaggiornato === "1"? "Aggiornato il: " : "Pubblicato il: "} ${props.document.upd}`}                            
                        </span>
                    }
                    {!auth && <span className="reader-meta-part reader-meta-auth">
                        {props.document.auth}
                    </span>}
                </>
            }
        </p>
    );
}

export default ReaderMeta;