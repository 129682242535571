import { useEffect } from 'react';

const useManageBodyClasses = () => {

    useEffect(() => {
        if (document.body.className.includes('is-home')) {
            document.body.classList.remove('is-home');
        }
        else {
            return;
        }
    }, []);

};

export default useManageBodyClasses;