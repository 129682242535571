import React from 'react'

const FilterWrapper = (props) => {
  return (
    <div className="search-side">
        <h4 className="search-side-title">{props.title}</h4>
        {props.children}
    </div>
  )
}

export default FilterWrapper