import React from 'react';

const ReaderBox = (props) => {

    const updated = `${props.document.novitaCountTot > 1 ? `sono presenti ${props.document.novitaCountTot} aggiornamenti` : `è presente ${props.document.novitaCountTot} aggiornamento`}`;
    
    const handleClickScroll = () => {
        const element = document.getElementById('reader-update');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <React.Fragment>
            {props.document.novitaCountTot > 0 &&
                <div className="reader-box-wrapper d-print-none">
                    <div className="reader-box reader-box--small reader-box--update reader-box--icon">
                        <p className="reader-box-txt">
                            In questo documento  <span className="black"> {updated} </span>.
                        </p>
                        <button className="reader-goto reader-goto--right" data-scroll-to onClick={handleClickScroll}>
                            Vedi aggiornamenti <span className="icon icon-arrow-right-small"></span>
                        </button>
                        <span className="itool">
                            <span className="icon icon-history"></span>
                        </span>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default ReaderBox;