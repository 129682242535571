import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import UtilityWrapper from '../components/UtilityWrapper/UtilityWrapper';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import TokenContext from '../store/token-context';
import SessioneScaduta from '../components/Modals/SessioneScaduta';

const OldBrowser = () => {

    const authCtx = useContext(AuthContext);
    const tokenCtx= useContext(TokenContext);
    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    const oldBrowsers = [
        { title: 'Safari', text: 'versione 13 e successive' },
        { title: 'Firefox', text: 'versione 70 e successive' },
        { title: 'Internet Explorer e Microsoft Edge', text: 'versione 44 e successive' },
        { title: 'Chrome', text: 'versione 78 e successive' },
    ];

    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky="sticky"></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal> }
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <UtilityWrapper>
                    <h1 className="errorpage-title">
                        <span className="errorpage-title-light">Oops!</span>
                        Browser non supportato
                    </h1>
                    <p className="errorpage-text">
                        Book24ORE non è disponibile sul browser che stai utilizzando. <br />Puoi accedere utilizzando un browser di ultima generazione tra quelli elencati di seguito
                    </p>
                    <div className="oldb">
                        <ul className="list-lined">
                            {oldBrowsers && oldBrowsers.map((item, index) => (
                                <li className="list-lined-item" key={index}>
                                    <a className="oldb-item" role={"listitem"}>
                                        <strong>{item.title}</strong>
                                        {item.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </UtilityWrapper>
                
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default OldBrowser;