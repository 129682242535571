import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchForType from '../SearchBar/SearchForType';
import SearchForTypeForMob from '../SearchBar/SearchForTypeForMob';
import Filters from './Filters/Filters';
import FiltersByArea from './FiltersByArea/FiltersByArea';
import FiltersNotes from './FiltersNotes/FiltersNotes';
import FooterFilters from './FooterFilters/FooterFilters';

const SearchFilters = (props) => {

    const [clear, setClear] = useState(false);
    const { isFromAnotherPage } = useParams();
    const searchError = (props.testo === '' || isFromAnotherPage === 'daMieiLibri');

    const addOrRemoveFilter = (newFilter) => {
        props.addOrRemoveFilter(newFilter);
    };

    const filteringBook = (idToFilter) => {

        props.filteringBook(idToFilter)
    }

    const filteringByColor = (colorToFilter) => {

        props.filteringByColor(colorToFilter);
    }

    const filteringByTag = (tagToFilter) => {

        props.filteringByTag(tagToFilter);
    }

    const filteringForNotes = (isFilter) => {
        props.filteringForNotes(isFilter);
    }

    const clearFilters = () => {
        props.clearFilters();
        setClear(true);
    }

    const changeClear = () => {
        setClear(false);
    }
    const changeYear = (year) => {
        props.changeYear(year);
    }

    const searchForCategory = (searchForCategory) => {
        props.searchForCategory(searchForCategory);
    }

    return (
        <div className="sticky-wrapper">
            <div className="sticky sticky--side">
                <div className="search-panel">
                    <div className="search-panel-head d-lg-none">
                        <h2 className="search-panel-title">
                            Filtri
                        </h2>
                        <a className="itool" role="button" data-toggle-class="is-search-panel-in" onClick={() => document.body.classList.remove('is-search-panel-in')}>
                            <span className="icon icon-cross"></span>
                        </a>
                    </div>
                    <div className="search-panel-scroll">
                        {props.isResearch &&
                            <>
                                <SearchForTypeForMob searchForCategory={searchForCategory} searchError={searchError}></SearchForTypeForMob>
                                <Filters isLoggedIn={props.isLoggedIn} changeYear={changeYear} addOrRemoveFilter={addOrRemoveFilter} testo={props.testo} resultResearch={props.resultResearch} isBack={props.isBack} clear={clear} changeClear={changeClear}></Filters>
                                <FiltersByArea addOrRemoveFilter={addOrRemoveFilter} testo={props.testo} resultResearch={props.resultResearch} isBack={props.isBack} clear={clear} changeClear={changeClear}></FiltersByArea>
                            </>
                        }
                        {props.isNotes &&
                            <FiltersNotes
                                resultsNotes={props.resultsNotes}
                                notFilteredNotes={props.notFilteredNotes}
                                mostUsedTag={props.mostUsedTag}
                                filteringBook={filteringBook}
                                filteringByColor={filteringByColor}
                                filteringByTag={filteringByTag}
                                filteringForNotes={filteringForNotes}
                            />
                        }
                    </div>
                    <div className="search-panel-foot d-lg-none">
                        <FooterFilters clearFilters={clearFilters}></FooterFilters>
                    </div>
                </div>
                <span className="search-panel-backdrop" data-toggle-class="is-search-panel-in"></span>
            </div>
        </div>
    );
}

export default SearchFilters;