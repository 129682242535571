import React from 'react';
import SearchCardFooterItem from './SearchCardFooterItem';

const SearchCardFooter = (props) => {

    const footerItems = props.documents;

    return(
        <div className="card-search">
            <div className="card-search-results" id="res-card-1">
                <ul className="list-lined list-lined--large">
                    {footerItems && footerItems.map((item, i) => (
                        <SearchCardFooterItem resultId={props.resultId} prodotto={item} itemId={i} key={i} resultBuyOrNot={props.resultBuyOrNot} idProv={props.idProv}></SearchCardFooterItem>
                    ))}
                </ul>
            </div>
            {footerItems && footerItems.length > 1 && <span className="card-search-info">
                e risultati in altri capitoli
            </span>}
        </div>
    );
}

export default SearchCardFooter;