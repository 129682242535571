import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./auth-context";
import useHttpRequest from "../hooks/http-request";
import { config } from '../constants/Constants';
import isLoadingContext from "../store/isLoadingcontext";
import { castForLogin, createCookieForDocument, getCookie, userNotHaveToken } from "../helper/helper";
import { getFingerPrint } from "../helper/calculateFingerprint";

const TokenContext = React.createContext({
    xmlconfig: window.xmlconfig,
    token: null,
    module: [],
    userModules: [],
    isSubscribed: false
});

export const TokenContextProvider = (props) => {

    const [modules, setModules] = useState([]);
    const [provvedimenti, setProvvedimenti] = useState("");
    const [modulesComplete, setModulesComplete] = useState([]);
    const [associazioneModuliPacchetti, setAssociazioneModuliPacchetti] = useState([]);
    const [arricchimenti, setArricchimenti] = useState([]);
    const [userModules, setUserModules] = useState(localStorage.getItem("userModules") === null ? [] : localStorage.getItem("userModules").split(","));
    const [userModulesWithId, setUserModulesWithId] = useState(localStorage.getItem("userModulesWithId") === null ? [] : JSON.parse(localStorage.getItem("userModulesWithId")));
    const [isSubscribed, setIsSubscribed] = useState(userModules.length > 0 ? true : false);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [ipAddress, setIpAddress] = useState('');
    const [callManageSIACSession, setCallManageSIACSession] = useState(false);
    const [fingerPrint, setFingerPrint] = useState(null);
    const [showMessageSiac, setShowMessageSiac] = useState(false);
    const [userType, setUserType] = useState(parseInt(localStorage.getItem("userType")));
    const [selectedValuesToken, setSelectedValuesToken] = useState([]);

    const authCtx = useContext(AuthContext);
    const sendRqst = useHttpRequest();
    const isLoadingCtx = useContext(isLoadingContext);

    const loadProductConfig = () => {

        const bodyFormatted = {
            parameters: {
                xpath: config.xpath,
                appId: config.appId
            }
        };

        const setXmlConfig = (data) => {
            window.xmlconfig = data.Result.root;
            const array = window.xmlconfig.Moduli.Modulo;
            let modulesInArray = [];
            let modulesCompleteInArray = [];
            for (let i = 0; i < array.length; i++) {
                modulesInArray.push(array[i]['@id']);
                modulesCompleteInArray.push(array[i])
            }

            const arrayAssociazioneModuli = window.xmlconfig.AssociazioneModuliPacchetti.Associazione;
            let assModPacc = [];
            arrayAssociazioneModuli.forEach(element => {
                assModPacc.push(element);
            });

            setModules((prevModulesList) => { return prevModulesList.concat(modulesInArray); });
            setModulesComplete((prevModuleListComplete) => { return prevModuleListComplete.concat(modulesCompleteInArray) });
            setAssociazioneModuliPacchetti(assModPacc);
            setArricchimenti(window.xmlconfig.arricchimenti.item);
            //setAssociazioneModuliPacchetti((prevAssModPacc) => { return [...prevAssModPacc, assModPacc]; });
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.configSectionNoToken,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST',
            }
        };

        const response = sendRqst.sendRequest(request, setXmlConfig, true);

        if (response.error) {
            return response;
        }
    };

    const loadProductConfigFromToken = (token) => {
        const bodyFormatted = {
            parameters : {
                xpath : config.xpath
            },
            token: token
        }

        const setNormaCommentataCorrelati = (data) => {
            window.xmlconfig = data.Result.root;

            const provvedimenti = window.xmlconfig.norma_commentata_correlati.sp['@provvlegge'];
            
            
            setProvvedimenti(provvedimenti);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.configSection,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST',
            }
        };

        const response = sendRqst.sendRequest(request, setNormaCommentataCorrelati, true);

        if (response.error) {
            return response;
        }
    };
    const callGetUserProfile = (token) => {

        const settingsResult = (data) => {

            if(data.StatusCode !== 200) return ;

            const userType = parseInt(data.Result['@user_type']);

            setUserType(userType);
            localStorage.setItem("userType", userType);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getUserType,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify({ token: token }),
                method: 'POST',
            }
        }

        sendRqst.sendRequest(request, settingsResult);
    };

    const loadDynamicToken = (getDynamicTokenExtNoAccountingLog = false, arrayFor = [], byPassOldToken = false) => {

        if (token !== undefined && token !== null && !byPassOldToken && sessionStorage.getItem("isBookIntegrate")!="fisco") return;

        const settingCookies = (data) => {
            if (data.Result.token === undefined)
                return;
            createCookieForDocument("plus_first_access", "true");
            createCookieForDocument("plus_token", data.Result.token, "", window.location.href.split("/")[2]);
            authCtx.isLoggedIn = true;
            //qui controllare se il token 
            setToken(data.Result.token);
            localStorage.setItem('token', data.Result.token);
            callGetUserProfile(data.Result.token);

            if (data.Result.modules === null) {
                setIsSubscribed(false);
                return;
            }
            if (data.Result.modules.length > 0){
                setUserModules(data.Result.modules);
                localStorage.setItem("userModules", data.Result.modules);
                let arrayModulesUser = [];
                data.Result.modules.forEach(el => {
                    let index = modulesComplete.map(el => el['@name']).indexOf(el);
                    if (index !== -1) {
                        arrayModulesUser.push(modulesComplete[index]);
                    }
                });
                setUserModulesWithId(arrayModulesUser);
                localStorage.setItem("userModulesWithId", JSON.stringify(arrayModulesUser));
            }
            if (getCookie("CustomPopupCookie")) {
                const popupCookie = JSON.parse(getCookie("CustomPopupCookie"));
                if (popupCookie.popups) {
                    for (let i = 0; i < popupCookie.popups.length; i++) {
                        if (popupCookie.popups[i]) {
                            popupCookie.popups[i].loginCounter += 1;
                        }
                    }
                    createCookieForDocument("CustomPopupCookie", JSON.stringify(popupCookie), 365);
                }
                else {
                    createCookieForDocument("CustomPopupCookie", "", -1);
                }
            }
            setCallManageSIACSession(true);
        };


        const FingerprintJS = require('@fingerprintjs/fingerprintjs')

        const fpPromise = FingerprintJS.load();
        const response = (async () => {
            const fp = await fpPromise
            const result = await fp.get()

            setFingerPrint(result.visitorId);
            return {
                parameters: {
                    userKey: authCtx.username,
                    appId: config.appId,
                    ipAddress: ipAddress,
                    fingerPrint: result.visitorId,
                    cSessions: true,
                    skipVerificaModuli: getDynamicTokenExtNoAccountingLog ? byPassOldToken ? false : true : null,
                    skipLoginProdotto: getDynamicTokenExtNoAccountingLog ? byPassOldToken ? false : true : null,
                    data: {
                        environment: "ON",
                        modules: getDynamicTokenExtNoAccountingLog ? arrayFor : modules,
                        accountType: 0
                    }
                }
            };
        })().then(bodyFormatted => {
            if(config.extraModules){
                bodyFormatted.parameters.extraModules = true;
            }
            let serviceToCall = getDynamicTokenExtNoAccountingLog ? config.serviceToCall.dynamicTokenExtNoAcc : config.serviceToCall.dynamicTokenExt;
            return {
                url: config.url.BACKEND_SERVICE + serviceToCall,
                req: {
                    headers: {
                        'Content-Type': 'application/json',
                        'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                    },
                    body: JSON.stringify(bodyFormatted),
                    method: 'POST',
                }
            };
        }).then(requestToSend => {
            const response = sendRqst.sendRequest(requestToSend, settingCookies, true);
            return response;
        }).then(resp => {
            if (resp !== '' && resp !== undefined) {
                const statusCode = resp.match(/^[^,]*/mi)[0].split(" ");
                if (userNotHaveToken(resp)) {
                    //localStorage.setItem("userLoggedWithoutToken", 1);
                    loadDynamicToken(true)
                }
                else if (statusCode[statusCode.length - 1] === "1002") {
                    loadDynamicToken(true)
                }
                else {
                    logoutToken();
                    authCtx.onLogout();
                }
            }
        });

    };

    useEffect(() => {
        if (!getCookie("plus_token") && authCtx.isLoggedIn) {
            loadDynamicToken();
        }
        if (!(authCtx.isLoggedIn)) {
            setIsSubscribed(false);
        }
    }, [authCtx.isLoggedIn])

    useEffect(() => {

        if (!callManageSIACSession || fingerPrint === null || token === null) return;

        const manageResponseSiac = (data) => {

            setCallManageSIACSession(false);

            if ((data.StatusCode === 200 && Object.keys(data.Result).length === 0) || data.Result.error.code === "-1") {
                return;
            }

            setShowMessageSiac(true);
        }


        const bodyFormatted = {
            parameters: {
                appId: config.appId,
                type: "check_access",
                userName: authCtx.username,
                service: "BIBLIOTECA24",
                fingerPrint: fingerPrint
            },
            token: token,
            encryptParams: ["parameters.userName"]
        };

        const rqst = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.manageSiacSession,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST",
            }
        };

        sendRqst.sendRequest(rqst, manageResponseSiac, null, null, "manageSiacSession");

    }, [callManageSIACSession, fingerPrint, token]);

    const tokenInfo = (token) => {

        const responseTokenInfo = (data) => {
            if (data.StatusCode === 200){
                setToken(localStorage.getItem("token"));
            }else{
                refreshToken();
            }
        }

        const bodyFormatted = {
            parameters: {},
            token: token
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getTokenInfo,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST",
            }
        };

        sendRqst.sendRequest(request, responseTokenInfo, false, null, "tokenInfo")
    };

    const refreshToken = () => {
        if (fingerPrint === null) return;
        const refreshedToken = (data) => {

            if ((data.StatusCode === 200 && Object.keys(data.Result).length === 0) || data.Result.error.code === "-1")
                return;

            logoutToken();
            authCtx.onLogout();
        }

        const bodyFormatted = {
            parameters: {
                appId: config.appId,
                type: "refresh",
                userName: authCtx.username,
                service: "BIBLIOTECA24",
                fingerPrint: fingerPrint
            },
            token: token,
            encryptParams: ["parameters.userName"]
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.manageSiacSession,
            req: {
                headers: {
                    'Content-Type': 'application/json',
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST",
            }
        };

        sendRqst.sendRequest(request, refreshedToken, false, null, "manageSiacSession")
    };

    const logoutToken = () => {
        setToken(null);
        setIsSubscribed(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userModules");
        localStorage.removeItem("userModulesWithId");
        localStorage.removeItem("userType");
    };

    const setIPConfig = (ip) => {
        setIpAddress(ip);
    }

    const closeMessageManageSiacConfig = (data) => {
        setShowMessageSiac(data);
    }

    const setSelectedValueTokenConfig = (modulesSelected) => {
        setSelectedValuesToken(modulesSelected);
    }

    useEffect(() => {
        let callLoadDynamicToken = setTimeout(() => {
            if (modules.length > 0 && authCtx.isLoggedIn) {
                loadDynamicToken();
            }

        }, 100);

        return () => {
            clearTimeout(callLoadDynamicToken);
        };
    }, [modules]);

    useEffect(() => {

        if (authCtx.isSoleAuth === undefined)
            logoutToken();

    }, [authCtx.isSoleAuth]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            tokenInfo(localStorage.getItem("token"));
        }

        return () => {};
    }, [localStorage.getItem("token")]);

    useEffect(() => {

        let refreshingToken = setInterval(() => {

            if (localStorage.getItem("isLoggedIn") && authCtx.isLoggedIn && localStorage.getItem("token") && token !== null) {
                refreshToken();
            }

        }, 60000);

        return () => {
            clearTimeout(refreshingToken);
        }
    });

    useEffect(() => {

        if (userModules.length > 0) {
            setIsSubscribed(true);
            return;
        }

        setIsSubscribed(false);

    }, [userModules]);

    useEffect(() => { })

    const contextValues = {
        loadProduct: loadProductConfig,
        loadProductFromToken: loadProductConfigFromToken,
        loadDynToken: loadDynamicToken,
        logoutToken: logoutToken,
        setIpConf: setIPConfig,
        closeMessageManageSiac: closeMessageManageSiacConfig,
        setSelectedValuesToken: setSelectedValueTokenConfig,
        modulesComplete: modulesComplete,
        allmodules: modules,
        allProvvedimenti: provvedimenti,
        userModules: userModules,
        userModulesWithId: userModulesWithId,
        isSubscribed,
        allAssocModPac: associazioneModuliPacchetti,
        arricchimenti: arricchimenti,
        token: token,
        showMessageSiac: showMessageSiac,
        userType: userType,
        selectedValuesToken: selectedValuesToken
    };

    return <TokenContext.Provider value={contextValues}>{props.children}</TokenContext.Provider>
}

export default TokenContext;