import React from 'react'
import BDExplorerList from './BDExplorerList'
import BDSearch from './BDSearch'

const BDExplorer = (props) => {
    return (
        <React.Fragment>
            <div className='col-lg-8 bdindex-explorer'>
            {
                props.documentsByLeaf && props.documentsByLeaf.length>0 &&
                    <div className="bdindex-scroll">
                        {/*<BDSearch/>*/}
                        <BDExplorerList levels={props.levels} documentsByLeaf={props.documentsByLeaf}/>
                    </div>
                
            }
            {
                (!props.documentsByLeaf || props.documentsByLeaf.length===0) &&
                <div class="bdindex-empty">
                    <img className="img-fluid" src="../../../dist/img/indice.png" alt=""/>
                </div>
            }
            </div>
        </React.Fragment>
    )
}

export default BDExplorer