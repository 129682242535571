import React, { useState } from "react";

const ColorObject = (props) => {

    const [isActive, setIsActive] = useState(false);

    const filteringByColor = (ev) => {

        const color = ev.currentTarget.dataset.value;

        setIsActive(!isActive);

        props.filteringByColor(color);
    }


    return (
        <React.Fragment>
            <li className="list-inline-item">
                <a className={`${props.filter.className} ${isActive ? 'is-active' : ''}`} role="button" data-value={props.filter.value} onClick={filteringByColor}>
                    <span className="icon icon-check"></span>
                </a>
            </li>
        </React.Fragment>
    )
}

export default ColorObject;