import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BDExplorer from '../components/BancheDati/BDExplorer';
import BDNavbar from '../components/BancheDati/BDNavbar';
import BDTree from '../components/BancheDati/BDTree';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import HeaderReader from '../components/HeaderReader/HeaderReader';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { config } from '../constants/Constants';
import { bodyFormattedGetTipoView, bodyFormattedReader, chooseBody, chooseDocument, chooseURL, FormattedDocumentForFE, viewContent } from '../helper/helperFunctionReader';
import useHttpRequest from '../hooks/http-request';
import useSaveAndDeleteReadingBook from '../hooks/saveAndDeleteReadingBook';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import TreeContext from '../store/context-tree';
import GetDocumentContext from '../store/get-documento';
import isLoadingContext from '../store/isLoadingcontext';
import TokenContext from '../store/token-context';

const BDIndex = (props) => {

    const { id, title } = useParams();
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const isLoadingCtx = useContext(isLoadingContext);
    const documentCtx = useContext(GetDocumentContext);
    const treeCtx = useContext(TreeContext);
    const sendRqst = useHttpRequest();
    const navigate = useNavigate();

    const [nestedLevels, setNestedLevels] = useState(null);
    const [documentsByLeaf, setDocumentsByLeaf] = useState(null);
    const [tipoDocument, setTipoDocument] = useState('');
    const saveDeleteB = useSaveAndDeleteReadingBook();
    const [documentReturns, setDocumentReturns] = useState({});
    const [navBarList, setNavBarList] = useState([]);

    useEffect(() => {
        setNestedLevels(treeCtx.array);
        setNavBarList(treeCtx.navBar);

    }, [treeCtx.navBar, treeCtx.array]);

    const getDocumentsByLeaf = (documentsLeaf) => {
        setDocumentsByLeaf(documentsLeaf);
    }

    useEffect(() => {

        setDocumentsByLeaf(treeCtx.newLeafArray);

    }, [treeCtx.newLeafArray]);

    useEffect(() => {

        let getProduct = setTimeout(() => {
            documentCtx.getDocumentoFromId(id);
        }, 200);

        return () => {
            clearTimeout(getProduct);
        };
    }, [id]);

    useEffect(() => {

        isLoadingCtx.setIfIsLoading(true);

        const tipoDocument = (data) => {

            let typeDocument = null;

            if (data.Result.ResultSet.Root === null) {
                setTipoDocument(typeDocument);
                return;
            }

            const getTipoV = data.Result.ResultSet.Root[0];
            typeDocument = chooseDocument(getTipoV.tipo);

            setTipoDocument(typeDocument);
            const showPremiumContent = viewContent(getTipoV, tokenCtx);
            loadDocument(typeDocument, getTipoV.pdfurl, showPremiumContent, getTipoV.comprato);
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedGetTipoView(config.nameList.tipoDocument, id, tokenCtx)),
                method: "POST"
            }
        };

        sendRqst.sendRequest(request, tipoDocument);

    }, [id, tokenCtx.token]);

    useEffect(() => {

        if (tokenCtx.token === null || tokenCtx.token === undefined) {
            navigate('/')
        }

    }, [tokenCtx])

    const testataHtml = (id, dataRes, typeDocument, showPremiumContent) => {

        const setTestataDocumentHtml = (data) => {

            let document = data.Result.ResultSet;
            if (document === null) {
                isLoadingCtx.setIfIsLoading(false);
                return;
            }
            document = document.Root[0];

            document = {
                Section: document.sezione,
                ChapterTitle: document.titolo,
                Date: document.data,
                page: document.pagina,
                Author: document.autore,
                TaxType: document.titolo,
                Immagine: document.immagine
            };

            document = { ...document, ...dataRes };
            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent)
            setDocumentReturns(res);
            saveDeleteB.setSaveBook(config.nameList.leggendo, { prop1: parseInt(res.idProvv), prop2: parseInt(res.idDocType), prop3: parseInt(res.id) }, showPremiumContent);
        };

        const bodyFormatted = bodyFormattedReader(id, config.nameList.getTestataHtml, tokenCtx);

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, setTestataDocumentHtml);
    };

    const loadDocument = (typeDocument, pdfUrl, showPremiumContent, isComprato) => {

        const setDocumentJson = (data) => {

            let document = {};

            if (typeDocument !== 'Sistema Frizzera') {
                testataHtml(data.Result.DocumentId, data.Result, typeDocument, showPremiumContent);
                return;
            }

            document = data.Result;

            const res = FormattedDocumentForFE(document, typeDocument, showPremiumContent);
            setDocumentReturns(res);
        };

        const bodyFormatted = chooseBody(typeDocument, id, tokenCtx.token, isComprato);

        if (bodyFormatted === null)
            return;

        const url = chooseURL(typeDocument);

        const request = {
            url: url,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: "POST"
            }
        }

        sendRqst.sendRequest(request, setDocumentJson);
    };

    document.body.classList.remove("is-drawer-in");
    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                <HeaderReader page='summary'
                    title={documentReturns.title_book}
                    tipoDocument={tipoDocument}
                    img={documentReturns.img}
                    isReader={true}
                    idDoc={documentReturns.id}
                    idProv={documentReturns.idProvv}
                    idDocType={documentReturns.idDocType}
                    isIntegrateInBD={props.isIntegrateInBD}
                />

                <div className="main-content ">
                    <BDNavbar navBarList={navBarList} />

                    <div className="bdindex" data-toggle-class-target>
                        <div className="container-fluid">
                            <div className="row">
                                <BDTree getDocumentsByLeaf={getDocumentsByLeaf} navBarList={navBarList} />
                                <BDExplorer levels={nestedLevels} documentsByLeaf={documentsByLeaf} />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca isReader={true}
                    img={documentReturns.img}
                    title={documentReturns.title_book}
                    tipoDocument={tipoDocument}
                    id={id}
                    idProvvedimento={documentReturns.idProvv}
                    idDocType={documentReturns.idDocType} />
            </Wrapper>
        </React.Fragment>

    )
}

export default BDIndex