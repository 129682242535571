import React from 'react'
import NoteSingle from './NoteSingle';

const NoteList = (props) => {

    const editNote = (editedNote) => {
        props.editNote(editedNote)
    };

    const deleteNote = (noteToDelete) => {
        props.deleteNote(noteToDelete);
    }

    return (
        <ul className="list list--large note-list">
            {props.noteList && props.noteList.map((note, index) => (
                <NoteSingle note={note}
                    idDocumento={props.idDocumento}
                    key={Math.random()}
                    mostUsedTag={props.mostUsedTag}
                    editNote={editNote}
                    deleteNote={deleteNote}
                />
            ))}
        </ul>
    )
}

export default NoteList