import React from "react";
import { Multiselect } from 'multiselect-react-dropdown';


const GenericDropdownList = (props) => {

    const addElement = (selectedItem) => {
        props.addElement(selectedItem);
    }

    const removeElement = (removedItem) => {
        props.removeElement(removedItem);
    }

    return (
        <React.Fragment>
            <Multiselect
                options={props.dropdownlist}
                selectedValues={props.ArraySelectedOptions}
                singleSelect={props.singleSelect}
                showCheckbox={props.showCheckbox}
                showArrow={true}
                displayValue="name"
                onSelect={addElement}
                onRemove={removeElement}
            />
        </React.Fragment>
    )
}

export default GenericDropdownList;