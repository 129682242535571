import React from 'react';
import { NavLink } from 'react-router-dom';
import UserAction from '../UserAction/UserAction';

const HeaderFirstRow = (props) => {

    return (
        <React.Fragment>
            <div className="mhead-top" style={{display: props.isIntegrateInBD ? 'none' : 'block' }}>
                <div className="container">
                    <div className="mhead-wrapper">
                        <div className="mhead-nav nav-lined nav-lined--logo">
                            <NavLink className="nav-link d-none d-md-block" to="/">
                                <img className="nav-logo" src="/dist/img/ilsole24ore.svg" alt="il Sole 24 ORE" />
                            </NavLink>
                        </div>
                        <UserAction></UserAction>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderFirstRow;