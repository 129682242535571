import React from 'react';
import { NavLink } from 'react-router-dom';

const BookPreview = (props) => {

    return (
        <>
            <figure className="card-img ">
                <a role="button">
                    <span className="card-img-book">
                        <img src={props.img} alt="" />
                    </span>
                </a>
            </figure>
            {props.node === undefined && !props.isBuyed &&
                <NavLink className="btn btn--black btn--big btn--full btn--shadow" to={``}>
                    PAC. VEN. NON. DEF.
                </NavLink>
            }
            {props.node !== undefined && !props.isBuyed &&
                <NavLink className="btn btn--black btn--big btn--full btn--shadow" to={`/pacchetto/${props.node}`}>
                    Acquista
                </NavLink>
            }
        </>
    );
}

export default BookPreview;