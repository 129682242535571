import React from "react";
import SchedaProdotto from "../SchedaProdotto/SchedaProdotto";
import SchedaProdottoAreaListaTematica from "./SchedaProdottoAreaListaTematica/SchedaProdottoAreaListaTematica";
import SchedaProdottoAreaListaTematicaWrapper from "./SchedaProdottoAreaListaTematicaWrapper/SchedaProdottoAreaListaTematicaWrapper";

const SchedaProdottoAreaListaTematicaList = (props) => {

    return (
        <React.Fragment>
            <SchedaProdottoAreaListaTematicaWrapper>
                <div className="card-list">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                        {props.allProductsAbbo.length > 0 &&
                            props.allProductsAbbo.map((prod, index) => (
                                <SchedaProdotto
                                    key={Math.random()}
                                    idProduct={prod.id}
                                    idForDelete={prod.idForDelete}
                                    isNew={prod.isNew}
                                    isUpdating={prod.isUpdating}
                                    title={prod.title}
                                    img={prod.img === '' ? "" : prod.img}
                                    listAutori={prod.auth}
                                    themeArea={prod.themeArea}
                                    isManageable={false}
                                    hasBookmark={false}
                                    isSubscription={true}
                                    classNameCard={"card card--book"}
                                    isAbbonamento={true}
                                    deleteProductList={[]}
                                    titleContainer={"lista"}
                                ></SchedaProdotto>
                            ))
                        }
                    </div>
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                        {props.allProducts.map(product => (
                            <SchedaProdottoAreaListaTematica
                                key={product.id}
                                product={product}
                                titleContainer={"lista"}
                            />
                        ))}
                    </div>
                </div>
            </SchedaProdottoAreaListaTematicaWrapper>
        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematicaList;