import React from 'react';
import { NavLink } from 'react-router-dom';

const ReaderHeadGoTo = (props) => {

    const goTo = props.littleSumm;

    return (
        <>
            {goTo.length > 0 && 
                <ul className="list-inline list-inline--large reader-head-nav">
                    <li className="list-inline-item">
                        <span className="reader-head-nav-label">
                            Prossimi capitoli: 
                        </span>
                    </li>
                    {goTo.map((item, index) => (
                        <li className="list-inline-item" key={index}>
                            <React.Fragment>
                                <NavLink className="reader-head-nav-link" to={"/reader/" + item.idDoc}>
                                    {item.text}
                                </NavLink>
                                {item?.isUpdated &&
                                    <a className="page-label" role={"button"}>
                                        Aggiornato
                                    </a>
                                }
                            </React.Fragment>
                        </li>
                    ))}
                </ul>
            }
        </>
    );
}

export default ReaderHeadGoTo;