import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SearchForType from './SearchForType';
import styles from "./SearchForType.module.css";

const SearchBar = (props) => {

    //const wordToSearch = useRef();
    const { isFromAnotherPage } = useParams();
    const [wordToSearch, setWordToSearch] = useState(null);
    const defaultVal = localStorage.getItem("word") === undefined || props.isBack !== "1" ? "" : localStorage.getItem("word");
    const searchError = (props.testo === '' || isFromAnotherPage === 'daMieiLibri');

    const wordHandler = (ev) => {

        if (ev.key === 'Enter') addNewWord(ev);

        setWordToSearch(ev.target.value);
    }

    const addNewWord = (ev) => {

        ev.preventDefault();

        const newWord = wordToSearch.trim();

        if (newWord !== undefined && newWord !== localStorage.getItem("word")) {

            props.addNewWord(newWord.trim());
        }

    };

    const searchForCategory = (searchForCategory) => {
        props.searchForCategory(searchForCategory);
    }

    useEffect(() => {

        if (localStorage.getItem("word"))
            setWordToSearch(localStorage.getItem("word"));
        else if (localStorage.getItem("word") === undefined)
            setWordToSearch("");

    }, [isFromAnotherPage]);

    const addSearchPanelIn = (ev) => {
        
        ev.preventDefault();

        if (document.body.className.includes('is-search-panel-in')) {
            document.body.classList.remove('is-search-panel-in');
            return;
        }

        document.body.classList.add("is-search-panel-in");
    }

    return (
        <React.Fragment>
            <div className="search-head">
                <div className="search-multi">
                    <div className="search-multi-box">
                        <label className="search-label">Cerca</label>
                        <input className="input search-multi-input" type="text" placeholder={props.placeHolder}
                            defaultValue={defaultVal}
                            onKeyUp={wordHandler}
                            /*ref={wordToSearch}*/ />
                    </div>
                    {window.location.pathname !== "/mie-note" &&
                        <div className={`search-multi-box d-none d-lg-block`} /*${searchError ? styles.disabled : ""}*/ >
                            <SearchForType searchForCategory={searchForCategory} /*searchError={searchError}*/></SearchForType>
                        </div>
                    }
                    <a className="itool d-lg-none" role={"button"} /*data-toggle-class="is-search-panel-in"*/ onClick={addSearchPanelIn}>
                        <span className="icon icon-filter"></span>
                    </a>
                    <button className="btn btn--big btn--round btn--black btn--icon-left d-none d-lg-block" type="submit" onClick={addNewWord}>
                        Cerca <span className="icon icon-search"></span>
                    </button>
                    <button className="itool itool--black d-lg-none" type="submit" onClick={addNewWord}>
                        <span className="icon icon-search"></span>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SearchBar;