import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const SearchForTypeForMob = (props) => {

    const [isActive, setIsActive] = useState(1);

    const { isFromAnotherPage } = useParams();

    const returnRightChoice = (searchForCategory) => {

        switch (searchForCategory) {
            case "Tutto":
                setIsActive(1);
                break;
            case "Testo":
                setIsActive(2);
                break;
            case "Titolo":
                setIsActive(3);
                break;
            case "Autore":
                setIsActive(4);
                break;
            default:
                setIsActive(2);
                break;
        }
    };

    const changeResearch = (ev) => {

        const searchForCategory = ev.target.getAttribute('value');

        returnRightChoice(searchForCategory);

        localStorage.setItem("search category", searchForCategory);
        props.searchForCategory(searchForCategory);
    };


    return (
        <React.Fragment>
            <div className="search-side d-lg-none">
                <h4 className="search-side-title">Cerca in</h4>

                <ul className="list list--large" >

                    <li className="list-item">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" name="cerca-in" id="area_tutto" value="Tutto"
                                checked={isActive === 1}
                                disabled={props.searchError || isFromAnotherPage === 'daMieiLibri'}
                                onClick={changeResearch}
                            />
                            <label className="custom-control-label" htmlFor="area_tutto">
                                Tutto
                            </label>
                        </div>
                    </li>

                    <li className="list-item">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" name="cerca-in" id="area_testo" value="Testo"
                                checked={isActive === 2}
                                disabled={props.searchError || isFromAnotherPage === 'daMieiLibri'}
                                onClick={changeResearch}
                            />
                            <label className="custom-control-label" htmlFor="area_testo">
                                Testo
                            </label>
                        </div>
                    </li>

                    <li className="list-item">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" name="cerca-in" id="area_titolo" value="Titolo"
                                checked={isActive === 3}
                                disabled={props.searchError || isFromAnotherPage === 'daMieiLibri'}
                                onClick={changeResearch}
                            />
                            <label className="custom-control-label" htmlFor="area_titolo">
                                Titolo
                            </label>
                        </div>
                    </li>

                    <li className="list-item">
                        <div className="custom-control custom-radio">
                            <input type="radio" className="custom-control-input" name="cerca-in" id="area_autore" value="Autore"
                                checked={isActive === 4}
                                disabled={props.searchError || isFromAnotherPage === 'daMieiLibri'}
                                onClick={changeResearch}
                            />
                            <label className="custom-control-label" htmlFor="area_autore">
                                Autore
                            </label>
                        </div>
                    </li>

                </ul>
            </div>
        </React.Fragment>
    )
}

export default SearchForTypeForMob;