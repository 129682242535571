import { useCallback, useContext } from "react";
import { useState } from "react";
import { config } from "../constants/Constants";
import AuthContext from "../store/auth-context";
import isLoadingContext from "../store/isLoadingcontext";
import TokenContext from "../store/token-context";

const useHttpRequest = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);
    const isLoadingCtx = useContext(isLoadingContext);

    const sendRequest = useCallback(async (requestConf, genericFunction, isCredential, isLogin, from) => {
        setError(null);
        const requestObjBase = {
            method: requestConf.req.method,
            body: requestConf.req.body,
            headers: requestConf.req.headers
        };

        const requestObjWithCred = {
            ...requestObjBase,
            credentials: 'include'
        };

        const response = await fetch(requestConf.url, isCredential ? requestObjWithCred : requestObjBase);
        try {
            //const response = await fetch(requestConf.url, isCredential ? requestObjWithCred : requestObjBase);
            if (response.ok && response.redirected)
                return genericFunction(requestConf.url);

            const data = await response.json();
            if (data.StatusCode === 401 && data.Exception.Message === "token scaduto o non valido!" && (localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined)) {
                authCtx.onLogout();
            } else if (data.StatusCode === 500 && data.Exception.Message === "Authentication Exception." && authCtx.isLoggedIn) {
                authCtx.onLogout();
            }/*else if (data.StatusCode === 500 && data.Exception.ExceptionType === "AuthenticationException" && data.Exception.Message.indexOf("CodeStatus: 1002") !== -1) {
                window.location.reload(false);
            }*/ else if (data.StatusCode === 500 && data.Exception.Message === "token scaduto o non valido!" && authCtx.isSoleAuth) {
                tokenCtx.loadDynToken();
            }else if(data.StatusCode === 500 && data.Exception.Message === "token scaduto o non valido!" && authCtx.isLoggedIn && !authCtx.isSoleAuth){
                localStorage.setItem("showModalSessione", true);
            } else {
                genericFunction(data);
            }

        } catch (error) {
            if(from === "manageSiacSession" || from === "tokenInfo"){
                localStorage.removeItem("token");
                window.location.reload(false);
            }

            isLoadingCtx.setIfIsLoading(false, 0);
            setError(error.message || 'Something went wrong');
            return error.message.toString();
        }
        finally {
            //isLoadingCtx.setIfIsLoading(false);
        }
    }, []);

    return {
        isLoading: isLoading,
        error: error,
        sendRequest: sendRequest
    };
}

export default useHttpRequest;