import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import DropdownMenuScroll from "./DropdownMenuScroll";
import ListNotes from "./DropdownMenuScrollItems/ListNotes";

const NotesNotification = (props) => {

    const searchWord = useRef(null);
    const [itemsFiltered, setItemsFiltered] = useState(props.notes.note);    
    const [notificationShow, setNotificationShow] = useState(false);

    const filterByWord = (ev) => {
        ev.preventDefault();

        const result = props.notes.filter(val => {
            return val.underlinedText.toLowerCase().includes(searchWord.current.value.toLowerCase()) ||
                val.text.toLowerCase().includes(searchWord.current.value.toLowerCase())
        });

        setItemsFiltered(result);
    }

    const item = {
        name: 'Note',
        placeholder: 'Cerca tra le mie note',
        spanIcon: 'icon icon-note',
        link: {
            class: 'card-search-goto',
            path: '/mie-note',
            content: 'Gestisci note',
            spanIcon: 'icon icon-arrow-right-small'
        },
        titleNote: props.notes.documentTitle,
        dropdownList: props.notes
    };

    useEffect(() => {

        setItemsFiltered(props.notes)
    }, [props.notes])

    return (
        <React.Fragment>
            <div className={`dropdown ${notificationShow ? "show" : ""}`}>
                <a className={`itool ${props.isFree ? 'is-disabled' : ''}`} role={"button"} data-toggle="dropdown" onClick={(ev) => {ev.preventDefault(); setNotificationShow(prev => !prev);}}>
                    <span className={item.spanIcon}></span>
                </a>
                <div className={`dropdown-menu dropdown-menu-right ${notificationShow ? "show" : ""}`}>
                    <div className="dropdown-menu-head">
                        <div className="dropdown-menu-head-top">
                            <span className="dropdown-menu-head-title">{item.name}</span>
                            {item?.link &&
                                <NavLink className={item.link.class} to={item.link.path}>
                                    {item.link.content}
                                    <span className={item.link.spanIcon}></span>
                                </NavLink>
                            }
                        </div>
                        <div className="search-input">
                            <input className="input input--round input--shadow" type="text" placeholder={item.placeholder} ref={searchWord} onChange={filterByWord} />
                            {/*<button className="search-input-submit" type="submit" onClick={filterByWord}>
                                <span className="icon icon-search"></span>
                        </button>*/}
                        </div>
                    </div>
                    {itemsFiltered?.length > 0 &&
                        <DropdownMenuScroll option={item.name}>
                            {itemsFiltered && itemsFiltered.map((item, index) => (
                                <ListNotes item={item} titleNote={item.titleNote} key={Math.random()}></ListNotes>
                            ))}
                        </DropdownMenuScroll>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default NotesNotification;