import React from 'react';

const ReaderHead = (props) => {

    return(
        <div className="reader-head" data-page-header>
            <div className="container">
                <div className="reader-container">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default ReaderHead;