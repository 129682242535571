import React from "react";

const Toast = (props) => {

    const closedSaved = () => {
        //props.handleSaved({name:"close"})
        document.body.classList.remove('is-toast-in');
    }

    return (
        <React.Fragment>
            <div className={"toastn " + props.classHeader}>
                <span className={"icon " + props.iconBody}></span>
                {props.message}
                <a className="toastn-close" role="button" data-toggle-class="is-toast-in" onClick={closedSaved}>
                    <span className="icon icon-cross-small"></span>
                </a>
            </div>
        </React.Fragment>
    )
}

export default Toast;