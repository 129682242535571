import React from 'react';

const CarouselNavigator = () => {
    return (
        <React.Fragment>
            <nav className="carousel-nav d-none d-md-block">
                <a className="carousel-btn carousel-btn--prev hide"><span className="icon icon-arrow-left"></span></a>
                <a className="carousel-btn carousel-btn--next hide"><span className="icon icon-arrow-right"></span></a>
            </nav>
        </React.Fragment>
    );
}

export default CarouselNavigator;