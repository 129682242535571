import React, { useContext, useRef } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { config } from "../../../constants/Constants";
import { convertArrayForFEBuyOrNot } from "../../../helper/castingObject";
import { bodyFormattedAcquista } from "../../../helper/helperResearch";
import useHttpRequest from "../../../hooks/http-request";
import isLoadingContext from "../../../store/isLoadingcontext";
import TokenContext from "../../../store/token-context";
import Book24Link from "../../UI/Book24Link/Book24Link";
import MainDrawer from "./MainDrawer";

const Ricerca = (props) => {

    const [isActiveHambMenu, setIsActiveHambMenu] = useState(false)
    const searchPlaceholder = props.isReader ? 'Cerca nel libro' : 'Cerca in Book24ORE';
    const wordToSearch = useRef(null);
    const [resultBuyOrNot, setResultBuyOrNot] = useState(null);
    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);
    const loadingCtx = useContext(isLoadingContext);

    const img = props.img === undefined || props.img === "" ? config.imgDefaultLibro : props.img;

    const navigate = useNavigate();

    const getBuyOrNot = (ev) => {

        if (ev.key !== 'Enter') return;

        const buyOrNot = (data) => {

            const buyOrNot = convertArrayForFEBuyOrNot(data.Result.ResultSet);


            goTo(buyOrNot.node);
            //setResultResearch(arrayToReturn);
            //isLoadingCtx.setIfIsLoading(false, 400);
        };

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getProdotti),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedAcquista(props.idProvvedimento, props.idDocType, tokenCtx))
            }
        };

        sendRqst.sendRequest(request, buyOrNot);
    };

    const goTo = (node) => {

        localStorage.setItem("img", img);
        localStorage.setItem("word", wordToSearch.current);
        localStorage.setItem("node", node);
        localStorage.setItem("isReader", true);
        localStorage.setItem("idDocument", props.idDoc)

        navigate(`/ricerca/level2/${props.idProvvedimento}/${props.testo}/${props.title}/${props.tipoDocument === "Sistema Frizzera" ? 1 : 0}`);
    }

    const goToResearchByEnter = (ev) => {
        
        //ev.preventDefault();
        if(ev.key !== "Enter") return;

        const newWord = wordToSearch.current.value;

        if (newWord !== undefined) {
            localStorage.setItem('word', newWord);
            navigate(`/ricerca/${1}`);
        }
        document.body.classList.remove("is-drawer-in");
    }

    const goToResearchByClick = (ev) => {

        if(ev.button !== 0) return;

        const newWord = wordToSearch.current.value;

        if (newWord !== undefined) {
            localStorage.setItem('word', newWord);
            navigate(`/ricerca/${1}`);
        }
        document.body.classList.remove("is-drawer-in");
    }

    const removeMenu = (ev) => {
        ev.preventDefault();

        document.body.classList.remove("is-drawer-in");
    }

    return (
        <React.Fragment>
            <div className={`drawer ${props.isReader ? 'drawer--book' : ''}`}>
                <div className="drawer-head">
                    <Book24Link /*settings={{ reloadDocument: true }}*/ />

                    <a className="itool" role="button" data-toggle-class="is-drawer-in" onClick={removeMenu}>
                        <span className="icon icon-cross"></span>
                    </a>
                </div>

                {props.isReader && props.id &&
                    <>
                        <div className="drawer-book mhead-reader">
                            <figure className="card-img ">
                                <NavLink to={"/reader/" + props.id}>
                                    <span className="card-img-book">
                                        <img src={img} alt="" />
                                    </span>
                                </NavLink>
                            </figure>
                            <NavLink to={"/reader/" + props.id}>
                                <span className="drawer-book-title mhead-reader-title">
                                    {props.title}
                                </span>
                            </NavLink>
                        </div>

                        <div className="drawer-search">
                            <div className="search-input">
                                <input className="input input--round input--shadow" type="text" placeholder={searchPlaceholder} onKeyDown={getBuyOrNot} ref={wordToSearch} />
                                <button className="search-input-submit" type="submit" onClick={getBuyOrNot}>
                                    <span className="icon icon-search"></span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {!props.isReader &&
                    <div className="drawer-search">
                        <div className="search-input">
                            <input className="input input--round input--shadow" type="text" placeholder={searchPlaceholder} onKeyDown={goToResearchByEnter} ref={wordToSearch} />
                            <button className="search-input-submit" type="submit" onClick={goToResearchByClick}>
                                <span className="icon icon-search"></span>
                            </button>
                        </div>
                    </div>
                }

                {props.tipoDocument !== null &&
                    <MainDrawer
                        isReader={props.isReader}
                        tipoDocument={props.tipoDocument}
                        id={props.id}
                        idProvvedimento={props.idProvvedimento}
                        idDocType={props.idDocType}
                        title={props.title}
                    />
                }

                <div className="drawer-foot">
                    <NavLink className="nav-link" to="/" reloadDocument>
                        <img className="nav-logo" src="/dist/img/ilsole24ore.svg" alt="il Sole 24 ORE" />
                    </NavLink>
                </div>
            </div>
            <span className="drawer-backdrop" data-toggle-class="is-drawer-in"/*{isActiveHambMenu ? "is-drawer-in": ""} onClick={setIsActiveHambMenu(prev => !prev)}*/></span>
        </React.Fragment>
    );
}

export default Ricerca;