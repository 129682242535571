import React, { useContext, useEffect, useState } from 'react';
import { config } from '../../constants/Constants';
import ProgressBar from '../UI/ProgressBar/ProgressBar';
import HeaderReaderGoTo from './HeaderReaderGoTo';
import HeaderRightIcon from './HeaderRightIcon';
import AuthContext from '../../store/auth-context';
import Profilo from '../Header/UserAction/Profilo/Profilo';
import useGetAndManageBookMarksAndNotes from '../../hooks/getAndManageBookmarksAndNotes';
import useManangeNoteAndTagOnReader from '../../hooks/useManageNoteAndTagOnReader';
import BookmarksNotification from './BookmarksNotification';
import NotesNotification from './NotesNotification';
import TokenContext from '../../store/token-context';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import useHttpRequest from '../../hooks/http-request';
import isLoadingContext from '../../store/isLoadingcontext';
import { convertArrayForFEBuyOrNot } from '../../helper/castingObject';
import { bodyFormattedAcquista } from '../../helper/helperResearch';
import { titleUrlSlug } from '../../helper/helper';
import GoHome from '../UI/GoHome/GoHome';

const HeaderReader = (props) => {

    const [isActiveHambMenu, setIsActiveHambMenu] = useState(false);

    const authCtx = useContext(AuthContext);
    const wordToSearch = useRef(null);
    const [resultBuyOrNot, setResultBuyOrNot] = useState(null);
    const [clickButton, setClickButton] = useState(false);
    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);
    const loadingCtx = useContext(isLoadingContext);
    const location = useLocation();

    const img = props.img === undefined || props.img === "" ? config.imgDefaultLibro : props.img;
    const searchPlaceholder = props.isReader ? 'Cerca nel libro' : 'Cerca in Book24ORE';
    const title = props.title;

    const navigate = useNavigate();

    const getBuyOrNot = (ev) => {

        if (ev.key !== 'Enter' && ev.button !== 0) return;
        //loadingCtx.setIfIsLoadingConfig(true);

        const buyOrNot = (data) => {

            const buyOrNot = convertArrayForFEBuyOrNot(data.Result.ResultSet.Root);

            goTo(buyOrNot[0].node);
            //setResultResearch(arrayToReturn);
            //isLoadingCtx.setIfIsLoading(false, 400);
        };

        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getProdotti),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedAcquista(props.idProv, props.idDocType, tokenCtx))
            }
        };

        sendRqst.sendRequest(request, buyOrNot);
    };

    const goTo = (node) => {

        localStorage.setItem("img", img);
        localStorage.setItem("word", wordToSearch.current.value);
        localStorage.setItem("node", node);
        localStorage.setItem("isReader", true);
        localStorage.setItem("id", props.idDoc);
        //loadingCtx.setIfIsLoadingConfig(false, 0);

        navigate(`/ricerca/level2/${props.idProv}/${wordToSearch.current.value}/${titleUrlSlug(props.title)}/${props.tipoDocument === "Sistema Frizzera" ? 1 : 0}`);
    }

    const showLogin = () => {
        if (!authCtx.isLoggedIn)
            authCtx.showModalLog(true);
    };

    const openHambMenu = (ev) => {
        ev.preventDefault();

        document.body.classList.add("is-drawer-in")
    }

    const setClick = (ev) => {
        ev.preventDefault();
        setClickButton(prev => !prev);
    };

    return (
        <>
            <div className="mhead mhead--sticky mhead--reader" data-main-header>
            <div className="mhead-top d-none d-print-block" >
                <div className="container-fluid">
                    <div className="mhead-wrapper">
                        <img className="nav-logo nav-logo--main" src="/dist/img/book24ore.svg" alt="Book24ORE" />
                    </div>
                </div>
            </div>
                <div className="mhead-top" >
                    <div className="container-fluid">
                        <div className="mhead-wrapper">

                            {/* Header left */}
                            <div className="mhead-nav mhead-reader">
                                <figure className="card-img ">
                                    { props.idDoc &&
                                    <NavLink to={"/reader/" + props.idDoc}>
                                        <span className="card-img-book">
                                            <img src={img} alt="" />
                                        </span>
                                    </NavLink>
                                    }
                                </figure>
                                <a className="itool" role="button" data-toggle-class="is-drawer-in" onClick={openHambMenu}/*{isActiveHambMenu ? "is-drawer-in": ""} onClick={setIsActiveHambMenu(prev => !prev)}*/>
                                    <span className="icon icon-index"></span>
                                </a>
                                <span className="mhead-reader-title d-none d-md-block d-print-block">
                                    {title}
                                </span>
                            </div>

                            {/* Header right */}
                            <div className="mhead-nav">
                                <ul className="list-inline list-inline--large">
                                    {props.tipoDocument !== "pdf" &&
                                        <li className="list-inline-item d-none d-lg-block">
                                            <div className="search-input">
                                                <input className="input input--round input--shadow" type="text" placeholder={searchPlaceholder} onKeyDown={getBuyOrNot} ref={wordToSearch} />
                                                <button className="search-input-submit" type="submit" onClick={getBuyOrNot}>
                                                    <span className="icon icon-search"></span>
                                                </button>
                                            </div>
                                        </li>
                                    }

                                    {/* Bookmarks & Notes */}
                                    {authCtx.isLoggedIn &&
                                        <HeaderRightIcon isFree={props.isFree} bookmarks={props.bookmarks} notes={props.notes} tipoDocument={props.tipoDocument} isIntegrateInBD={props.isIntegrateInBD} notesForMenu={props.notesForMenu}/>
                                    }

                                    {!authCtx.isLoggedIn &&
                                        <>
                                            <GoHome></GoHome>
                                            <a className="btn btn--icon-left btn--icon-big" href="#modal-login" data-toggle="modal" onClick={showLogin}>
                                                Accedi
                                                <span className="icon icon-user"></span>
                                            </a>
                                        </>
                                    }
                                    {authCtx.isLoggedIn &&
                                        <li className="list-inline-item" onClick={setClick}>
                                            <Profilo clickButton={clickButton} isIntegrateInBD={props.isIntegrateInBD}></Profilo>
                                        </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {props.page !== 'research' && location.pathname.split("/")[1] === "reader" &&
                    <ProgressBar />
                }
            </div>

            {props.page !== 'research' && props.tipoDocument !== 'pdf' &&
                <HeaderReaderGoTo page={props.page} littleSumm={props.littleSumm} />
            }

        </>
    );
}

export default HeaderReader;