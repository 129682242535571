import React from 'react';
import styles from './Loader.module.css';

const Loader = (themeArea) => {

    let divClass = '';

    switch (themeArea.themeArea) {
        case 'Fisco':
            divClass = 'fis'
            break;
        case 'Diritto':
            divClass = 'dir'
            break;
        case 'Lavoro':
            divClass = 'lav'
            break;
        case 'PA':
            divClass = 'pa'
            break;
        case 'Tecnici':
            divClass = 'tec'
            break;
        default:
            break;
    }

    return(
        <React.Fragment>
            <div className={styles.ldsEllipsis}>
                <div className={styles[divClass]}></div>
                <div className={styles[divClass]}></div>
                <div className={styles[divClass]}></div>
                <div className={styles[divClass]}></div>
            </div>
        </React.Fragment>
    );
}

export default Loader;