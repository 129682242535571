import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import FaqCard from '../components/FaqCard/FaqCard';
import FaqListItem from '../components/FaqListItem/FaqListItem';
import FaqWrapper from '../components/FaqWrapper/FaqWrapper';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Ricerca from '../components/Header/Ricerca/Ricerca';
import Wrapper from '../components/Helper/Wrapper/Wrapper';
import LoginModal from '../components/Modals/Login';
import MessageManageSiac from '../components/Modals/MessageManageSiac';
import { config } from '../constants/Constants';
import { updateView } from '../helper/TealiumHelper';
import useManageBodyClasses from '../hooks/useManageBodyClasses';
import AuthContext from '../store/auth-context';
import TokenContext from '../store/token-context';
import SessioneScaduta from "../components/Modals/SessioneScaduta"

const FAQ = (props) => {

    const [showSessione, setShowSessione] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);

    const faqCards = [
        { img: config.imgFaq1 === "" ? config.imgDefault3 : config.imgFaq1, title: 'Ricerca avanzata', text: 'Puoi cercare i contenuti di tuo interesse in tutto il catalogo o limitare la ricerca ai libri del tuo abbonamento o al singolo libro. Inoltre, puoi  cercare per titolo, per autore o in tutti i contenuti.' },
        { img: config.imgFaq2 === "" ? config.imgDefault3 : config.imgFaq2, title: 'Volumi organizzati per area tematica', text: 'Naviga tra le aree tematiche (Fisco e imprese, Diritto, Lavoro, Tecnici, Pubblica amministrazione) e i relativi pacchetti di abbonamento  (Manuali, Guide pratiche, fascicoli di attualità, Punto lavoro, ecc.) per trovare l\'offerta più adatta alle tue esigenze.' },
        { img: config.imgFaq3 === "" ? config.imgDefault3 : config.imgFaq3, title: 'Esperienza di lettura', text: 'Nei libri di Book24 potrai sottolineare, aggiungere note, ridimensionare il testo e molto altro per un’esperienza di lettura più adatta a te.' }
    ];

    const faqs = [
        {
            q: "E' possibile visualizzare una guida alle funzionalità del prodotto?",
            a: "Le consigliamo il video tutorial al seguente link: <a href='https://youtu.be/lFzKxwmEbEg' target='_blank'>https://youtu.be/lFzKxwmEbEg</a>"
        },
        {
            q: "Come faccio a sapere di quali volumi è composto un pacchetto di abbonamento?",
            a: "Basta andare nella pagina relativa al pacchetto di abbonamento per visualizzare tutti i volumi che, fino a quel momento, sono stati pubblicati e integrati nel pacchetto."
        },
        {
            q: "I volumi presenti in un pacchetto di abbonamento cambiano nel tempo?",
            a: "Sì. I pacchetti sono aggiornati con i nuovi volumi pubblicati, in base ai criteri definiti dall'Editore."
        },
        {
            q: "Book24ORE è un sito di e-commerce o una piattaforma di consultazione?",
            a: "Entrambi. Book24ORE è un catalogo on line relativo a tutte le novità editoriali per il mondo professionale: commercialisti, avvocati, consulenti del lavoro, professionisti tecnici, dipendenti e funzionari della pubblica amministrazione. È possibile consultare l'indice, la descrizione, il profilo autorale, e visualizzare in anteprima alcune porzioni del testo, oltre a effettuare l'acquisto dei pacchetti in abbonamento. <br /> Book24ORE è anche una piattaforma di consultazione dei volumi acquistati, accessibile da tutti i device. Oltre ai testi, sarà possibile accedere a contenuti integrativi e di arricchimento, ove previsto dall'Editore."
        },
        {
            q: "Come faccio a sapere quali libri fanno parte della mia biblioteca?",
            a: "È sufficiente andare nella sezione 'I miei libri' dopo aver effettuato la login. L'area mostrerà tutti i volumi che rientrano nel proprio abbonamento, oltre a quelli acquistati o attivati singolarmente."
        },
        {
            q: "In quali formati sono consultabili i volumi su Book24ORE?",
            a: "Book24ORE gestisce pubblicazioni in diversi formati: pdf, html a edizione e html in aggiornamento continuo."
        },
        {
            q: "Come distinguo i volumi 'in aggiornamento' da quelli a 'edizione'?",
            a: "I primi riportano l'indicazione 'data di aggiornamento' accanto alla data; i secondi, l'indicazione 'data di pubblicazione'."
        },
        {
            q: "Cosa caratterizza i volumi 'in aggiornamento'?",
            a: "Book24ORE permette di annotare, taggare, evidenziare i testi, oltre a inserire i segnalibri. Sarà inoltre possibile effettuare una ricerca all'interno delle note, dei tag e dei segnalibri, sia all'interno del singolo volume che all'interno di tutti i volumi del proprio abbonamento. <br /> Queste funzionalità sono possibili esclusivamente sui volumi in html a edizione. <br /> È inoltre possibile copiare porzioni di testo su tutti i formati."
        },
        {
            q: "Ho acquistato un volume con QRcode. Come faccio ad accedere alla versione on line?",
            a: "Basta accedere alla sezione «Attiva un libro» nel menu in alto a destra e inquadrare il QR Code, dopo aver abilitato - se necessario - la camera del PC/Mobile. <br /> Per accedere al servizio, è necessario essere registrato ai servizi del Sole 24 Ore. Una volta completata la registrazione (in caso di nuovo utente) o inserite le credenziali (per i già registrati), sarà possibile accedere alla versione digitale del libro e a eventuali contenuti aggiuntivi accedendo all'area «I miei libri» del sito Book24ORE da qualsiasi device."
        }
    ];

    useEffect(() => {
        document.body.classList.remove('is-drawer-in');
    }, []);

    useEffect(() => {

        if (window.utag === null || window.utag === undefined) return;

        updateView("book24ore:faq", authCtx, tokenCtx, "book24ore:faq");

    }, [tokenCtx.token, authCtx.isLoggedIn, window.utag]);

    useEffect(() => {
        setShowSessione(localStorage.getItem("showModalSessione") ? true : false);
    });
    useManageBodyClasses();

    return (
        <React.Fragment>
            <Wrapper>
                <Header showSecondRow={false} classSticky={"sticky"} isIntegrateInBD={props.isIntegrateInBD}></Header>
                {authCtx.showLoginModal && <LoginModal show={authCtx.showLoginModal}></LoginModal>}
                {tokenCtx.showMessageSiac && <MessageManageSiac show={tokenCtx.showMessageSiac}></MessageManageSiac>}
                {showSessione && <SessioneScaduta show={showSessione} closeModal={(dismiss) => { setShowModal(dismiss.showModal) }}></SessioneScaduta>}
                <div className="main-content ">
                    <FaqWrapper>
                        <h1 className="faqpage-title">
                            Scopri Book<span className="faqpage-title-light">24ORE</span>
                        </h1>
                        <p className="faqpage-text">
                            Book24ORE è la nuova biblioteca professionale del Sole 24 ORE. La piattaforma raccoglie l'ampio patrimonio di pubblicazioni - manuali, guide pratiche, instant - edito dal Sole 24 ORE per il mondo professionale.
                        </p>
                        <div className="faqpage-feature">
                            <div className="row">
                                {faqCards && faqCards.map((card, index) => (
                                    <FaqCard card={card} key={index} />
                                ))}
                            </div>
                        </div>
                    </FaqWrapper>

                    <FaqWrapper isLight={true}>
                        <h2 className="faqpage-title faqpage-title--small">
                            Hai delle domande? Abbiamo delle risposte
                        </h2>
                        <p className="faqpage-text">
                            Continuiamo a migliorare le funzionalità che offriamo ai nostri lettori e abbonati, per fornire loro un servizio sempre pronto a rispondere alle loro esigenze.
                        </p>
                        <div className="faqpage-list">
                            <ul className="list list--mid">
                                {faqs && faqs.map((faq, index) => (
                                    <FaqListItem faq={faq} index={index} key={index} />
                                ))}
                            </ul>
                        </div>
                    </FaqWrapper>
                </div>

                <Footer isIntegrateInBD={props.isIntegrateInBD}></Footer>
                <Ricerca></Ricerca>
            </Wrapper>
        </React.Fragment>
    )
}

export default FAQ