import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../../../constants/Constants";
import useHttpRequest from "../../../../../hooks/http-request";
import TokenContext from "../../../../../store/token-context";
import AuthContext from "../../../../../store/auth-context";

const Filters = (props) => {

    const { isFromAnotherPage } = useParams();
    const [isUpdated, setIsUpdated] = useState(false);
    const [myBook, setMyBook] = useState(isFromAnotherPage === "daMieiLibri");
    const [listYear, setListYear] = useState(["tutti"]);
    const [selected, setSelected] = useState("");
    const authCtx = useContext(AuthContext);
    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);

    const searchError = (props.resultResearch === null || props.testo === '');

    const addOrRemoveFilter = (event) => {


        if (event.target.value === "isUpdated") {
            setIsUpdated(!isUpdated);
        } else if (event.target.value === "MyBook") {
            setMyBook(!myBook);
        }

        props.addOrRemoveFilter(event.target.value);
    };

    const changeYear = (event) => {

        const sel = event.target.value;
        setSelected(sel);
        props.changeYear(sel);
    }

    useEffect(() => {

        if (props.isBack !== "1" && localStorage.getItem("mantieniIFiltri") === null) return;

        localStorage.setItem("MyBook", isFromAnotherPage === "daMieiLibri");
        if (localStorage.getItem("MyBook") === "true")
            setMyBook(true);
        if (localStorage.getItem("isUpdated") === "true")
            setIsUpdated(true);
        if(localStorage.getItem("year"))
            setSelected(localStorage.getItem("year"));

    }, []);

    useEffect(() => {
        if (props.isBack !== "1" && localStorage.getItem("mantieniIFiltri") === null) return;

        setMyBook(localStorage.getItem("MyBook") === "true");
        setIsUpdated(localStorage.getItem("isUpdated") === "true");
        setSelected(localStorage.getItem("year"))
    }, [isFromAnotherPage]);

    useEffect(() => {

        if (props.clear) {
            setIsUpdated(false);
            setMyBook(false);
        }

        props.changeClear(false);
    }, [props.clear]);


    useEffect(() => {

        if (tokenCtx.token === undefined || tokenCtx.token === null || listYear.length > 1) return;

        const settingsYears = (data) => {

            if (Object.keys(data.Result).length === 0 || data.Result.ResultSet === null) return;

            let array = [];

            data.Result.ResultSet.Root.forEach(el => {

                if (el !== null && el.anno !== "1900")
                    array.push(el.anno);
            });

            setListYear(["tutti", ...array]);
        };

        const body = {
            "parameters": {
                "name": config.nameList.getAnni,
                "staticToken": ""
            },
            "token": tokenCtx.token
        }

        const rqst = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(body)
            }
        };

        sendRqst.sendRequest(rqst, settingsYears)

    }, [tokenCtx.token]);

    return (
        <React.Fragment>
            <div className="search-side">
                <h4 className="search-side-title">Filtra</h4>
                <ul className="list list--large">
                    <li className="list-item">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="switch-updating" value='isUpdated' onClick={addOrRemoveFilter} checked={isUpdated} disabled={searchError && isFromAnotherPage !== 'daMieiLibri'} />
                            <label className="custom-control-label" htmlFor="switch-updating">Solo libri in aggiornamento</label>
                        </div>
                    </li>
                    {props.isLoggedIn &&
                        <>
                            <li className="list-item">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="switch-my" value='MyBook' onClick={addOrRemoveFilter} checked={myBook} disabled={searchError || isFromAnotherPage === 'daMieiLibri'} />
                                    <label className="custom-control-label" htmlFor="switch-my">Solo tra i miei libri</label>
                                </div>
                            </li>
                            <li className="list-item">
                                <div className="custom-select">
                                    <select className="custom-select-input" onChange={changeYear} disabled={searchError && isFromAnotherPage !== "daMieiLibri"} value={selected}>
                                        {listYear.map(year => (
                                            <option key={Math.random()} value={year}>{year}</option>
                                        ))}
                                    </select>
                                    <label className="custom-select-label">Anno di pubblicazione</label>
                                </div>
                            </li>
                        </>
                    }
                </ul>
            </div>
        </React.Fragment>
    );
}

export default Filters;