import React, { useState } from "react";

const isLoadingContext = React.createContext({
    isLoading: false
});

export const IsLoadingContextProvider = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    //const [isLockedLoading, setIsLockedLoading] = useState("");

    const setIfIsLoadingConfig = (props, timeout) => {
        if (props && !isLoading) {
            setIsLoading(true);
            return;
        }

        finishedLoading(timeout);
    };

    const finishedLoading = (timeout) => {

        let delay = setTimeout(() => {
            setIsLoading(false);
        }, timeout);

        return () => {
            clearTimeout(delay);
        };
    };

    const ctxValues = {
        isLoading: isLoading,
        setIfIsLoading: setIfIsLoadingConfig
    };

    return <isLoadingContext.Provider value={ctxValues}>{props.children}</isLoadingContext.Provider>
};

export default isLoadingContext;