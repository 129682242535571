import React from "react";
import { NavLink } from "react-router-dom";
import CarouselNavigator from "../../UI/CarouselNavigator/CarouselNavigator";

const ProdottiWrapper = (props) => {

    const next = () => {
        props.next();
    };

    const previous = () => {
        props.previous();
    };

    return (
        <React.Fragment>
            <section className={`${"section"} ${props.sectionClassName}`}>
                <div className="container">
                    <div className="carousel-wrapper">
                        <div className="section-head">
                            <h2 className="section-title">{props.title}</h2>

                            {props.seeAll && 
                                <NavLink className="btn btn--all btn--icon-right" to="/ricerca/daMieiLibri">
                                    Vedi tutti <span className="icon icon-arrow-right"></span>
                                </NavLink>
                            }

                            {!props.seeAll && <CarouselNavigator previous={previous} next={next}></CarouselNavigator>}
                        </div>

                        {props.children}

                    </div>

                </div>
            </section>
        </React.Fragment>
    );

}

export default ProdottiWrapper;