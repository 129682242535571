import React from 'react'
import BDNavbarElement from './BDNavbarElement'

const BDNavbar = (props) => {

    const listNav = props.navBarList;

    return (
        <div className="bdindex-nav">
            <div className="container-fluid">
                <ul className="list-inline bdindex-nav-list">
                    {listNav !== null && listNav !== undefined &&
                        listNav.map(el => (
                            <BDNavbarElement el={el} key={Math.random()}></BDNavbarElement>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default BDNavbar