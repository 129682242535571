import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../../constants/Constants";
import { titleUrlSlug } from "../../../../helper/helper";
import { updateView } from "../../../../helper/TealiumHelper";
import AuthContext from "../../../../store/auth-context";
import GetDocumentContext from "../../../../store/get-documento";
import TokenContext from "../../../../store/token-context";
import Paragraph from "../../../UI/Paragraph/Paragraph";


const SchedaAutori = (props) => {

    const [autori, setAutori] = useState([]);

    const documentoCtx = useContext(GetDocumentContext);
    const authCtx = useContext(AuthContext);
    const tokenCtx = useContext(TokenContext);

    let { id } = useParams();


    useEffect(() => {

        if (documentoCtx.autori.length > 0)
            setAutori(documentoCtx.autori);
        else
            setAutori([])

    }, [documentoCtx.autori]);

    useEffect(() => {
        let getProduct = setTimeout(() => {
            documentoCtx.getDocumentoFromId(id);
            documentoCtx.getList(config.nameList.tiPotrebbeInteressare, id);
        }, 200);

        return () => {
            clearTimeout(getProduct);
        };
    }, [id]);

    useEffect(() => {

        if(window.utag === null || window.utag === undefined) return;

        updateView(`book24ore:prodotto:${titleUrlSlug(documentoCtx.prodotto.title.toLowerCase())}:autori`,authCtx, tokenCtx, "book24ore:prodotto");
                
    },[tokenCtx.token, authCtx.isLoggedIn, window.utag]);

    return (
        <React.Fragment>
            <ul className="list-lined list-lined--large">
                {documentoCtx.autori.length > 0 && documentoCtx.autori.map((auth, index) => (
                    <li className="list-lined-item" key={index}>
                        <div className="page-authbox">
                            <Paragraph
                                title={auth.auth}
                                classTitle="page-subtitle"
                                text={auth.bio}
                                classPar={"page-txt"}
                                img={auth.img}
                                viewImg={true}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}

export default SchedaAutori;