import { config } from "../constants/Constants";
import { mustToken } from "./helper";

export const createArrayInputParams = (id) => {

    return [{
        name: "iddocumento",
        type: "Int",
        value: id
    }];
};

export const bodyFormatted = (name, id, tokenCtx, useDynamicToken=false) => {

    return {
        parameters: {
            name: name,
            inputParameters: createArrayInputParams(id),
            staticToken: useDynamicToken ? '' : config.token.STATIC_TOKEN,
        },
        token: useDynamicToken ? tokenCtx.token : '',
    };

};

export const bodyFormattedRelDoc = (docId, rows, tokenCtx) => {
    return {
        parameters: {
            documentId: docId,
            rows: rows,
            staticToken: tokenCtx ? '' : config.token.STATIC_TOKEN,
        },
        token: tokenCtx ? tokenCtx : ''
    };
};

export const bodyFormattedRefDoc = (docId, correlati, tokenCtx) => {
    return {
        parameters: {
            idDocumento: docId,
            correlati: correlati
        },
        token: tokenCtx ? tokenCtx : ''
    };
};
export const bodyFormattedSumm = (ind_summ, node_indx_summ, tokenCtx, name) => {

    let wantDynamicToken = name === undefined ? '' : name;
    return {
        parameters: {
            packages: "",
            taxId: ind_summ,
            taxParentId: node_indx_summ,
            leafDocuments: false,
            use_pcpa: true,
            start: 0,
            rows: 9999,
            staticToken: mustToken(wantDynamicToken) ? '' : config.token.STATIC_TOKEN,
        },
        token: mustToken(wantDynamicToken) ? tokenCtx.token : ''
    };
};

export const bodyFormattedLeafDocuments = (ind_summ, node_indx_summ, tokenCtx, name) => {

    let wantDynamicToken = name === undefined ? '' : name;
    return {
        parameters: {
            taxId: ind_summ,
            nodeId: node_indx_summ,
            start: 0,
            rows: 9999,
            staticToken: mustToken(wantDynamicToken) ? '' : config.token.STATIC_TOKEN,
        },
        token: mustToken(wantDynamicToken) ? tokenCtx.token : ''
    };
};

export const bodyFormattedSummSmart = (idProv, iddoctype, tokenCtx, withDynamicToken) => {

    return {
        parameters: {
            provvId: idProv,
            docTypeId: iddoctype,
            full: true,
            staticToken: withDynamicToken ? '' : config.token.STATIC_TOKEN,
        },
        token: withDynamicToken ? tokenCtx.token : ''
    };
}

export const getRequestSumm = (id, type_summ, ind_summ, node_indx_summ, idProv, iddoctype, tokenCtx, withDynamicToken) => {

    if (type_summ === config.serviceToCall.getTaxonomy.split("/")[1]) {
        return {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getTaxonomy,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedSumm(ind_summ, node_indx_summ, tokenCtx)),
                method: "POST"
            }
        };
    } else if (type_summ === config.serviceToCall.getSummarySmart.split("/")[1]) {
        return {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getSummarySmart,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormattedSummSmart(idProv, iddoctype, tokenCtx, withDynamicToken)),
                method: "POST"
            }
        };
    }
}

export const castArrayForFE = (array) => {

    let castedArray = [];

    array.forEach((el) => {
        castedArray.push({
            id: el.Id,
            name: el.Description,
            parent: el.Parent === null || el.Parent === undefined ? '0' : el.Parent.toString(),
            children: [],
            leaf: el.Leaf,
            documentId: el.Leaf ? el.DocumentId : '',
            livello: el.Leaf ? 3 : el.Parent !== null && el.Parent !== undefined && el.Parent !== 0 ? 2 : 1,
        });
    });

    return castedArray;
};

export const formattedLeafArrayForFE = (array) => {
    
    let arrayCastedForFE= []

    array.forEach(el => {

        arrayCastedForFE.push({
            idDoc: el.idDocumento,
            idProv: el.idProvvedimento,
            idDocType: el.idDocType,
            upTitle: el.text1,
            object: el.text2,
            text: el.abstract,
            family: el.famiglia,
            subFamily: el.sottoFamiglia
        });
    })

    return arrayCastedForFE;
};