const prod = {
    extraModules: false,
    socialLoginUrl: "https://du.ilsole24ore.com/utenti/AuthFiles/SocialLogin/DUSocialLoginJS.ashx",
    logout: "https://du.ilsole24ore.com/utenti/AuthFiles/LogOff.aspx?RURL=https://book24ore.ilsole24ore.com",
    scriptTealium: "//tags.tiqcdn.com/utag/ilsole24ore/book24ore/prod/utag.sync.js",
    urlSmart: "https://smartfisco24.ilsole24ore.com/private/default.aspx",
    url: {
        API_URL_SITO: 'https://book24ore.ilsole24ore.com/',
        API_URL_LOGIN_CENTRALE: 'https://api24.ilsole24ore.com/Users/userLogin?',
        API_URL_PROFILO: 'https://api24.ilsole24ore.com/Web/SSOLogin.aspx?',
        API_URL_GETPROFILO: 'https://api24.ilsole24ore.com/Users/userLoginSSO?',
        BACKEND_SERVICE: 'https://dwa.ilsole24ore.com/oth/api/',
        QRCODE_SERVICE: 'https://jsonorb.ilsole24ore.com/joplus/api/'
    },
    token: {
        STATIC_TOKEN: 'geZgfRZH2lUZJikdwXpJpoqSgWc+g82ndGHPFHoF0Sq6+7Q/9FyDD5Til+p8KaVIRoGq728W7RmunqNjcLYWSc0oczLZvf1Iu4Frk+JsoIbN/v9bxfNvBNzvgBSZmCsAQHX+WDwTyr3qXGNI3FIxta7cYW2+rie9GxrR/JdgkPMG9MU4/eqTErHCtrsWP9tD7aZCV7Eh7ebIFKK6l24hgbmE58D6vgHF9wiqyMzAGMpp1Q5Ya4kWCVSI4Uxi3MWMdQBesNw/y6OGQzHubLqdDOAsBTIfg0WkUpwlNuGZuwunDkBwjlQChLoo0rUCjxtRX1jcX8tGu9patEMYY3EAzqIAa3qOk8s8gzzpjjnwsQATsGq7/TNBI/70aNNO04l7vpIPQSo/ELGp7KIL9oBzKYn2hI8oT1Cc4Fr+U1Q+gwQgPZujQ0r3xqeNPdsmzDN1XuRXPMCGi3LOUfksgCHanW+ssy2IviGknfqGUbio6g5g/BjbOVSOmohXXLFOhqRv'
    },
    serviceToCall: {
        configSection: 'BD.Common.BDCommonServiceREST.svc/GetConfigSection',
        configSectionNoToken: 'BD.Common.BDCommonServiceREST.svc/GetConfigSectionNoToken',        
        manageSiacSession: 'BD.USer.BDUserServiceREST.svc/ManageSiacSession',
        dynamicTokenExt: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExt',        
        dynamicTokenExtNoAcc: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExtNoAccountingLog',
        getProdotti: 'BD.Common.BDCommonServiceREST.svc/GetData',
        refreshToken: 'BD.User.BDUserServiceREST.svc/RefreshToken',
        find: 'JsonData.UserDataREST.svc/FindByQuery',
        delete: 'JsonData.UserDataREST.svc/Delete',
        countNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Count',
        getNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Get',
        updateStateNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Read',
        getDocumento: 'BD.Document.BDDocumentServiceREST.svc/GetDocument',
        getProdottiFrizzera: 'BD.Gpf.Document.BDGpfDocumentServiceREST.svc/GetDocument',
        getTaxonomy: 'BD.Taxonomy.BDTaxonomyServiceREST.svc/GetTaxonomy',
        getDocumentsByLeaf: 'BD.Document.BDDocumentServiceREST.svc/GetDocumentsByLeafListIndex',
        getSummarySmart: 'BD.Document.BDDocumentServiceREST.svc/GetSummarySmart',
        getResultSearch: 'BD.Search.BDSearchServiceREST.svc/PullSearch',
        save: 'JsonData.UserDataREST.svc/Save',
        update: 'JsonData.UserDataREST.svc/Update',
        qrCodeActivation: "BD.User.BDUserServiceREST.svc/AttivaContenutiDigitali",
        getUserType: "BD.User.BDUserServiceREST.svc/GetUserProfile",
        getTokenInfo: "BD.User.BDUserServiceREST.svc/GetTokenInfo",
        getDisposizioni: "BD.Document.BDDocumentServiceREST.svc/GetDisposizioni",
        getRelatedDocuments: "BD.Document.BDDocumentServiceREST.svc/GetRelatedDocuments",
        getCountReferences: "BD.Document.BDDocumentServiceREST.svc/GetCountReferences"
    },
    nameList: {
        strumentiProf: 'BIBLIOTECA24_Strumenti',
        ultimeUscite: 'BIBLIOTECA24_GetUltimeUscite',
        leggendo: 'biblioteca_leggendo',
        segnalibro: 'biblioteca_segnalibro',
        documentiConNote: 'biblioteca_segnati',
        note: 'biblioteca_note',
        continuaALegg: 'BIBLIOTECA24_GetLeggendo',
        proposteAbbon: 'BIBLIOTECA24_GetProposte',
        piuLetti: 'biblioteca_letto',
        listPiuLetti: 'BIBLIOTECA24_GetPiuLetti',
        tiPotrebbeInteressare: 'BIBLIOTECA24_GetInteressare',
        giaLetti: "biblioteca_gialetto",
        daLeggere: "BIBLIOTECA24_DaLeggere",
        tipoDocument: "BIBLIOTECA24_GetTipoViewer",
        getLittleSumm: "BIBLIOTECA24_GetSommarietto_Precedente_Successivo",
        getSchedaPac: "BIBLIOTECA24_GetSchedaPacchetto",
        getLibriPacc: "BIBLIOTECA24_GetLibriPacchetto",
        getLibriInteress: "BIBLIOTECA24_GetInteressarePacchetto",
        getTestataHtml: "BIBLIOTECA24_GetTestata_DocumentoHtml",
        getBuyOrNot: "BIBLIOTECA24_GetLibriAcquistare",
        getAggiornamentiReader: "Biblioteca24_GetAggiornamenti",
        getInformationBookmarks: "BIBLIOTECA24_Segnalibro",
        getBuyedOrNot: "BIBLIOTECA24_Comprato",
        getAnni: "BIBLIOTECA24_GetAnni",
        archivio: "BIBLIOTECA24_GetLibriRicerca",
        ricercaPerTitolo: "BIBLIOTECA24_GetLibriRicercaTitolo",
        getPacchetto: "BIBLIOTECA24_GetProposte",
        getDocumentoSommario: "BIBLIOTECA24_GetDocumentoSommario",
        getContenutiEsistentiQr: "BIBLIOTECA24_ContenutiDigitali_Esistenti",
        getAreeTematiche: "BIBLIOTECA24_GetAreeTematiche",
        getAggiungiPiuLetti: "BIBLIOTECA24_Aggiungi_PiuLetti",
        getNormaCommentata: "PlusSmart_GetCorrelati_NormaCommentata_Legge",       
    },
    serviceDocumenti: {
        documento: 'BIBLIOTECA24_GetDocumento',
        autori: 'BIBLIOTECA24_GetAutori',
        libroPdf: "BIBLIOTECA24_GetLibroPdf",
        normaCommentata: "Biblioteca24_GetCorrelati_NormaCommentata_Legge"
    },
    jsonorb: {
        jsonorb_apikey: '918704ec-4811-45b6-a169-16bae3df69a8',
        jsonorb_addCache: true
    },
    appKey: 'DroidQuot',
    appId: '4263',
    xpath: "/root",
    src_dst: {
        app_js: '/dist/js/app.js'
    },
    imgGenericOG: "https://book24ore.ilsole24ore.com/dist/img/og-generica.png",
    imgLink: "https://static.ilsole24ore.com/dda/",
    imgLinkPacchetti: "/dist/img/pacchetti/",
    imgDefaultPacch: "/dist/img/placeholder-pacchetto.png",
    imgDefaultLibro: "/dist/img/placeholder-libro.png",
    imgDefault1: "//via.placeholder.com/212x300",
    imgDefault2: "//via.placeholder.com/400x400",
    imgDefault3: "//via.placeholder.com/800x600",
    imgFaq1: "/dist/img/ricerca.png",
    imgFaq2: "/dist/img/organizza.png",
    imgFaq3: "/dist/img/lettura.png",
    imgAdv: "",
    rows: 50,
    domain: '.ilsole24ore.com',
    siteCode: 'CO',
    bioMancante: 'Biografia mancante',
    getPDF: 'GetPdf',
    Research: {
        queryExtFirstPiece: '-idprovpart:(232 230 231 233 240) ',
        queryExtIsUpdated: ' AND biblio_aggiornamento:(1)'
    },
    Zoom: {
        initialScale: 1,
        minScale: 0.8,
        maxScale: 1.2,
        initialPositionX: 0,
        initialPositionY: 0
    },
    linkToViewerntpro: ["https://viewerntpro.ilsole24ore.com/private/default.aspx?appid=4239&redirect=false&btnPrint=true&origine=","#showdoc/"],
    linkRegistration: "https://du.ilsole24ore.com/utenti/Registrazione.aspx?schema=Reg2013&Pers=home2013&Newsletters=DURegCO&&SiteCode=CN",
    forgotPassword: "https://du.ilsole24ore.com/utenti/passwordReset.aspx?RURL=",
    linkFooter: {
        datiSocietari: "https://www.gruppo24ore.ilsole24ore.com/it-it/institutional/dati-societari",
        websystem: "https://24oresystem.ilsole24ore.com/it/digital",
        informativaCookies: "https://du.ilsole24ore.com/utenti/privacyfiles/informativa_cookies.html",
        privacyPolicy: "https://du.ilsole24ore.com/utenti/privacyfiles/privacy_policy.html"
    },
    linkProfiloPersonale: "https://du.ilsole24ore.com/utenti/areautente/my24_ilmioprofilo.aspx",
    color: {
        seppia: ['s', '</span>'],
        yellow: ['y', '</span>'],
        pink: ['p', '</span>'],
        violet: ['v', '</span>']
    },
    filters: [
        {
            label: "Solo libri in aggiornamento",
            id: "notifiche_solo-libri-in-aggiornamento",
            dataValue: "libri_aggiornamento",
            checked: false
        },
        {
            label: "Nuovi libri aggiunti",
            id: "notifiche_nuovi-libri-aggiunti",
            dataValue: "libri_nuovi",
            checked: false
        }
    ],
    themeAreas: [
        {
            name: 'Fisco e imprese',
            id: 'FiscoEImprese',
            class: 'clr-fis',
            imgPath: '/dist/img/area-fisco.svg',
            link: '/Area/FiscoEImprese'
        },
        {
            name: 'Diritto',
            id: 'Diritto',
            class: 'clr-dir',
            imgPath: '/dist/img/area-diritto.svg',
            link: '/Area/Diritto'
        },
        {
            name: 'Lavoro',
            id: 'Lavoro',
            class: 'clr-lav',
            imgPath: '/dist/img/area-lavoro.svg',
            link: '/Area/Lavoro'
        },
        {
            name: 'PA',
            id: 'PA',
            class: 'clr-pa',
            imgPath: '/dist/img/area-pa.svg',
            link: '/Area/PA'
        },
        {
            name: 'Tecnici',
            id: 'Tecnici',
            class: 'clr-tec',
            imgPath: '/dist/img/area-tecnici.svg',
            link: '/Area/Tecnici'
        }
    ],
    types: ["nuovoLibro", "aggiornamentoFrizzera"],
    linkScopriDiPiu:"https://24oreprofessionale.ilsole24ore.com/prodotti/book24ore/",
    licenseKeyPdfExpress: '5SRuh9T7Fc3yiLyhIIHt',
    ip: 'https://api.ipify.org?format=json'
};

const staging = {    
    extraModules: false,
    socialLoginUrl: "http://duprovisioning.dlv.24orepro.in.ilsole24ore.it/Utenti/AuthFiles/SocialLogin/DUSocialLoginJS.ashx",
    logout: "http://duprovisioning.dlv.24orepro.in.ilsole24ore.it/Utenti/AuthFiles/LogOff.aspx?RURL=http://book24ore.dlv.24orepro.in.ilsole24ore.it/",
    scriptTealium: "//tags.tiqcdn.com/utag/ilsole24ore/book24ore/qa/utag.sync.js",
    urlSmart: "http://smart24fisco.dlv.24orepro.in.ilsole24ore.it/private/default.aspx",
    url: {
        API_URL_SITO: 'http://book24ore.dlv.24orepro.in.ilsole24ore.it/',
        API_URL_LOGIN_CENTRALE: 'http://api24.dlv.24orepro.in.ilsole24ore.it/Users/userLogin?',
        API_URL_PROFILO: 'http://api24.dlv.24orepro.in.ilsole24ore.it/Web/SSOLogin.aspx?',
        API_URL_GETPROFILO: 'http://api24.dlv.24orepro.in.ilsole24ore.it/Users/userLoginSSO?',
        BACKEND_SERVICE: 'http://dwa.dlv.24orepro.in.ilsole24ore.it/oth/api/',
        QRCODE_SERVICE: 'https://jsonorb1dlv.ilsole24ore.com/api/'
    },
    token: {
        STATIC_TOKEN: 'geZgfRZH2lUZJikdwXpJpmdoEqmcbGEU+9uBN305JKsuqT9jbeVO5hazLTNTeWPBEDBDXs7/ITKhPDJftdt4CVoAVyfjbW9ZlnAXbewoUNbrfsx9ZbfPUTI0ElX0bXh8fqEqlDCre4K0NlQT1NaFQS7OOGkx+up85FHVbwDS3DybxzE5KFwQMNmMRs2oJh+d6nqcnUgdjS+a81JNJ8sPt7jErPh67Gm4YSrfQbV+lK/7HkrkqQuFKHxyjt2f56+/ZEVRvijdZGlCrsZHaS8ctkJcZw+zGFXXV/fcQcp1tLaVWPAa1gAiZdatBesyL9LXsOLVkoOOtozgQC+V37g+t9RBsYOepRAFQjogXhf88pBj0adrCB9/jHVDsVbNtsYYGR1X6Soa9bwZFBlIc0Ntd2VStnaTQlSesSmQb3+zRgwLyIZnOY0nAH9I6xZCcHSWHVHrOfk1/SeW0L4nzyh5be3UuXsMvpW1OfLqpTvS5zbTbWlrMM9xzVWGJ2Rsi5vL'
    },
    serviceToCall: {
        configSection: 'BD.Common.BDCommonServiceREST.svc/GetConfigSection',
        configSectionNoToken: 'BD.Common.BDCommonServiceREST.svc/GetConfigSectionNoToken',
        manageSiacSession: 'BD.USer.BDUserServiceREST.svc/ManageSiacSession',
        dynamicTokenExt: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExt',
        dynamicTokenExtNoAcc: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExtNoAccountingLog',
        getProdotti: 'BD.Common.BDCommonServiceREST.svc/GetData',
        refreshToken: 'BD.User.BDUserServiceREST.svc/RefreshToken',
        find: 'JsonData.UserDataREST.svc/FindByQuery',
        delete: 'JsonData.UserDataREST.svc/Delete',
        countNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Count',
        getNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Get',
        updateStateNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Read',
        getDocumento: 'BD.Document.BDDocumentServiceREST.svc/GetDocument',
        getProdottiFrizzera: 'BD.Gpf.Document.BDGpfDocumentServiceREST.svc/GetDocument',
        getTaxonomy: 'BD.Taxonomy.BDTaxonomyServiceREST.svc/GetTaxonomy',
        getDocumentsByLeaf: 'BD.Document.BDDocumentServiceREST.svc/GetDocumentsByLeafListIndex',
        getSummarySmart: 'BD.Document.BDDocumentServiceREST.svc/GetSummarySmart',
        getResultSearch: 'BD.Search.BDSearchServiceREST.svc/PullSearch',
        save: 'JsonData.UserDataREST.svc/Save',
        update: 'JsonData.UserDataREST.svc/Update',
        qrCodeActivation: "BD.User.BDUserServiceREST.svc/AttivaContenutiDigitali",
        getUserType: "BD.User.BDUserServiceREST.svc/GetUserProfile",
        getTokenInfo: "BD.User.BDUserServiceREST.svc/GetTokenInfo",
        getDisposizioni: "BD.Document.BDDocumentServiceREST.svc/GetDisposizioni",
        getRelatedDocuments: "BD.Document.BDDocumentServiceREST.svc/GetRelatedDocuments",
        getCountReferences: "BD.Document.BDDocumentServiceREST.svc/GetCountReferences"
    },
    nameList: {
        strumentiProf: 'BIBLIOTECA24_Strumenti',
        ultimeUscite: 'BIBLIOTECA24_GetUltimeUscite',
        leggendo: 'biblioteca_leggendo',
        segnalibro: 'biblioteca_segnalibro',
        documentiConNote: 'biblioteca_segnati',
        note: 'biblioteca_note',
        continuaALegg: 'BIBLIOTECA24_GetLeggendo',
        proposteAbbon: 'BIBLIOTECA24_GetProposte',
        piuLetti: 'biblioteca_letto',
        listPiuLetti: 'BIBLIOTECA24_GetPiuLetti',
        tiPotrebbeInteressare: 'BIBLIOTECA24_GetInteressare',
        giaLetti: "biblioteca_gialetto",
        daLeggere: "BIBLIOTECA24_DaLeggere",
        tipoDocument: "BIBLIOTECA24_GetTipoViewer",
        getLittleSumm: "BIBLIOTECA24_GetSommarietto_Precedente_Successivo",
        getSchedaPac: "BIBLIOTECA24_GetSchedaPacchetto",
        getLibriPacc: "BIBLIOTECA24_GetLibriPacchetto",
        getLibriInteress: "BIBLIOTECA24_GetInteressarePacchetto",
        getTestataHtml: "BIBLIOTECA24_GetTestata_DocumentoHtml",
        getBuyOrNot: "BIBLIOTECA24_GetLibriAcquistare",
        getAggiornamentiReader: "Biblioteca24_GetAggiornamenti",
        getInformationBookmarks: "BIBLIOTECA24_Segnalibro",
        getBuyedOrNot: "BIBLIOTECA24_Comprato",
        getAnni: "BIBLIOTECA24_GetAnni",
        archivio: "BIBLIOTECA24_GetLibriRicerca",        
        ricercaPerTitolo: "BIBLIOTECA24_GetLibriRicercaTitolo",
        getPacchetto: "BIBLIOTECA24_GetProposte",
        getDocumentoSommario: "BIBLIOTECA24_GetDocumentoSommario",
        getContenutiEsistentiQr: "BIBLIOTECA24_ContenutiDigitali_Esistenti",
        getAreeTematiche: "BIBLIOTECA24_GetAreeTematiche",
        getAggiungiPiuLetti: "BIBLIOTECA24_Aggiungi_PiuLetti",
        getNormaCommentata: "PlusSmart_GetCorrelati_NormaCommentata_Legge"          
    },
    serviceDocumenti: {
        documento: 'BIBLIOTECA24_GetDocumento',
        autori: 'BIBLIOTECA24_GetAutori',
        libroPdf: "BIBLIOTECA24_GetLibroPdf",
        normaCommentata: "Biblioteca24_GetCorrelati_NormaCommentata_Legge"
    },
    jsonorb: {
        jsonorb_apikey: '918704ec-4811-45b6-a169-16bae3df69a8',
        jsonorb_addCache: true
    },
    appKey: 'DroidQuot',
    appId: '4263',
    xpath: "/root",
    src_dst: {
        app_js: '/dist/js/app.js'
    },
    imgGenericOG: "https://book24ore.ilsole24ore.com/dist/img/og-generica.png",
    imgLink: "https://static.ilsole24ore.com/dda/",
    imgLinkPacchetti: "/dist/img/pacchetti/",
    imgDefaultPacch: "/dist/img/placeholder-pacchetto.png",
    imgDefaultLibro: "/dist/img/placeholder-libro.png",
    imgDefault1: "//via.placeholder.com/212x300",
    imgDefault2: "//via.placeholder.com/400x400",
    imgDefault3: "//via.placeholder.com/800x600",
    imgFaq1: "/dist/img/ricerca.png",
    imgFaq2: "/dist/img/organizza.png",
    imgFaq3: "/dist/img/lettura.png",
    imgAdv: "",
    rows: 50,
    domain: '.dlv.24orepro.in.ilsole24ore.it',
    siteCode: 'CO',
    bioMancante: 'Biografia mancante',
    getPDF: 'GetPdf',
    Research: {
        queryExtFirstPiece: '-idprovpart:(232 230 231 233 240) ',
        queryExtIsUpdated: ' AND biblio_aggiornamento:(1)'
    },
    Zoom: {
        initialScale: 1,
        minScale: 0.8,
        maxScale: 1.2,
        initialPositionX: 0,
        initialPositionY: 0
    },
    linkToViewerntpro: ["http://viewerntpro.dlv.24orepro.in.ilsole24ore.it/private/default.aspx?appid=4239&redirect=false&btnPrint=true&origine=","#showdoc/"],
    linkRegistration: "https://du.ilsole24ore.com/utenti/Registrazione.aspx?schema=Reg2013&Pers=home2013&Newsletters=DURegCO&&SiteCode=CN",
    forgotPassword: "http://duprovisioning.dlv.24orepro.in.ilsole24ore.it/utenti/passwordReset.aspx?RURL=",
    linkFooter: {
        datiSocietari: "https://www.gruppo24ore.ilsole24ore.com/it-it/institutional/dati-societari",
        websystem: "https://24oresystem.ilsole24ore.com/it/digital",
        informativaCookies: "https://du.ilsole24ore.com/utenti/privacyfiles/informativa_cookies.html",
        privacyPolicy: "https://du.ilsole24ore.com/utenti/privacyfiles/privacy_policy.html"
    },
    linkProfiloPersonale: "http://duprovisioning.dlv.24orepro.in.ilsole24ore.it/Utenti/areautente/my24_ilmioprofilo.aspx",
    color: {
        seppia: ['s', '</span>'],
        yellow: ['y', '</span>'],
        pink: ['p', '</span>'],
        violet: ['v', '</span>']
    },
    filters: [
        {
            label: "Solo libri in aggiornamento",
            id: "notifiche_solo-libri-in-aggiornamento",
            dataValue: "libri_aggiornamento",
            checked: false
        },
        {
            label: "Nuovi libri aggiunti",
            id: "notifiche_nuovi-libri-aggiunti",
            dataValue: "libri_nuovi",
            checked: false
        }
    ],
    themeAreas: [
        {
            name: 'Fisco e imprese',
            id: 'FiscoEImprese',
            class: 'clr-fis',
            imgPath: '/dist/img/area-fisco.svg',
            link: '/Area/FiscoEImprese'
        },
        {
            name: 'Diritto',
            id: 'Diritto',
            class: 'clr-dir',
            imgPath: '/dist/img/area-diritto.svg',
            link: '/Area/Diritto'
        },
        {
            name: 'Lavoro',
            id: 'Lavoro',
            class: 'clr-lav',
            imgPath: '/dist/img/area-lavoro.svg',
            link: '/Area/Lavoro'
        },
        {
            name: 'PA',
            id: 'PA',
            class: 'clr-pa',
            imgPath: '/dist/img/area-pa.svg',
            link: '/Area/PA'
        },
        {
            name: 'Tecnici',
            id: 'Tecnici',
            class: 'clr-tec',
            imgPath: '/dist/img/area-tecnici.svg',
            link: '/Area/Tecnici'
        }
    ],
    types: ["nuovoLibro", "aggiornamentoFrizzera"],
    linkScopriDiPiu:"https://24oreprofessionale.ilsole24ore.com/prodotti/book24ore/",
    licenseKeyPdfExpress: 'S4r4e9vEMtgQ6IT4sX6q',
    ip: 'https://api.ipify.org?format=json'
};

const dev = {
    extraModules: true,
    socialLoginUrl: "http://duprovisioning.dev.24orepro.in.ilsole24ore.it/Utenti/AuthFiles/SocialLogin/DUSocialLoginJS.ashx",
    logout: "http://duprovisioning.dev.24orepro.in.ilsole24ore.it/Utenti/AuthFiles/LogOff.aspx?RURL=http://biblioteca24.dev.24orepro.in.ilsole24ore.it/",
    scriptTealium: "//tags.tiqcdn.com/utag/ilsole24ore/book24ore/qa/utag.sync.js",
    urlSmart: "http://smart24frizzera.dev.24orepro.in.ilsole24ore.it/private/default.aspx",
    url: {
        API_URL_SITO: 'http://biblioteca24.dev.24orepro.in.ilsole24ore.it/',
        API_URL_LOGIN_CENTRALE: 'http://api24.dev.24orepro.in.ilsole24ore.it/Users/userLogin?',
        API_URL_GETPROFILO: 'http://api24.dev.24orepro.in.ilsole24ore.it/Users/userLoginSSO?',
        API_URL_PROFILO: 'http://api24.dev.24orepro.in.ilsole24ore.it/Web/SSOLogin.aspx?',
        BACKEND_SERVICE: 'http://dwa.dev.24orepro.in.ilsole24ore.it/api/'
                       /*'http://jsonorb1.dev.24orepro.in.ilsole24ore.it/api/'*/,
        QRCODE_SERVICE: 'https://jsonorb1dlv.ilsole24ore.com/api/'
    },
    token: {
        STATIC_TOKEN: 'geZgfRZH2lUZJikdwXpJptTUaMfzIqGt4nyQ6zpXGYxS3+Y0fsVEL/rN8QX+wtJ9csV3w/y3jlAJP5b/SRft+IokUIJb09PLvFWTYZDULVwp9FwwhaQdm/jtmAy7oU0w+Qi4zxW+EeO9vCHnUgFauO3FUOzwXjEXji/yIxJFBH0Lhm1KtPWfsMH9MaCS/bLW/rwNxg27No7tVWt8kYbBi5hU/T2QjCNhjGUHLpT9Lz6rmjT3OvRhwjmIG2rIWqevszzt+TvsJmErbU4MeEtUWUldjK1tei400E6Q0kqZpQYIwMxEzIqwc6VNHtLhnfcjIGsCWDnEvHnCqrhrxZBggoUndwIqqS0h2Xr9RR1XHIVpufb3uqdP3+cA2OM3BsQ+eYLHRfO0bNmoKAVV31sYh9dFGoMghynBjhxiKUSEoDOPNmH6igwYJ1nmgUvwPswSVe9oop8L9mJWdrX60U/CQ88qw8iI3bvTooHAXq5A3dfPemEkssQ64/yhO3S8kor57FxmoNcFnp+kaXn/MBPWOkAu6JiOj1UqdL05KxaeKek='
    },
    serviceToCall: {
        configSection: 'BD.Common.BDCommonServiceREST.svc/GetConfigSection',
        configSectionNoToken: 'BD.Common.BDCommonServiceREST.svc/GetConfigSectionNoToken',
        manageSiacSession: 'BD.USer.BDUserServiceREST.svc/ManageSiacSession',
        dynamicTokenExt: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExt',
        dynamicTokenExtNoAcc: 'BD.USer.BDUserServiceREST.svc/GetDynamicTokenExtNoAccountingLog',
        getProdotti: 'BD.Common.BDCommonServiceREST.svc/GetData',
        refreshToken: 'BD.User.BDUserServiceREST.svc/RefreshToken',
        find: 'JsonData.UserDataREST.svc/FindByQuery',
        delete: 'JsonData.UserDataREST.svc/Delete',
        countNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Count',
        getNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Get',
        updateStateNotification: 'Fuoco.InBox.FE.MessageServiceREST.svc/Read',
        getDocumento: 'BD.Document.BDDocumentServiceREST.svc/GetDocument',
        getProdottiFrizzera: 'BD.Gpf.Document.BDGpfDocumentServiceREST.svc/GetDocument',
        getTaxonomy: 'BD.Taxonomy.BDTaxonomyServiceREST.svc/GetTaxonomy',
        getDocumentsByLeaf: 'BD.Document.BDDocumentServiceREST.svc/GetDocumentsByLeafListIndex',
        getSummarySmart: 'BD.Document.BDDocumentServiceREST.svc/GetSummarySmart',
        getResultSearch: 'BD.Search.BDSearchServiceREST.svc/PullSearch',
        save: 'JsonData.UserDataREST.svc/Save',
        update: 'JsonData.UserDataREST.svc/Update',
        qrCodeActivation: "BD.User.BDUserServiceREST.svc/AttivaContenutiDigitali",
        getUserType: "BD.User.BDUserServiceREST.svc/GetUserProfile",
        getTokenInfo: "BD.User.BDUserServiceREST.svc/GetTokenInfo",
        getDisposizioni: "BD.Document.BDDocumentServiceREST.svc/GetDisposizioni",
        getRelatedDocuments: "BD.Document.BDDocumentServiceREST.svc/GetRelatedDocuments",
        getCountReferences: "BD.Document.BDDocumentServiceREST.svc/GetCountReferences"
    },
    nameList: {
        strumentiProf: 'BIBLIOTECA24_Strumenti',
        ultimeUscite: 'BIBLIOTECA24_GetUltimeUscite',
        leggendo: 'biblioteca_leggendo',
        segnalibro: 'biblioteca_segnalibro',
        documentiConNote: 'biblioteca_segnati',
        note: 'biblioteca_note',
        continuaALegg: 'BIBLIOTECA24_GetLeggendo',
        proposteAbbon: 'BIBLIOTECA24_GetProposte',
        piuLetti: 'biblioteca_letto',
        listPiuLetti: 'BIBLIOTECA24_GetPiuLetti',
        tiPotrebbeInteressare: 'BIBLIOTECA24_GetInteressare',
        giaLetti: "biblioteca_gialetto",
        daLeggere: "BIBLIOTECA24_DaLeggere",
        tipoDocument: "BIBLIOTECA24_GetTipoViewer",
        getLittleSumm: "BIBLIOTECA24_GetSommarietto_Precedente_Successivo",
        getSchedaPac: "BIBLIOTECA24_GetSchedaPacchetto",
        getLibriPacc: "BIBLIOTECA24_GetLibriPacchetto",
        getLibriInteress: "BIBLIOTECA24_GetInteressarePacchetto",
        getTestataHtml: "BIBLIOTECA24_GetTestata_DocumentoHtml",
        getBuyOrNot: "BIBLIOTECA24_GetLibriAcquistare",
        getAggiornamentiReader: "Biblioteca24_GetAggiornamenti",
        getInformationBookmarks: "BIBLIOTECA24_Segnalibro",
        getBuyedOrNot: "BIBLIOTECA24_Comprato",
        getAnni: "BIBLIOTECA24_GetAnni",
        archivio: "BIBLIOTECA24_GetLibriRicerca",
        ricercaPerTitolo: "BIBLIOTECA24_GetLibriRicercaTitolo",
        getPacchetto: "BIBLIOTECA24_GetProposte",
        getDocumentoSommario: "BIBLIOTECA24_GetDocumentoSommario",
        getContenutiEsistentiQr: "BIBLIOTECA24_ContenutiDigitali_Esistenti",
        getAreeTematiche: "BIBLIOTECA24_GetAreeTematiche",
        getAggiungiPiuLetti: "BIBLIOTECA24_Aggiungi_PiuLetti",
        getNormaCommentata: "PlusSmart_GetCorrelati_NormaCommentata_Legge",           
    },
    serviceDocumenti: {
        documento: 'BIBLIOTECA24_GetDocumento',
        autori: 'BIBLIOTECA24_GetAutori',
        libroPdf: "BIBLIOTECA24_GetLibroPdf",
        normaCommentata: "Biblioteca24_GetCorrelati_NormaCommentata_Legge"
    },
    jsonorb: {
        jsonorb_apikey: '918704ec-4811-45b6-a169-16bae3df69a8',
        jsonorb_addCache: false
    },
    appKey: 'DroidQuot',
    appId: '4263',
    xpath: "/root",
    src_dst: {
        app_js: '/dist/js/app.js'
    },
    imgGenericOG: "https://book24ore.ilsole24ore.com/dist/img/og-generica.png",
    imgLink: "https://static.ilsole24ore.com/dda/",
    imgLinkPacchetti: "/dist/img/pacchetti/",
    imgDefaultPacch: "/dist/img/placeholder-pacchetto.png",
    imgDefaultLibro: "/dist/img/placeholder-libro.png",
    imgDefault1: "//via.placeholder.com/212x300",
    imgDefault2: "//via.placeholder.com/400x400",
    imgDefault3: "//via.placeholder.com/800x600",
    imgFaq1: "/dist/img/ricerca.png",
    imgFaq2: "/dist/img/organizza.png",
    imgFaq3: "/dist/img/lettura.png",
    imgAdv: "",
    rows: 50,
    domain: '.dev.24orepro.in.ilsole24ore.it',
    siteCode: 'CO',
    bioMancante: 'Biografia mancante',
    getPDF: 'GetPdf',
    Research: {
        queryExtFirstPiece: '-idprovpart:(232 230 231 233 240) ',
        queryExtIsUpdated: ' AND biblio_aggiornamento:(1)'
    },
    Zoom: {
        initialScale: 1,
        minScale: 0.8,
        maxScale: 1.2,
        initialPositionX: 0,
        initialPositionY: 0
    },
    linkToViewerntpro: ["http://viewerntpro.dev.24orepro.in.ilsole24ore.it/private/default.aspx?appid=4239&redirect=false&btnPrint=true&origine=", "#showdoc/"],
    linkRegistration: "https://du.ilsole24ore.com/utenti/Registrazione.aspx?schema=Reg2013&Pers=home2013&Newsletters=DURegCO&&SiteCode=CN",
    forgotPassword: "http://duprovisioning.dev.24orepro.in.ilsole24ore.it/utenti/passwordReset.aspx?RURL=",
    linkFooter: {
        datiSocietari: "https://www.gruppo24ore.ilsole24ore.com/it-it/institutional/dati-societari",
        websystem: "https://24oresystem.ilsole24ore.com/it/digital",
        informativaCookies: "https://du.ilsole24ore.com/utenti/privacyfiles/informativa_cookies.html",
        privacyPolicy: "https://du.ilsole24ore.com/utenti/privacyfiles/privacy_policy.html"
    },
    linkProfiloPersonale: "http://duprovisioning.dev.24orepro.in.ilsole24ore.it/Utenti/areautente/my24_ilmioprofilo.aspx",
    color: {
        seppia: ['s', '</span>'],
        yellow: ['y', '</span>'],
        pink: ['p', '</span>'],
        violet: ['v', '</span>']
    },
    filters: [
        {
            label: "Solo libri in aggiornamento",
            id: "notifiche_solo-libri-in-aggiornamento",
            dataValue: "libri_aggiornamento",
            checked: false
        },
        {
            label: "Nuovi libri aggiunti",
            id: "notifiche_nuovi-libri-aggiunti",
            dataValue: "libri_nuovi",
            checked: false
        }
    ],
    themeAreas: [
        {
            name: 'Fisco e imprese',
            id: 'FiscoEImprese',
            class: 'clr-fis',
            imgPath: '/dist/img/area-fisco.svg',
            link: '/Area/FiscoEImprese'
        },
        {
            name: 'Diritto',
            id: 'Diritto',
            class: 'clr-dir',
            imgPath: '/dist/img/area-diritto.svg',
            link: '/Area/Diritto'
        },
        {
            name: 'Lavoro',
            id: 'Lavoro',
            class: 'clr-lav',
            imgPath: '/dist/img/area-lavoro.svg',
            link: '/Area/Lavoro'
        },
        {
            name: 'PA',
            id: 'PA',
            class: 'clr-pa',
            imgPath: '/dist/img/area-pa.svg',
            link: '/Area/PA'
        },
        {
            name: 'Tecnici',
            id: 'Tecnici',
            class: 'clr-tec',
            imgPath: '/dist/img/area-tecnici.svg',
            link: '/Area/Tecnici'
        }
    ],
    linkScopriDiPiu:"https://24oreprofessionale.ilsole24ore.com/prodotti/book24ore/",
    types: ["nuovoLibro", "aggiornamentoFrizzera"],
    licenseKeyPdfExpress: 'wKtIu9xEKzdIgfRwV4Ng',
    ip: 'https://api.ipify.org?format=json'
};

export const config = process.env.REACT_APP_ENV === 'development' ? dev : process.env.REACT_APP_ENV === 'staging' ? staging : prod;