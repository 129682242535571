import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { config } from "../../constants/Constants";
import { cleanSpan, editDocument, existNoteForThisParagraph, updateNotes } from "../../helper/helperFunctionReader";
import useContextMenu from "../../hooks/useContextMenu";
import useManangeNoteAndTagOnReader from "../../hooks/useManageNoteAndTagOnReader";


const AddOrUpdateTags = (props) => {

    const [arrayTags, setArrayTags] = useState([]);
    const [arrayTagsNotEdit, setArrayTagsNoteEdit] = useState([]);
    const [inputTag, setInputTag] = useState('');
    const [notSaveDisabled, setNotSaveDisabled] = useState(false);
    const [arrayTagMostUsed, setArrayTagMostUsed] = useState(props.tagMostUsed);
    const [showCreateTag, setShowCreateTag] = useState(false);

    const newTag = useRef(null);
    const { cleanFunction, handleClick, id } = useContextMenu();
    const noteAndTag = useManangeNoteAndTagOnReader();

    let textCompleteToEdit = props.showAddUpdateTagNote.noteToUpd.textCompleteToEdit;
    let textToEdit = props.showAddUpdateTagNote.noteToUpd.textToEdit;

    handleClick();

    const handleClose = () => {
        props.closeModal({ showModal: false, noteToUpd: {} });
    };

    const deleteTag = (ev) => {

        ev.preventDefault();

        let tagToDelete = ev.currentTarget.dataset.value;

        let index = arrayTags.indexOf(tagToDelete);

        let newArrayTag = arrayTags;
        newArrayTag.splice(index, 1);

        setArrayTags([...newArrayTag]);
    }

    const addTag = (ev) => {

        ev.preventDefault();

        let tagToAdd = ev.currentTarget.dataset.value;

        if (tagToAdd === undefined) {
            tagToAdd = newTag.current.value;
            newTag.current.value = "";
            setInputTag("");
            setShowCreateTag(newTag.current.value !== "");
        }

        setArrayTags(prev => [...prev, tagToAdd]);
        setArrayTagMostUsed(props.tagMostUsed);
    }

    const addNewTagFromInput = () => {
        setInputTag(newTag.current.value);

        const result = props.tagMostUsed.filter(tag => tag.toLowerCase().includes(newTag.current.value.toLowerCase()))
        setArrayTagMostUsed(result);
        setShowCreateTag(newTag.current.value !== "");
    }

    const saveTagHandler = (ev) => {

        ev.preventDefault();

        let newNote = props.showAddUpdateTagNote.noteToUpd.noteToUpd;

        let editedText = document.getElementById("textComplete").innerHTML;

        //per update
        const noteToUpd = existNoteForThisParagraph(newNote.idNote, props.notes.data.note);

        let updatedNewNote = {};
        let documentToUpd = {};
        let updatedNotes = [];

        if (Object.keys(noteToUpd).length > 0) {
            noteToUpd.tag = arrayTags;
            updatedNotes = updateNotes(noteToUpd, props.notes);
        } else {
            let objEdit = editDocument(editedText, textToEdit, { start: newNote.startNote, end: newNote.endNote }, newNote.color, noteToUpd, props.document.titleDoc);
            objEdit.note.tag = arrayTags;
            updatedNewNote = {
                underlinedText: cleanSpan(objEdit.newDocument, { start: objEdit.note.startNote, end: objEdit.note.endNote },noteToUpd),
                ...objEdit.note,
                ...{
                    startPar: newNote.startPar,
                    endPar: newNote.endPar
                }
            };
            updatedNotes = updateNotes(updatedNewNote, props.notes);
            props.textWithNote.data.documentText = objEdit.newDocument;

            documentToUpd = {
                ...props.textWithNote
            };
        }

        const salvaNota = () => {

            const refreshDocument = () => {
                cleanFunction();
                //handleClose();
                props.refreshDocument({ showModal: false, noteToUpd: {} });
            };

            noteAndTag.saveOrUpdateNote(props.notes, updatedNotes, { prop1: props.document.idProvv, prop2: props.document.id, prop3: props.document.idDocType, prop4: props.document.mainTitle, prop5: props.document.titleDoc }, config.nameList.note, refreshDocument);

        };

        if (Object.keys(documentToUpd).length > 0)
            noteAndTag.updateDocument(documentToUpd, salvaNota);
        else
            salvaNota();

    }


    useEffect(() => {

        if (arrayTagsNotEdit.length !== arrayTags.length) {

            setNotSaveDisabled(true);
            return;
        }
        
        let setDisabled = false;
        arrayTags.forEach(tag => {
            
            if (arrayTagsNotEdit.indexOf(tag) === -1) {
                setNotSaveDisabled(true);
                setDisabled= true;
                return;
            }
        });
        
        if(!setDisabled)
            setNotSaveDisabled(false);

    }, [arrayTags]);

    useEffect(() => {

        let idNote = props.showAddUpdateTagNote.noteToUpd.noteToUpd.idNote;
        if (idNote === null) return;

        const indexNote = props.notes.data.note.map(val => val.idNote).indexOf(idNote);

        if(indexNote === -1) return;

        setArrayTagsNoteEdit([...props.notes.data.note[indexNote].tag]);
        setArrayTags([...props.notes.data.note[indexNote].tag]);

    }, [id])

    return (
        <React.Fragment>
            <Modal show={props.showAddUpdateTagNote.showModal} onHide={handleClose}>
                <div className="modal-content">
                    <a role="button" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                        <span className="icon icon-cross-small"></span>
                    </a>

                    <Modal.Header className="modal-header">
                        <h3 className="modal-title">Aggiungi Tag</h3>
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <input className="input" type="text" placeholder="Aggiungi tag" ref={newTag} onChange={addNewTagFromInput} maxLength={60} />

                        {showCreateTag &&
                            <div className="tag-btn" onClick={addTag}>
                                <a className="btn btn--round btn--icon-left" role="button">
                                    Crea <strong>{inputTag}</strong> <span className="icon icon-plus"></span>
                                </a>
                            </div>
                        }

                        {!showCreateTag &&
                            <ul className="list-inline tag-list-current">
                                {arrayTags.map((tag) => (
                                    <li className="list-inline-item" key={Math.random()}>
                                        <span className="tag tag--del">
                                            {tag}
                                            <a className="tag-del" role="button" data-value={tag} onClick={deleteTag} >
                                                <span className="icon icon-cross-small"></span>
                                            </a>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        }

                        <ul className="list tag-list-search">
                            {arrayTagMostUsed.map(tag => (
                                <li className="list-item" key={Math.random()}>
                                    <a className="search-opt " role="button" data-value={tag} onClick={addTag}>
                                        {tag}
                                    </a>
                                </li>
                            ))}
                        </ul>

                    </Modal.Body>

                    <div className="modal-footer">
                        <a className="btn btn--txt" role="button" onClick={handleClose}>Annulla</a>
                        <a className={`btn btn--black ${notSaveDisabled ? "" : "disabled"}`} role="button" onClick={saveTagHandler}>Salva</a>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default AddOrUpdateTags;