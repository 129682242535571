import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router";
import { config } from "../../../../../constants/Constants";
import useHttpRequest from "../../../../../hooks/http-request";
import TokenContext from "../../../../../store/token-context";
import AuthContext from "../../../../../store/auth-context";
import { formatArea } from "../../../../../helper/helper";

const FiltersByArea = (props) => {

    const arrayFilter = localStorage.getItem("filters")?.split(",");
    const { isFromAnotherPage } = useParams();

    const searchError = props.resultResearch === null || (props.testo === '' && isFromAnotherPage !== 'daMieiLibri');

    const authCtx = useContext(AuthContext);
    const sendRqst = useHttpRequest();
    const tokenCtx = useContext(TokenContext);

    const [fisco, setFisco] = useState(false);
    const [diritto, setDiritto] = useState(false);
    const [lavoro, setLavoro] = useState(false);
    const [PA, setPA] = useState(false);
    const [tecnici, setTecnici] = useState(false);

    const [areaFisco, setAreaFisco] = useState(false);
    const [areaDiritto, setAreaDiritto] = useState(false);
    const [areaLavoro, setAreaLavoro] = useState(false);
    const [areaPA, setAreaPA] = useState(false);
    const [areaTecnici, setAreaTecnici] = useState(false);
    const [areaTematicaUtente, setAreaTematicaUtente] = useState([]);

    const addOrRemoveFilter = (event) => {

        props.addOrRemoveFilter(event.target.value);
        setRightFilter(event.target.value);

    };

    const isChecked = (materia) => {

        if (arrayFilter === undefined || arrayFilter === null) return false;

        if (!arrayFilter.find(el => el === materia)) {
            return false;
        }

        return true;
    };

    const setRightFilter = (data) => {

        switch (data) {
            case "Fisco":
                setFisco(!fisco);
                break;
            case "Diritto":
                setDiritto(!diritto);
                break;
            case "Lavoro":
                setLavoro(!lavoro);
                break;
            case "PA":
                setPA(!PA);
                break;
            case "Tecnici":
                setTecnici(!tecnici);
                break;

        }
    };

    useEffect(() => {
        if(isFromAnotherPage !== 'daMieiLibri' && localStorage.getItem("MyBook") === "false"){
            setAreaTematicaUtente([]); return
        }
        const setAreaDisabled = (result) => {
            setAreaTematicaUtente(formatArea(result));

        }
        const bodyFormatted = {
            parameters: {
              'name': config.nameList.getAreeTematiche,
              'inputParameters': [],
              "staticToken": ""
            },
            "token": tokenCtx.token
          };
        const request = {
            url: config.url.BACKEND_SERVICE.concat(config.serviceToCall.getProdotti),
            req: {
                method: 'POST',
                headers:
                {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted)
            }
        };
        sendRqst.sendRequest(request, setAreaDisabled);
    }, [isFromAnotherPage, localStorage.getItem("MyBook"), tokenCtx.token]);

    useEffect(() => {
        if (props.isBack !== "1" && !props.clear && localStorage.getItem("mantieniIFiltri") === null) return;

        setFisco(isChecked("Fisco"));
        setDiritto(isChecked("Diritto"));
        setLavoro(isChecked("Lavoro"));
        setPA(isChecked("PA"));
        setTecnici(isChecked("Tecnici"));

        props.changeClear(false);
        
    }, [props.clear]);
    
    useEffect(() => {

        if(areaTematicaUtente.length === 0 && isFromAnotherPage !== "daMieiLibri"){
            setAreaFisco(false);
            setAreaDiritto(false);
            setAreaLavoro(false);
            setAreaPA(false);
            setAreaTecnici(false);
            return
        }
        if (areaTematicaUtente.find(el => el === "Fisco")) setAreaFisco(false); else setAreaFisco(true);
        if (areaTematicaUtente.find(el => el === "Diritto")) setAreaDiritto(false); else setAreaDiritto(true);
        if (areaTematicaUtente.find(el => el === "Lavoro")) setAreaLavoro(false); else setAreaLavoro(true);
        if (areaTematicaUtente.find(el => el === "PA")) setAreaPA(false); else setAreaPA(true);
        if (areaTematicaUtente.find(el => el === "Tecnici")) setAreaTecnici(false); else setAreaTecnici(true);
    }, [areaTematicaUtente]);

    return (
        <React.Fragment>
            <div className="search-side">
                <h4 className="search-side-title">Filtra per area</h4>
                <ul className="list list--large">
                    <li className="list-item">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="area_fisco-e-imprese" value='Fisco' onClick={addOrRemoveFilter} checked={fisco} disabled={searchError || areaFisco} />
                            <label className="custom-control-label" htmlFor="area_fisco-e-imprese">
                                Fisco e imprese
                            </label>
                        </div>
                    </li>
                    <li className="list-item">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="area_diritto" value='Diritto' onClick={addOrRemoveFilter} checked={diritto} disabled={searchError || areaDiritto} />
                            <label className="custom-control-label" htmlFor="area_diritto">
                                Diritto
                            </label>
                        </div>
                    </li>
                    <li className="list-item">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="area_lavoro" value='Lavoro' onClick={addOrRemoveFilter} checked={lavoro} disabled={searchError || areaLavoro} />
                            <label className="custom-control-label" htmlFor="area_lavoro">
                                Lavoro
                            </label>
                        </div>
                    </li>
                    <li className="list-item">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="area_pa" value='PA' onClick={addOrRemoveFilter} checked={PA} disabled={searchError || areaPA} />
                            <label className="custom-control-label" htmlFor="area_pa">
                                PA
                            </label>
                        </div>
                    </li>
                    <li className="list-item">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="area_tecnici" value='Tecnici' onClick={addOrRemoveFilter} checked={tecnici} disabled={searchError || areaTecnici} />
                            <label className="custom-control-label" htmlFor="area_tecnici">
                                Tecnici
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </React.Fragment >
    );
}

export default FiltersByArea;