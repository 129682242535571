import React, { useContext, useState } from "react";

import useHttpRequest from "../hooks/http-request";
import { config } from '../constants/Constants';
import TokenContext from "./token-context";

import { exportToArrayForFE } from "../helper/castingObject";
import { mustToken, settingArrayParams, settingArrayParamsWithAut } from "../helper/helper";
import AuthContext from "./auth-context";

const ManageProductListsContext = React.createContext({
    ultimeUscite: [],
    strumentiPerProfessionisti: [],
    continuaALeggere: [],
    proposteDiAbbonamento: [],
    allList: [],
    piuLetti: [],
    giaLetti: [],
    daLeggere: [],
    saveIsFinishToRead: []
});

const chooseName = (tipo, returnRightName) => {

    switch (tipo) {
        case (config.nameList.giaLetti):
            return tipo;
        case (config.nameList.daLeggere):
            return tipo;
        default:
            return returnRightName;
    }
};

export const ManageProductListsContextProvider = (props) => {

    const [ultimeUscite, setUltimeUscite] = useState([]);
    const [strumentiPerProfessionisti, setStrumentiPerProfessionisti] = useState([]);
    const [continuaALeggere, setContinuaALeggere] = useState([]);
    const [proposteDiAbbonamento, setProposteDiAbbonamento] = useState([]);
    const [piuLetti, setPiuLetti] = useState([]);
    const [allList, setAllList] = useState([]);
    const [giaLetti, setGiaLetti] = useState([]);
    const [daLeggere, setDaLeggere] = useState([]);
    const [saveIsFinishToRead, setSaveIsFinishToRead] = useState(null);


    const tokenCtx = useContext(TokenContext);
    const loginCtx = useContext(AuthContext);
    const sendRqst = useHttpRequest();

    //Senza Passare Dalla FindByQuery
    const getListConfig = (name, documenti, areaTematica, rows, tipo) => {

        const settingList = (name, arrayProduct, tipo) => {
            name = chooseName(tipo, name);
            switch (name) {
                case config.nameList.ultimeUscite:
                    if (rows !== undefined) {
                        setAllList(arrayProduct);
                    }
                    else {
                        setUltimeUscite(arrayProduct);
                    }
                    break;
                case config.nameList.continuaALegg:
                    setContinuaALeggere(arrayProduct);
                    break;
                case config.nameList.strumentiProf:
                    setStrumentiPerProfessionisti(arrayProduct);
                    break;
                case config.nameList.proposteAbbon:
                    setProposteDiAbbonamento(arrayProduct);
                    break;
                case config.nameList.listPiuLetti:
                    setPiuLetti(arrayProduct);
                    break;
                case config.nameList.giaLetti:
                    setGiaLetti(arrayProduct);
                    break;
                case config.nameList.daLeggere:
                    setDaLeggere(arrayProduct);
                    break;
                default:
                    break;
            }
        };


        const castInList = (data) => {

            let arrayProduct = [];

            if (data.Result.ResultSet !== null && data.Result.ResultSet !== undefined) {
                arrayProduct = exportToArrayForFE(name, data.Result.ResultSet.Root, documenti);
            }

            settingList(name, arrayProduct, tipo);
        };

        let arrayInputParams = settingArrayParams(documenti, areaTematica, rows);

        if (loginCtx.isLoggedIn && name === config.nameList.proposteAbbon) {
            let userModules = '';

            tokenCtx.userModulesWithId.forEach((el, index) => {

                userModules = userModules.concat(el['@id']);

                if (index < tokenCtx.userModulesWithId.length) {
                    userModules = userModules.concat(",");
                }

            });
            if(userModules){
                arrayInputParams = [...arrayInputParams,
                {
                    name: 'moduli_utente',
                    type: 'VarChar',
                    value: userModules
                }
                ];
            }
        }
        if(name === "BIBLIOTECA24_GetProposte"){
            arrayInputParams = [...arrayInputParams,
                {
                    name: 'loggato',
                    type: 'Int',
                    value: loginCtx.isLoggedIn ? 1 : 0
                }
            ];
        }
        let bodyFormatted = {
            parameters: {
                name: name,
                inputParameters: arrayInputParams,
                staticToken: mustToken(name) ? '' : config.token.STATIC_TOKEN,
            },
            token: mustToken(name) ? tokenCtx.token : '',
        };

        if (loginCtx.isLoggedIn && name === config.nameList.proposteAbbon && tokenCtx.token) {
            bodyFormatted.parameters.staticToken = '';
            bodyFormatted.token = tokenCtx.token;
        }

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };


        const response = sendRqst.sendRequest(request, castInList, false);

        if (response.error) {
            return response;
        }
    };

    const getFindByQueryConfig = (name, sort, themeArea) => {

        if(!tokenCtx.token) return false
        const castInList = (data) => {

            let provvedimenti = [];
            let iddoctype = [];
            let iddocumenti = [];
            let idToDelete = [];
            let documenti = [];
            let tipo;

            if (data.Result === null) getListWithAutConfig(name, null, undefined);

            data.Result.Documents.forEach(element => {

                provvedimenti.push({
                    idProv: element.data.idprovvedimento
                });
                iddoctype.push({
                    idDcTyp: element.data.iddoctype
                });
                iddocumenti.push({
                    idProv: element.data.idprovvedimento,
                    idDoc: element.data.iddocumento
                })
                idToDelete.push({
                    idProv: element.data.idprovvedimento,
                    idToDelete: element._id.$oid
                });
                tipo = element.tipo;
            });
            const letName = config.nameList.continuaALegg;

            documenti = {
                idProv: provvedimenti,
                idDoctype: iddoctype,
                idDoc: iddocumenti,
                idToDelete: idToDelete
            };

            getListWithAutConfig(letName, documenti, tipo);
        };

        let query = {
            tipo: name,
            appId: config.appId
        };

        if (sort === undefined) {
            query = { ...query, userKey: loginCtx.username }
        }

        const bodyFormatted = {
            excludeUserKey: sort === undefined ? false : true,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token ? tokenCtx.token : config.token.STATIC_TOKEN,
            encryptParams: sort === undefined ? ["query.userKey"] : null
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, castInList, false);
    };

    const getFindByQueryGiaLettoConfig = (name, sort, themeArea) => {

        const castInList = (data) => {

            let documenti = [];
            let provvedimenti = [];
            let iddoctype = [];
            let idDocumenti = [];
            let idToDelete = [];
            let tipo;

            data.Result.Documents.forEach(element => {

                provvedimenti.push({
                    idProv: element.data.idprovvedimento
                });
                iddoctype.push({
                    idDcTyp: element.data.iddoctype
                });

                idToDelete.push({
                    idProv: element.data.idprovvedimento,
                    idToDelete: element._id.$oid
                });

                idDocumenti.push({
                    idProv: element.data.idprovvedimento,
                    idDoc: element.data.iddocumento
                });

                tipo = element.tipo;
            });

            documenti = {
                idProv: provvedimenti,
                idDoctype: iddoctype,
                idToDelete: idToDelete
            };

            setSaveIsFinishToRead(idDocumenti)
            getListWithAutConfig(config.nameList.continuaALegg, documenti, tipo);
        };

        let query = {
            tipo: name,
            appId: config.appId
        };

        if (sort === undefined) {
            query = { ...query, userKey: loginCtx.username }
        }

        const bodyFormatted = {
            excludeUserKey: sort === undefined ? false : true,
            fields: {},
            pageNumber: 1,
            pageSize: 999,
            sort: null, //sort === undefined ? null : sort,
            query: query,
            getTotalDocs: false,
            token: tokenCtx.token ? tokenCtx.token : config.token.STATIC_TOKEN,
            encryptParams: sort === undefined ? ["query.userKey"] : null
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.find,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json-patch+json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, castInList, false);
    };

    const deleteProdToContinuaALeggereConfig = (idForDelete) => {

        const reloadContinuaALeggere = () => {
            getFindByQueryConfig(config.nameList.leggendo);
        };

        const bodyFormatted = {
            token: tokenCtx.token,
            id: idForDelete
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.delete,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, reloadContinuaALeggere, false);
    };

    const getListWithAutConfig = (name, documenti, tipo) => {


        const settingList = (name, arrayProduct, tipo) => {
            name = chooseName(tipo, name);
            switch (name) {
                case config.nameList.continuaALegg:
                    setContinuaALeggere(arrayProduct);
                    break;
                case config.nameList.leggendo :
                    setContinuaALeggere(arrayProduct);
                    break;
                case config.nameList.giaLetti:
                    setGiaLetti(arrayProduct);
                    break;
                case config.nameList.daLeggere:
                    setDaLeggere(arrayProduct);
                    break;
                default:
                    break;
            }
        };

        const castInList = (data) => {

            let arrayProduct = [];

            if (data.Result.ResultSet !== null && data.Result.ResultSet !== undefined) {
                arrayProduct = exportToArrayForFE(name, data.Result.ResultSet.Root, documenti);
            }


            settingList(name, arrayProduct, tipo);
        };


        if (documenti === null) settingList(name, [], undefined);

        const arrayInputParams = settingArrayParamsWithAut(documenti);

        const bodyFormatted = {
            parameters: {
                name: name,
                inputParameters: arrayInputParams,
                staticToken: mustToken(name) ? '' : config.token.STATIC_TOKEN,
            },
            token: mustToken(name) ? tokenCtx.token : '',
        };

        const request = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(request, castInList, false);
    };

    const contextValues = {
        getList: getListConfig,
        getFindByQuery: getFindByQueryConfig,
        deleteProdToContinuaALeggere: deleteProdToContinuaALeggereConfig,
        getFindByQueryGiaLetto: getFindByQueryGiaLettoConfig,
        ultimeUscite: ultimeUscite,
        strumentiPerProfessionisti: strumentiPerProfessionisti,
        continuaALeggere: continuaALeggere,
        proposteDiAbbonamento: proposteDiAbbonamento,
        allList: allList,
        piuLetti: piuLetti,
        giaLetti: giaLetti,
        daLeggere: daLeggere,
        saveIsFinishToRead: saveIsFinishToRead
    };

    return <ManageProductListsContext.Provider value={contextValues}>{props.children}</ManageProductListsContext.Provider>
}

export default ManageProductListsContext;