import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { config } from "../../../constants/Constants";
import { convertArrayForFEBuyOrNot } from "../../../helper/castingObject";
import { createImgLink } from "../../../helper/helper";
import { updateLink } from "../../../helper/TealiumHelper";
import useHttpRequest from "../../../hooks/http-request";
import AuthContext from "../../../store/auth-context";
import TokenContext from "../../../store/token-context";

const SchedaProdottoSpecifica = (props) => {

    const [pacchetti, setPacchetti] = useState([]);

    const authCtx = useContext(AuthContext);
    const img = validURL(encodeURI(props.img)) ? props.img : createImgLink(props.img);
    const tokenCtx = useContext(TokenContext);
    const sendRqst = useHttpRequest();

    useEffect(() => {

        if (props.isPacchetto || props.pack.length === 1) return;

        const settingsBuyOrNot = (data) => {

            if (data.Result.ResultSet === null) {
                setPacchetti([]);
                return;
            }

            setPacchetti(convertArrayForFEBuyOrNot(data.Result.ResultSet.Root));
        }

        const bodyFormatted = {
            "parameters": {
                "name": config.nameList.getPacchetto,
                "inputParameters": [{
                    "name": "nodi",
                    "type": "VarChar",
                    "value": props.packString
                }],
                "staticToken": authCtx.isLoggedIn ? "" : config.token.STATIC_TOKEN
            },
            "token": authCtx.isLoggedIn ? tokenCtx.token : ""
        };

        const rqs = {
            url: config.url.BACKEND_SERVICE + config.serviceToCall.getProdotti,
            req: {
                headers: {
                    'jsonorb-apikey': config.jsonorb.jsonorb_apikey,
                    'Content-Type': 'application/json',
                    'jsonorb-addcache': config.jsonorb.jsonorb_addCache
                },
                body: JSON.stringify(bodyFormatted),
                method: 'POST'
            }
        };

        sendRqst.sendRequest(rqs, settingsBuyOrNot);

    }, []);

    const showModalPacchettiHandle = () => {
        props.showModalPacchettiHandle(
            {
                settingBuyOrNot: pacchetti,
                showModal: true
            }
        );
    }
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }
    return (
        <React.Fragment>
            <div className="col-lg-4 order-lg-first d-none d-lg-block">
                <div className="sticky-wrapper">
                    
                        <div className="sticky sticky--side">


                            <figure className="card-img ">
                                {!props.isPacchetto &&
                                    <NavLink to={`/reader/${props.isBuyed ? props.idDocument : props.preview}`} onClick={(ev) => { updateLink({ title: "immagine-prodotto", name: "" }, props.titleContainer, props.titleTealium) }}>
                                        <span className="card-img-book">
                                            <img className="" src={props.img === "" ? config.imgDefaultLibro : img} alt="" />
                                        </span>
                                    </NavLink>
                                }

                                {props.isPacchetto &&
                                    <a className="btn btn--big btn--full" href={`${props.link === null ? '' : props.link}`}>
                                        <img className="card-img-full" src={props.img === "" ? config.imgDefaultPacch : img} alt="" />
                                    </a>
                                }
                            </figure>
                            {(props.isBuyed !== undefined) &&
                            <>
                            {(!props.isPacchetto && props.preview) &&
                                <>
                                    {!props.isBuyed &&
                                        <NavLink className="btn btn--big btn--full btn--shadow" to={`/reader/${props.preview}`} onClick={(ev) => { updateLink({ title: "Leggi Anteprima", name: "" }, props.titleContainer, props.titleTealium) }}>
                                            Leggi Anteprima
                                        </NavLink>
                                    }
                                    {props.isBuyed &&
                                        <NavLink className="btn btn--black btn--big btn--full btn--shadow" to={`/reader/${props.idDocument}`} onClick={(ev) => { updateLink({ title: "Leggi", name: "" }, props.titleContainer, props.titleTealium) }}>
                                            Leggi
                                        </NavLink>
                                    }
                                </>
                            }
                            {!props.isPacchetto && !props.isBuyed &&
                                <div className="btn-wlabel">
                                    {props.pack.length === 1 &&
                                        <NavLink className="btn btn--black btn--big btn--full btn--shadow" role={"button"} to={`/pacchetto/${props.pack}`} onClick={(ev) => { updateLink({ title: "Acquista", name: "" }, props.titleContainer, props.titleTealium) }}>
                                            Acquista
                                        </NavLink>
                                    }
                                    {props.pack.length > 1 &&
                                        <a className="btn btn--black btn--big btn--full btn--shadow" onClick={showModalPacchettiHandle}>
                                            Acquista
                                        </a>
                                    }

                                    <span className="btn-labelinfo">
                                        Acquistabile solo con pacchetto
                                    </span>
                                </div>
                            }
                            {(props.isPacchetto && (!props.isBuyed || !authCtx.isLoggedIn)) &&
                                <div>
                                    <a className="btn btn--big btn--full" href={`${props.link === null ? '' : props.link}`} onClick={(ev) => { updateLink({ title: "Acquista", name: "" }, props.titleContainer, props.titleTealium) }} target="_parent">
                                        Acquista
                                    </a>
                                </div>
                            }
                            </>
                        }
                        </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoSpecifica;