import React from "react";
import { createImgLink } from "../../../../helper/helper";
import SchedaProdottoAreaListaTematicaBody from "../SchedaProdottoAreaListaTematicaBody/SchedaProdottoAreaListaTematicaBody";
import SchedaProdottoAreaListaTematicaFooter from "../SchedaProdottoAreaListaTematicaFooter/SchedaProdottoAreaListaTematicaFooter";
import SchedaProdottoAreaListaTematicaHeader from "../SchedaProdottoAreaListaTematicaHeader/SchedaProdottoAreaListaTematicaHeader";

const SchedaProdottoAreaListaTematica = (props) => {

    const img = createImgLink(props.product.img);

    return (
        <React.Fragment>
            <div className="col">
                <SchedaProdottoAreaListaTematicaHeader
                    img={img}
                    id={props.product.id}
                    title={props.product.title}
                    isNew={props.product.isNew}
                    isSubscription={props.product.isSubscription}
                    titleContainer={props.titleContainer}
                >
                    <SchedaProdottoAreaListaTematicaBody
                        isSubscription={props.product.isSubscription}
                        title={props.product.title}
                        idProduct={props.product.id}
                        listAutori={props.product.auth}
                        img={img}
                        titleContainer={props.titleContainer}
                    >
                        <SchedaProdottoAreaListaTematicaFooter
                            themeArea={props.product.themeArea}
                            isUpdating={props.product.isUpdating}
                            titleContainer={props.titleContainer}
                        />
                    </SchedaProdottoAreaListaTematicaBody>
                </SchedaProdottoAreaListaTematicaHeader>
            </div>
        </React.Fragment>
    );
}

export default SchedaProdottoAreaListaTematica;