import React from 'react';
import Iframe from 'react-iframe'
import { createUrlPdf } from '../../../../../helper/helper';

const IFramePDF = (props) => {

    const url = createUrlPdf(props.pdf_summ);

    return(
        <React.Fragment>
            <Iframe url={url}
        width="640px"
        height="840px"
        id=""
        className=""
        display="block"
        position="relative"/>
        </React.Fragment>
    );
};

export default IFramePDF;